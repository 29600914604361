import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import HasVirtualInspectionTable from './HasVirtualInspectionTable'

const HasVirtualInspection = () => {
    
    return (
        <>
            <PageTitle>Virtual Inspections</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card className="shadow">
                    <Card.Body className="d-flex flex-column">
                        <HasVirtualInspectionTable />
                    </Card.Body>

                </Card>
            </div>
        </>

    )
}

export default HasVirtualInspection
