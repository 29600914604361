import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import ApiCalls from "../../../network/ApiCalls";
import * as Yup from 'yup'
import { useDialog } from "../../../contexts/DialogContext";
import { ForgotRequest } from "../../../network/PostRequestModels/UserRequest";


const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [success, setSuccess] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const { showFailureDialog, showSuccessDialog } = useDialog();

  const [errorText, setErrorText] = useState<string | undefined>('');

  const handleSendCodeClick = async () => {
    try {
      if (email) {
        setErrorText(undefined);
        setHasErrors(undefined)
        setLoading(true)
        let request: ForgotRequest = {
          email,
          userSectionType: "vendor"
        }
        await ApiCalls.forgotPassword(request);
        showSuccessDialog('Verification Code is sent to ' + email);
        setErrorText('');
        history.replace('/auth/login')
        setHasErrors(false)
        setLoading(false)
      } else {
        showFailureDialog('Email is not valid!');
        setLoading(false);
        showFailureDialog('Email is not valid!');


      }
    } catch (error: any) {
      showFailureDialog("Please check the email!");
      setErrorText(error?.response?.data?.message);
      setHasErrors(true)
      setLoading(false)
    }
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
        </div>
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            placeholder=''
            autoComplete='off'
            
            className='form-control'
          />

          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            </div>
          </div>

        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type="submit"
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            onClick={handleSendCodeClick}
            disabled={loading}
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
      </form>
    </>
  )
}
export default ForgotPassword;
