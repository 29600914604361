import {useDispatch, useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import ApiCalls from '../../network/ApiCalls'
import {useQuery} from 'react-query'
import dayjs from 'dayjs'
import {IVendorSubscriptionInvoice} from '../../interfaces/Subscribe'
import * as actionCreators from '../../redux/actionCreators/userActionCreators'
import {useEffect, useState} from 'react'
import {useDialog} from '../../contexts/DialogContext'
import {useHistory} from 'react-router-dom'
import {GetUserDetailsResponse} from '../../network/NetworkResponses/NetworkResponses'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import StripeContainer from '../subscription/Stripe/StripeContainer'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const Suspended = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const vendor = user.vendor
  const vendorId = user.userSection.vendorId
  const [invoice, setInvoice] = useState<IVendorSubscriptionInvoice>()
  const [loading, setLoading] = useState(false)
  const [showAddCardModal, setShowAddCardModal] = useState(false)
  const {showFailureDialog} = useDialog()

  const {
    data: cardList,
    isLoading: cardsLoading,
    error: cardListError,
    refetch: cardListFetching,
  } = useQuery(['Get Vendor Sources', vendorId], () => ApiCalls.getCustomerSources(), {
    cacheTime: 50000,
    refetchOnWindowFocus: false,
  })

  const {
    data: invData,
    isLoading: invLoading,
    error: invError,
    refetch: refetchSubscription,
  } = useQuery(['Get Vendor Subscriptions'], () => ApiCalls.getVendorSuspendedInvoice(vendorId), {
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!invLoading && invData?.data) {
      setInvoice(invData?.data)
    }
  }, [invData, invLoading])

  const getInvoiceStatus = () => {
    if (invoice) {
      if (invoice.invoiceStatus.name == 'Paid') {
        return <button className='btn btn-sm btn-success ms-3'>Paid</button>
      }
      if (invoice.invoiceStatus.name == 'Due') {
        return <button className='btn btn-sm btn-warning ms-3'>Due</button>
      }
      if (invoice.invoiceStatus.name == 'Cancelled' || invoice.invoiceStatus.name == 'Unpaid') {
        return <button className='btn btn-sm btn-danger ms-3'>{invoice.invoiceStatus.name}</button>
      }
    } else {
      return <></>
    }
  }

  const deleteSource = async (sourceId: string) => {
    try {
      await ApiCalls.deleteCustomerSource(sourceId)
      cardListFetching()
    } catch (error: any) {
      showFailureDialog(error?.data?.response?.message)
    }
  }

  const setDefaultSource = async (sourceId: string) => {
    try {
      await ApiCalls.putDefaultCustomerSource({source: sourceId})
      cardListFetching()
    } catch (error: any) {
      showFailureDialog(error?.data?.response?.message)
    }
  }

  const chargeInvoice = async () => {
    try {
      setLoading(true)
      await ApiCalls.chargeSubscriptionInvoice(invoice!.id)

      const user: GetUserDetailsResponse = await ApiCalls.getUserDetails()
      dispatch(actionCreators.getUserInfoSuccess(user.data))

      history.push('/dashboard')
    } catch (error: any) {
      setLoading(false)
      showFailureDialog(error?.response?.data.message)
    }
  }

  return (
    <>
      <PageTitle>Account Suspended</PageTitle>
      <div className='card mb-5 mb-xl-12'>
        <div className='card-body'>
          <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed mb-12 p-6'>
            <i className='ki-duotone ki-information fs-2tx text-danger me-4'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold'>
                <h4 className='text-gray-900 fw-bold'>We need your attention!</h4>

                <div className='fs-6 text-gray-700'>
                  Your account has been suspended because the payment for your invoice #
                  <b>{invoice?.invoiceNumber}</b> and dated{' '}
                  <b>{dayjs(invoice?.paymentDate).format('MMMM DD, YYYY')}</b> could not be received
                  from your defined card. Please make a payment so that you can continue to use your
                  account.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header card-header-stretch pb-0'>
          <div className='card-title'>
            <h3 className='m-0'>Payment Methods</h3>
          </div>
          <div className='card-toolbar m-0'>
            <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
              <li className='nav-item' role='presentation'>
                <div className='nav-link fs-5 fw-bold me-5 active'>Credit / Debit Card</div>
              </li>
            </ul>
          </div>
        </div>
        <div id='kt_billing_payment_tab_content' className='card-body tab-content'>
          <div
            id='kt_billing_creditcard'
            className='tab-pane fade show active'
            role='tabpanel'
            aria-labelledby='
            kt_billing_creditcard_tab'
          >
            <h3 className='mb-5'>My Cards</h3>
            <div className='row gx-9 gy-6'>
              {cardList?.data?.map((cl: any, index: number) => {
                return (
                  <div
                    className='col-xl-6'
                    data-kt-billing-element='card'
                    key={'card-item-' + index}
                  >
                    <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                      <div className='d-flex flex-column py-2'>
                        <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                          {cl.brand}
                          {cl.default && (
                            <span className='badge badge-light-success fs-7 ms-2'>Primary</span>
                          )}
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='me-4'>
                            <CreditLogos brand={cl.brand} />
                          </div>
                          <div>
                            <div className='fs-4 fw-bold'>** **** {cl.last4}</div>
                            <div className='fs-6 fw-semibold text-gray-400'>
                              Card expires at {cl.exp_month}/{cl.exp_year}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center py-2'>
                        {!cl.default && (
                          <button
                            className='btn btn-sm btn-light btn-active-light-primary'
                            onClick={() => {
                              setDefaultSource(cl.id)
                            }}
                          >
                            Set as Primary
                          </button>
                        )}
                        <button
                          className='btn btn-sm btn-danger ms-2'
                          onClick={() => {
                            deleteSource(cl.id)
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className='col-xl-6'>
                <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
                  <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                    <div className='mb-3 mb-md-0 fw-semibold'>
                      <h4 className='text-gray-900 fw-bold'>Add New Card</h4>

                      <div className='fs-6 text-gray-700 pe-7'>
                        The card information you have added is stored with the <br />
                        <strong>Stripe</strong> infrastructure.
                      </div>
                    </div>
                    <button
                      className='btn btn-primary px-6 align-self-center text-nowrap'
                      onClick={() => {
                        setShowAddCardModal(true)
                      }}
                    >
                      Add Card{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-body p-lg-20'>
          <div className='d-flex flex-column flex-xl-row'>
            <div className='flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0'>
              <div className='mt-n1'>
                <div className='d-flex flex-stack pb-10'>
                  <img alt='Logo' src='/media/logos/turnify-logo.png' width={160} />
                  <div>
                    {invoice?.invoiceStatus.name != 'Paid' &&
                      dayjs(invoice?.paymentDate).format('MM') == dayjs().format('MM') && (
                        <button
                          className='btn btn-sm btn-info'
                          disabled={loading}
                          onClick={() => {
                            chargeInvoice()
                          }}
                        >
                          Pay Now
                        </button>
                      )}
                    {getInvoiceStatus()}
                  </div>
                </div>
                <div className='m-0'>
                  <div className='fw-bold fs-3 text-gray-800 mb-8'>
                    Invoice #{invoice?.invoiceNumber}
                  </div>
                  <div className='row g-5 mb-11'>
                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Invoice Date:</div>

                      <div className='fw-bold fs-6 text-gray-800'>
                        {dayjs(invoice?.paymentDate).format('DD MMM YYYY')}
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Payment Date:</div>

                      <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                        <span className='pe-2'>
                          {dayjs(invoice?.paymentDate).format('DD MMM YYYY')}
                        </span>

                        {dayjs(invoice?.paymentDate).isAfter(dayjs()) && (
                          <span className='fs-7 text-danger d-flex align-items-center'>
                            <span className='bullet bullet-dot bg-danger me-2'></span>
                            Due in {dayjs(invoice?.paymentDate).diff(dayjs(), 'days')} days
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row g-5 mb-12'>
                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Issue For:</div>
                      <div className='fw-bold fs-6 text-gray-800'>{vendor?.name}</div>
                      <div className='fw-semibold fs-7 text-gray-600'>{vendor?.phoneNumber}</div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Issued By:</div>
                      <div className='fw-bold fs-6 text-gray-800'>TidyHost</div>
                      <div className='fw-semibold fs-7 text-gray-600'>
                        150 Orchard Court, Blue Bell, PA 19422
                        <br />
                        concierge@turnify.com <br />
                        (833)TDY-HOST
                      </div>
                    </div>
                  </div>
                  <div className='flex-grow-1'>
                    <div className='table-responsive border-bottom mb-9'>
                      <table className='table mb-3'>
                        <thead>
                          <tr className='border-bottom fs-6 fw-bold text-muted'>
                            <th className='min-w-175px pb-2'>Description</th>
                            <th className='min-w-70px text-end pb-2'>Quantity</th>
                            <th className='min-w-80px text-end pb-2'>Amount</th>
                            <th className='min-w-100px text-end pb-2'>Total Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {invoice?.vendorSubscriptionInvoiceItems.map((detail, index) => {
                            return (
                              <tr
                                className='fw-bold text-gray-700 fs-5 text-end'
                                key={'invoice-item-' + index}
                              >
                                <td className='d-flex align-items-center pt-6'>
                                  <i className='fa fa-genderless text-danger fs-2 me-2'></i>
                                  {detail.itemType.name}
                                </td>

                                <td className='pt-6'>{detail.quantity}</td>
                                <td className='pt-6'>${detail.grossAmount}</td>
                                <td className='pt-6 text-dark fw-bolder'>
                                  ${detail.totalGrossAmount}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <div className='mw-300px'>
                        <div className='d-flex flex-stack mb-3'>
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'>Subtotal:</div>

                          <div className='text-end fw-bold fs-6 text-gray-800'>
                            $ {invoice?.grossAmount}
                          </div>
                        </div>

                        <div className='d-flex flex-stack mb-3'>
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'>VAT</div>

                          <div className='text-end fw-bold fs-6 text-gray-800'>
                            $ {invoice?.tax}
                          </div>
                        </div>

                        <div className='d-flex flex-stack mb-3'>
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'>Subtotal + VAT</div>
                          <div className='text-end fw-bold fs-6 text-gray-800'>
                            $ {invoice?.netAmount}
                          </div>
                        </div>

                        <div className='d-flex flex-stack'>
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'>Total</div>
                          <div className='text-end fw-bold fs-6 text-gray-800'>
                            $ {invoice?.netAmount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showAddCardModal}
        onHide={() => {
          setShowAddCardModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Add New Card</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <StripeContainer resync={cardListFetching} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Suspended

type CreditProps = {
  brand: string | undefined
}
const CreditLogos: React.FC<CreditProps> = ({brand}) => {
  switch (brand) {
    case 'MasterCard':
      return <img src={toAbsoluteUrl('/media/logos/creditcard-logos/mc.png')} alt='mc' />
    case 'Visa':
      return (
        <img src={toAbsoluteUrl('/media/logos/creditcard-logos/visa_electron.png')} alt='visa' />
      )
    case 'AmericanExpress':
      return (
        <img src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express.png')} alt='ae' />
      )
    default:
      return (
        <img
          src={toAbsoluteUrl('/media/logos/creditcard-logos/american_express_green.png')}
          alt='defaultcard'
        />
      )
  }
}
