import { IHostPropertyOffer, IHostPropertyOffered, IPMSProperty } from "../../interfaces/Property";
import * as actions from "../actionTypes/hostPropertyTypes";

export interface HostPropertyState {
    hostPropertyList: IPMSProperty[],
    selectedCleaningProviderId?: number | null,
    propertySubmission?: {
        submissionError?: string | null,
        submitting: boolean
    },
    
    propertyDelete: {
        modalOpen: boolean
        propertyId: number | null
    }
    
    propertyOfferedDialog: {
        modalOpen: boolean,
        refetch: () => void,
        offeredService?: IHostPropertyOffer | null
    },
    approveEnabled?: boolean
    
}

const initialState: HostPropertyState = {
    hostPropertyList: [],
    selectedCleaningProviderId: null,
    propertySubmission: {
        submissionError: null,
        submitting: false
    },
    propertyDelete: {
        modalOpen: false,
        propertyId: null
    },
    propertyOfferedDialog: {
        modalOpen: false,
        refetch: () => { },
        offeredService: null
    },
    approveEnabled: false
}

export default function hostPropertyReducer(
    state: HostPropertyState = initialState,
    action: actions.HostPropertyAction
): HostPropertyState {
    switch (action.type) {
        case actions.GET_HOST_PROPERTY_LIST:
            return state;
        case actions.SET_HOST_PROPERTY_LIST:
            return { ...state, hostPropertyList: action.hostPropertyList }
        case actions.CLEAR_HOST_PROPERTY_LIST:
            return { ...state, hostPropertyList: [] }
        case actions.SET_HOST_PROPERTY_SUCCESS:
            return { ...state, hostPropertyList: action.hostPropertyList }
        case actions.SET_HOST_PROPERTY_FAILURE:
            return { ...state, hostPropertyList: [] }
        case actions.POST_HOST_PROPERTY_FORM:
            return { ...state, propertySubmission: { submissionError: null, submitting: true } }
        case actions.POST_HOST_PROPERTY_FORM_SUCCESS:
            return { ...state, hostPropertyList: [], propertySubmission: { submissionError: null, submitting: false } }
        case actions.POST_HOST_PROPERTY_FORM_FAILURE:
            return { ...state, propertySubmission: { submissionError: action.error, submitting: true } }
        case actions.GET_SELECTED_CLEANING_PROVIDER:
            return state;
        case actions.SET_SELECTED_CLEANING_PROVIDER:
            return { ...state, selectedCleaningProviderId: action.cleaningProviderId }
        case actions.CLEAR_SELECTED_CLEANING_PROVIDER:
            return { ...state, selectedCleaningProviderId: null }
        case actions.OPEN_PROPERTY_OFFERED_DIALOG:
            return { ...state, propertyOfferedDialog: { modalOpen: true, refetch: action.refetch, offeredService: action.offeredService } }
        case actions.CLOSE_PROPERTY_OFFERED_DIALOG:
            return { ...state, propertyOfferedDialog: { modalOpen: false, refetch: () => { }, offeredService: null } }
        case actions.SET_PROPERTY_APPROVE_ENABLED:
            return { ...state, approveEnabled: action.approveEnabled }
            case actions.OPEN_DELETE_PROPERTY_MODAL:
                return { ...state, propertyDelete: { modalOpen: true, propertyId: action.propertyId } }
            case actions.CLOSE_DELETE_PROPERTY_MODAL:
                return { ...state, propertyDelete: { modalOpen: false, propertyId: null } }
        default:
            return state;
    }
}