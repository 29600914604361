import {useHistory} from 'react-router-dom'
import {MarketplaceOfferListResponse} from '../../network/NetworkResponses/NetworkResponses'
import {encryptText} from '../../utils/util'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useEffect, useState} from 'react'
import MarketplaceQutoeRequestList from './MarketplaceQuoteRequestList'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import ApiCalls from '../../network/ApiCalls'
import {AxiosResponse} from 'axios'
import {useQuery} from 'react-query'
import {IMarketplaceOffer} from '../../interfaces/Marketplace'

type TabNames = 'new' | 'won' | 'lost' | 'pending'

const MarketplaceCard = () => {
  const history = useHistory()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [selectedTab, setSelectedTab] = useState<TabNames>('new')
  const [selectedStatus, setSelectedStatus] = useState<number>(1)
  const [offerData, setOfferData] = useState<IMarketplaceOffer[]>([])

  useEffect(() => {
    getOfferData(1)
  }, [])

  const handleChangeTab = (key: any) => {
    if (key == 'new') {
      getOfferData(1)
      setSelectedStatus(1)
    }
    if (key == 'pending') {
      getOfferData(2)
      setSelectedStatus(2)
    }
    if (key == 'won') {
      getOfferData(3)
      setSelectedStatus(3)
    }
    if (key == 'lost') {
      getOfferData(4)
      setSelectedStatus(4)
    }

    setSelectedTab(key)
  }

  const getOfferData = (id: number) => {
    ApiCalls.getVendorOffersByStatus({
      vendorId: user.userSection.vendorId,
      marketplaceOfferStatusTypeId: id,
    })
      .then((d: any) => {
        setOfferData(d.data.data)
      })
      .catch(() => {
        setOfferData([])
      })
  }

  return (
    <>
      <Card>
        <Card.Header className='align-items-center'>
          <Card.Title>All Requests</Card.Title>
        </Card.Header>
        <Card.Body>
          <Tabs defaultActiveKey='new' className='' onSelect={handleChangeTab}>
            <Tab eventKey='new' title='New'>
              {selectedTab === 'new' ? (
                <MarketplaceQutoeRequestList data={offerData} title='New' />
              ) : null}
            </Tab>
            <Tab eventKey='pending' title='Pending'>
              {selectedTab === 'pending' ? (
                <MarketplaceQutoeRequestList data={offerData} title='Pending' />
              ) : null}
            </Tab>
            <Tab eventKey='won' title='Won'>
              {selectedTab === 'won' ? (
                <MarketplaceQutoeRequestList data={offerData} title='Won' />
              ) : null}
            </Tab>
            <Tab eventKey='lost' title='Lost'>
              {selectedTab === 'lost' ? (
                <MarketplaceQutoeRequestList data={offerData} title='Lost' />
              ) : null}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  )
}

export default MarketplaceCard
