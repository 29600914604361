import {useCallback, useEffect, useRef, useState} from 'react'
import {
  Badge,
  Button,
  ButtonToolbar,
  Card,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {useDialog} from '../../contexts/DialogContext'
import {IStaffAddress} from '../../interfaces/Address'
import {ILocation} from '../../interfaces/Property'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import AutocompleteMap from '../../umut-components/Inputs/AutocompleteMap'
import MapZip from '../../umut-components/Map/GoogleMapZip.js'
import DeleteDialog from '../../umut-components/Modals/DeleteDialog'
import {getLocationByAddressForStaff} from '../../utils/util'
import ServiceCitiesTable from './ServiceCitiesTable'
var debounce = require('debounce')

const ServiceCities = () => {
  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)

  const {isLoading, refetch, data} = useQuery(
    ['Vendor Cities'],
    () => ApiCalls.getVendorCityService(vendorId),
    {refetchOnWindowFocus: false}
  )
  const formRef = useRef<any>(null)
  const {
    data: vendorInformation,
    isLoading: isLoadingInformation,
    error,
  } = useQuery(['Get Vendor Details by Id', vendorId], () => ApiCalls.getVendorById(vendorId), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
    enabled: vendorId ? true : false,
  })
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [defaultInput, setDefaultInput] = useState<any>()
  const [pastLocations, setPastLocations] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [inputLoading, setInputLoading] = useState(false)
  const [text, setText] = useState<string>('')
  const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({address: ' '})
  const [x, setx] = useState([])

  //with marker request
  const handleSave = useCallback((zipCode: string) => {
    try {
      setLoading(true)
      ApiCalls.createVendorCityService({
        vendorId: Number(vendorId),
        cityZipCodes: [zipCode],
      }).then((response: any) => {
        response?.data?.notAddedZipCodes.length == 0
          ? showSuccessDialog(
              'Repeat the process to enter more service areas you service. This will broaden your business and increase the number of jobs you can receive.'
            )
          : showFailureDialog(`Zipcode Not found: ${response?.data?.notAddedZipCodes}`)
        refetch()
        setLoading(false)
      })
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error?.response?.data?.message)
    }
  }, [])
  //with input request
  const handleInputAdded = () => {
    try {
      setInputLoading(true)
      ApiCalls.createVendorCityService({
        vendorId: Number(vendorId),
        cityZipCodes: [defaultInput],
      })
        .then((response: any) => {
          response?.data?.notAddedZipCodes.length == 0
            ? showSuccessDialog(
                'Repeat the process to enter more service areas you service. This will broaden your business and increase the number of jobs you can receive.'
              )
            : showFailureDialog(`Zipcode Not found: ${response?.data?.notAddedZipCodes}`)
          // setErrorMessage(`Zipcode Not found: ${response?.data?.notAddedZipCodes}`)
          refetch()
          if (formRef) {
            setDefaultInput(null)
            formRef.current.value = null
          }
          setInputLoading(false)
        })
        .catch((error: any) => {
          setInputLoading(false)
          setErrorMessage(error?.response?.data?.message)
        })
    } catch (error: any) {
      setInputLoading(false)
      setErrorMessage(error?.response?.data?.message)
    }
  }
  //with location request
  const handleAutocompleteAdded = () => {
    if (fullLocation && vendorId) {
      try {
        setInputLoading(true)
        ApiCalls.createVendorCityService({
          vendorId: Number(vendorId),
          cityZipCodes: [fullLocation?.zipCode],
        }).then((response: any) => {
          response?.data?.notAddedZipCodes.length == 0
            ? showSuccessDialog(
                'Repeat the process to enter more service areas you service. This will broaden your business and increase the number of jobs you can receive.'
              )
            : showFailureDialog(`Zipcode Not found: ${response?.data?.notAddedZipCodes}`)
          setErrorMessage(`Zipcode Not found: ${response?.data?.notAddedZipCodes}`)
          refetch()
          setText('')
          setInputLoading(false)
        })
      } catch (error: any) {
        setInputLoading(false)
        setErrorMessage(error?.response?.data?.message)
      }
    }
  }

  const handleInputChange = (event: any) => {
    setDefaultInput(event.target.value)
  }
  const handleSelectAddress = (e: string) => {
    setSelectedLocation({...selectedLocation, address: e})
    setText(e)
  }
  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '')
  }
  useEffect(() => {
    getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
  }, [selectedLocation])

  useEffect(() => {
    if (data ?? null) {
      setPastLocations(data?.data?.data)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className='w-100 justify-content-center'>
        <Spinner animation={'border'} />
      </div>
    )
  }
  return (
    <>
      <div>
        <PageTitle>Service Areas</PageTitle>

        <Card>
          <Card.Body className='row h-lg-600px'>
            <div className='col-lg-5 col-sm-12'>
              <MapZip
                pastLocations={pastLocations}
                handleSave={handleSave}
                pending={loading}
                center={{
                  lat: vendorInformation?.data?.cityLat,
                  lng: vendorInformation?.data?.cityLong,
                }}
              />
            </div>
            <div className='col-lg-7 col-sm-12 h-100'>
              <div className='h-5 mb-3 d-flex position-relative'>
                <div style={{zIndex: 1}} className='left-0 position-absolute mt-8 d-flex'>
                  <Dropdown>
                    <Dropdown.Toggle variant='success' size='sm' id='dropdown-basic'>
                      Add Manually
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='w-275px'>
                      <Dropdown.ItemText className='d-flex'>
                        <input
                          type='number'
                          placeholder='zip code ...'
                          ref={formRef}
                          className='form-control ml-3'
                          style={{height: 40}}
                          onChange={debounce(handleInputChange, 200)}
                        />
                        <button
                          type='button'
                          disabled={pastLocations?.some(
                            (item: any) => item.zipCode === defaultInput
                          )}
                          className='btn btn-sm btn-success rounded ms-3'
                          onClick={() => {
                            handleInputAdded()
                          }}
                        >
                          {inputLoading ? <Spinner animation='border' size='sm' /> : '+'}
                        </button>
                      </Dropdown.ItemText>
                      <Dropdown.ItemText className='d-flex'>
                        <AutocompleteMap
                          text={text}
                          setText={setText}
                          handleSelectAddress={handleSelectAddress}
                          handleOnError={handleAutocompleteError}
                        />
                        <button
                          type='button'
                          className='btn btn-sm btn-success rounded ms-3'
                          onClick={handleAutocompleteAdded}
                        >
                          {inputLoading ? <Spinner animation='border' size='sm' /> : '+'}
                        </button>
                      </Dropdown.ItemText>
                    </Dropdown.Menu>
                  </Dropdown>

                  <ButtonToolbar>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-`}>
                          We need to know the cities you are willing to work in before we can match
                          you with clients in those areas. Add your service cities so Turnify can
                          find you clients within this area.
                        </Tooltip>
                      }
                    >
                      <span className='svg-icon svg-icon-primary svg-icon-2x d-flex align-items-center ms-3'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          xmlnsXlink='http://www.w3.org/1999/xlink'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                          version='1.1'
                        >
                          <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                            <rect x='0' y='0' width='24' height='24' />
                            <circle fill='#000000' opacity='0.3' cx='12' cy='12' r='10' />
                            <rect fill='#000000' x='11' y='10' width='2' height='7' rx='1' />
                            <rect fill='#000000' x='11' y='7' width='2' height='2' rx='1' />
                          </g>
                        </svg>
                      </span>
                    </OverlayTrigger>
                  </ButtonToolbar>
                </div>
              </div>
              <div className='mt-8 h-100' style={{overflowY: 'hidden'}}>
                <ServiceCitiesTable
                  data={pastLocations}
                  refetch={refetch}
                  vendorId={Number(vendorId)}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <DeleteDialog />
    </>
  )
}

export default ServiceCities
