import React, {useState} from 'react'
import StepWizard from 'react-step-wizard'
import JobDetails from '../../pages/job-details/JobDetails'
import AvailableJobDetails from '../../pages/jobs-available/Job/AvailableJobDetails'
import StepNav from '../StepNav/StepNav'
import Assignment from './Steps/Assignment'

type TabNames = 'job-details' | 'assignment' | 'staffs-and-roles' | 'checklists'

const JobsAvailableWizard = () => {
  const [selectedTab, setSelectedTab] = useState<TabNames>('job-details')

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  return (
    <>
      <StepWizard
        nav={<StepNav stepNames={['Job Details', 'Assignment']} />}
        transitions={{
          enterRight: '',
          enterLeft: '',
          exitRight: '',
          exitLeft: '',
        }}
      >
        <AvailableJobDetails goToStep={handleChangeTab} />
        <Assignment goToStep={handleChangeTab} />
      </StepWizard>
    </>
  )
}

export default JobsAvailableWizard
