import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import InspectionDetailDialog from './inspection/InspectionDetailDialog'
import VirtualInspectionTable from './VirtualInspectionTable'

const VirtualInspections = () => {
    return (
        <>
            <PageTitle>Virtual Inspection Jobs</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card >
                    <Card.Body className="d-flex flex-column">
                        <VirtualInspectionTable />
                    </Card.Body>
                </Card>
            </div>
            <InspectionDetailDialog />
        </>
    )
}

export default VirtualInspections
