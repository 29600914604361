import React, {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {CheckBox} from 'devextreme-react'
import {useParams} from 'react-router'
import {PermissionRequest} from '../../../network/PostRequestModels/Permission'
import * as staffActionCreators from '../../../redux/actionCreators/staffActionCreators'
import {Card, Spinner} from 'react-bootstrap-v5'
import {IHostPermission} from '../../../interfaces/Property'
import ProfilePhotoTemp from '../../Icons/ProfilePhotoTemp'
import {Link} from 'react-router-dom'
import {decryptText, encryptText, convertUTCDateToLocalDate} from '../../../utils/util'
import EmptyTable from '../../Icons/EmptyTable'
import {IExcludedStaff} from '../../../interfaces/Staff'
import StaffDialog from '../../../pages/staff/StaffDialog'
import {useDispatch} from 'react-redux'
import SkeletonBoxLoading from '../../Loading/SkeletonBoxLoading'

type Props = {
  hostId: number
  providerType: number | undefined
}

const PermissionStep: React.FC<Props> = ({hostId, providerType}) => {
  let {propertyId}: any = useParams()
  propertyId = propertyId ? decryptText(propertyId) : undefined
  const [reqLoading, setReqLoading] = useState({boolean: false, userId: null})
  const dispatch = useDispatch()
  const {
    data: staffData,
    isFetching: staffDataLoading,
    error: staffDataError,
    refetch: refetchStaff,
  } = useQuery(
    ['Staff Permission - Staff', hostId],
    () => ApiCalls.getPermissionVendors(propertyId),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )
  const {
    data: permissionData,
    isFetching: permissionDataLoading,
    error: permissionDataError,
    refetch,
  } = useQuery('Permissions', () => ApiCalls.getAcceptedProperties(propertyId), {
    refetchOnWindowFocus: false,
  })
  const {
    data: excludedStaffs,
    isLoading: excludedLoading,
    error: excludedError,
    refetch: refetchExcluded,
  } = useQuery(['Get Excluded Staff', propertyId], () =>
    ApiCalls.getExcludedStaffByPropertyId(propertyId)
  )

  const onCheckAll = async () => {
    let permissionRequest: PermissionRequest[] = []
    staffData?.data?.data.map((item: IHostPermission, idx: number) => {
      var userId: any = item.userId
      var roleId: any = item.roleId
      var vendorId: any = item.vendorId

      var hasPermission = permissionData?.data?.data.some(
        (permission: any) => permission.userId == item.userId && permission.roleId == item.roleId
      )
      if (!hasPermission) {
        permissionRequest.push({
          hostPropertyId: parseInt(propertyId),
          userId,
          roleId,
        })
      }
    })

    if (permissionRequest.length > 0) {
      for (const req of permissionRequest) {
        await ApiCalls.addPropertyPermission(req).catch((err) => err)
      }
      await refetch()
    }
  }

  const savePermissions = async (permission: boolean, userId: any, roleId: any, vendorId: any) => {
    try {
      const reqData: PermissionRequest = {
        hostPropertyId: parseInt(propertyId),
        userId,
        roleId,
      }
      let permissionRequest: PermissionRequest[] = [
        {
          hostPropertyId: parseInt(propertyId),
          userId,
          roleId,
        },
      ]
      if (permission) {
        setReqLoading({boolean: true, userId: userId})
        await ApiCalls.addPropertyPermission(reqData).catch((err) => err)
        await refetch()
        setReqLoading({boolean: false, userId: userId})
      } else {
        const permissionId = permissionData?.data?.data?.find(
          (permission: any) => permission.userId === userId && permission.roleId === roleId
        )
        setReqLoading({boolean: true, userId: userId})
        await ApiCalls.deletePropertyPermission(permissionId.id).catch((err) => err)
        await refetch()
        setReqLoading({boolean: false, userId: userId})
      }
    } catch (err: any) {}
  }
  const cancelExcluded = async (excludedId: number, userId: any) => {
    try {
      if (propertyId && userId && excludedId) {
        setReqLoading({boolean: true, userId: userId})
        await ApiCalls.deleteExcludeStaff(excludedId)
        await refetchExcluded()
        await refetchStaff()
        await refetch()
        setReqLoading({boolean: false, userId: userId})
      }
    } catch (error) {}
  }

  const renderPermissionBox = (item: IHostPermission, idx: number) => {
    let isPermitted = permissionData?.data?.data.some(
      (permission: any) => permission.userId == item.userId && permission.roleId == item.roleId
    )
    return (
      <Card
        key={idx}
        style={{width: '24rem'}}
        className={`border border-secondary mt-10 gap-3 shadow-sm p-5 ribbon ribbon-top bg-hover-light`}
      >
        {providerType === 2 && (
          <div
            style={{top: 2, left: 25}}
            className='ribbon-label text-center text-gray text-wrap fs-9 h-25px w-100px'
          >
            Turnify Pro
          </div>
        )}
        <div className='d-flex justify-content-end'>
          {reqLoading.boolean && reqLoading.userId == item.userId ? (
            <Spinner animation='grow' variant='warning' />
          ) : (
            <CheckBox
              value={isPermitted}
              onValueChange={(check: boolean) => {
                savePermissions(check, item.userId, item.roleId, item.vendorId)
              }}
            />
          )}
        </div>
        <div>
          <Link
            to={
              (item.hostId ? '/host-staff-details/' : '/vendor-staff-details/') +
              encryptText(item.id)
            }
            className='d-flex px-10 '
          >
            <div>
              <ProfilePhotoTemp
                width={75}
                height={75}
                fontSize={25}
                borderRadius='50%'
                profilePhotoPath={item?.profilePhotoPath}
                firstName={item.firstName ?? ''}
                lastName={item?.lastName ?? ''}
              />
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center ms-5'>
              <Card.Title>
                {item.firstName} {item.lastName}
              </Card.Title>
              <span className='d-flex text-muted text-left w-100'>{item.roleName}</span>
            </div>
          </Link>
        </div>
        <Card.Body>
          <div className='mb-7'>
            {/* <div className="d-flex justify-content-between align-items-center my-3">
                            <span >Last Login:</span>
                            <span className={`badge badge-${item.lastLoginOn ? "success" : "warning"} badge-sm ms-2 `}>{convertUTCDateToLocalDate(item.lastLoginOn)}</span>
                        </div> */}
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span>Email:</span>
              <a
                href={`mailto:${item.email}`}
                className='text-muted d-flex align-items-center text-wrap'
              >
                {item.email}
              </a>
            </div>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span className='text-dark-75 font-weight-bolder mr-2'>Phone:</span>
              <a href={`tel:${item.phoneNumber}`} className='text-muted'>
                {item.phoneNumber}
              </a>
            </div>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span>Vendor:</span>
              <Link to={`/vendor-details/${item.vendorId}`}>
                <span className=''>{item.vendorName}</span>
              </Link>
            </div>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span className='text-dark-75 font-weight-bolder mr-2'>Status:</span>
              <span
                className={`badge badge-${
                  item?.accountStatusId == 1 ? 'success' : 'warning'
                } badge-sm ms-2 `}
              >
                {item?.accountStatusName}
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    )
  }
  type ExcludedProps = {
    item: IExcludedStaff
  }
  const ExcludedBox: FC<ExcludedProps> = ({item}) => {
    return (
      <Card
        style={{width: '24rem'}}
        className={`border border-secondary mt-10 gap-3 shadow-sm p-5 bg-hover-ligth`}
      >
        <div className='d-flex justify-content-end'>
          {reqLoading.boolean && reqLoading.userId == item?.userId ? (
            <Spinner animation='border' />
          ) : (
            <span
              onClick={() => {
                cancelExcluded(item?.id, item?.userId)
              }}
              className='svg-icon svg-icon-2x cursor-pointer fill-danger'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                width='24px'
                height='24px'
                viewBox='0 0 24 24'
                version='1.1'
              >
                <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                  <rect x='0' y='0' width='24' height='24' />
                  <path
                    d='M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M19.0710678,4.92893219 L19.0710678,4.92893219 C19.4615921,5.31945648 19.4615921,5.95262146 19.0710678,6.34314575 L6.34314575,19.0710678 C5.95262146,19.4615921 5.31945648,19.4615921 4.92893219,19.0710678 L4.92893219,19.0710678 C4.5384079,18.6805435 4.5384079,18.0473785 4.92893219,17.6568542 L17.6568542,4.92893219 C18.0473785,4.5384079 18.6805435,4.5384079 19.0710678,4.92893219 Z'
                    fill='#000000'
                    fill-rule='nonzero'
                    opacity='0.3'
                  />
                </g>
              </svg>
            </span>
          )}
        </div>
        <div className='d-flex px-10 '>
          <div>
            <ProfilePhotoTemp
              width={75}
              height={75}
              fontSize={25}
              borderRadius='50%'
              profilePhotoPath={item?.profilePhotoPath}
              firstName={item.firstName ?? ''}
              lastName={item?.lastName ?? ''}
            />
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center ms-5'>
            <Card.Title>
              {item.firstName} {item.lastName}
            </Card.Title>
            <span className='badge badge-danger'>Excluded</span>
          </div>
        </div>
        <Card.Body>
          <div className='mb-7'>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span>Last Login:</span>
              <span
                className={`badge badge-${
                  item?.lastLoginOn ? 'success' : 'warning'
                } badge-sm ms-2 `}
              >
                {convertUTCDateToLocalDate(item?.lastLoginOn)}
              </span>
            </div>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span>Email:</span>
              <a
                href={`mailto:${item.email}`}
                className='text-muted d-flex align-items-center text-wrap'
              >
                {item.email}
              </a>
            </div>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span className='text-dark-75 font-weight-bolder mr-2'>Phone:</span>
              <a href={`tel:${item.phoneNumber}`} className='text-muted'>
                {item.phoneNumber}
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
    )
  }

  if (staffDataLoading || permissionDataLoading) {
    return (
      <div className='min-h-400px'>
        <SkeletonBoxLoading />
      </div>
    )
  }

  if (staffData?.data?.data?.length === 0) {
    return (
      <>
        <EmptyTable title="You have not added any staff members to your Turnify account yet. To grant access to staff members for this property, please navigate to the 'Staff' menu and add new staff members to your account." />
      </>
    )
  }

  return (
    <>
      <div className='mt-5 mb-5'>
        <span className='text-danger'>
          Select which staff members are allowed to access the property and perform their duties. By
          checking the box next to their name, you grant them access to the property and ensure they
          receive notifications for any jobs created for the property. If a staff member is not
          selected, they will not be notified about any jobs created for this property.
        </span>
      </div>
      <div>
        {permissionData?.data?.data.length !== staffData?.data?.data.length && (
          <button
            className='btn btn-primary btn-sm me-5'
            onClick={() => {
              onCheckAll()
            }}
          >
            Select All
          </button>
        )}
      </div>
      <div className='row gap-10 d-flex justify-content-center'>
        {staffData?.data?.data.map((item: IHostPermission, idx: number) => (
          <>{renderPermissionBox(item, idx)}</>
        ))}
        {excludedStaffs?.data?.data.map((item: IExcludedStaff) => (
          <>
            <ExcludedBox item={item} />
          </>
        ))}
      </div>
    </>
  )
}

export default PermissionStep
