import { yupResolver } from '@hookform/resolvers/yup'
import React, { Dispatch, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import * as Yup from 'yup'
import ApiCalls from '../../../network/ApiCalls'
import { AcceptStaffInviteRequest } from '../../../network/PostRequestModels'
import { UserAction } from '../../../redux/actionTypes/userActionTypes'
import * as ActionCreators from '../../../redux/actionCreators/userActionCreators';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const schema = Yup.object().shape({
    newPassword:Yup.string()
    //.matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number')
    .min(6, 'Password must be at least 6 character.')
    .required('Password is required'),
    newPasswordAgain:Yup.string().oneOf([Yup.ref('newPassword'),null],'Password must be matched'),
})

type ParamObj = {
    token:string
}
type PasswordInput = {
newPassword:string;
newPasswordAgain:string;
}

const defaultValues: PasswordInput = {
    newPassword: '',
    newPasswordAgain: '',
}

const ForgotPasswordConfirm:React.FC = () => {
     const dispatch = useDispatch<Dispatch<UserAction>>();
     const query = new URLSearchParams(useLocation().search);
     const token =query.get("token");
     const history = useHistory();
     const [acceptSuccess, setAcceptSuccess] = useState<boolean | null>(null);
     const [loading, setLoading] = useState(false);

     
    const { control, formState, handleSubmit, reset, register } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { isValid, dirtyFields, errors } = formState;

    const sendPassword = async (data: PasswordInput) => {
        try {
            setLoading(true);
            let request: AcceptStaffInviteRequest = {
                token: token ?? '',
                newPassword: data.newPassword
            }
            await ApiCalls.resetPassword(request);
            history.replace('/auth/login')
            setAcceptSuccess(true);
            setAcceptSuccess(true);
        }
        catch (err: any) {
            setAcceptSuccess(false);
        }
    }

    if (acceptSuccess === null) {
        return (
            <div className='rounded p-6 d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
                <form
                    className='form w-100 w-md-600px p-10 border rounded shadow'
                    onSubmit={handleSubmit(sendPassword)}
                    noValidate
                    id='kt_login_signin_form'
                >
                    <div className="mb-10 d-flex justfiy-content-center">
                        <img style={{ width: "100%" }} src="media/logos/turnify-logo.png" alt="turnify-logo" />
                    </div>
                    <div className='mb-10 text-center'>
                        <h1 className='text-dark mb-3'>Confirm Account</h1>
                    </div>
                    <div className='fv-row mb-10'>
                        <div className='d-flex justify-content-between mt-n5'>
                            <div className='d-flex flex-stack mb-2'>
                                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                            </div>
                        </div>
                        <input
                            {...register("newPassword")}
                            type='password'
                            autoComplete='off'
                            className='form-control form-control-lg form-control-solid'
                            required
                        />
                        <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.newPassword?.message}</p>
                    </div>
                    <div className='fv-row mb-10'>
                        <div className='d-flex justify-content-between mt-n5'>
                            <div className='d-flex flex-stack mb-2'>
                                <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password Again</label>
                            </div>
                        </div>
                        <input
                            {...register("newPasswordAgain")}
                            type='password'
                            autoComplete='off'
                            className='form-control form-control-lg form-control-solid'
                            required
                        />
                        <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.newPasswordAgain?.message}</p>
                    </div>

                    <div className='text-center'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-lg btn-primary w-100 mb-5'
                            disabled={!isValid || loading}
                        >
                            {!loading && <span className='indicator-label'>Continue</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        )
    }

    if (acceptSuccess) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh', fontSize: 40, fontWeight: 'bold' }}>
                <img alt="error" style={{ width: 150, height: 150 }} src={toAbsoluteUrl('/media/icons/success.png')} />
                Confirm Success
                <div className="d-flex justify-content-center flex-column flex-sm-row mt-5">
                <a>
                    <img onClick={() => {
                   /*dispatch(ActionCreators.logout());
                    history.replace('/auth/login');*/ 
                }}  style={{marginRight:"10px", width: 200,objectFit:"contain",background:"transparent" }} src={toAbsoluteUrl('/media/icons/Get-It-On-Google-Play-PNG-Clipart.png')} />
                </a>
                <a>
                    <img onClick={() => {
                    /*dispatch(ActionCreators.logout());
                    history.replace('/auth/login');*/
                }}  style={{ width: 200,objectFit:"contain",background:"transparent" }} src={toAbsoluteUrl('/media/icons/pstore.png')} />
                </a>
                </div>
                {/* <button className="btn btn-success" onClick={() => {
                    dispatch(ActionCreators.logout());
                    history.replace('/auth/login');
                }}>Back to Login Page</button> */}
            </div>
        )
    }

    if (!acceptSuccess) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh', fontSize: 40, fontWeight: 'bold' }}>
                <img alt="error" style={{ width: 150, height: 150 }} src={toAbsoluteUrl('/media/icons/danger.png')} />
                Error
                <button className="btn btn-danger" onClick={() => {
                    dispatch(ActionCreators.logout());
                    history.replace('/auth/login');
                }}>Back to Login Page</button>
            </div>
        )
    }


    return null;
}
export default ForgotPasswordConfirm
