import React, { Dispatch, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { IJob, IJobDetail } from '../../../../../interfaces/Job';
import ApiCalls from '../../../../../network/ApiCalls';
import { JobAction } from '../../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog';
import ServiceDialog from './ServiceDialog';
import ServiceTable from './ServiceTable';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import ReviewDialog from './ReviewTab/ReviewDialog';

type Props = {
    jobDetail: IJobDetail,
    jobEventsActive: boolean
}

const ServiceTab: React.FC<Props> = ({ jobDetail, jobEventsActive }) => {
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const { jobAvailableId } = useSelector((state: RedusxAppState) => state.jobAvailable);
    const { data, isLoading, error, refetch } = useQuery(['Get Job Services By Id Job Id', jobAvailableId], () => ApiCalls.getJobServices(jobAvailableId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobAvailableId ? true : false });

    if (isLoading) {
        return <SkeletonTableLoading />
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <ServiceTable data={data?.data?.data} refetch={refetch} jobEventsActive={jobEventsActive} />
            {
                jobEventsActive &&
                <>
                    {jobDetail ? <ServiceDialog jobDetail={jobDetail} /> : null}
                </>
            }
            <ReviewDialog />
        </>
    )
}

export default ServiceTab;