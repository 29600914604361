import {createContext, useContext, useState} from 'react'
import {IWindowContext} from '../interfaces/Window'

const WindowContext = createContext({})

export const WindowProvider = ({children}: any) => {
  const [windowWidth, setWindowWidth] = useState<number>(window?.innerWidth)

  const resizeWindowWidth = () => {
    setWindowWidth(window?.innerWidth)
  }

  window.addEventListener('resize', resizeWindowWidth)

  return <WindowContext.Provider value={{windowWidth}}>{children}</WindowContext.Provider>
}

export default WindowContext

export const useWindow = (): any => useContext(WindowContext)
