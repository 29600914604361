import React, { Dispatch, useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap-v5'
import { useSelector } from 'react-redux'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import JobDetails from '../../pages/job-details/JobDetails'
import { useParams } from 'react-router'
import { decryptText } from '../../utils/util'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { IJobDetail } from '../../interfaces/Job'
import ApiCalls from '../../network/ApiCalls'
import SwitchBox from '../../umut-components/Inputs/SwitchBox'
import { TextInput } from '../../umut-components/Inputs/TextInput'
import { Button } from '@material-ui/core'
import { useDialog } from '../../contexts/DialogContext'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  refetch: () => void
}

const ForceFinishDialog: React.FC<Props> = ({ modalOpen, handleClose, refetch }) => {
  const { jobDetail } = useSelector((state: RedusxAppState) => state.job)
  const { showSuccessDialog }: any = useDialog()
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [technicalDiff, setTechnicalDiff] = useState(false)
  const [note, setNote] = useState("")

  const [loading, setLoading] = useState<boolean>(false)

  let { jobId }: any = useParams()
  jobId = decryptText(jobId)

  const acceptRequest = async () => {
    if (jobDetail) {
      try {
        setLoading(true)
        var formData = new FormData()
        formData.append('jobId', jobDetail?.id.toString())
        formData.append('hasTechnicalDifficulties', technicalDiff.toString())
        formData.append('forceReason', note)
        formData.append('forceFinishMedia', imagePreview)
        await ApiCalls.postForceFinishRequest(formData)
        await refetch()
        showSuccessDialog('Request sent successfully')
        setLoading(false)
        handleClose()
      } catch (err: any) {
        setLoading(false)
        setErrorMessage(err?.response?.data?.errors?.[0].message ?? err?.response?.data?.message )
      }
    }

  }
  const changeImage = (e: any) => {
    try {
      setErrorMessage(null);
      var file = e.target.files[0];
      if (file.size <= 2097152) {
        setImagePreview(file)
      }
      else {
        setErrorMessage('Max file size is 2MB.');
      }
    }
    catch (err: any) {
      setErrorMessage('File cannot be uploaded.')
    }
  }


  return (
    <>
      <Modal show={modalOpen} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Force Finish</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-warning">Are you sure you want to make a finish request to this job?</Modal.Body>
        <div className="d-flex justify-content-center flex-column p-8">
          <label className='form-label fs-6 fw-bolder text-dark'>Note{<span style={{ color: "red" }}>*</span>}</label>
          <TextInput onValueChange={(text: string) => setNote(text)} placeholder="Note" />
          <SwitchBox classNames="mt-2" disabled={false} text="Has Technical diffculities ?" onCheckboxChange={(check: any) => setTechnicalDiff(check)} defaultChecked={false} />
          <div className="text-center d-flex flex-column">
            <Button
              className='mt-6'
              variant="contained"
              component="label"
            >
              + Add Photo
              <input
                type="file"
                accept="image/jpeg, image/png"
                onChange={changeImage}
                hidden
              />
            </Button>
            (Max Photo Size 2MB)
          </div>


        </div>
        <Modal.Footer className="d-flex flex-column align-items-end">
          <div>
            <span className="text-danger">{errorMessage}</span>
          </div>
          <div>
            <button className='btn btn-sm btn-danger' onClick={handleClose}>
              Close
            </button>
            <button disabled={loading} className='btn btn-success btn-sm ms-2' onClick={acceptRequest}>
              Finish Request {loading && <Spinner animation='border' size="sm" />}
            </button>
          </div>

        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ForceFinishDialog
