import * as deleteDialogActionCreators from '../../redux/actionCreators/deleteDialogActionCreators'
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import { useQuery } from 'react-query'
import { Spinner } from 'react-bootstrap-v5'
import { useSelector } from 'react-redux'
import ApiCalls from '../../network/ApiCalls'
import UserEditForm from '../../umut-components/Forms/UserEditForm'
import { RedusxAppState } from '../../redux/reducers/rootReducer'

type Props = {
    modalOpen: boolean,
    modalClose: () => void,
}   

const ProfileEdit: React.FC<Props> = ({ modalOpen, modalClose}) => {
    const {
        user: {
            user: { id },
        },
    } = useSelector((state: RedusxAppState) => state.user)
    const {
        data: userDetailData,
        isLoading: userDetailLoading,
        error: isUserDetailError,
        refetch
    } = useQuery(['Get Vendors Detail'], () => ApiCalls.getUserDetailsById(id), {
        refetchOnWindowFocus: false,
        enabled: id ? true : false
    })
    

    return (
        <Modal show={modalOpen} onHide={modalClose} size='xl' centered>
            <ModalHeader closeButton>
                <Modal.Title>Edit Vendor</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {userDetailLoading ? (
                    <Spinner animation='border' size='sm' />
                ) : (
                    <UserEditForm
                        user={userDetailData?.data}
                        userId={id}
                        successFunc={() => {
                            modalClose()
                            window.location.reload();
                          }}

                    />
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ProfileEdit
