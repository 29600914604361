import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useSelector} from 'react-redux'
import {useDialog} from '../../../contexts/DialogContext'
import ApiCalls from '../../../network/ApiCalls'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'

type Props = {
  description: string
  isVisible: boolean
  modalClose: () => void
  refetch: () => void
}

const VendorEditModal: React.FC<Props> = ({description, isVisible, modalClose, refetch}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [desc, setDesc] = useState<string>(description)
  const {showFailureDialog, showSuccessDialog} = useDialog()

  const descChange = (data: any) => {
    setDesc(data.target.value)
  }

  const updateDesc = () => {
    try {
      var requestData = {description: desc, vendorId: user.userSection.vendorId}
      ApiCalls.updateVendorDescription(requestData)
        .then(() => {
          showSuccessDialog('The vendor description successfully updated.')
          setTimeout(() => {
            modalClose()
            refetch()
          }, 200)
        })
        .catch(() => {
          showFailureDialog('An error occur during updating vendor description!')
        })
    } catch (error) {
      showFailureDialog('An error occur during updating vendor description!')
    }
  }

  return (
    <Modal show={isVisible} onHide={modalClose} size='xl' centered>
      <ModalHeader closeButton>
        <Modal.Title>Edit Vendor Description</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <textarea
          className='form-control'
          rows={5}
          value={desc}
          onChange={(e) => {
            descChange(e)
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-link text-muted me-4'
          onClick={() => {
            modalClose()
          }}
        >
          Cancel
        </button>
        <button
          className='btn btn-sm btn-danger'
          onClick={() => {
            updateDesc()
          }}
        >
          Update
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default VendorEditModal
