import * as vendorDetailActionCreators from '../../../redux/actionCreators/vendorDetailActionCreators'
import {Dispatch} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {PageTitle} from '../../../../_metronic/layout/core'
import ApiCalls from '../../../network/ApiCalls'
import {VendorDetailActions} from '../../../redux/actionTypes/vendorDetailActionTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import DocumentsTable from './VendorDocumentsTable'
import VendorDocumentDialog from './VendorDocumentDialog'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'

const VendorDocument = () => {
  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const {data, isLoading, refetch} = useQuery(
    ['Get Documents', vendorId],
    () => ApiCalls.getVendorDocuments(vendorId, null),
    {refetchOnWindowFocus: false}
  )
  const {
    documentsState: {ModalOpen},
    documentsState: {ModalData},
  } = useSelector((state: RedusxAppState) => state.vendorDetail)
  const {data: docTypes, isLoading: docIsLoading} = useQuery(
    ['Get Types'],
    () => ApiCalls.getVendorDocumentsTypes(),
    {refetchOnWindowFocus: false}
  )
  const showViewDispatch = useDispatch<Dispatch<VendorDetailActions>>()

  return (
    <>
      <PageTitle>Documents</PageTitle>
      {data?.data?.data.length == 0 && (
        <div className='row g-5 g-xl-8 mb-4'>
          <Card>
            <Card.Body className='text-danger'>
              <p>
                At Turnify, we continually strive to enhance the trust and reliability in our
                community. Becoming a Verified PRO can help you stand out to potential clients and
                build a more robust reputation on our platform.
              </p>
              <p>
                To become a Verified PRO, we ask that you provide us with two essential documents:
              </p>
              <ul>
                <li>
                  <b>W9 Form</b> – This IRS form allows us to accurately report payments made to
                  your services for tax purposes.
                </li>
                <li>
                  <b>Proof of Insurance</b> – A valid insurance certificate assures us and our
                  clients that you are fully insured for the work you provide.
                </li>
              </ul>
              <p>
                You can upload these documents directly through your Vendor Dashboard under the
                “Company Information” {'->'} “Documents” section.
              </p>
              <p>
                By becoming a Verified PRO, you enhance your visibility and credibility among
                Turnify clients, resulting in increased opportunities for work. Your status will be
                highlighted on your profile, and you’ll be prioritized in search results.
              </p>
            </Card.Body>
          </Card>
        </div>
      )}
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <DocumentsTable refetch={refetch} data={data?.data?.data} />
            )}
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <button
              className='btn btn-success'
              disabled={data?.data?.count >= 2}
              onClick={() =>
                showViewDispatch(vendorDetailActionCreators.openDocumentView(docTypes?.data?.data))
              }
              style={{height: '100%'}}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Document
            </button>
          </Card.Footer>
        </Card>
      </div>
      <DeleteDialog />
      {ModalOpen && (
        <VendorDocumentDialog
          refetch={refetch}
          existingDocuments={data?.data?.data.map((doc: any) => doc.documentTypeId)}
        />
      )}
    </>
  )
}

export default VendorDocument
