import {
  Column,
  DataGrid,
  Export,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
} from 'devextreme-react/data-grid'
import React, {Dispatch, FC, useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDialog} from '../../../contexts/DialogContext'
import ApiCalls from '../../../network/ApiCalls'
import * as jobAvailableCreators from '../../../redux/actionCreators/JobAvailableActionCreators'
import {JobAvailableActions} from '../../../redux/actionTypes/jobAvailableTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {
  onExporting,
  toAmazonUrl,
  convertUTCDateToLocalDate,
  toRequestTimeFormat,
} from '../../../utils/util'
import ApproveButton from '../../Buttons/ApproveButton'
import ArrowButton from '../../Buttons/ArrowButton'
import EmptyTable from '../../Icons/EmptyTable'
import ProfilePhotoTemp from '../../Icons/ProfilePhotoTemp'
import DateTimePicker from '../../Inputs/DateTimePicker'

type Props = {
  previousStep?: () => void
  goToStep: (key: any) => void
}

const Assignment: FC<Props> = ({previousStep, goToStep}) => {
  const {showSuccessDialog} = useDialog()
  const {
    hostPropertyId,
    jobAvailableId,
    selectedStaffs,
    roleId,
    refetch,
    cleaningPeriodStart,
    cleaningPeriodFinish,
    jobTimezone,
  }: any = useSelector((state: RedusxAppState) => state.jobAvailable)
  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const [scheduleStart, setScheduleStart] = useState(
    toRequestTimeFormat(jobTimezone, cleaningPeriodStart)
  )
  const {data, isLoading, error} = useQuery(
    ['Get Available Staff', hostPropertyId, scheduleStart],
    () => ApiCalls.getAvailableStaff(vendorId, roleId, hostPropertyId, scheduleStart),
    {cacheTime: 50000, enabled: hostPropertyId && scheduleStart ? true : false}
  )
  const dispatch = useDispatch<Dispatch<JobAvailableActions>>()
  const [control, setControl] = useState({error: '', loading: false})
  const tableRef = useRef<DataGrid>(null)

  useEffect(() => {
    return () => {
      dispatch(jobAvailableCreators.pushSelectedStaffs([]))
    }
  }, [])

  const handleAssign = async () => {
    if (selectedStaffs.length > 0)
      try {
        setControl({...control, loading: true})
        await ApiCalls.putSchedule({
          vendorId: vendorId,
          jobId: jobAvailableId,
          scheduledStart: scheduleStart ? scheduleStart : cleaningPeriodStart,
          cleanerIds: [...selectedStaffs],
        })
        refetch()
        showSuccessDialog('This job has been scheduled successfully')
        dispatch(jobAvailableCreators.closeJobAvailableDialog())
        setControl({...control, loading: false})
      } catch (error: any) {
        setControl({...control, error: error?.response?.data?.message})
      }
  }

  const onSelectionChanged = (cellValue: any) => {
    dispatch(
      jobAvailableCreators.pushSelectedStaffs(
        cellValue.selectedRowsData.map((element: any) => element.userId)
      )
    )
  }

  const renderProfilePhoto = (cellValue: any) => {
    return cellValue?.data?.profilePhotoPath ? (
      <img
        alt='pp'
        style={{width: 45, height: 45, borderRadius: '50%'}}
        src={
          cellValue?.data?.profilePhotoPath
            ? toAmazonUrl(cellValue?.data?.profilePhotoPath)
            : toAbsoluteUrl('/media/icons/noImage.png')
        }
      />
    ) : (
      <ProfilePhotoTemp
        firstName={cellValue?.data?.firstName}
        lastName={cellValue?.data?.lastName}
        width={45}
        height={45}
        borderRadius='50%'
      />
    )
  }
  const calculateFullName = (cellValue: any) => {
    return cellValue?.firstName + ' ' + cellValue?.lastName
  }

  const goBack = () => {
    goToStep(1)
  }

  return (
    <div className='position-relative'>
      {data?.data?.data?.length === 0 ? (
        <EmptyTable title='No staff available for this job' />
      ) : (
        <>
          <div style={{zIndex: 1}} className='position-absolute top-15'>
            <span
              className='fs-8 text-primary cursor-pointer'
              onClick={() => tableRef.current?.instance.clearFilter()}
            >
              Clear Filter
            </span>
          </div>
          <DataGrid
            id='availableStaffTable'
            keyExpr='id'
            onRowPrepared={(e) => {
              e.rowElement.classList.add('data-grid-row')
            }}
            dataSource={data?.data?.data}
            showBorders={false}
            ref={tableRef}
            showColumnLines={false}
            showRowLines={true}
            hoverStateEnabled={true}
            height={'calc(54vh - 60px)'}
            allowColumnResizing={true}
            onSelectionChanged={onSelectionChanged}
            onExporting={(e) => onExporting(e, 'Assignments')}
          >
            <Export enabled />
            <Selection mode='multiple' showCheckBoxesMode='always' />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />
            <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
            <Paging defaultPageSize={50} />

            <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />
            <Column
              dataField='firstName'
              calculateCellValue={calculateFullName}
              caption='Fullname'
              minWidth={280}
              dataType='string'
              cssClass='cls'
            />
            <Column
              dataField='roleName'
              caption='Role'
              minWidth={100}
              width={200}
              dataType='string'
              cssClass='cls'
            />
            <Column dataField='email' dataType='string' width={200} cssClass='cls' />
            <Column dataField='phoneNumber' dataType='string' width={180} cssClass='cls' />
          </DataGrid>
        </>
      )}
      <div className='d-flex justify-content-between flex-row w-100 mt-6'>
        <ArrowButton type='back' func={goBack} />
        <strong className='text-danger'>{control.error}</strong>

        <div className='d-flex gap-3 align-items-center'>
          <div className='d-flex justify-content-center flex-column'>
            <DateTimePicker
              onChange={(date: any) => {
                setScheduleStart(date)
              }}
              min={convertUTCDateToLocalDate(cleaningPeriodStart, jobTimezone)}
              defaultValue={convertUTCDateToLocalDate(cleaningPeriodStart, jobTimezone)}
              dateOutOfRangeMessage={`Schedule date is not correct.`}
            />
            <small className='text-muted'>
              Job time {convertUTCDateToLocalDate(cleaningPeriodStart, jobTimezone)}
            </small>
          </div>
          <div className='mb-4'>
            <ApproveButton
              disabled={selectedStaffs?.length === 0 || !scheduleStart || control.loading}
              spinner={control.loading}
              approveFn={() => {
                handleAssign()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Assignment)
