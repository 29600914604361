import React, { Dispatch, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { IJob, IJobNote } from '../../../../../interfaces/Job';
import ApiCalls from '../../../../../network/ApiCalls';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import NoteAddDialog from './NoteAddDialog';
import NoteItem from './NoteItem';

type Props = {
    jobEventsActive: boolean;
}

const NotesTab: React.FC<Props> = ({ jobEventsActive }) => {
    const { jobAvailableId } = useSelector((state: RedusxAppState) => state.jobAvailable);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const { data, isLoading, error, refetch } = useQuery(['Get Job Notes', jobAvailableId], () => ApiCalls.getJobNotes(jobAvailableId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobAvailableId ? true : false });

    const handleCloseModal = () => {
        setAddModalOpen(false);
    }

    const handleSuccess = () => {
        setAddModalOpen(false);
        refetch();
    }

    if (isLoading) {
        return <div className='d-flex justify-content-center p-6'>Loading...</div>
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <div className='row'>
                <div className='w-100 d-flex flex-column mt-10 ms-5'>
                    <div className='col d-flex flex-wrap' style={{ maxHeight: 'calc(50vh - 60px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        {data?.data?.jobNotesResult?.length === 0 && <div className='d-flex align-items-center text-muted fs-4'>No Job Notes Found</div>}
                        {data?.data?.jobNotesResult?.map((note: IJobNote) => <div className="col-sm-12 w-450px ms-5">
                            <NoteItem note={note} noteType='job' />
                        </div>
                        )}
                    </div>
                </div>
            </div>
            <NoteAddDialog modalOpen={addModalOpen} handleClose={handleCloseModal} handleSuccess={handleSuccess} />
        </>
    )
}

export default NotesTab;