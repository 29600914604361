import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import React, { FC } from 'react'
import { Card } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { PageTitle } from '../../../_metronic/layout/core';
import ApiCalls from '../../network/ApiCalls';
import { LiveJobResponse } from '../../network/NetworkResponses/NetworkResponses';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading';
import LiveTable from './LiveTable';

type Props = {
    type: "my-team" | "turnify"
}

const Live: FC<Props> = ({ type }) => {
    const { user } = useSelector((state: RedusxAppState) => state.user);
  
  
    const { data: liveData, isFetching: liveLoading, error: liveError } = useQuery<AxiosResponse<LiveJobResponse>>('Get Live Jobs', () => ApiCalls.getLiveJobs(user.userSection.vendorId), { cacheTime: 500000 });

    if (liveError) {
        return <div>Error</div>
    }

    return (
        <>
            <PageTitle>Live</PageTitle>
            <Card>
                <Card.Body>
                    {
                        liveLoading ? <SkeletonTableLoading /> :
                            liveData && <LiveTable data={liveData?.data.data} />
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default Live;