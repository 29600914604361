import React, {Dispatch, useEffect, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {JobStatusType} from '../../enums/JobEnum'
import ApiCalls from '../../network/ApiCalls'
import {setJobDetail} from '../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {decryptText, convertUTCDateToLocalDate} from '../../utils/util'
import DeclineScheduleDialog from './declineScheduleDialog'
import ForceFinishDialog from './forceFinishDialog'
import IssuesTab from './Tabs/IssuesTab/IssuesTab'
import NotesTab from './Tabs/NotestTab/NotesTab'
import PhotosTab from './Tabs/PhotosTab/PhotosTab'
import PropertyTab from './Tabs/PropertyTab/PropertyTab'
import ServiceTab from './Tabs/ServiceTab/ServiceTab'
import StaffTab from './Tabs/StaffTab/StaffTab'
import TasksTab from './Tabs/TasksTab/TasksTab'

type TabNames =
  | 'services'
  | 'photos'
  | 'notes'
  | 'tasks'
  | 'property'
  | 'vendor'
  | 'client'
  | 'staff'
  | 'issue'

const JobDetails = () => {
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const history = useHistory()
  const {jobDetail} = useSelector((state: RedusxAppState) => state.job)

  const [selectedTab, setSelectedTab] = useState<TabNames>('services')
  const [jobEventsActive, setJobEventsActive] = useState<boolean>(false)
  const [forceFinishModalOpen, setForceFinishModalOpen] = useState(false)
  const [declineScheduleModalOpen, setDeclineScheduleModalOpen] = useState(false)

  let {jobId}: any = useParams()
  jobId = decryptText(jobId)
  const {data, isLoading, error, refetch} = useQuery(
    'Get Job Detail',
    () => ApiCalls.getJobDetails(jobId),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }
  const closeTypeModal = () => {
    setForceFinishModalOpen(false)
    setDeclineScheduleModalOpen(false)
  }

  useEffect(() => {
    if (data) {
      setJobEventsActive(
        !(
          data?.data?.jobStatusType.name === JobStatusType.COMPLETED ||
          data?.data?.jobStatusType.name === JobStatusType.CANCELLED
        )
      )
      dispatch(setJobDetail(data.data))
    }
    return () => {
      dispatch(setJobDetail(null))
    }
  }, [data])

  const redirectToJob = () => {
    history.push({pathname: '/jobs-vendors'})
  }

  if (isLoading) {
    return <div className='d-flex justify-content-center p-6'>Loading...</div>
  }

  if (error) {
    return <div className='d-flex justify-content-center p-6'>Error</div>
  }

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Header className='border-bottom-1 py-5  gap-1'>
            <div className='d-flex align-items-start justify-content-center flex-column'>
              <strong className='fs-4'>{data?.data.hostProperty.address}</strong>
              <div className='mt-3 mb-5'>
                {/* <span className='badge badge-primary badge me-3'>{jobId}</span> */}
                <span className='badge badge-info me-3'>
                  {data?.data?.jobStatusType.name === 'Started'
                    ? 'In Progress'
                    : data?.data?.jobStatusType.name}
                </span>
                <span className='badge badge-warning me-3'>
                  {data?.data?.hostProperty.isMarketplace
                    ? 'Marketplace'
                    : data?.data?.hostProperty.cleaningProvider.name}
                </span>
                {data?.data?.scheduledStart ? (
                  <span className='badge badge-success me-3'>
                    {convertUTCDateToLocalDate(
                      data?.data?.scheduledStart,
                      data?.data?.hostProperty.city.timeZone
                    )}{' '}
                  </span>
                ) : (
                  <span className='badge badge-success me-3'>
                    {convertUTCDateToLocalDate(
                      data?.data?.cleaningPeriodStart,
                      data?.data?.hostProperty.city.timeZone
                    )}{' '}
                  </span>
                )}

                {data?.data?.scheduledFinish ? (
                  <span className='badge badge-danger'>
                    {convertUTCDateToLocalDate(
                      data?.data?.scheduledFinish,
                      data?.data?.hostProperty.city.timeZone
                    )}
                  </span>
                ) : (
                  <span className='badge badge-danger'>
                    {convertUTCDateToLocalDate(
                      data?.data?.cleaningPeriodEnd,
                      data?.data?.hostProperty.city.timeZone
                    )}
                  </span>
                )}
              </div>
            </div>

            <div className='d-flex align-items-center'>
              {jobDetail?.jobStatusType.name === JobStatusType.SCHEDULED && (
                <div>
                  <button
                    onClick={() => {
                      setDeclineScheduleModalOpen(true)
                    }}
                    type='button'
                    className='btn btn-sm btn-danger'
                    style={{float: 'right', marginLeft: '15px'}}
                  >
                    Decline Job
                  </button>
                </div>
              )}
              {(jobDetail?.jobStatusType.name === JobStatusType.SCHEDULED ||
                jobDetail?.jobStatusType.name === JobStatusType.STARTED) && (
                <div>
                  <button
                    onClick={() => {
                      setForceFinishModalOpen(true)
                    }}
                    type='button'
                    className='btn btn-sm btn-success'
                    style={{float: 'right', marginLeft: '15px'}}
                  >
                    Force Finish
                  </button>
                </div>
              )}
            </div>
          </Card.Header>
          <Card.Body className='py-2 min-h-200px'>
            <Tabs defaultActiveKey='services' className='' onSelect={handleChangeTab}>
              <Tab eventKey='services' title='Services'>
                {selectedTab === 'services' ? (
                  <ServiceTab jobDetail={data?.data} jobEventsActive={jobEventsActive} />
                ) : null}
              </Tab>
              <Tab eventKey='photos' title='Checklist'>
                {selectedTab === 'photos' ? <PhotosTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='notes' title='Notes'>
                {selectedTab === 'notes' ? <NotesTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='tasks' title='Tasks'>
                {selectedTab === 'tasks' ? <TasksTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='issue' title='Reported Issue'>
                {selectedTab === 'issue' ? <IssuesTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='property' title='Property Detail'>
                {selectedTab === 'property' ? (
                  <PropertyTab
                    jobEventsActive={jobEventsActive}
                    hostPropertyId={data?.data?.hostPropertyId}
                  />
                ) : null}
              </Tab>
              <Tab eventKey='staff' title='Staff Detail'>
                {selectedTab === 'staff'
                  ? data?.data && (
                      <StaffTab jobDetail={data?.data} jobEventsActive={jobEventsActive} />
                    )
                  : null}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
      {forceFinishModalOpen && (
        <ForceFinishDialog
          modalOpen={forceFinishModalOpen}
          handleClose={closeTypeModal}
          refetch={refetch}
        />
      )}
      {declineScheduleModalOpen && (
        <DeclineScheduleDialog
          modalOpen={declineScheduleModalOpen}
          handleClose={closeTypeModal}
          refetch={refetch}
          redirect={redirectToJob}
        />
      )}
    </>
  )
}

export default JobDetails
