import React, {Dispatch, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {VendorAction} from '../../redux/actionTypes/vendorTypes'
import ApiCalls from '../../network/ApiCalls'
import SelectInput from '../../umut-components/Inputs/SelectInput'
import {IVendorServiceRequest} from '../../network/PostRequestModels/VendorRequestForm'
import {useDialog} from '../../contexts/DialogContext'
import VendorServiceForm from './VendorServiceForm'

type Props = {
  visible: boolean
  handleClose: () => void
  refetch: () => void
}

type ServiceType = {
  label: String
  value: Number
}

const AddVendorService: React.FC<Props> = ({visible, handleClose, refetch}) => {
  return (
    <Modal show={visible} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>Add Service</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <VendorServiceForm handleClose={handleClose} refetch={refetch} />
      </Modal.Body>
    </Modal>
  )
}

export default AddVendorService
