import {IVendorReference} from '../../interfaces/Reference'
import * as actions from '../actionTypes/vendorDetailActionTypes'

export interface vendorDetailState {
  referenceState: {
    ModalOpen: boolean
    ModalData: IVendorReference | null
  }
  documentsState: {
    ModalOpen: boolean
    ModalData: any | null
  }
}

const initialState: vendorDetailState = {
  referenceState: {
    ModalOpen: false,
    ModalData: null,
  },
  documentsState: {
    ModalOpen: false,
    ModalData: null,
  },
}

export default function vendorDetailReducer(
  state: vendorDetailState = initialState,
  action: actions.VendorDetailActions
) {
  switch (action.type) {
    case actions.REFERENCE_OPEN_VIEW:
      return {...state, referenceState: {ModalOpen: true, ModalData: action.vendor}}
    case actions.REFERENCE_CLOSE_VIEW:
      return {...state, referenceState: {ModalOpen: false, ModalData: null}}
    case actions.DOCUMENT_OPEN_VIEW:
      return {...state, documentsState: {ModalOpen: true, ModalData: action.documentTypes}}
    case actions.DOCUMENT_CLOSE_VIEW:
      return {...state, documentsState: {ModalOpen: false, ModalData: null}}
    default:
      return state
  }
}
