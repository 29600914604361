import * as actions from '../actionTypes/deleteDialogTypes'

export interface DeleteState {
  deleteFn?: () => Promise<void>
  modalOpen: boolean
  bodyText: string | null
  title: string | null
}

const initialState: DeleteState = {
  deleteFn: undefined,
  modalOpen: false,
  bodyText: null,
  title: null,
}

export default function deleteDialogReducer(
  state: DeleteState = initialState,
  action: actions.DeleteActions
): DeleteState {
  switch (action.type) {
    case actions.OPEN_DELETE_DIALOG:
      return {
        ...state,
        deleteFn: action.deleteFn,
        bodyText: action.bodyText,
        title: action.title,
        modalOpen: true,
      }
    case actions.CLOSE_DELETE_DIALOG:
      return {...state, modalOpen: false, deleteFn: undefined, bodyText: null, title: null}
    default:
      return state
  }
}
