import React, {Dispatch} from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import SelectBox from '../../Inputs/SelectBox'
import {TextInput} from '../../Inputs/TextInput'
import {useDispatch, useSelector} from 'react-redux'
import {useDialog} from '../../../contexts/DialogContext'
import {IQuestion} from '../../../interfaces/Checklist'
import {ChecklistAction} from '../../../redux/actionTypes/checklistTypes'
import * as checklistActionCreator from '../../../redux/actionCreators/checklistActionCreators'
import SwitchBox from '../../Inputs/SwitchBox'
import {Button} from '@material-ui/core'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import SelectInput from '../../Inputs/SelectInput'
import {AxiosResponse} from 'axios'
import {LanguageResponse} from '../../../network/NetworkResponses/NetworkResponses'
import DeleteButton from '../../Buttons/DeleteButton'
import {LobRoleNameEnum} from '../../../enums/UserRoles'
import {ChecklistQuestionAddRequest} from '../../../network/PostRequestModels/ChecklistRequests'

type Props = {
  checklistId: string
  cleaningProviderName: string
}

type LanguageOptions = {
  label: string
  value: string
}

type NameInput = {
  code: string
  description: string
  title: string
}

const QuestionAddForm: React.FC<Props> = ({checklistId, cleaningProviderName}) => {
  const {showSuccessDialog} = useDialog()
  const {questionAddDialog, checklistItems} = useSelector(
    (state: RedusxAppState) => state.checklist
  )
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const [questionRequest, setQuestionRequest] = useState<ChecklistQuestionAddRequest>({
    hostPropertyChecklistId: parseInt(checklistId),
    hostPropertyChecklistSectionId: questionAddDialog.selectedSection?.id,
    isPhotoMandatory: true,
    orderIndex: questionAddDialog.selectedSection
      ? questionAddDialog.selectedSection.questions.length
      : 0,
    hasVirtualInspection: false,
  } as ChecklistQuestionAddRequest)
  const [imagePreview, setImagePreview] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [checklistAnswerTypes, setChecklistAnswerTypes] = useState([])
  const [questionNames, setQuestionNames] = useState<NameInput[]>([
    {code: 'en', description: '', title: ''},
  ])
  const [languages, setLanguages] = useState<LanguageOptions[]>([])
  const {
    data: answerTypesData,
    isLoading: answerTypesLoading,
    error: answerTypesError,
  } = useQuery('Get Answer Types', () => ApiCalls.getChecklistAnswerTypes(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })
  const {
    data: languageData,
    isLoading: languageLoading,
    error: languageError,
  } = useQuery<AxiosResponse<LanguageResponse>>('Get Langauges', () => ApiCalls.getLanguages(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const handleQuestionAdd = async () => {
    try {
      if (questionRequest.checklistAnswerTypeId) {
        const isNamesValid =
          !questionNames.some(
            (name) =>
              name.title === '' || name.description === '' || !name.title || !name.description
          ) && questionNames.length > 0
        if (isNamesValid) {
          setLoading(true)
          setErrorMessage(null)
          var formData = new FormData()
          const titles = questionNames.reduce(
            (total, currentName) => ({...total, [currentName.code]: currentName.title}),
            {}
          )
          const descriptions = questionNames.reduce(
            (total, currentName) => ({...total, [currentName.code]: currentName.description}),
            {}
          )
          formData.append('title', JSON.stringify(titles))
          formData.append('description', JSON.stringify(descriptions))
          formData.append(
            'hostPropertyChecklistId',
            questionRequest.hostPropertyChecklistId.toString()
          )
          formData.append(
            'hostPropertyChecklistSectionId',
            questionRequest.hostPropertyChecklistSectionId.toString()
          )
          formData.append('checklistAnswerTypeId', questionRequest.checklistAnswerTypeId.toString())
          formData.append('orderIndex', questionRequest.orderIndex.toString())
          formData.append('isPhotoMandatory', questionRequest.isPhotoMandatory.toString())
          formData.append(
            'hasVirtualInspection',
            questionRequest.isPhotoMandatory
              ? questionRequest.hasVirtualInspection!.toString()
              : 'false'
          )
          formData.append('samplePhoto', questionRequest.samplePhoto)
          const {checklistAnswerTypeId, isPhotoMandatory} = questionRequest
          if (
            checklistAnswerTypeId !== undefined &&
            isPhotoMandatory !== undefined &&
            isNamesValid
          ) {
            await ApiCalls.addQuestion(formData)
            dispatch(checklistActionCreator.closeQuestionAddDialog())
            dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)))
            setLoading(false)
            showSuccessDialog('Question Added.')
          } else {
            setLoading(false)
            setErrorMessage('All fields are required.')
          }
        } else {
          setErrorMessage('Please fill all titles and descriptions.')
        }
      } else {
        setErrorMessage('Please select answer type.')
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  const changeImage = (e: any) => {
    try {
      setErrorMessage(null)
      var file = e.target.files[0]
      if (file.size <= 2097152) {
        setQuestionRequest({...questionRequest, samplePhoto: file})
        if (file) {
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            setImagePreview(reader.result)
          }
        } else {
          setImagePreview(null)
        }
      } else {
        setErrorMessage('Max file size is 2MB.')
      }
    } catch (err: any) {
      setErrorMessage('File cannot be uploaded.')
    }
  }

  const handleChangeLanguage = (languageCode: string, index: number) => {
    let newQuestionNames = [...questionNames]
    newQuestionNames[index].code = languageCode
    setQuestionNames(newQuestionNames)
  }

  const handleChangeDescription = (newText: string, index: number) => {
    let newQuestionNames = [...questionNames]
    newQuestionNames[index].description = newText
    setQuestionNames(newQuestionNames)
  }

  const handleChangeTitle = (newText: string, index: number) => {
    let newQuestionNames = [...questionNames]
    newQuestionNames[index].title = newText
    setQuestionNames(newQuestionNames)
  }

  const handleAddName = () => {
    let newName: NameInput = {code: 'en', description: '', title: ''}
    setQuestionNames([...questionNames, newName])
  }

  const removeName = (removeIndex: number) => {
    if (questionNames.length > 1) {
      setQuestionNames(questionNames.filter((name, index) => index !== removeIndex))
    } else {
      setQuestionNames([{code: 'en', description: '', title: ''}])
    }
  }

  useEffect(() => {
    if (answerTypesData) {
      setChecklistAnswerTypes(
        answerTypesData?.data?.data?.map((type: any) => ({label: type.name, value: type.id}))
      )
    }
  }, [answerTypesData])

  useEffect(() => {
    if (languageData) {
      setLanguages(
        languageData.data.data.map((language) => ({label: language.name, value: language.code}))
      )
    }
  }, [languageData])

  if (answerTypesLoading || languageLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <form className='form w-100'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          {questionNames.map((name, index) => (
            <div className='row mt-6'>
              <div className='col-5'>
                <label className='w-100 form-label fs-6 fw-bolder text-dark'>
                  Language #{index + 1}
                </label>
                <SelectInput
                  options={languages}
                  defaultValue={name.code}
                  onValueChanged={(value) => handleChangeLanguage(value, index)}
                />
              </div>
              <div className='col-6'>
                <label className='w-100 form-label fs-6 fw-bolder text-dark'>
                  Title #{index + 1}
                </label>
                <input
                  value={name.title}
                  onChange={(e) => handleChangeTitle(e.target.value, index)}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  required
                />
              </div>
              <div className='col-1 d-flex align-items-end'>
                <DeleteButton
                  iconWidth='20'
                  iconHeight='20'
                  classNames='btn btn-link btn-sm text-danger'
                  deleteFn={() => removeName(index)}
                />
              </div>
              <div className='col-12 border-bottom pb-6 mt-3'>
                <label className='w-100 form-label fs-6 fw-bolder text-dark'>
                  Description #{index + 1}
                </label>
                <textarea
                  style={{minHeight: 90}}
                  value={name.description}
                  onChange={(e) => handleChangeDescription(e.target.value, index)}
                  className='form-control'
                  autoComplete='off'
                  required
                />
              </div>
            </div>
          ))}
          <button type='button' className='btn btn-info btn-sm w-100 mt-3' onClick={handleAddName}>
            Add checklist item name in different language
          </button>

          <label className='w-100 form-label fs-6 fw-bolder text-dark mt-6'>Answer Type</label>
          <small className='text-muted'>
            What type of response are you expecting from your cleaner for this task? For example,
            select "number" to require a numeric response, such as "How may spoons are there?"
            answer "4".
          </small>
          <SelectInput
            classNames='mt-2'
            defaultValue={questionRequest.checklistAnswerTypeId}
            options={checklistAnswerTypes}
            onValueChanged={(value: any) =>
              setQuestionRequest({...questionRequest, checklistAnswerTypeId: value})
            }
          />

          <div className='col-lg-12 mt-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Photo Required?</label>
            <SwitchBox
              text='photos are great, but adding too many to a checklist could result in your team taking more time during the cleanings to upload them. Be concise, it will help your team tremendously!'
              mutedText={true}
              defaultChecked={true}
              onCheckboxChange={(check: boolean) =>
                setQuestionRequest({...questionRequest, isPhotoMandatory: check})
              }
            />
          </div>
          {questionRequest.isPhotoMandatory && (
            <div className='col-lg-12 mt-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Do you want this checklist item to be inspected virtually?{' '}
                <span className='text-danger'>*</span>
                <small className='text-muted'>additional fees may apply.</small>
              </label>
              <SwitchBox
                text='Want an extra set of eyes? Turn on Virtual Inspection for this room and Turnify virtual inspector will ensure that the room is prepared to your spec. *Additional charges may apply'
                mutedText={true}
                defaultChecked={questionRequest.hasVirtualInspection ?? false}
                onCheckboxChange={(check: boolean) =>
                  setQuestionRequest({...questionRequest, hasVirtualInspection: check})
                }
              />
            </div>
          )}

          <div className='row mt-6'>
            {imagePreview ? (
              <div className='d-flex justify-content-center'>
                <img
                  className='rounded'
                  style={{objectFit: 'contain', marginBottom: '6px', width: '100%'}}
                  alt='selected-sample'
                  src={imagePreview}
                />
              </div>
            ) : null}
            <Button className='col-lg-12' variant='contained' component='label'>
              Upload Image
              <input type='file' accept='image/jpeg, image/png' onChange={changeImage} hidden />
            </Button>
            <small className='text-muted mt-5'>
              Upload a photo to show your cleaners how you want your guests to see the room after
              this checklist item is completed.They will be encouraged to take a similar photo for
              you. (Max Photo Size 2MB)
            </small>
          </div>

          <div className='row mt-6'>
            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={loading}
                onClick={handleQuestionAdd}
              >
                Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col-12 text-end'>
              <strong style={{color: 'red'}}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default React.memo(QuestionAddForm)
