import {
    Column,
    DataGrid,
    Export,
    HeaderFilter, Paging,
    Scrolling,
    SearchPanel
} from 'devextreme-react/data-grid'
import { FC, useRef } from 'react'
import { useHistory } from 'react-router'
import { IPayments } from '../../interfaces/Payment'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import config from '../../config/config.json';
import { encryptText, getDateFilter, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../utils/util'

type Props = {
    data: IPayments[] | undefined,
    refetch: () => void
}
const UnpaidPaymentTable: FC<Props> = ({ data, refetch }) => {
    const history = useHistory();
    const tableRef = useRef<DataGrid>(null)


    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue?.job?.startedOn)
    }

    const calculateAmount = (cellValue: any) => {
        return '$' + cellValue.totalAmount;
    }

    const calculateJobLink = (cellValue: any) => {
        return <button className='btn btn-link btn-sm text-primary' onClick={() => {
            history.push('/job-details/' + encryptText(cellValue?.data?.job?.id));
        }}>Show Job</button>
    }

    if (data?.length === 0) {
        return <EmptyTable title='No Billings' />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>

            <DataGrid
                id='jobTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Payment")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />

                <Column dataField='hostProperty.name' caption="Property" minWidth={50} dataType="string" cssClass='cls' />

                <Column
                    dataField='totalAmount'
                    caption="amount(Net)"
                    calculateCellValue={calculateAmount}
                    minWidth={150}
                    cssClass='cls'
                    alignment='center'
                />
                
                <Column dataField="job.startedOn" dataType='time' selectedFilterOperation='between' caption="JOB START" format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledStart} minWidth={150} cssClass='cls'  >
                    <HeaderFilter dataSource={getDateFilter('job.startedOn')} />
                </Column>
                
                <Column caption="Job" cellRender={calculateJobLink} width={200} dataType="string" cssClass='cls' />
            </DataGrid>
        </div>
    )
}

export default UnpaidPaymentTable
