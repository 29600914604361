import { IScheduleStaff } from "../../interfaces/Staff";

export const OPEN_ASSIGN_DIALOG = "OPEN_ASSIGN_DIALOG";
export interface OpenAssignDialog {
      type: typeof OPEN_ASSIGN_DIALOG,
      staffDetail: IScheduleStaff
}
export const CLOSE_ASSIGN_DIALOG = "CLOSE_ASSIGN_DIALOG";
export interface CloseAssignDialog {
      type: typeof CLOSE_ASSIGN_DIALOG,
}
export const SCHEDULE_START_TIME = "SCHEDULE_START_TIME";
export interface ScheduleStartTime {
      type: typeof SCHEDULE_START_TIME,
      scheduleStartTime: string
}


export type AssignAction =
      | OpenAssignDialog
      | CloseAssignDialog
      | ScheduleStartTime