import React, { Dispatch, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import * as changePasswordActionCreators from '../../redux/actionCreators/changePasswordActionCreators';
import { ChangePasswordActions } from '../../redux/actionTypes/changePasswordTypes';
import { TextInput } from '../Inputs/TextInput';
import { useHistory } from 'react-router-dom';

const ChangePasswordModal: React.FC = () => {
    const { title, bodyText, modalOpen, changePassFn } = useSelector((state: RedusxAppState) => state.changePassword);
    const dispatch = useDispatch<Dispatch<ChangePasswordActions>>();
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [password, setPassword] = useState({ oldPassword: "", newPassword: "", passwordConfirm: "" })
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [matchError, setMatchError] = useState<string | null>(null)

    const handleClose = () => {
        dispatch(changePasswordActionCreators.closeChangePassword());
    }


    const handleChangePass = async () => {
        let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
        if (password.newPassword !== password.passwordConfirm) {
            setMatchError("* passwords do not match")
        } else
            try {
                setMatchError("")
                setLoading(true);
                setErrorMessage(null);
                changePassFn && await changePassFn({ newPassword: password.newPassword });
                setLoading(false);
                handleClose()

            }
            catch (err: any) {
                setLoading(false);
                setErrorMessage(err?.response?.data?.message);
            }
    }

    return (
        <Modal show={modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>{title}</Modal.Title>
            </ModalHeader>
            <Modal.Body>{bodyText}
                <form className='form w-100 my-10'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-12'>

                            <label className='form-label fs-6 fw-bolder text-dark mt-3'>New Password</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, newPassword: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                            <label className='form-label fs-6 fw-bolder text-dark mt-6'>New Password (confirm)</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, passwordConfirm: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="d-flex mx-5" variant="link" onClick={() => { handleClose(); setMatchError("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleChangePass} disabled={loading}>
                    Change {loading ? <Spinner animation='border' size='sm' /> : null}
                </Button>
                <strong className='text-danger'>{errorMessage}</strong>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangePasswordModal;