export const OPEN_CHANGE_PASSWORD = 'OPEN_CHANGE_PASSWORD'
export interface OpenChangePassword {
  type: typeof OPEN_CHANGE_PASSWORD
  changePassFn: (data:any) => Promise<void>
  bodyText: string
  title: string
}

export const CLOSE_CHANGE_PASSWORD = 'CLOSE_CHANGE_PASSWORD'
export interface CloseChangePassword {
  type: typeof CLOSE_CHANGE_PASSWORD
}

export type ChangePasswordActions = OpenChangePassword | CloseChangePassword
