import { AxiosResponse } from 'axios';
import React from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import ApiCalls from '../../../../../network/ApiCalls';
import { IJobResponse } from '../../../../../network/NetworkResponses/NetworkResponses';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import { decryptText } from '../../../../../utils/util';
import JobTable from './JobTable';

const HistoryTab = () => {
    let { hostId }: any = useParams();
    hostId = decryptText(hostId);
    const { data, isLoading, error } = useQuery<AxiosResponse<IJobResponse>>(['Get host job history', hostId], () => ApiCalls.getJobsByHost(hostId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: hostId ? true : false });

    if (error) {
        return <div>Error</div>
    }

    if (isLoading) {
        return <SkeletonTableLoading />
    }

    return (
        <>
            <JobTable data={data?.data.data ?? []} />
        </>
    )
}

export default HistoryTab;