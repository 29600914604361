import React, { Dispatch, useEffect, useState } from 'react'
import { IJobChecklistPhoto, IJobDetail } from '../../../../interfaces/Job'
import { toAmazonUrl } from '../../../../utils/util'
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators'
import { useDispatch } from 'react-redux'
import { JobAction } from '../../../../redux/actionTypes/jobTypes'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useHistory } from 'react-router'


type Props = {
  item: IJobChecklistPhoto,

}


const PhotoItem: React.FC<Props> = ({ item }) => {
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const history = useHistory();

  const handleVirtualInspection = () => {
    //Network request to change virtual inspection
  }

  return (
    < div
      onClick={() => {
        dispatch(jobActionCreator.openChecklistPhotoDialog(item))
      }}
      style={{ position: 'relative' }}
      className='d-flex flex-column border ribbon ribbon-top rounded mt-6 me-6 w-sm-300px cursor-pointer min-h-170px bg-hover-light'
    >
      <div
        style={{ top: 2, left: 18 }}

        className='ribbon-label text-center text-gray text-wrap fs-9 h-25px w-100px'
      >
        {item.hostPropertyChecklistSectionRoomIndex}
      </div>
      <div>
        <div className="d-flex justify-content-center">
          <img
            alt='answer'
            className='rounded-top w-sm-250px bg-white'
            style={{ maxHeight: 65, objectFit: 'contain' }}
            src={
              item.jobUserPhotoUrl ?
                item.jobUserPhotoUrl
                :
                item.samplePhotoUrl
                  ? toAmazonUrl(item.samplePhotoUrl)
                  : toAbsoluteUrl('/media/icons/noPhoto.png')
            }
          />
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column my-3 px-3 w-280px'>
          <p className="d-inline-block text-truncate text-center mw-200px">
            {item.hostPropertyChecklistSectionQuestionDescription?.en ?? item.hostPropertyChecklistSectionQuestionDescription[Object.keys(item.hostPropertyChecklistSectionQuestionDescription)[0]]}
          </p>
          <strong className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-center w-100'>- {item.questionAnswer ? item.questionAnswer === "undefined" ? "" : item.questionAnswer : 'Not answered yet'}</strong>
          {
            item.JobChecklistDetailQuestionStatusTypeName === "Completed"
              ?
              <img alt='completed' className='position-absolute' style={{ width: 18, height: 18, top: 15, right: 15 }} src={toAbsoluteUrl('/media/icons/success.png')} />
              :
              <img alt='not-completed' className='position-absolute' style={{ width: 18, height: 18, top: 15, right: 15 }} src={toAbsoluteUrl('/media/icons/danger.png')} />
          }
        </div>
      </div>

    </div >
  )
}

export default PhotoItem
  /*
< div className = 'd-flex flex-row align-items-center justify-content-between border-bottom ribbon ribbon-top rounded mt-6 pb-3 position-relative' onClick = {() => { dispatch(jobActionCreator.openChecklistPhotoDialog(item)) }} >

< div className = 'd-flex flex-column align-items-start justify-content-center flex-grow-1 px-6 col-9' >
<span style={{ fontWeight: 400 }}>{item.hostPropertyChecklistSectionRoomIndex}</span>
<span className='text-muted'>{item.hostPropertyChecklistSectionQuestionTitle.en ?? item.hostPropertyChecklistSectionQuestionTitle[Object.keys(item.hostPropertyChecklistSectionQuestionTitle)[0]]}</span>
</div >
<button className='btn btn-link btn-sm text-info' onClick={() => dispatch(jobActionCreator.openChecklistPhotoDialog(item))}>Show Details</button>
</div >
*/
