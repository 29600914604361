import {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import Dashboard from '../pages/dashboard/Dashboard'
import JobAvailable from '../pages/jobs-available/JobsAvailable'
import VendorsJobs from '../pages/jobs/VendorsJobs'
import StaffOffered from '../pages/offeredServices/staffOffered/StaffOffered'
import VendorOffered from '../pages/offeredServices/vendorOffered/VendorOffered'
import BillingInformation from '../pages/payment/BillingInformation'
import Payment from '../pages/payment/Payment'
import PaymentSuccess from '../pages/payment/PaymentSuccess'
import Profile from '../pages/profile/Profile'
import ServiceCities from '../pages/serviceCities/ServiceCities'
import StaffDetail from '../pages/staff/staff-detail/StaffDetail'
import StaffStatus from '../pages/staff/staff-status/PendingStaffs'
import Staffs from '../pages/staff/Staffs'
import UnpaidPayment from '../pages/unpaid-payments/UnpaidPayment'
import VendorAccount from '../pages/vendorDetails/vendorAccount/VendorAccount'
import VendorDocument from '../pages/vendorDetails/vendorDocuments/VendorDocument'
import VendorReference from '../pages/vendorDetails/vendorReferences/VendorReference'
import Violation from '../pages/violation/Violation'
import HasVirtualInspection from '../pages/virtual-inspection/HasVirtualInspection'
import VirtualInspections from '../pages/virtual-inspection/VirtualInspection'
import JobDetails from './../pages/job-details/JobDetails'
import ForgotPassword from '../pages/auth/components/ForgotPassword'
import ForgotPasswordConfirm from '../pages/auth/components/ForgotPasswordConfirm'
import Live from '../pages/live/Live'
import Marketplace from '../pages/marketplace/Marketplace'
import MarketplaceDetail from '../pages/marketplace/MarketplaceDetail'
import Chat from '../pages/chat/ChatPage'
import ServiceRateQuoteRequests from '../pages/offeredRates/OfferedRates'
import {RedusxAppState} from '../redux/reducers/rootReducer'
import {useSelector} from 'react-redux'
import Subscribe from '../pages/subscribe/Subscribe'
import Subscription from '../pages/subscription/Subscription'
import SubscriptionInvoice from '../pages/subscription/SubscriptionInvoice'
import HostDetails from '../pages/hosts/host-details/HostDetails'
import Hosts from '../pages/hosts/hosts/Hosts'
import PropertyList from '../pages/hosts/properties/PropertyList'
import AddPropertyWizard from '../pages/hosts/properties/AddPropertyWizard'
import PropertyEdit from '../pages/hosts/properties/property-edit/PropertyEdit'
import PropertyChecklists from '../pages/hosts/properties/checklist/PropertyChecklists'
import ChecklistSections from '../pages/hosts/properties/checklist/ChecklistSections/ChecklistSections'
import VendorServices from '../pages/vendor-services/VendorServices'
import {ProductFruits} from 'react-product-fruits'
import Suspended from '../pages/subscribe/Suspended'
import useWindowSize from '../hooks/useWindowSize'

export function PrivateRoutes() {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const isMobile = useWindowSize()

  return (
    <Suspense fallback={<FallbackView />}>
      {!isMobile && (
        <ProductFruits
          workspaceCode='dVyBr8JdwgtIDiFF'
          language='en'
          user={{
            username: user.user.email,
            firstname: user.user.firstName,
            lastname: user.user.lastName,
            role: user.role,
            signUpAt: user.createdOn,
          }}
        />
      )}
      <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/jobs/live' component={Live} />
        <Route path='/unscheduled-jobs' component={JobAvailable} />
        {/* <Route path='/jobs-vendors' component={VendorsJobs} /> */}
        <Route exact path='/jobs/pending'>
          <VendorsJobs type='Pending' />
        </Route>
        <Route exact path='/jobs/scheduled'>
          <VendorsJobs type='Scheduled' />
        </Route>
        <Route exact path='/jobs/completed'>
          <VendorsJobs type='Completed' />
        </Route>
        <Route exact path='/jobs/cancelled'>
          <VendorsJobs type='Cancelled' />
        </Route>
        <Route exact path='/jobs/virtual-inspections' component={HasVirtualInspection} />
        <Route exact path='/jobs/virtual-inspection/:jobId' component={VirtualInspections} />
        <Route path='/job-details/:jobId' component={JobDetails} />
        <Route
          exact
          path='/quote-requests/detail/:marketplaceOfferId'
          component={MarketplaceDetail}
        />
        <Route exact path='/quote-requests' component={Marketplace} />
        <Route path='/pending-staff' component={StaffStatus} />
        <Route path='/staff-details/:staffId/:id' component={StaffDetail} />
        <Route path='/staff/:userId' component={Staffs} />
        <Route path='/staff-management/list' component={Staffs} />
        <Route path='/messaging/inbox' component={Chat} />
        <Route path='/profile' component={Profile} />
        <Route path='/vendor-details/account' component={VendorAccount} />
        <Route path='/vendor-details/references' component={VendorReference} />
        <Route path='/vendor-details/documents' component={VendorDocument} />
        <Route path='/services/service-areas' component={ServiceCities} />
        <Route path='/vendor-violation' component={Violation} />
        <Route path='/vendor-violation/:jobId' component={JobDetails} />
        {/* <Route path='/offered-services/vendor' component={VendorOffered} /> */}
        <Route path='/services/my-services' component={VendorServices} />
        <Route path='/offered-services/staff' component={StaffOffered} />
        <Route path='/services/service-rates' component={ServiceRateQuoteRequests} />
        <Route path='/payment-success/:successToken' component={PaymentSuccess} />
        <Route path='/payments/stripe-integration' component={BillingInformation} />
        <Route path='/payments/payouts/paid' component={Payment} />
        <Route path='/payments/payouts/unpaid' component={UnpaidPayment} />
        <Route exact path='/clients/property/edit/:propertyId' component={PropertyEdit} />
        <Route exact path='/clients/property/add-a-property' component={AddPropertyWizard} />
        <Route
          exact
          path='/clients/property/checklists/:propertyId'
          component={PropertyChecklists}
        />
        <Route
          exact
          path='/clients/property/checklist-sections/:checklistId'
          component={ChecklistSections}
        />
        <Route exact path='/clients/list' component={Hosts} />
        <Route exact path='/clients/detail/:hostId' component={HostDetails} />
        <Route exact path='/clients/properties'>
          <PropertyList provider='turnify' />
        </Route>
        <Route path='/subscribe' component={Subscribe} />
        <Route path='/suspended' component={Suspended} />
        <Route
          path='/payments/subscription/invoice/:invoiceNumber'
          component={SubscriptionInvoice}
        />
        <Route path='/payments/subscription' component={Subscription} />
        {user.vendor.subscribed && (
          <>
            {user.vendor.suspended ? (
              <>
                <>
                  <Redirect from='/auth' to='/suspended' />
                  <Redirect exact from='/' to='/suspended' />
                </>
              </>
            ) : (
              <>
                <Redirect from='/auth' to='/dashboard' />
                <Redirect exact from='/' to='/dashboard' />
              </>
            )}
          </>
        )}
        {user.vendor.subscribed == false && (
          <>
            <Redirect from='/auth' to='/subscribe' />
            <Redirect exact from='/' to='/subscribe' />
          </>
        )}
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
