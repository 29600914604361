import { FC, useState, useEffect } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ApiCalls from '../../../../../network/ApiCalls'
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer'
import SelectInput from '../../../../../umut-components/Inputs/SelectInput'
import { decryptText } from '../../../../../utils/util'

type Props = {
  show: Boolean
  closeModal: () => void
  refetch: () => void
  existingDocument: number[]
}
const StaffDocumentModal: FC<Props> = ({ show, closeModal, refetch, existingDocument }) => {
  let { id }: any = useParams()
  id = decryptText(id);
  const {
    user: {
      userSection: { vendorId },
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const { data: docTypes, isLoading: docIsLoading } = useQuery(
    ['Get User Document Types'],
    () => ApiCalls.getVendorUserDocumentTypes(),
    { refetchOnWindowFocus: false }
  )
  const [selectedDocument, setSelectedDocument] = useState<any>()
  const [documentType, setDocumentType] = useState<any>(null)
  const [documents, setDocuments] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const handleDocumentSend = async () => {
    try {
      setLoading(true)
      var formData = new FormData()
      formData.append('vendorId', vendorId)
      formData.append('vendorUserId', id)
      formData.append('vendorUserDocumentTypeId', documentType)
      formData.append('documentFile', selectedDocument)
      if (vendorId && documentType != 0 && selectedDocument) {
        await ApiCalls.createVendorUserDocuments(formData)
        setLoading(false)
        closeModal()
        refetch()
      } else {
        setLoading(false)
        setErrorMessage('All fields are required.')
      }
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error?.response?.data.message)
    }
  }
  const handleCloseModal = () => {
    closeModal()
    setErrorMessage('')
  }
  const documentChange = (input: any) => {
    try {
      let file = input.target.files[0]
      if (file) {
        setSelectedDocument(file)
      } else {
        setSelectedDocument(null)
      }
    }
    catch (err: any) {
      //Handle file cannot be uploaded
    }
  }
  useEffect(() => {
    if (documents && documents.length === 1) {
      setDocumentType(documents[0].value);
    }
  }, [documents])



  useEffect(() => {
    if (docTypes) {
      setDocuments(docTypes?.data?.data?.filter((doc: any) => !existingDocument?.includes(doc.id)).map((type: any) => ({ label: type.name, value: type.id })))

    }
  }, [docTypes])

  useEffect(() => {
    setDocumentType(null)
    setSelectedDocument(null)
  }, [show])

  return (
    <div>
      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Document View</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex flex-column gap-8 '>
          <div>
            <Form.Label>Document type</Form.Label>
            <SelectInput
              defaultValue={documentType}
              disabledText='Select Document Type'
              options={documents}
              onValueChanged={(value) => {
                setDocumentType(value)
              }}
            />
          </div>

          <div>
            <Form.Label className='text-muted'>Please select file</Form.Label>
            <Form.Control
              onChange={documentChange}
              disabled={documentType ? false : true}
              type='file'
              accept={'image/*,application/pdf'}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='col mt-3'>
            <div className='d-flex col gap-3 justify-content-end'>
              <button type='button' className='btn btn-light' onClick={handleCloseModal}>
                Cancel
              </button>
              <button
                type='button'
                disabled={loading || !selectedDocument}
                className='btn btn-success'
                onClick={handleDocumentSend}
              >
                Send {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col text-end'>
              <strong style={{ color: 'red' }}>{errorMessage}</strong>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default StaffDocumentModal
