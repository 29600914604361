import React from 'react'
import {Card, Accordion} from 'react-bootstrap-v5'
import {IJobHostPropertyChecklist} from '../../../../../interfaces/Job'
import EmptyData from '../../../../../umut-components/Icons/EmptyData'
import PropertyDetails from '../../../../../umut-components/PropeprtyDetails/PropertyDetails'

type Props = {
  checklistData: IJobHostPropertyChecklist | null
}

const ChecklistTab: React.FC<Props> = ({checklistData}) => {
  if (checklistData == null) {
    return <EmptyData emptyText='There is no checklist info which related with this job' />
  }

  return (
    <div className='mt-6'>
      <div className='row'>
        <Accordion defaultActiveKey='csection'>
          {checklistData.checklistSections.map((section) => {
            return (
              <div className='col-12 mb-3'>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    className='border border-1'
                    variant='link'
                    eventKey={'section-' + section.roomIndex}
                  >
                    <Card.Title># {section.roomIndex}</Card.Title>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={'section-' + section.roomIndex}>
                    <Card.Body>
                      {section.questions.map((question, qi) => {
                        return (
                          <Card>
                            <Card.Body className='ps-0'>
                              <div className='row'>
                                <div className='col-1'>
                                  <strong>{'# Q' + (qi + 1)}</strong>
                                </div>
                                <div className='col-11'>
                                  <div>
                                    <strong>{question.title.en}</strong>
                                  </div>
                                  <div>{question.description.en}</div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        )
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}

export default ChecklistTab
