import React, {useEffect, useState} from 'react'
import {Card, ProgressBar, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'

const Dashboard: React.FC = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const history = useHistory()
  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)

  const {data, isLoading, refetch} = useQuery(
    ['Get OnBoarding Info', vendorId],
    () => ApiCalls.getOnBoarding(vendorId),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )

  const [progress, setProgress] = useState(0)
  const conditionDoc = data?.data?.vendorDocumentCount < 2
  const conditionCities = data?.data?.vendorServiceCityCount == 0
  const conditionStaff = data?.data?.staff?.length == 0
  const conditionManageService = data?.data?.vendorOfferedServiceCount == 0
  const conditionPayments = !data?.data?.isVerifiedStripeAccount
  const conditions = [
    conditionDoc,
    conditionCities,
    conditionStaff,
    conditionPayments,
    conditionManageService,
  ]

  const directUrl = (url: string) => {
    //history.push(url)
    window.open(url, '_blank')
  }

  useEffect(() => {
    const prog = conditions.filter((item) => item == false)
    setProgress(prog.length * (100 / conditions.length))
  }, [data])

  if (isLoading) {
    return (
      <div className='w-100 d-flex justify-content-center'>
        <Spinner animation='border'></Spinner>
      </div>
    )
  }

  if (!user.vendor.subscribed) {
    history.push('/subscribe')
  }

  if (user.vendor.subscribed && user.vendor.suspended) {
    history.push('/suspended')
  }

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            <div className='container d-flex flex-xl-row col flex-column justify-content-between p-5'>
              <div className='d-flex flex-column justify-content-center p-5 mt-10'>
                <div>
                  <div className='mb-10 d-flex justify-content-between align-items-center'>
                    <ProgressBar className='w-100' animated variant='success' now={progress} />
                    {progress == 100 && (
                      <svg
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        version='1.1'
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                      >
                        <g
                          id='Stockholm-icons-/-Navigation-/-Check'
                          stroke='none'
                          stroke-width='1'
                          fill='none'
                          fill-rule='evenodd'
                        >
                          <polygon id='Shape' points='0 0 24 0 24 24 0 24'></polygon>
                          <path
                            d='M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z'
                            id='Path-94'
                            fill='#000000'
                            fill-rule='nonzero'
                            transform='translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) '
                          ></path>
                        </g>
                      </svg>
                    )}
                  </div>
                  <h2 style={{fontWeight: 400}} className='text-left fs-1'>
                    Welcome to{' '}
                    <span style={{color: '#ff5d5d', fontWeight: 600, fontSize: 25}}>
                      TurnifyPRO!
                    </span>
                  </h2>
                  <p className='fs-3 mt-10'>
                    In order to use your account properly, follow the steps below:
                    <br />
                    1. Add the cities you provide your services
                    <br />
                    2. Add your staff
                    <br />
                    3. Add your services and rates
                    <br />
                    4. Add your bank information to receive your payments
                    <br />
                    5. Add your documents <br />
                  </p>
                </div>

                {progress == 100 ? (
                  <button className='w-100 btn btn-secondary'>
                    Vendor registration flow completed
                  </button>
                ) : (
                  <div className='d-flex flex-xl-row flex-column w-100 gap-3 mt-5 col'>
                    <button
                      disabled={!conditionCities}
                      onClick={() => {
                        directUrl('/services/service-areas')
                      }}
                      className=' btn btn-success font-weight-bold position-relative text-nowrap'
                    >
                      1. Service Cities
                      {conditionCities && (
                        <span className='bullet bullet-dot bg-success h-8px w-8px position-absolute translate-middle top-0 end-10 animation-blink '></span>
                      )}
                    </button>
                    <button
                      disabled={!conditionStaff}
                      onClick={() => {
                        directUrl('/staff-management/list')
                      }}
                      className=' btn btn-primary font-weight-bold position-relative text-nowrap'
                    >
                      2. Add Staff
                      {conditionStaff && (
                        <span className='bullet bullet-dot bg-success h-8px w-8px position-absolute translate-middle top-0 end-10 animation-blink '></span>
                      )}
                    </button>
                    <button
                      disabled={!conditionManageService}
                      onClick={() => {
                        directUrl('/services/my-services')
                      }}
                      className='btn btn-secondary font-weight-bold position-relative text-nowrap'
                    >
                      3. Services
                      {conditionManageService && (
                        <span className='bullet bullet-dot bg-success h-8px w-8px position-absolute translate-middle top-0 end-10 animation-blink '></span>
                      )}
                    </button>
                    <button
                      disabled={!conditionPayments}
                      onClick={() => {
                        directUrl('/payments/stripe-integration')
                      }}
                      className='btn btn-info font-weight-bold position-relative text-nowrap'
                    >
                      4. Payments
                      {conditionPayments && (
                        <span className='bullet bullet-dot bg-success h-8px w-8px position-absolute translate-middle top-0 end-10 animation-blink '></span>
                      )}
                    </button>
                    <button
                      disabled={!conditionDoc}
                      onClick={() => {
                        directUrl('/vendor-details/documents')
                      }}
                      className='btn btn-danger font-weight-bold position-relative text-nowrap'
                    >
                      5. Documents
                      {conditionDoc && (
                        <span className='bullet bullet-dot bg-success h-8px w-8px position-absolute translate-middle top-0 end-10 animation-blink '></span>
                      )}
                    </button>
                  </div>
                )}
              </div>
              <div className='d-flex justify-content-end '>
                <img
                  width='500'
                  height='367'
                  style={{objectFit: 'cover'}}
                  src='media/illustrations/dashboard.jpg'
                  alt=''
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default Dashboard
