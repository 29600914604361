import * as actions from '../actionTypes/jobAvailableTypes'

export function openJobAvailableDialog(
  jobAvailableId: number,
  cleaningPeriodStart: string,
  refetch: () => void
): actions.OpenJobAvailableDialog {
  return {
    type: actions.OPEN_JOB_AVAILABLE_DIALOG,
    jobAvailableId,
    cleaningPeriodStart,
    refetch

  }
}

export function closeJobAvailableDialog(): actions.CloseJobAvailableDialog {
  return {
    type: actions.CLOSE_JOB_AVAILABLE_DIALOG,
    refetch: () => { }


  }
}
export function pushHostPropertyId(hostPropertyId: number, jobTimezone: string): actions.PushHostProperty {
  return {
    type: actions.PUSH_HOST_PROPERTY,
    hostPropertyId,
    jobTimezone
  }
}
export function pushRoleId(roleId: number): actions.PushRoleId {
  return {
    type: actions.PUSH_ROLEID,
    roleId
  }
}
export function pushSelectedStaffs(selectedStaffs: number[]): actions.PushSelectedStaffs {
  return {
    type: actions.PUSH_SELECTED_STAFFS,
    selectedStaffs
  }
}