import React from 'react'
import { letterToHex, stringToColour, toAmazonUrl } from '../../utils/util';

type Props = {
    profilePhotoPath?: string;
    firstName: string;
    lastName: string;
    width?: number | string;
    height?: number | string;
    borderRadius?: string;
    fontSize?: string | number;
    classNames?: string;
    margin?: string | number;
    padding?: string | number;
}

const getAndCapitalizeFirstLetter = (letter: string) => {
    return letter.substr(0, 1).toUpperCase();
}

const ProfilePhotoTemp: React.FC<Props> = ({ profilePhotoPath, firstName, lastName, width, height, borderRadius, fontSize, classNames, margin, padding }) => {
    if (profilePhotoPath) {
        return <img className={classNames} alt={firstName + lastName} style={{ width, height, borderRadius }} src={toAmazonUrl(profilePhotoPath)} />
    }

    let backgroundColor = stringToColour(firstName + ' ' + lastName);

    return (
        <div className={'d-flex flex-row justify-content-center align-items-center ' + classNames} style={{ width, height, borderRadius, color: 'white', backgroundColor, margin, padding }}>
            <strong style={{ fontSize }}>{firstName ? getAndCapitalizeFirstLetter(firstName) : null}</strong>
            <strong style={{ fontSize }}>{lastName ? getAndCapitalizeFirstLetter(lastName) : null}</strong>
        </div>
    )
}

export default ProfilePhotoTemp;