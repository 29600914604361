import { useState } from 'react'
import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { PageTitle } from '../../../../../../_metronic/layout/core'
import ApiCalls from '../../../../../network/ApiCalls'
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer'
import { useParams } from 'react-router-dom'
import StaffDocument from './StaffDocument'
import StaffDocumentModal from './StaffDocumentModal'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'
import { decryptText } from '../../../../../utils/util'
import { letterSpacing } from '@material-ui/system'
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable'

const StaffDocuments = () => {
  let { staffId }: any = useParams()
  staffId = decryptText(staffId);
  const {
    user: {
      userSection: { vendorId },
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const { data, isLoading, refetch } = useQuery(
    ['Get Staff Documents', staffId],
    () => ApiCalls.getVendorUserDocuments(staffId, vendorId),
    { refetchOnWindowFocus: false }
  )
  const [show, setShow] = useState(false)
  const closeModal = () => setShow(false)
  return (
    <div>
      <PageTitle>User Documents</PageTitle>
      <div className='row mt-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <div className='d-flex justify-content-center p-6 fw-bold'>Loading...</div>
            ) : (
              <div className='row gap-2 d-flex'>
                {data?.data?.data.length == 0 ? <EmptyTable title="No Documents" /> : data?.data?.data.map((item: any, idx: number) => (
                  <div className='col-lg-2 col-12 min-w-200px'>
                    <StaffDocument data={item} key={idx} refetch={refetch} />
                  </div>
                ))}
              </div>
            )}
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <button
              className='btn btn-success'
              style={{ height: '100%' }}
              disabled={data?.data?.count >= 2}
              onClick={() => setShow(true)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New User Document
            </button>
          </Card.Footer>
        </Card>
      </div>
      <DeleteDialog />
      {show && <StaffDocumentModal show={show} closeModal={closeModal} existingDocument={data?.data?.data.map((doc: any) => doc.vendorUserDocumentTypeId)} refetch={refetch} />}
    </div>
  )
}

export default StaffDocuments
