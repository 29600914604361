import React, {Dispatch, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import HostTable from './HostTable'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import {useDispatch} from 'react-redux'
import {HostAction} from '../../../redux/actionTypes/hostTypes'
import * as hostActionCreator from '../../../redux/actionCreators/hostActionCreators'
import HostAddDialog from './HostAddDialog'
import HostEditDialog from './HostEditDialog'

const Hosts: React.FC = () => {
  const dispatch = useDispatch<Dispatch<HostAction>>()
  const {data, isLoading, error, refetch} = useQuery(
    'Get All Hosts',
    () => ApiCalls.getAllHosts(),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const clickAddButton = () => {
    dispatch(hostActionCreator.openAddHostDialog(refetch))
  }

  return (
    <>
      <PageTitle>Clients</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <HostTable refetch={refetch} data={data?.data.data} />
            )}
          </Card.Body>
          <Card.Footer className='d-flex align-items-center justify-content-between'>
            <div />
            <button className='btn btn-success' style={{height: '100%'}} onClick={clickAddButton}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add Client
            </button>
          </Card.Footer>
        </Card>
      </div>
      <HostAddDialog />
      <HostEditDialog />
    </>
  )
}

export default Hosts
