import { AxiosResponse } from "axios";
import { Card } from "react-bootstrap-v5";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { PageTitle } from "../../../_metronic/layout/core";
import ApiCalls from "../../network/ApiCalls";
import { IPaymentResponse } from "../../network/NetworkResponses/NetworkResponses";
import { RedusxAppState } from "../../redux/reducers/rootReducer";
import SkeletonTableLoading from "../../umut-components/Loading/SkeletonTableLoading";
import PaymentTable from "./UnpaidPaymentTable";

const UnpaidPayment = () => {
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { data, isLoading, error, refetch } = useQuery<AxiosResponse<IPaymentResponse>>(['Get Unpaid Payment By Vendor Id', user.userSection.vendorId], () => ApiCalls.getUnpaidPaymentByVendorId(user.userSection.vendorId), { cacheTime: 500000, refetchOnWindowFocus: false });

    return (
        <>
            <div>
                <PageTitle>Upcoming Payouts</PageTitle>
                <div className='row g-5 g-xl-8'>
                    <Card className='shadow'>
                        <Card.Body>
                            {isLoading ? <SkeletonTableLoading /> : <PaymentTable data={data?.data?.data} refetch={refetch} />}
                        </Card.Body>
                    </Card>
                </div>
            </div>

        </>
    )
}

export default UnpaidPayment
