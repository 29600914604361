import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {IBillingInformation} from '../../interfaces/Billing'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {
  GetUserDetailsResponse,
  IAddressResponse,
} from '../../network/NetworkResponses/NetworkResponses'
import {useMutation, useQueryClient} from 'react-query'
import {AxiosResponse} from 'axios'
import {IStripeInfo} from '../../interfaces/Stripe'
import {Spinner} from 'react-bootstrap-v5'
import {
  IPaymentAccount,
  ISubscriptionPackPrice,
  ISubscriptionPacks,
} from '../../interfaces/Subscribe'
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {useDialog} from '../../contexts/DialogContext'
import * as actionCreators from '../../redux/actionCreators/userActionCreators'
import {useHistory} from 'react-router-dom'

const PUBLIC_KEY_DEV =
  'pk_live_51HcuWEFdTV6mupoltscS6CT6w2zU4pgx8EF8KgHAvIJQTrcei0foUygvcZuORP2rzOife9s0DuqxKnhG3yVUuAIZ006kIchKT7'
//const PUBLIC_KEY_DEV = 'pk_test_51NQRuKCcKelwKj3PKYb8dTgcqPCwP9hSk5XEaIu0Sv1IlJ3EH30sLnxO3NrN0pUX7i8ntEnWlWZuxNm9O6BKr6cv00yLf3RHDf'

const stripeLoad = loadStripe(PUBLIC_KEY_DEV)

type SelectType = {
  label: string
  value: number
}

type InputType = {
  account_number: string
  routing_number: string
}

const schema = Yup.object().shape({
  account_number: Yup.string().required('Account number is required'),
  routing_number: Yup.string()
    .length(9, 'Must have 9 digits')
    .required('Routing number is required'),
})

const defaultValues: InputType = {
  account_number: '',
  routing_number: '',
}

const Subscribe = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const history = useHistory()
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [disableModalOpen, setDisabledModalOpen] = useState<boolean>(false)
  const [selectedPackType, setSelectedPackType] = useState('monthly')
  const [selectedPack, setSelectedPack] = useState<ISubscriptionPackPrice>()
  const {showFailureDialog} = useDialog()
  const dispatch = useDispatch()

  const {
    data: cardList,
    isLoading: cardsLoading,
    error: cardListError,
    refetch: cardListFetching,
  } = useQuery(
    ['Get Customer Sources', user.userSection.vendorId],
    () => ApiCalls.getCustomerSources(),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )

  const {
    data: subscriptionPacks,
    isLoading: subscriptionPacksLoading,
    error: subscriptionPacksError,
    refetch: subscriptionPacksFetching,
  } = useQuery<AxiosResponse<ISubscriptionPacks[]>>(
    ['Get Subscription Packs'],
    () => ApiCalls.getSubscriptionPacks(),
    {
      cacheTime: 50000,
      refetchOnWindowFocus: true,
    }
  )

  const {
    data: paymentAccount,
    isLoading,
    error,
    refetch,
  } = useQuery<AxiosResponse<IPaymentAccount>>(
    ['Get Payment Account', user.userSection.vendorId],
    () => ApiCalls.getPaymentAccounts(),
    {cacheTime: 100000, retry: false, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (!isLoading) {
      if (!paymentAccount?.data.stripeAccountId || error) {
        try {
          ApiCalls.createPaymentAccounts()
            .then((response) => {
              if (!response.status) {
                throw response
              }
            })
            .catch((error) => console.log(error))
        } catch (error) {
          console.log(error)
        }
      }
    }
  }, [paymentAccount, isLoading])

  useEffect(() => {
    if (!subscriptionPacksLoading) {
      if (subscriptionPacks && subscriptionPacks?.data.length > 0) {
        const pack = findPack(
          subscriptionPacks?.data[0].vendorSubscriptionPackPrices,
          selectedPackType
        )
        setSelectedPack(pack)
      }
    }
  }, [subscriptionPacksLoading, subscriptionPacks])

  const findPackPrice = (prices: any, type: string) => {
    var find = prices.find((p: any) => p.type == type)
    if (find != undefined) {
      return find.subscriptionFee
    }
  }

  const findPack = (prices: any, type: string) => {
    var find = prices.find((p: any) => p.type == type)
    if (find != undefined) {
      return find
    }
  }

  const handleSubmit = (d: any) => {
    try {
      const data = {
        vendorSubscriptionPackId: selectedPack?.vendorSubscriptionPackId,
        type: selectedPack?.type,
        subscriptionFee: selectedPack?.subscriptionFee,
        stripeAccountCardId: d,
      }
      ApiCalls.subscribeToPack(data)
        .then(async (response) => {
          const user: GetUserDetailsResponse = await ApiCalls.getUserDetails()
          dispatch(actionCreators.getUserInfoSuccess(user.data))

          history.push('/dashboard')
        })
        .catch((error) => {
          showFailureDialog(error?.response?.data?.message)
        })
    } catch (error) {
      showFailureDialog('An error occurred during the operation. Please try again.')
    }
  }

  if (isLoading) {
    return (
      <div>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <>
      <PageTitle>Subscribe</PageTitle>
      <div className='card' id='kt_pricing'>
        <div className='card-body p-lg-17'>
          <div className='d-flex flex-column'>
            <div className='mb-13 text-center'>
              <h1 className='fs-2hx fw-bold mb-5'>Choose Your Plan</h1>

              <div className='text-gray-400 fw-semibold fs-5'>
                You can complete your membership by choosing one of the monthly or annual packages
                that suits you.
              </div>
            </div>
            <div
              className='nav-group nav-group-outline mx-auto mb-15'
              data-kt-buttons='true'
              data-kt-initialized='1'
            >
              <button
                onClick={() => {
                  setSelectedPackType('monthly')
                  const pack = findPack(
                    subscriptionPacks?.data[0].vendorSubscriptionPackPrices,
                    'monthly'
                  )
                  setSelectedPack(pack)
                }}
                className={
                  selectedPackType == 'monthly'
                    ? 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active'
                    : 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2'
                }
              >
                Monthly
              </button>

              <button
                onClick={() => {
                  setSelectedPackType('annual')
                  const pack = findPack(
                    subscriptionPacks?.data[0].vendorSubscriptionPackPrices,
                    'annual'
                  )
                  setSelectedPack(pack)
                }}
                className={
                  selectedPackType == 'annual'
                    ? 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active'
                    : 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2'
                }
              >
                Annual
              </button>
            </div>
            {subscriptionPacksLoading && <SkeletonTableLoading />}
            {!subscriptionPacksLoading && subscriptionPacks?.data && (
              <div className='row g-10'>
                {subscriptionPacks?.data.map((pack, index) => {
                  return (
                    <div
                      className='col-xl-4 offset-xl-4 col-md-6 offset-md-3 col-12'
                      key={'pack-' + index}
                    >
                      <div className='d-flex h-100 align-items-center selected-pack-price'>
                        <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                          <div className='mb-7 text-center'>
                            <h1 className='text-dark mb-5 fw-bolder'>{pack.name}</h1>

                            <div className='text-center'>
                              <span className='mb-2 text-primary'>$</span>

                              <span
                                className='fs-3x fw-bold text-primary'
                                data-kt-plan-price-month='39'
                                data-kt-plan-price-annual='399'
                              >
                                {findPackPrice(pack.vendorSubscriptionPackPrices, selectedPackType)}
                              </span>

                              <span className='fs-7 fw-semibold opacity-50'>
                                /<span data-kt-element='period'>{selectedPackType}</span>
                              </span>
                            </div>
                          </div>
                          <div className='w-100 mb-10'>
                            <div dangerouslySetInnerHTML={{__html: pack.description}}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className='mt-5'>
            <Elements stripe={stripeLoad}>
              <CardForm cardComplete={handleSubmit} />
            </Elements>
          </div>
        </div>
      </div>
    </>
  )
}

export default Subscribe

type CardFormProps = {
  cardComplete: (d: any) => void
}

const CardForm: React.FC<CardFormProps> = ({cardComplete}) => {
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const stripe = useStripe()
  const elements = useElements()

  const queryClient = useQueryClient()
  const {mutateAsync} = useMutation(ApiCalls.postCustomerSource, {
    onSuccess: () => queryClient.invalidateQueries('Get Customer Sources'),
  })

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  const handleSubmit = async (ev: any) => {
    ev.preventDefault()
    setProcessing(true)

    const card = elements?.getElement(CardElement)

    if (stripe && elements && card) {
      const payload = await stripe.createToken(card)
      let source = payload.token?.id

      if (source) {
        try {
          setError(null)
          await mutateAsync({source}).then((response: any) => {
            setTimeout(() => {
              setProcessing(false)
            }, 3000)
            cardComplete(response?.data?.message)
          })
        } catch (error: any) {
          setError(`Registration failed.`)
          setProcessing(false)
        }
      }
      if (payload?.error) {
        setError(`Payment failed ${payload.error.message}`)
        setProcessing(false)
      }
    }
  }

  return (
    <div className='row w-100'>
      <div className='col-xl-4 offset-xl-4 col-md-6 offset-md-3 col-12 mx-auto'>
        <div className='p-5 ms-2'>
          <div className='tab-content'>
            <span id='card-header ' className='fs-5 fw-bold'>
              New card:
            </span>
            <div id='nav-tab-card' className='tab-pane fade show active mt-5'>
              {error && <p className='alert alert-danger'>{error}</p>}

              <form onSubmit={handleSubmit} role='form' id='payment-form'>
                <div className='form-group'>
                  <label htmlFor='username'>Full name (on the card)</label>
                  <input
                    type='text'
                    name='username'
                    placeholder='John Doe'
                    required
                    className='form-control'
                  />
                </div>
                <div className='form-group mt-5'>
                  <label htmlFor='cardNumber'>Card number</label>
                  <fieldset className='FormGroup'>
                    <div className='FormRow form-control rounded h-40px'>
                      <div className='my-auto'>
                        <CardElement options={cardStyle} id='card-element' />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <button
                  type='submit'
                  disabled={processing}
                  className='subscribe btn btn-success btn-block w-100 rounded-sm shadow-sm mt-15'
                >
                  {' '}
                  Subscribe {processing && <Spinner animation='border' size='sm' />}{' '}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
