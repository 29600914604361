import { IVendorService } from "../../interfaces/Vendors";
import * as actions from "../actionTypes/vendorTypes";

export interface VendorState {
    vendorOfferDialog: {
        modalOpen: boolean,
        refetch: () => void
    },
    vendorEditOfferDialog: {
        modalOpen: boolean,
        vendorService: IVendorService | null
    }
}

const initialState: VendorState = {
    vendorOfferDialog: {
        modalOpen: false,
        refetch: () => { }
    },
    vendorEditOfferDialog: {
        modalOpen: false,
        vendorService: null
    }
}

export default function vendorReducer(
    state: VendorState = initialState,
    action: actions.VendorAction
): VendorState {
    switch (action.type) {
        case actions.OPEN_VENDOR_OFFER_DIALOG:
            return { ...state, vendorOfferDialog: { modalOpen: true, refetch: action.refetch } }
        case actions.CLOSE_VENDOR_OFFER_DIALOG:
            return { ...state, vendorOfferDialog: { modalOpen: false, refetch: () => { } } }
        case actions.OPEN_VENDOR_EDIT_OFFER_DIALOG:
            return { ...state, vendorEditOfferDialog: { modalOpen: true, vendorService: action.vendorService } }
        case actions.CLOSE_VENDOR_EDIT_OFFER_DIALOG:
            return { ...state, vendorEditOfferDialog: { modalOpen: false, vendorService: null } }
        default:
            return state;
    }
}