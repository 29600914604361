import {
  Column,
  DataGrid,
  HeaderFilter,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  Selection,
} from 'devextreme-react/data-grid'
import {FC, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {ICity} from '../../../../../interfaces/Cities'
import ApiCalls from '../../../../../network/ApiCalls'
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton'

type Props = {
  data: ICity[]

  vendorId: number
  userId: number
  staffRefetch: () => void
}
export const StaffReduceTable: FC<Props> = ({data, vendorId, userId, staffRefetch}) => {
  const [loading, setLoading] = useState(false)
  const [selectedZipCode, setSelectedZipCode] = useState<any>([])

  const reduceServiceStaffs = async () => {
    try {
      setLoading(true)
      await ApiCalls.postStaffCityService({
        vendorId: vendorId,
        userId: userId,
        cityZipCodes: selectedZipCode,
      })
      await staffRefetch()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const onSelectionChanged = (cellValue: any) => {
    setSelectedZipCode(cellValue.selectedRowsData.map((element: any) => element.zipCode))
  }

  return (
    <div>
      <h3>Available Service Cities</h3>
      <small className='text-danger'>
        The cities in this list are the cities that Vendor serves.
      </small>
      <DataGrid
        id='ServiceCitiesTable'
        keyExpr='zipCode'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(50vh - 60px)'}
        allowColumnResizing={true}
        onSelectionChanged={onSelectionChanged}
      >
        <HeaderFilter visible={true} />
        <Selection mode='multiple' showCheckBoxesMode='always' />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <SearchPanel visible={true} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Cities: {0}'
            column='cityName'
            summaryType='count'
          />
        </Summary>
        <Column dataField='cityName' minWidth={50} cssClass='cls' caption='CITY' />
        <Column
          dataField='stateName'
          alignment='center'
          caption='STATE'
          minWidth={150}
          cssClass='cls'
        />
        <Column
          dataField='zipCode'
          alignment='center'
          caption='ZIPCODE'
          minWidth={50}
          cssClass='cls'
        />

        <Column minWidth={20} alignment='center' dataField='' cssClass='cls' />
      </DataGrid>
      <div className='mt-5'>
        <Button
          onClick={reduceServiceStaffs}
          disabled={loading}
          className={`btn-sm btn-success w-100`}
        >
          Add to Zone + {loading && <Spinner size='sm' animation='border' />}
        </Button>
      </div>
    </div>
  )
}

export default StaffReduceTable
