import React, {Dispatch, useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useDialog} from '../../contexts/DialogContext'
import {IStaffAddress} from '../../interfaces/Address'
import {ILocation} from '../../interfaces/Property'
import {IStaff} from '../../interfaces/Staff'
import ApiCalls from '../../network/ApiCalls'
import {StaffAddRequest} from '../../network/PostRequestModels'
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators'
import {StaffAction} from '../../redux/actionTypes/staffActionTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {getLocationByAddress, getLocationByAddressForStaff, isPhoneValid} from '../../utils/util'
import AutocompleteMap from '../Inputs/AutocompleteMap'
import PhoneTextInput from '../Inputs/PhoneInput'
import SelectInput from '../Inputs/SelectInput'
import {TextInput} from '../Inputs/TextInput'

type Props = {
  staff: IStaff | null
  refetch: () => void
}

const StaffForm: React.FC<Props> = ({staff, refetch}) => {
  const {showSuccessDialog} = useDialog()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const [staffRequest, setStaffRequest] = useState<StaffAddRequest>({} as StaffAddRequest)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null)
  const [roles, setRoles] = useState([])
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({address: ' '})
  const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)
  const [text, setText] = useState<string>('')
  const {data: countries} = useQuery('Get Countries', () => ApiCalls.getCountries(), {
    cacheTime: 50000,
    retry: false,
    refetchOnWindowFocus: false,
  })
  const {
    data: roleTypesData,
    isLoading: roleTypesLoading,
    error: roleTypesError,
  } = useQuery('Get Roles', () => ApiCalls.getStaffRoles(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const {
    data: staffFee,
    isLoading: staffFeeLoading,
    error: staffFeeError,
  } = useQuery('Get Vendor SubscriptionStaffFee', () => ApiCalls.getSubscriptionStaffFee(), {
    cacheTime: 500000,
    refetchOnWindowFocus: true,
  })

  const handleSelectAddress = (e: string) => {
    setSelectedLocation({...selectedLocation, address: e})
    setText(e)
  }

  const handleSendStaff = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      if (staffRequest.phoneNumber) {
        const finalRequest: StaffAddRequest = {
          ...staffRequest,
          //userAddressCreateDto: {...fullLocation, userAddressTypeId: 2},
          userAddressCreateDto: {
            address: 'Philadelphia, PA, USA',
            zipCode: '19107',
            countryName: 'United States',
            stateName: 'Pennsylvania',
            cityName: 'Philadelphia',
            userAddressTypeId: 1,
          },
          phoneNumber: staffRequest.phoneNumber,
          vendorId: user.userSection.vendorId,
        }
        const {email, firstName, lastName, vendorId, phoneNumber, roleId, userAddressCreateDto} =
          finalRequest
        if (
          email &&
          firstName &&
          lastName &&
          phoneNumber &&
          vendorId &&
          roleId &&
          !emailErrorMessage &&
          userAddressCreateDto
        ) {
          await ApiCalls.createStaff(finalRequest)
          dispatch(staffActionCreator.closeStaffAddDialog())
          refetch()
          showSuccessDialog('Staff ' + (staff ? 'Updated.' : 'Added.'))
          dispatch(staffActionCreator.getStaffList(user.userSection.hostId))
          setLoading(false)
        } else {
          setLoading(false)
          setErrorMessage('All fields are required.')
        }
      } else {
        setLoading(false)
        setErrorMessage('Phone is not valid!')
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  const onEmailChange = (email: string) => {
    let pattern = /^\S+@\S+\.\S+$/
    if (pattern.test(email)) {
      setEmailErrorMessage(null)
    } else {
      setEmailErrorMessage('Please enter valid email.')
    }
    setStaffRequest({...staffRequest, email})
  }

  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '')
  }

  useEffect(() => {
    getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
  }, [selectedLocation])

  useEffect(() => {
    if (roleTypesData) {
      setRoles(
        roleTypesData?.data
          ?.filter((role: any) => role.lobRoleName === 'Vendor')
          .map((type: any) => ({label: type.roleName, value: type.roleId}))
      )
    }
  }, [roleTypesData])

  if (roleTypesLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <>
      <div className='mb-3'>
        {staffFee?.data && (
          <>
            {staffFee?.data?.freeStaffLeft > 0 && (
              <strong className='text-danger'>
                You have {staffFee?.data?.freeStaffLeft} free staff left. After your entitlement
                expires, ${staffFee?.data?.staffFee} per staff will be reflected on your bills.
              </strong>
            )}
            {staffFee?.data?.freeStaffLeft == 0 && (
              <strong className='text-danger'>
                You are no longer entitled to free staff. ${staffFee?.data?.staffFee} payment will
                be taken from your defined card in the process of adding staff. If the payment is
                not received, the addition process will not be performed.
              </strong>
            )}
          </>
        )}
      </div>
      <form className='form w-100 mt-5'>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              Email {<span style={{color: 'red'}}>*</span>}
            </label>
            <TextInput onValueChange={onEmailChange} defaultValue={staffRequest.email} />
            <strong className='d-flex fw-bold break-word text-danger'>{emailErrorMessage}</strong>

            <label className='form-label fs-6 fw-bolder text-dark mt-6'>
              First Name {<span style={{color: 'red'}}>*</span>}
            </label>
            <TextInput
              onValueChange={(text: string) => setStaffRequest({...staffRequest, firstName: text})}
              defaultValue={staffRequest.firstName}
            />

            <label className='form-label fs-6 fw-bolder text-dark mt-6'>
              Last Name {<span style={{color: 'red'}}>*</span>}
            </label>
            <TextInput
              onValueChange={(text: string) => setStaffRequest({...staffRequest, lastName: text})}
              defaultValue={staffRequest.lastName}
            />

            {/* <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            City {<span style={{color: 'red'}}>*</span>}
          </label>
          <AutocompleteMap
            text={text}
            setText={setText}
            handleSelectAddress={handleSelectAddress}
            handleOnError={handleAutocompleteError}
          />
          {!selectedLocation.address ||
            (fullLocation?.zipCode == '' && (
              <>
                <strong className='text-danger fw-6'>Please enter a valid address</strong>
                <br />
              </>
            ))} */}

            <div className='row mt-5'>
              <div className='fv-row col-12'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  Phone {<span style={{color: 'red'}}>*</span>}
                </label>
                <PhoneTextInput
                  onChange={(text: string) => setStaffRequest({...staffRequest, phoneNumber: text})}
                />
              </div>
            </div>
            <label className='form-label fs-6 fw-bolder text-dark mt-6'>
              Select Role {<span style={{color: 'red'}}>*</span>}
            </label>
            <SelectInput
              defaultValue={staffRequest.roleId}
              options={roles}
              onValueChanged={(id: number) => setStaffRequest({...staffRequest, roleId: id})}
              disabledText='Select Role'
            />

            <div className='row mt-6'>
              <div className='col-12 text-end'>
                <button
                  type='button'
                  className='btn btn-success'
                  disabled={loading}
                  onClick={handleSendStaff}
                >
                  {staff ? 'Edit' : 'Add'} Staff{' '}
                  {loading ? <Spinner animation='border' size='sm' /> : null}
                </button>
              </div>
              <div className='col-12 text-end'>
                <strong style={{color: 'red'}}>{errorMessage}</strong>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default React.memo(StaffForm)
