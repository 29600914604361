import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import JobsAvailableModal from './JobsAvailableModal'
import JobAvailableTable from './JobsAvailableTable'

const JobAvailable = () => {
  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)

  const {refetch, data, error, isFetching} = useQuery(
    ['Jobs Available'],
    () => ApiCalls.getJobsAvailable(vendorId),
    {
      refetchOnWindowFocus: false,
      enabled: vendorId ? true : false,
    }
  )

  return (
    <>
      <PageTitle>Unscheduled Jobs</PageTitle>
      <Card>
        <Card.Title></Card.Title>
        <Card.Body>
          {isFetching ? (
            <SkeletonTableLoading />
          ) : (
            <JobAvailableTable refetch={refetch} data={data?.data?.data} />
          )}
        </Card.Body>
      </Card>

      <JobsAvailableModal />
    </>
  )
}

export default JobAvailable
