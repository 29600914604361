import { IScheduleStaff } from '../../interfaces/Staff';
import * as actions from '../actionTypes/assignTypes'

export interface AssignState {
    assignDialog: {
        modalOpen: boolean;
        staffDetail: IScheduleStaff | null
    },
    scheduleStartTime: string | null

}

const initialState: AssignState = {
    assignDialog: {
        modalOpen: false,
        staffDetail: null
    },
    scheduleStartTime: null

}

export default function assignReducer(
    state: AssignState = initialState,
    action: actions.AssignAction
): AssignState {
    switch (action.type) {
        case actions.OPEN_ASSIGN_DIALOG:
            return { ...state, assignDialog: { modalOpen: true, staffDetail: action.staffDetail } }
        case actions.CLOSE_ASSIGN_DIALOG:
            return { ...state, assignDialog: { modalOpen: false, staffDetail: null } }
        case actions.SCHEDULE_START_TIME:
            return { ...state, scheduleStartTime: action.scheduleStartTime }
        default:
            return state;
    }
}