
import * as actions from '../actionTypes/violationTypes'
import { IViolation } from './../../interfaces/Violation';

export interface violationDetailState {

    ModalOpen: boolean
    ModalData: IViolation | null

}

const initialState: violationDetailState = {

    ModalOpen: false,
    ModalData: null,

}

export default function violationReducer(
    state: violationDetailState = initialState,
    action: actions.ViolationDetailActions
) {
    switch (action.type) {
        case actions.VIOLATION_OPEN_VIEW:
            return { ...state, ModalOpen: true, ModalData: action.violation }
        case actions.VIOLATION_CLOSE_VIEW:
            return { ...state, ModalOpen: false, ModalData: null }
        default:
            return state
    }
}