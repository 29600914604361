import React, {Dispatch, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import Map from '../../../../umut-components/Map/GoogleMap'
import {useDispatch, useSelector} from 'react-redux'
import {HostAction} from '../../../../redux/actionTypes/hostTypes'
import ArrowButton from '../../../../umut-components/Buttons/ArrowButton'
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton'
import AutocompleteMap from '../../../../umut-components/Inputs/AutocompleteMap'
import SwitchBox from '../../../../umut-components/Inputs/SwitchBox'
import {
  IHostPropertyVendorInput,
  IHostPropertyAutomation,
  IHostPropertyICal,
  ILocation,
} from '../../../../interfaces/Property'
import {Button} from 'react-bootstrap-v5'
import TimePicker from '../../../../umut-components/Inputs/TimePicker'
import {IStaffAddress} from '../../../../interfaces/Address'
const debounce = require('debounce')

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyVendorInput) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: any
}

const AddPropertyDetails: React.FC<Props> = ({
  missingField,
  handleChangeHostProperty,
  goToStep,
  currentStep,
}) => {
  const dispatch = useDispatch<Dispatch<HostAction>>()
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({})
  const [selectedPropertyAutomation, setSelectedPropertyAutomation] =
    useState<IHostPropertyAutomation>({
      isAutomaticCalendarSync: false,
      isAutomaticPayment: true,
      isAutomaticSchedule: false,
      usePMS: false,
      checkInTime: '15:00',
      checkOutTime: '11:00',
    })

  const [hasSameDayBooking, setHasSameDayBooking] = useState<boolean>(false)
  const [placeId, setPlaceId] = useState<string | undefined>(undefined)
  const [validTime, setValidTime] = useState<string | null>(null)
  const [selectedICalUrls, setSelectedICalUrls] = useState<IHostPropertyICal[]>([{}])
  const [icalErrorIndexes, setIcalErrorIndexes] = useState<number[]>([])
  const [text, setText] = useState<string>('')
  const [hasMissingField, setHasMissingField] = useState<boolean>(false)

  useEffect(() => {
    if (+currentStep == 1) {
      window.scrollTo(0, 0)
    }
  }, [currentStep])

  const onMarkerChange = (changedLocation: ILocation) => {
    setText(changedLocation.address ? changedLocation.address : '')
    setSelectedLocation({...changedLocation, suite: selectedLocation.suite})
  }

  const onSuiteChange = (e: any) => {
    setSelectedLocation({...selectedLocation, suite: e.target.value})
  }

  const handleSelectPlaceId = (e: string) => {
    // setSelectedLocation({ ...selectedLocation, address: e });
    setPlaceId(e)
  }

  const changeScheduling = (check: boolean) => {
    setSelectedPropertyAutomation({
      ...selectedPropertyAutomation,
      isAutomaticSchedule: check,
      isAutomaticCalendarSync: check,
    })
  }

  const changeCheckoutTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkOutTime: event})
  }

  const changeCheckinTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkInTime: event})
  }

  const checkICalValid = (value: string, changeIndex: number) => {
    ApiCalls.validateICal(value)
      .then((res) => {
        if (res.data) {
          setIcalErrorIndexes(icalErrorIndexes.filter((index) => index !== changeIndex))
        } else {
          setIcalErrorIndexes([...icalErrorIndexes, changeIndex])
        }
      })
      .catch((err) => setIcalErrorIndexes([...icalErrorIndexes, changeIndex]))
  }
  const checkICalValidDebounce = debounce(checkICalValid, 600)

  const changeICalUrl = (value: string, changeIndex: number) => {
    setSelectedICalUrls(
      selectedICalUrls.map((iCal, index) =>
        index === changeIndex ? {...iCal, icalUrl: value} : iCal
      )
    )
    checkICalValidDebounce(value, changeIndex)
  }

  const addNewICalUrl = () => {
    setSelectedICalUrls([...selectedICalUrls, {icalUrl: ''}])
  }

  const deleteIcalUrl = (index: number) => {
    var icals = selectedICalUrls
    if (icals.length == 1) {
      var emptyIcal = [
        {
          id: 1,
          icalUrl: '',
          isDeleted: false,
        },
      ]
      setSelectedICalUrls([...emptyIcal])
    } else {
      icals.splice(index, 1)
      setSelectedICalUrls([...icals])
    }
  }

  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '')
  }

  const onNextStepClick = () => {
    if (
      !selectedLocation.address ||
      !selectedLocation.zipCode ||
      !selectedLocation.latitude ||
      !selectedLocation.longitude ||
      !selectedLocation.country ||
      !selectedLocation.state ||
      !selectedLocation.city
    ) {
      setHasMissingField(true)
    } else {
      setHasMissingField(false)
      var prop = {
        address: selectedLocation?.address,
        suite: selectedLocation.suite,
        city: selectedLocation.city,
        state: selectedLocation.state,
        country: selectedLocation.country,
        latitude: selectedLocation.latitude,
        longitude: selectedLocation.longitude,
        zipCode: selectedLocation?.zipCode,
        hostPropertyAutomation: selectedPropertyAutomation,
        hostPropertyICals: selectedICalUrls,
      }
      handleChangeHostProperty(prop)
      goToStep(2)
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='form w-100 mt-5'>
            <div className='row mb-6 d-flex justify-content-center'>
              {/* begin::Form group */}
              <div className='fv-row col-lg-4'>
                <Map
                  selectedAddress={selectedLocation.address}
                  placeId={placeId}
                  initialLocation={location}
                  onLatLngChanged={onMarkerChange}
                  initialAddress={null}
                  draggable={true}
                />
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='fv-row col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  Address {<span style={{color: 'red'}}>*</span>}
                </label>
                <AutocompleteMap
                  disabled={false}
                  text={text}
                  setText={setText}
                  handleSelectPlaceId={handleSelectPlaceId}
                  handleOnError={handleAutocompleteError}
                />
                {hasMissingField && !selectedLocation.address && (
                  <>
                    <strong className='text-danger'>Missing Field</strong>
                    <br />
                  </>
                )}

                <label className='form-label fs-6 fw-bolder text-dark mt-3'>Suite/Apt # </label>
                <input
                  value={selectedLocation.suite}
                  onChange={onSuiteChange}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  required
                />
                <label className='form-label fs-6 fw-bolder text-dark mt-3'>
                  Country {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  value={selectedLocation.country}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  disabled
                  required
                />

                {hasMissingField && !selectedLocation.country && (
                  <strong className='text-danger'>Missing Field</strong>
                )}
                <div className='row mt-5'>
                  <div className='fv-row col-lg-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      State {<span style={{color: 'red'}}>*</span>}
                    </label>
                    <input
                      value={selectedLocation.state}
                      className='form-control'
                      type='text'
                      autoComplete='off'
                      disabled
                      required
                    />
                    {hasMissingField && !selectedLocation.state && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                  </div>
                  <div className='fv-row col-lg-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      City {<span style={{color: 'red'}}>*</span>}
                    </label>
                    <input
                      value={selectedLocation.city}
                      className='form-control'
                      type='text'
                      autoComplete='off'
                      disabled
                      required
                    />
                    {hasMissingField && !selectedLocation.city && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                  </div>
                </div>
                <label className='form-label fs-6 fw-bolder text-dark mt-3'>
                  Zip Code {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  value={selectedLocation.zipCode}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  disabled
                  required
                />
                {hasMissingField && !selectedLocation.zipCode && (
                  <strong className='text-danger'>Missing Field</strong>
                )}
              </div>
            </div>
            <div className='row mt-10'>
              <div className='col-lg-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>Same Day Booking</label>
                <SwitchBox
                  mutedText
                  defaultChecked={hasSameDayBooking}
                  text='When enabled, Check-in Time cannot be earlier then Check-out Time.'
                  onCheckboxChange={setHasSameDayBooking}
                />

                <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                  Automatic Scheduling
                </label>
                <SwitchBox
                  mutedText
                  defaultChecked={
                    selectedPropertyAutomation.isAutomaticSchedule !== undefined
                      ? selectedPropertyAutomation.isAutomaticSchedule
                      : false
                  }
                  text='When enabled, cleaners will be automatically sent cleaning requests for all of your checkouts.'
                  onCheckboxChange={(e) => {
                    changeScheduling(e)
                  }}
                />

                {selectedPropertyAutomation.isAutomaticCalendarSync && (
                  <>
                    {selectedICalUrls.map((iCal, index, arr) => {
                      return (
                        <div className='row'>
                          <strong className='col-12 d-flex mb-3 mt-3'>
                            ICal URL #{index + 1}
                            {!hasMissingField &&
                              !icalErrorIndexes.includes(index) &&
                              iCal.icalUrl != '' &&
                              iCal.icalUrl != undefined && (
                                <div className='success-check'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='14px'
                                    height='14px'
                                    viewBox='0 0 24 24'
                                    version='1.1'
                                  >
                                    <g
                                      stroke='none'
                                      stroke-width='1'
                                      fill='none'
                                      fill-rule='evenodd'
                                    >
                                      <polygon points='0 0 24 0 24 24 0 24' />
                                      <path
                                        d='M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z'
                                        fill='#fff'
                                        fill-rule='nonzero'
                                        transform='translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) '
                                      />
                                    </g>
                                  </svg>
                                </div>
                              )}
                          </strong>
                          <div className='col-11'>
                            <input
                              value={iCal.icalUrl}
                              defaultValue={iCal.icalUrl}
                              onChange={(e: any) => {
                                changeICalUrl(e.target.value, index)
                              }}
                              className={'form-control '}
                              type={'text'}
                              autoComplete='off'
                              required
                            />
                            {hasMissingField && (iCal.icalUrl === '' || !iCal.icalUrl) && (
                              <strong className='text-danger'>
                                Missing Field
                                <br />
                              </strong>
                            )}
                            {icalErrorIndexes.includes(index) && (
                              <strong className='text-danger'>ICal is not valid!</strong>
                            )}
                            {!icalErrorIndexes.includes(index) && (
                              <strong className='text-success'></strong>
                            )}
                          </div>
                          {
                            <DeleteButton
                              deleteFn={() => {
                                deleteIcalUrl(index)
                              }}
                              classNames='col-1 btn btn-link text-danger'
                            />
                          }
                          {index === arr.length - 1 ? (
                            <Button
                              style={{maxWidth: 1030}}
                              disabled={iCal.icalUrl === '' || !iCal.icalUrl}
                              type='button'
                              className='btn-success col-12 btn-sm m-2'
                              onClick={() => addNewICalUrl()}
                            >
                              Add new ICal
                            </Button>
                          ) : null}
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
              <div className='d-flex flex-column col-lg-6'>
                <strong>Please select the check-in / check-out times of your property.</strong>
                <small className='text-muted'>
                  Assignments will be made based on the times you have chosen while creating the
                  cleaning schedule for your property.
                </small>
                <div className='row'>
                  <div className='w-50'>
                    {' '}
                    <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                      Check-out Time
                    </label>
                    <TimePicker
                      onChange={changeCheckoutTime}
                      defaultValue={selectedPropertyAutomation.checkOutTime ?? '11:00'}
                    />
                  </div>
                  <div className='w-50'>
                    <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                      Check-in Time
                    </label>
                    <TimePicker
                      onChange={changeCheckinTime}
                      defaultValue={selectedPropertyAutomation.checkInTime ?? '15:00'}
                    />
                    {validTime && <strong className='text-danger'>{validTime}</strong>}
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end flex-row w-100 mt-6'>
              <ArrowButton type='forward' func={onNextStepClick} classNames='align-self-end' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPropertyDetails
