import React, { Dispatch, useEffect, useState } from 'react'
import { ListGroup, Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { IStaff } from '../../interfaces/Staff';
import ApiCalls from '../../network/ApiCalls';
import { JobReviewRequest } from '../../network/PostRequestModels/JobRequests';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators';
import { decryptText, toAmazonUrl } from '../../utils/util';
import SelectInput from '../Inputs/SelectInput';
import { TextInput } from '../Inputs/TextInput';
import SkeletonTableLoading from '../Loading/SkeletonTableLoading';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import ProfilePhotoTemp from '../Icons/ProfilePhotoTemp';

type StaffProps = {
    staff: IStaff,
    setSelectedStaff: (staff: IStaff | null) => void
}

const StaffItem: React.FC<StaffProps> = ({ staff, setSelectedStaff }) => {
    return (
        <ListGroup.Item action onClick={() => { setSelectedStaff(staff) }}>
            <div className='row p-3'>
                <div className='col-3'>
                    <ProfilePhotoTemp profilePhotoPath={staff.profilePhotoPath} firstName={staff.firstName} lastName={staff.lastName} width={35} height={35} borderRadius='50%' />
                </div>
                <div className='col-9 d-flex align-items-center'>
                    <strong className='text-muted'>{staff.firstName + ' ' + staff.lastName}</strong>
                </div>
            </div>
        </ListGroup.Item>
    )
}

const ReviewForm: React.FC = () => {
    const { reviewDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const [selectedStaff, setSelectedStaff] = useState<IStaff | null>(null);
    const [reviewRequest, setReviewRequest] = useState<JobReviewRequest>({} as JobReviewRequest);
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState<string | null>(null);
    const { data: staffData, isLoading: staffLoading, error: staffError } = useQuery(['Get Job Staffs', jobId],
        () => ApiCalls.getJobStaffs(jobId),
        { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false });
    const { data: rateData } = useQuery('Get Rate Types', () => ApiCalls.getRateTypes(), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false })

    const handleAddReview = async () => {
        if (selectedStaff && reviewRequest.reviewNote && reviewRequest.jobRatingTypeId && reviewDialog.serviceTypeId) {
            setErrorText(null);
            setLoading(true);
            try {
                const { userId, roleId } = selectedStaff;
                const request: JobReviewRequest = {
                    ...reviewRequest,
                    userId,
                    roleId,
                    jobId: parseInt(jobId),
                    serviceTypeId: reviewDialog.serviceTypeId
                }
                await ApiCalls.addJobReview(request);
                setLoading(false);
                reviewDialog.refetch();
                dispatch(jobActionCreator.closeReviewDialog());
            }
            catch (err: any) {
                setLoading(false);
                setErrorText(err?.response?.data?.message ? err?.response?.data?.message : "Unexpected error.");
            }
        }
        else {
            setErrorText('All fields required.');
        }
    }

    const changeNote = (text: string) => {
        setReviewRequest({ ...reviewRequest, reviewNote: text })
    }

    const changeRate = (value: any) => {
        setReviewRequest({ ...reviewRequest, jobRatingTypeId: value })
    }

    useEffect(() => {
        if (rateData) {
            setRates(rateData.data?.data?.map((type: any) => ({ label: type.name, value: type.id })));
        }
    }, [rateData])

    return (
        <div className="row">
            <div className="col-lg-4">
                <h6>Select Staff to Review</h6>
                <ListGroup style={{ maxHeight: 400, overflowY: 'auto' }}>
                    {
                        staffLoading ? <SkeletonTableLoading /> : staffData?.data?.data?.length > 0 ? staffData?.data?.data?.map((staff: IStaff) => <StaffItem staff={staff} setSelectedStaff={setSelectedStaff} />) : "No staff found for this job"
                    }
                </ListGroup>
            </div>
            <div className="col-lg-8">
                {selectedStaff ?
                    <>
                        <div className='row p-6 bg-light rounded mt-6'>
                            <div className='col-2 d-flex justify-content-center'>
                                <ProfilePhotoTemp width={50} height={50} fontSize={20} borderRadius='50%' profilePhotoPath={selectedStaff.profilePhotoPath} firstName={selectedStaff.firstName} lastName={selectedStaff.lastName} />
                            </div>
                            <div className='col-10 d-flex justify-content-center align-items-start flex-column'>
                                <strong>{selectedStaff.firstName + ' ' + selectedStaff.lastName}</strong>
                                <small className='text-muted'>{selectedStaff.phoneNumber}</small>
                            </div>
                            <form className='form w-100 mt-5'>
                                <div className='row d-flex justify-content-center'>
                                    <div className='col-lg-12'>
                                        <label className='form-label fs-6 fw-bolder text-dark'>Rate</label>
                                        <SelectInput options={rates} onValueChanged={changeRate} />

                                        <label className='form-label fs-6 fw-bolder text-dark mt-6'>Note</label>
                                        <TextInput onValueChange={changeNote} />
                                    </div>
                                </div>
                                <div className='w-100 d-flex justify-content-end mt-5'>
                                    <button type='button' className='btn btn-success' onClick={handleAddReview} disabled={loading || !selectedStaff || !reviewRequest.reviewNote || !reviewRequest.jobRatingTypeId}>
                                        Save Review {loading && <Spinner animation='border' size='sm' />}
                                    </button>
                                </div>
                                <strong className='w-100 d-flex flex-column text-end text-danger mt-3'>{errorText}</strong>
                            </form>
                        </div>
                    </>
                    :
                    null}
            </div>
        </div>
    )
}

export default ReviewForm;