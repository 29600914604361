import {yupResolver} from '@hookform/resolvers/yup'
import {FC, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import {useForm} from 'react-hook-form'
import {useQuery} from 'react-query'
import {Link, useHistory} from 'react-router-dom'
import {components} from 'react-select'
import * as Yup from 'yup'
import {useDialog} from '../../../contexts/DialogContext'
import ApiCalls from '../../../network/ApiCalls'
import {LoginRequest, RegisterRequest} from '../../../network/PostRequestModels'
import PhoneTextInput from '../../../umut-components/Inputs/PhoneInput'
import SelectInput from '../../../umut-components/Inputs/SelectInput'
import Policy from './Policy'
import TermsOfUse from './TermsOfUse'
import ReCAPTCHA from 'react-google-recaptcha'
import ReactPixel from 'react-facebook-pixel'
import {useDispatch} from 'react-redux'
import * as actionCreator from '../../../redux/actionCreators/userActionCreators'

type InputType = {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordAgain: string
  companyName: string
  name: string
  location: string
  website: string
  handleUnit: number
  vendorTypeId: number
  dispatchStrategyTypeId: number
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Please Enter Valid Email').required('Email is required'),
  password: Yup.string()
    //.matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,'Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter and one number')
    .min(6, 'Password must be at least 6 character.')
    .required('Password is required'),
  passwordAgain: Yup.string().oneOf([Yup.ref('password'), null], 'Password must be matched'),
  companyName: Yup.string().nullable(),
  name: Yup.string().required('Vendor Name is required'),
  website: Yup.string().nullable(),
  handleUnit: Yup.number().required(),
  vendorTypeId: Yup.number().required(),
  dispatchStrategyTypeId: Yup.number().required(),
})

const defaultValues: InputType = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordAgain: '',
  companyName: '',
  name: '',
  location: '',
  website: '',
  handleUnit: 0,
  vendorTypeId: 0,
  dispatchStrategyTypeId: 0,
}

const vendorTypes = [
  {
    label: 'Cleaning Company',
    value: 1,
  },
  {
    label: 'Individual',
    value: 2,
  },
]

export function Register() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectedVendorType, setSelectedVendorType] = useState<number>(1)
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState<string | undefined>('')
  const [validationErrors, setValidationErrors] = useState<any>({})
  const [location, setLocation] = useState<any>(null)
  const [locationError, setLocationError] = useState<string | undefined>(undefined)
  const [policyModal, setPolicyModal] = useState(false)
  const [policyType, setPolicyType] = useState<string>('policy')
  const [captchaVerified, setCaptchaVerified] = useState(false)

  const {showFailureDialog, showSuccessDialog} = useDialog()
  const {data: countries} = useQuery('Get Countries', () => ApiCalls.getCountries(), {
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {control, formState, handleSubmit, reset, register} = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const captchaKeyLive = '6LfEvBgmAAAAAIy74lLwSwBfj2o9AmGkWAoZ00wN'
  const captchaKeyDev = '6LfAhBgmAAAAACgOmw3dvLTZdzwCEM7ApPtxO9tB'

  const {isValid, dirtyFields, errors} = formState

  const logoutFunc = () => {
    dispatch(actionCreator.logout())
  }

  const onLogin = async (data: InputType) => {
    if (captchaVerified) {
      try {
        if (!location?.label) return setLocationError('Location is required!')

        let locationPlaces = location.label.split(/, /)
        locationPlaces.reverse()

        if (locationPlaces.length < 2) {
          showFailureDialog('Please specify your exact location.')
          setLoading(false)
          setErrorText('Please specify your exact location.')
        } else {
          let statePlaces = locationPlaces[1].split(/ /)
          if (locationPlaces[0] == 'USA' || locationPlaces[0] == 'US') {
            locationPlaces[0] = 'United States'
          }
          if (locationPlaces[1] == 'DC') {
            locationPlaces[1] = 'Washington'
          }
          setErrorText(undefined)
          setLoading(true)
          setLocationError(undefined)

          if (phoneNumber && companyPhoneNumber) {
            const registerRequest: RegisterRequest = {
              vendorUserInfo: {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                phoneNumber: phoneNumber,
              },
              vendorInfo: {
                name: data.name,
                countryName: locationPlaces[0],
                stateName: statePlaces[0],
                cityName: locationPlaces[2],
                website: data.website ? data.website : undefined,
                phoneNumber: companyPhoneNumber,
                handleUnit: 1,
                vendorTypeId: selectedVendorType,
                dispatchStrategyTypeId: 2,
              },
            }
            await ApiCalls.register(registerRequest)
            showSuccessDialog('Your registration has been successfully completed.')
            setErrorText('')
            setLoading(false)
            setLocationError(undefined)
            ReactPixel.trackCustom('VendorSubmit', {email: data.email})
            // history.push('/auth/login')

            const loginRequest: LoginRequest = {
              email: data.email,
              password: data.password,
            }
            dispatch(actionCreator.login(loginRequest, logoutFunc))
          } else {
            showFailureDialog('Phone is not valid!')
            setLoading(false)
            setErrorText('Phone is not valid!')
          }
        }
      } catch (err: any) {
        if (err?.response?.data) {
          showFailureDialog(err?.response?.data?.message)
          setLoading(false)
          setErrorText(err?.response?.data?.message)
        } else {
          if (locationError != undefined) {
            showFailureDialog('Location is not valid, please check city or your state is available')
          }
          showFailureDialog(err?.response?.data?.errors?.[0]?.message)
          setLoading(false)
          setErrorText(err?.response?.data?.errors?.[0]?.message)
          let errors: any = {}
          err?.response?.data?.errors?.forEach((error: any) => {
            errors[error?.field] = error?.message
          })
          setValidationErrors(errors)
        }
      }
    } else {
      showFailureDialog('Please verify the captcha')
      setLoading(false)
    }
  }
  const modalClose = () => {
    setPolicyModal(false)
  }

  const recaptchaChange = (d: any) => {
    setCaptchaVerified(true)
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Start typing to get results</span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <div>
      <form
        className='form w-100 '
        onSubmit={handleSubmit(onLogin)}
        noValidate
        id='kt_login_signup_form'
      >
        {/* begin::Heading */}
        <div className='mb-10 text-center'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Sign Up to Turnify</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            Already have an account?
            <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
              Login as a Vendor
            </Link>
          </div>
          {/* end::Link */}
        </div>
        {/* end::Heading */}

        <ul className='nav nav-tabs flex-nowrap text-nowrap justify-content-evenly border-0 mb-3'>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0'
              data-bs-toggle='tab'
              href='https://my.turnify.com/auth/register'
            >
              Sign up as Host
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0 active'
              data-bs-toggle='tab'
              href='javascript:;'
            >
              Sign up as Vendor
            </a>
          </li>
        </ul>

        <div className='d-flex align-items-center mb-10'>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          <span className='fw-bold text-gray-400 fs-7 mx-2'>Contact</span>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>

        <div className='row'>
          {/* begin::Form group */}
          <div className='fv-row col-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              First Name {<span style={{color: 'red'}}>*</span>}
            </label>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              autoComplete='off'
              {...register('firstName')}
              required
            />
            <p style={{color: 'red'}}>{errors.firstName?.message}</p>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row col-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              Last Name {<span style={{color: 'red'}}>*</span>}
            </label>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              autoComplete='off'
              {...register('lastName')}
              required
            />
            <p style={{color: 'red'}}>{errors.lastName?.message}</p>
          </div>
          {/* end::Form group */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            Email {<span style={{color: 'red'}}>*</span>}
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            type='email'
            autoComplete='off'
            {...register('email')}
            required
          />
          <p style={{color: 'red'}}>{errors.email?.message}</p>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                Password {<span style={{color: 'red'}}>*</span>}
              </label>
              {/* end::Label */}
            </div>
          </div>
          <input
            {...register('password')}
            type='password'
            autoComplete='off'
            className='form-control form-control-lg form-control-solid'
            required
          />
          <p style={{color: 'red'}}>{errors.password?.message}</p>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                Password Again {<span style={{color: 'red'}}>*</span>}
              </label>
              {/* end::Label */}
            </div>
          </div>
          <input
            {...register('passwordAgain')}
            type='password'
            autoComplete='off'
            className='form-control form-control-lg form-control-solid'
            required
          />
          <p style={{color: 'red'}}>{errors.passwordAgain?.message}</p>
        </div>
        {/* end::Form group */}

        <div className='row mb-10'>
          {/* begin::Form group */}
          <div className='fv-row col-12 '>
            <label className='form-label fs-6 fw-bolder text-dark'>
              Phone {<span style={{color: 'red'}}>*</span>}
            </label>
            <PhoneTextInput onChange={setPhoneNumber} />
            {!phoneNumber && errorText && <span className='text-danger'>Phone is not valid</span>}
          </div>
          {/* end::Form group */}
        </div>

        <div className='d-flex align-items-center mb-10'>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          <span className='fw-bold text-gray-400 fs-7 mx-2'>Vendor</span>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>
        <div>
          {/* begin::Form group */}
          <div className='fv-row'>
            <label className='form-label fs-6 fw-bolder text-dark'>Vendor Type</label>
            <SelectInput
              options={vendorTypes}
              defaultValue={1}
              onValueChanged={setSelectedVendorType}
            />
            <p style={{color: 'red'}}>{errors.name?.message}</p>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              Vendor Name {<span style={{color: 'red'}}>*</span>}
            </label>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              autoComplete='off'
              {...register('name')}
              required
            />
            <p style={{color: 'red'}}>{errors.name?.message}</p>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          {/* <div className='fv-row'>
          <label className='form-label fs-6 fw-bolder text-dark'>Country</label>
          <input
            className='form-control form-control-lg form-control-solid'
            type='text'
            autoComplete='off'
            {...register("countryName")}
            required
          />
          <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.countryName?.message}</p>
        </div> */}
          {/* end::Form group */}

          {/* begin::Form group */}
          {/* <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>State</label>
          <input
            className='form-control form-control-lg form-control-solid'
            type='email'
            autoComplete='off'
            {...register("stateName")}
            required
          />
          <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.stateName?.message}</p>
        </div> */}
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mt-5 mb-0'>
                  Location {<span style={{color: 'red'}}>*</span>}
                </label>
                {/* end::Label */}
              </div>
            </div>
            <GooglePlacesAutocomplete
              {...register('location')}
              selectProps={{
                value: location,
                onChange: setLocation,
                components: {NoOptionsMessage},
                placeholder: 'Please Enter location',
              }}
              apiOptions={{language: 'en'}}
              apiKey='AIzaSyBA9K-sDSC-_yqnJRfCGiH4b3GFTWOUt84'
            />
            {locationError && <p style={{color: 'red'}}>{locationError}</p>}
            {(validationErrors['cityName'] || validationErrors['stateName']) && (
              <p style={{color: 'red'}}>Location is not valid!</p>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Website</label>
                {/* end::Label */}
              </div>
            </div>
            <input
              {...register('website')}
              type='text'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid'
              required
            />
            {validationErrors['website'] && (
              <strong style={{color: 'red'}}>{validationErrors['website']}</strong>
            )}
          </div>
          {/* end::Form group */}

          <div className='row mb-10'>
            {/* begin::Form group */}
            <div className='fv-row col-12'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                Company Phone {<span style={{color: 'red'}}>*</span>}
              </label>
              <PhoneTextInput onChange={setCompanyPhoneNumber} />
              {!companyPhoneNumber && errorText && (
                <span className='text-danger'>Phone is not valid</span>
              )}
            </div>

            {/* end::Form group */}
          </div>
        </div>

        {/* begin:: ErrorText */}
        {/* <strong style={{ color: 'red' }}>{errorText}</strong> */}
        {/* end:: ErrorText */}

        {/* begin::Action */}
        <div className='text-center'>
          <div>
            <h5 className='text-muted fs-7 mb-5'>
              By signing up, you agree to our{' '}
              <span
                onClick={() => {
                  setPolicyModal(true)
                  setPolicyType('Termsofus')
                }}
                className='cursor-pointer'
                style={{color: '#ff5d5d'}}
              >
                Terms of Use,
              </span>
              <span
                onClick={() => {
                  setPolicyModal(true)
                  setPolicyType('privacy')
                }}
                className='cursor-pointer'
                style={{color: '#ff5d5d'}}
              >
                {' '}
                Privacy Policy
              </span>{' '}
              and to receive Turnify emails, newsletters & updates.
            </h5>
          </div>
          <div style={{marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center'}}>
            <ReCAPTCHA sitekey={captchaKeyLive} onChange={recaptchaChange} />
          </div>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className={`btn btn-lg w-100 mb-5 bg-primary text-white ${
              errorText && !loading ? 'mt-5' : ''
            }`}
            disabled={!isValid || loading}
          >
            {!loading && <span className='indicator-label'>Sign up as a Vendor</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* end::Action */}
      </form>
      <PolicyModal modalOpen={policyModal} modalClose={modalClose} policyType={policyType} />
    </div>
  )
}
type Props = {
  modalOpen: boolean
  modalClose: () => void
  policyType: string
}
const PolicyModal: FC<Props> = ({modalOpen, modalClose, policyType}) => {
  return (
    <Modal show={modalOpen} onHide={modalClose} size='lg'>
      <Modal.Body style={{height: '80vh', overflowY: 'auto', padding: 50}}>
        {policyType == 'privacy' ? <Policy /> : <TermsOfUse />}
      </Modal.Body>
    </Modal>
  )
}
