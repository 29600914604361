import {IAddress} from '../../interfaces/Address'
import {IStaff, IStaffDetail} from '../../interfaces/Staff'
import * as actions from '../actionTypes/addressTypes'

export interface AddressState {
  addressList: IAddress[]
  loading: boolean
  errorText: string | null
  addressEdit: {
    dialogOpen: boolean
    address: IAddress | null
  }
}

const initialState: AddressState = {
  addressList: [],
  loading: false,
  errorText: '',
  addressEdit: {
    dialogOpen: false,
    address: null,
  },
}

export default function addressReducer(
  state: AddressState = initialState,
  action: actions.AddressAction
): AddressState {
  switch (action.type) {
    case actions.GET_USER_ADDRESSES:
      return {...state, loading: true, errorText: null}
    case actions.GET_USER_ADDRESSES_SUCCESS:
      return {...state, addressList: action.addressList, loading: false, errorText: null}
    case actions.GET_USER_ADDRESSES_FAILURE:
      return {...state, addressList: [], loading: false, errorText: action.errorText}
    case actions.OPEN_ADDRESS_DIALOG:
      return {...state, addressEdit: {dialogOpen: true, address: action.address}}
    case actions.CLOSE_ADDRESS_DIALOG:
      return {...state, addressEdit: {dialogOpen: false, address: null}}
    default:
      return state
  }
}
