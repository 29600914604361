import React, {Dispatch, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import {
  IHostPropertyAccess,
  IHostPropertyVendorInput,
  IHostPropertyBedroom,
} from '../../../../interfaces/Property'
import SelectInput from '../../../../umut-components/Inputs/SelectInput'
import AsyncSelect from 'react-select/async'
import {TextInput} from '../../../../umut-components/Inputs/TextInput'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Card} from 'react-bootstrap-v5'
import ArrowButton from '../../../../umut-components/Buttons/ArrowButton'

const booleanOptions = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
]

interface IValues {
  hasStorage: boolean
  storageDescription: string
  storageInQuote: boolean
  hasParking: boolean
  parkingDescription: string
  cleaningProviderId: number
  hasLaundry: boolean
  hasDryer: boolean
  laundryInQuote: boolean
  laundryToBeDone: boolean
  laundryMachineCycle: number
  laundryDryerCycle: number
  laundryLinenNumber: string
  hasTrashDisposal: boolean
  trashDays: string
  propertySize: number
}

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyVendorInput) => void
  goToStep: (key: any) => void
  missingField?: boolean
  currentStep: any
}

const AddPropertyProviders: React.FC<Props> = ({
  missingField,
  handleChangeHostProperty,
  goToStep,
  currentStep,
}) => {
  const {data: cleanerProvidersData, error: cleanerProviderError} = useQuery(
    'Cleaner Providers',
    () => ApiCalls.getCleaningProviders(),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )
  const {data: accessTypesData, error: accessTypesError} = useQuery(
    'Unit Access Types',
    () => ApiCalls.getAccessTypes(),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const [cleanerProviders, setCleanerProviders] = useState([])
  const [accessTypes, setAccessTypes] = useState([])
  const [hasMissingField, setHasMissingField] = useState<boolean>(false)
  const [cleaningProviderId, setCleaningProviderId] = useState(2)
  const [hostId, setHostId] = useState(0)
  const [selectedNumberOfBathRooms, setSelectedNumberOfBathRooms] = useState<number>(1)
  const [selectedNumberOfBedRooms, setSelectedNumberOfBedRooms] = useState<number>(0)
  const [selectedNumberOfBeds, setSelectedNumberOfBeds] = useState<number>(0)
  const [selectedHostPropertyBedrooms, setSelectedHostPropertyBedrooms] = useState<
    IHostPropertyBedroom[]
  >([])

  const [selectedValues, setSelectedValues] = useState<IValues>({
    hasStorage: false,
    storageDescription: '',
    storageInQuote: false,
    hasParking: false,
    parkingDescription: '',
    cleaningProviderId: 2,
    hasLaundry: false,
    hasDryer: false,
    laundryInQuote: false,
    laundryToBeDone: false,
    laundryMachineCycle: 0,
    laundryDryerCycle: 0,
    laundryLinenNumber: '',
    hasTrashDisposal: false,
    trashDays: '',
    propertySize: 0,
  })

  const [selectedAccess, setSelectedAccess] = useState<IHostPropertyAccess>({})

  useEffect(() => {
    if (+currentStep == 2) {
      window.scrollTo(0, 0)
    }
  }, [currentStep])

  useEffect(() => {
    if (cleanerProvidersData) {
      setCleanerProviders(
        cleanerProvidersData?.data?.data?.map((type: any) => ({label: type.name, value: type.id}))
      )
    }
  }, [cleanerProvidersData])

  useEffect(() => {
    if (accessTypesData) {
      setAccessTypes(
        accessTypesData?.data?.data?.map((type: any) => ({label: type.name, value: type.id}))
      )
    }
  }, [accessTypesData])

  const searchHost = (key: any, callback: any) => {
    if (key.length > 2) {
      ApiCalls.getHostSearch(key)
        .then((res) => {
          if (res?.data) {
            var hostResult = []
            for (let i = 0; i < res.data.hosts.length; i++) {
              const host = res.data.hosts[i]
              hostResult.push({label: host.hostName, value: host.hostId})
            }

            callback(hostResult)
          } else {
            callback([])
          }
        })
        .catch((e) => {
          callback([])
        })
    }
  }

  const hostSelect = (d: any) => {
    setHostId(d.value)
  }

  const unitAccessIdChange = (value: number) => {
    setSelectedAccess({...selectedAccess, unitAccessTypeId: value})
  }

  const buildingAccessIdChange = (value: number) => {
    setSelectedAccess({...selectedAccess, buildingAccessTypeId: value})
  }

  const unitAccessTextChange = (value: string) => {
    setSelectedAccess({...selectedAccess, unitAccessValue: value})
  }

  const buildingAccessTextChange = (value: string) => {
    setSelectedAccess({...selectedAccess, buildingAccessValue: value})
  }

  const storageTextChange = (value: any) => {
    setSelectedValues({...selectedValues, storageDescription: value})
  }

  const laundryMachineCycleChange = (value: string) => {
    setSelectedValues({...selectedValues, laundryMachineCycle: +value})
  }

  const laundryDryerCycleChange = (value: string) => {
    setSelectedValues({...selectedValues, laundryDryerCycle: +value})
  }

  const laundryLinenNumberChange = (value: string) => {
    setSelectedValues({...selectedValues, laundryLinenNumber: value})
  }

  const trashDaysChange = (value: string) => {
    setSelectedValues({...selectedValues, trashDays: value})
  }

  const handleBedroomChange = (index: number, hostPropertyBedroom: IHostPropertyBedroom) => {
    setSelectedHostPropertyBedrooms(
      selectedHostPropertyBedrooms.map((bedroom, currentIndex) =>
        currentIndex === index ? hostPropertyBedroom : bedroom
      )
    )
  }

  useEffect(() => {
    if (selectedNumberOfBedRooms > 0) {
      let arr: IHostPropertyBedroom[] = []
      let obj: IHostPropertyBedroom = {numberOfBeds: 1}
      for (let i = 0; i < selectedNumberOfBedRooms; i++) {
        arr.push(obj)
      }
      setSelectedHostPropertyBedrooms(arr)
    }
  }, [selectedNumberOfBedRooms])

  const onNextStepClick = () => {
    if (hostId == 0 || hostId == undefined) {
      setHasMissingField(true)
    } else {
      setHasMissingField(false)
      let count = 0
      selectedHostPropertyBedrooms.forEach((room) => {
        count += room?.numberOfBeds ? room?.numberOfBeds : 0
      })

      handleChangeHostProperty({
        cleaningProviderId: cleaningProviderId,
        hostId: hostId,
        numberOfBedrooms: selectedNumberOfBedRooms,
        numberOfBathrooms: selectedNumberOfBathRooms,
        numberOfBeds: selectedNumberOfBedRooms ? count : selectedNumberOfBeds,
        hostPropertyBedrooms: selectedHostPropertyBedrooms,
        hostPropertyAccess: selectedAccess,
        hostPropertyServiceProvider: selectedValues,
      })

      goToStep(3)
    }
  }

  const onBackStepClick = () => {
    goToStep(1)
  }

  return (
    <>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='form w-100'>
            <div className='row mb-6 d-flex justify-content-center'>
              <div className='fv-row col-lg-12 mb-5'>
                <label className='form-label fs-6 fw-bolder text-dark w-100'>Client</label>
                <div>
                  <AsyncSelect
                    name='form-field-name'
                    placeholder='Type at least 3 characters to search for clients'
                    defaultOptions
                    cacheOptions
                    loadOptions={searchHost}
                    onChange={hostSelect}
                    noOptionsMessage={() =>
                      'No client name found - please click "client directory" to add your client first'
                    }
                  />
                  {hasMissingField && hostId == 0 && (
                    <strong className='text-danger'>Missing Field</strong>
                  )}
                </div>
              </div>
            </div>
            {/* Access Unit Area */}
            <div className='row mb-6 border-bottom pb-5'>
              <div className='col-lg-4 col-form-label'>
                <label className='fw-bolder text-dark fs-6'>Access Unit</label>
                <div>
                  <small className='text-muted'>
                    Provide additional information about unit access process
                  </small>
                </div>
              </div>
              <div className='col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  How do cleaners access the unit? <span className='text-danger'>*</span>
                </label>
                <small className='text-muted col-lg-12 mb-3'>
                  This information will be kept strictly confidential and will not be shared with
                  anyone except the assigned cleaner(s). The access information will only be made
                  available to the cleaner on the day of cleaning to ensure the highest level of
                  security.
                </small>
                <div className='row'>
                  <div className='col-lg-3'>
                    <SelectInput
                      options={accessTypes}
                      onValueChanged={unitAccessIdChange}
                      defaultValue={selectedAccess?.unitAccessTypeId}
                    />
                    {(missingField || hasMissingField) && !selectedAccess.unitAccessTypeId && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                  </div>
                  <div className='col-lg-9'>
                    <TextInput
                      onValueChange={unitAccessTextChange}
                      defaultValue={selectedAccess.unitAccessValue}
                    />
                    {(missingField || hasMissingField) && !selectedAccess.unitAccessValue && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Access Building Area */}
            <div className='row mb-6 border-bottom pb-5'>
              <div className='col-lg-4 col-form-label'>
                <label className='fw-bolder text-dark fs-6'>Access Building</label>
                <div>
                  <small className='text-muted'>
                    Provide additional information about building access process
                  </small>
                </div>
              </div>
              <div className='col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  How do cleaners access the building? <span className='text-danger'>*</span>
                </label>
                <small className='text-muted col-lg-12 mb-3'>
                  This information will be kept strictly confidential and will not be shared with
                  anyone except the assigned cleaner(s). The access information will only be made
                  available to the cleaner on the day of cleaning to ensure the highest level of
                  security.
                </small>
                <div className='row'>
                  <div className='col-lg-3'>
                    <SelectInput
                      options={accessTypes}
                      onValueChanged={buildingAccessIdChange}
                      defaultValue={selectedAccess?.buildingAccessTypeId}
                    />
                    {(missingField || hasMissingField) && !selectedAccess.buildingAccessTypeId && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                  </div>
                  <div className='col-lg-9'>
                    <TextInput
                      onValueChange={buildingAccessTextChange}
                      defaultValue={selectedAccess.buildingAccessValue}
                    />
                    {(missingField || hasMissingField) && !selectedAccess.buildingAccessValue && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Laundry Area */}
            <div className='row mb-6 border-bottom pb-5'>
              <div className='col-lg-4 col-form-label'>
                <label className='fw-bolder text-dark fs-6'>Laundry</label>
                <div>
                  <small className='text-muted'>
                    Provide additional information about laundry process
                  </small>
                </div>
              </div>
              <div className='col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  Does that property have a laundry? <span className='text-danger'>*</span>
                </label>
                <div className='col-lg-12'>
                  <SelectInput
                    options={booleanOptions}
                    onValueChanged={(value) =>
                      setSelectedValues({
                        ...selectedValues,
                        hasLaundry: value === 1 ? true : false,
                      })
                    }
                    defaultValue={selectedValues.hasLaundry ? 1 : 2}
                  />
                </div>
                {selectedValues.hasLaundry && (
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                      Does that property have a dryer?
                    </label>
                    <div className='col-lg-12'>
                      <SelectInput
                        options={booleanOptions}
                        onValueChanged={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            hasDryer: value === 1 ? true : false,
                          })
                        }
                        defaultValue={selectedValues.hasDryer ? 1 : 2}
                      />
                    </div>
                  </>
                )}
                <>
                  <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                    Client need laundry service to be included your quote?{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <div className='col-lg-12'>
                    <SelectInput
                      options={booleanOptions}
                      onValueChanged={(value) =>
                        setSelectedValues({
                          ...selectedValues,
                          laundryInQuote: value === 1 ? true : false,
                        })
                      }
                      defaultValue={selectedValues.laundryInQuote ? 1 : 2}
                    />
                  </div>
                </>
                {selectedValues.laundryInQuote && (
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                      Will the laundry to be done in the promises?{' '}
                      <span className='text-danger'>*</span>
                    </label>
                    <div className='col-lg-12'>
                      <SelectInput
                        options={booleanOptions}
                        onValueChanged={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            laundryToBeDone: value === 1 ? true : false,
                          })
                        }
                        defaultValue={selectedValues.laundryToBeDone ? 1 : 2}
                      />
                    </div>
                  </>
                )}
                {selectedValues.laundryToBeDone && (
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                      How many cycles does it take for your washing machine and dryer to wash your
                      linens in a regular turnover? <span className='text-danger'>*</span>
                    </label>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <TextInput
                          onValueChange={laundryMachineCycleChange}
                          defaultValue={selectedValues?.laundryMachineCycle}
                          type='number'
                          placeholder='Laundry Machine (Counter)'
                        />
                        {(missingField || hasMissingField) &&
                          selectedValues.laundryToBeDone &&
                          selectedValues?.laundryMachineCycle == 0 && (
                            <strong className='text-danger'>Missing field</strong>
                          )}
                      </div>
                      <div className='col-lg-6'>
                        <TextInput
                          onValueChange={laundryDryerCycleChange}
                          defaultValue={selectedValues?.laundryDryerCycle}
                          type='number'
                          placeholder='Dryer (Counter)'
                        />
                        {(missingField || hasMissingField) &&
                          selectedValues.laundryToBeDone &&
                          selectedValues?.laundryDryerCycle == 0 && (
                            <strong className='text-danger'>Missing field</strong>
                          )}
                      </div>
                    </div>
                  </>
                )}
                {selectedValues.laundryInQuote && !selectedValues.laundryToBeDone && (
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                      Please provide the number of linens for each turnover{' '}
                      <span className='text-danger'>*</span>
                    </label>
                    <div className='col-lg-6'>
                      <TextInput
                        onValueChange={laundryLinenNumberChange}
                        defaultValue={selectedValues?.laundryLinenNumber}
                        type='text'
                        placeholder='King Sheet / Queen Sheet / Twin Sheet etc.'
                      />
                      {(missingField || hasMissingField) &&
                        !selectedValues.laundryToBeDone &&
                        selectedValues?.laundryLinenNumber == '' && (
                          <strong className='text-danger'>Missing field</strong>
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Storage Area */}
            <div className='row mb-6 border-bottom pb-5'>
              <div className='col-lg-4 col-form-label'>
                <label className='fw-bolder text-dark fs-6'>Storage</label>
                <div>
                  <small className='text-muted'>
                    Provide additional information about storage process
                  </small>
                </div>
              </div>
              <div className='col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  Property have a storage? <span className='text-danger'>*</span>
                </label>
                {selectedValues.hasStorage && (
                  <small className='text-muted mb-3'>
                    Please give more information about the location and access details of the
                    storage for your staff
                  </small>
                )}
                <div className='row'>
                  <div className='col-lg-3'>
                    <SelectInput
                      options={booleanOptions}
                      onValueChanged={(value) =>
                        setSelectedValues({
                          ...selectedValues,
                          hasStorage: value === 1 ? true : false,
                        })
                      }
                      defaultValue={selectedValues.hasStorage ? 1 : 2}
                    />
                  </div>
                  <div className='col-lg-9'>
                    {selectedValues.hasStorage && (
                      <TextInput
                        onValueChange={storageTextChange}
                        defaultValue={selectedValues?.storageDescription}
                      />
                    )}
                    {(missingField || hasMissingField) &&
                      selectedValues.hasStorage &&
                      (!selectedValues?.storageDescription ||
                        selectedValues?.storageDescription === '') && (
                        <strong className='text-danger'>Missing field</strong>
                      )}
                  </div>
                </div>
                {selectedValues.hasStorage && (
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                      Does the client require storage service to be included in their quote?{' '}
                      <span className='text-danger'>*</span>
                    </label>
                    <div className='col-lg-12'>
                      <SelectInput
                        options={booleanOptions}
                        onValueChanged={(value) =>
                          setSelectedValues({
                            ...selectedValues,
                            storageInQuote: value === 1 ? true : false,
                          })
                        }
                        defaultValue={selectedValues.storageInQuote ? 1 : 2}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Trash Area */}
            <div className='row mb-6 border-bottom pb-5'>
              <div className='col-lg-4 col-form-label'>
                <label className='fw-bolder text-dark fs-6'>Trash Disposal</label>
                <div>
                  <small className='text-muted'>
                    Provide additional information about trash disposal process
                  </small>
                </div>
              </div>
              <div className='col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  Does the trash need taken out on a specific day of the week?{' '}
                  <span className='text-danger'>*</span>
                </label>
                <div className='col-lg-12'>
                  <SelectInput
                    options={booleanOptions}
                    onValueChanged={(value) =>
                      setSelectedValues({
                        ...selectedValues,
                        hasTrashDisposal: value === 1 ? true : false,
                      })
                    }
                    defaultValue={selectedValues.hasTrashDisposal ? 1 : 2}
                  />
                </div>
                {selectedValues.hasTrashDisposal && (
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark col-lg-12 mt-5'>
                      Which days? <span className='text-danger'>*</span>
                    </label>
                    <div className='col-lg-12'>
                      {/*<SelectInput
                                        options={weekDaysOptions}
                                        onValueChanged={(value) =>
                                            setTrashDays(value)
                                        }
                                        defaultValue={getTrashDays()}
                                    />*/}
                      <TextInput
                        onValueChange={trashDaysChange}
                        defaultValue={selectedValues?.trashDays}
                        placeholder='Day List / Hours (Before)'
                      />
                      {(missingField || hasMissingField) &&
                        selectedValues.hasTrashDisposal &&
                        selectedValues?.trashDays === '' && (
                          <strong className='text-danger'>Missing field</strong>
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Parking Area */}
            <div className='row mb-6 border-bottom pb-5'>
              <div className='col-lg-4 col-form-label'>
                <label className='fw-bolder text-dark fs-6'>Parking</label>
                <div>
                  <small className='text-muted'>
                    Provide additional information about parking process
                  </small>
                </div>
              </div>
              <div className='col-lg-8'>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  Property have parking?
                </label>
                {selectedValues.hasParking && (
                  <small className='text-muted mb-3'>
                    Please give more information about the parking
                  </small>
                )}
                <div className='row'>
                  <div className='col-lg-3'>
                    <SelectInput
                      options={booleanOptions}
                      onValueChanged={(value) =>
                        setSelectedValues({
                          ...selectedValues,
                          hasParking: value === 1 ? true : false,
                        })
                      }
                      defaultValue={selectedValues.hasParking ? 1 : 2}
                    />
                  </div>
                  <div className='col-lg-9'>
                    {selectedValues.hasParking && (
                      <>
                        <TextInput
                          onValueChange={(text) =>
                            setSelectedValues({...selectedValues, parkingDescription: text})
                          }
                          defaultValue={selectedValues.parkingDescription}
                          placeholder='Please provide information on how to use the parking service (Paid or free etc.)'
                        />
                        {(missingField || hasMissingField) &&
                          selectedValues.hasParking &&
                          (!selectedValues.parkingDescription ||
                            selectedValues.parkingDescription === '') && (
                            <strong className='text-danger'>Missing Field</strong>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Property Size Area */}
            <div className='row mb-6'>
              <div className='col-lg-4 col-form-label'>
                <label className='fw-bolder text-dark fs-6'>Property Size</label>
                <div>
                  <small className='text-muted'>
                    Provide additional information about property size process
                  </small>
                </div>
              </div>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-12 mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Size (sqft.)</label>
                    <TextInput
                      onValueChange={(text) =>
                        setSelectedValues({...selectedValues, propertySize: +text})
                      }
                      type='number'
                      defaultValue={selectedValues.propertySize}
                      placeholder='Type here'
                    />
                    {(missingField || hasMissingField) && selectedValues.propertySize == 0 && (
                      <strong className='text-danger'>Missing Field</strong>
                    )}
                  </div>
                  <div className='row mb-5'>
                    <div className='col-lg-4'>
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        Number of Bedrooms
                      </label>
                      <div className='row align-items-center'>
                        <div className='col-lg-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-dark w-100'
                            onClick={() => {
                              setSelectedNumberOfBedRooms(
                                selectedNumberOfBedRooms > 0 ? selectedNumberOfBedRooms - 1 : 0
                              )
                              if (selectedHostPropertyBedrooms.length > 1) {
                                setSelectedHostPropertyBedrooms(
                                  selectedHostPropertyBedrooms.filter(
                                    (bedroom, index) =>
                                      index !== selectedHostPropertyBedrooms.length - 1
                                  )
                                )
                              } else {
                                setSelectedHostPropertyBedrooms([])
                              }
                            }}
                          >
                            -
                          </button>
                        </div>
                        <div className='col-lg-6'>
                          <input
                            value={selectedNumberOfBedRooms ? selectedNumberOfBedRooms : 'Studio'}
                            placeholder='Type bedroom number'
                            className='form-control text-center'
                            type='text'
                            autoComplete='off'
                            disabled={true}
                            readOnly={true}
                          />
                        </div>
                        <div className='col-lg-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-dark w-100'
                            onClick={() => {
                              let hostPropertyBedroom: IHostPropertyBedroom = {numberOfBeds: 1}
                              setSelectedNumberOfBedRooms(selectedNumberOfBedRooms + 1)
                              setSelectedHostPropertyBedrooms([
                                ...selectedHostPropertyBedrooms,
                                hostPropertyBedroom,
                              ])
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      {(missingField || hasMissingField) && selectedNumberOfBedRooms < 0 && (
                        <strong className='text-danger'>Missing Field</strong>
                      )}
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-lg-4'>
                      <label className='form-label fs-6 fw-bolder text-dark'>
                        Number of Bathroom
                      </label>
                      <div className='row align-items-center'>
                        <div className='col-lg-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-dark w-100'
                            onClick={() => {
                              if (selectedNumberOfBathRooms > 1) {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 0.5)
                              } else if (selectedNumberOfBathRooms === 1) {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 1)
                              }
                            }}
                          >
                            -
                          </button>
                        </div>
                        <div className='col-lg-6'>
                          <input
                            value={selectedNumberOfBathRooms}
                            placeholder='Type bathroom number'
                            className='form-control text-center'
                            type='text'
                            autoComplete='off'
                            disabled={true}
                            readOnly={true}
                          />
                        </div>
                        <div className='col-lg-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-dark w-100'
                            onClick={() => {
                              if (selectedNumberOfBathRooms === 0) {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 1)
                              } else {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 0.5)
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      {(missingField || hasMissingField) && selectedNumberOfBathRooms < 0.5 && (
                        <strong className='text-danger'>Missing Field</strong>
                      )}
                    </div>
                  </div>
                  {selectedHostPropertyBedrooms.length > 0 && (
                    <div className='row mb-5'>
                      <div className='col-lg-12'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Number of Bed</label>
                        <div className='row'>
                          {selectedHostPropertyBedrooms.map((bedroom, index) => (
                            <BedroomCard
                              index={index}
                              hostPropertyBedroom={bedroom}
                              changeHostPropertyBedroom={handleBedroomChange}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-between flex-row w-100 mt-6'>
              <ArrowButton type='back' func={onBackStepClick} />
              <ArrowButton type='forward' func={onNextStepClick} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPropertyProviders

type CardProps = {
  index: number
  hostPropertyBedroom: IHostPropertyBedroom
  changeHostPropertyBedroom: (index: number, hostPropertyBedroom: IHostPropertyBedroom) => void
  disabled?: boolean
}

const BedroomCard: React.FC<CardProps> = ({
  index,
  hostPropertyBedroom,
  changeHostPropertyBedroom,
}) => {
  const [numberOfBeds, setNumberOfBeds] = useState(
    hostPropertyBedroom.numberOfBeds ? hostPropertyBedroom.numberOfBeds : 1
  )

  return (
    <Card className='col-lg-3 m-3 shadow'>
      <strong>Bedroom #{index + 1}</strong>
      <div className='d-flex flex-row justify-content-start align-items-center flex-wrap'>
        <img alt='bed' src={toAbsoluteUrl('/media/icons/duotone/Home/Bed.svg')} className='me-3' />
        <small className={'me-3'}>Number of Beds</small>
        <div className='d-flex flex-row justify-content-center align-items-center mt-5 mb-5'>
          <div className='row'>
            <div className='col-lg-4'>
              <button
                type='button'
                className='btn btn-sm btn-dark'
                onClick={() => {
                  const newValue: IHostPropertyBedroom = {
                    numberOfBeds: numberOfBeds > 0 ? numberOfBeds - 1 : 0,
                  }
                  setNumberOfBeds(numberOfBeds > 0 ? numberOfBeds - 1 : 0)
                  changeHostPropertyBedroom(index, newValue)
                }}
              >
                -
              </button>
            </div>
            <div className='col-lg-4 d-flex align-items-center justify-content-center'>
              <strong className='w-100 text-center'>{numberOfBeds}</strong>
            </div>
            <div className='col-lg-4'>
              <button
                type='button'
                className='btn btn-sm btn-dark'
                onClick={() => {
                  const newValue: IHostPropertyBedroom = {numberOfBeds: numberOfBeds + 1}
                  setNumberOfBeds(numberOfBeds + 1)
                  changeHostPropertyBedroom(index, newValue)
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
