import React, { Dispatch, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { JobAction } from '../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { toAmazonUrl } from '../../../../utils/util';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import ApiCalls from '../../../../network/ApiCalls';
import { useDialog } from '../../../../contexts/DialogContext';
import NotFound from '../../../../umut-components/Icons/NotFound';
import dayjs from 'dayjs';
import config from '../../../../config/config.json';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';
import NoPhoto from '../../../../umut-components/Icons/NoPhoto';

type Props = {
    jobEventsActive: boolean;
    refetch: () => void;
}

const IssueMediaDialog: React.FC<Props> = ({ jobEventsActive, refetch }) => {
    const { showFailureDialog } = useDialog();
    const { jobIssueMediaDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        dispatch(jobActionCreator.closeIssueDialog());
    }

    const resolveIssue = async () => {
        if (jobIssueMediaDialog.issue) {
            setLoading(true);
            try {
                await ApiCalls.resolveIssue(jobIssueMediaDialog.issue.id);
                setLoading(false);
                refetch();
                handleClose();
            }
            catch (err: any) {
                setLoading(false);
                showFailureDialog(err?.response?.data?.message);
            }
        }
    }

    return (
        <Modal show={jobIssueMediaDialog.modalOpen} onHide={handleClose} size="lg" centered>
        <ModalHeader closeButton>
            <Modal.Title>Issue Media</Modal.Title>
        </ModalHeader>
        <Modal.Body>
            {
                jobIssueMediaDialog.issue ?
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='row'>
                                <div className='d-flex align-items-center mb-3'>
                                    <ProfilePhotoTemp classNames='me-2' width={35} height={35} borderRadius='50%' profilePhotoPath={jobIssueMediaDialog.issue.reporter.profilePhotoPath} firstName={jobIssueMediaDialog.issue.reporter.firstName} lastName={jobIssueMediaDialog.issue.reporter.lastName} />
                                    <strong>{jobIssueMediaDialog.issue.reporter.firstName + ' ' + jobIssueMediaDialog.issue.reporter.lastName}</strong>
                                </div>
                                <strong className='col-sm-4 mt-3'>
                                    Reported At
                                </strong>
                                <small className='col-sm-8 text-muted mt-3'>
                                    {jobIssueMediaDialog.issue.reporter.reportOn ? dayjs(jobIssueMediaDialog.issue.reporter.reportOn).format(config.date_time_format) : "Unknown"}
                                </small>
                                <strong className='col-sm-4 mt-3'>
                                    Title
                                </strong>
                                <small className='col-sm-8 text-muted mt-3'>
                                    {jobIssueMediaDialog.issue.jobIssueTypeName}
                                </small>
                                <strong className='col-sm-4 mt-3'>
                                    Issue Note
                                </strong>
                                <small className='col-sm-8 text-muted mt-3'>
                                    {jobIssueMediaDialog.issue.note}
                                </small>
                                <strong className='col-sm-4 mt-3'>
                                    Status
                                </strong>
                                <small className='col-sm-8 text-muted mt-3'>
                                    {jobIssueMediaDialog.issue.jobIssueStatusTypeName}
                                </small>
                                {jobIssueMediaDialog.issue.jobIssueStatusTypeName === "Resolved" ?
                                    <>
                                        <strong className='col-sm-4 mt-3'>
                                            Resolved By
                                        </strong>
                                        <small className='col-sm-8 text-muted mt-3'>
                                            {jobIssueMediaDialog.issue.resolver.firstName} {jobIssueMediaDialog.issue.resolver.lastName}
                                        </small>
                                        <strong className='col-sm-4 mt-3'>
                                            Resolved At
                                        </strong>
                                        <small className='col-sm-8 text-muted mt-3'>
                                            {jobIssueMediaDialog.issue.resolver.resolveOn ? dayjs(jobIssueMediaDialog.issue.resolver.resolveOn).format(config.date_time_format) : "Unknown"}
                                        </small>
                                    </>
                                    : null}
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            {
                                jobIssueMediaDialog.issue?.mediaPath && jobIssueMediaDialog.issue?.mediaMimeType ?
                                    (jobIssueMediaDialog.issue?.mediaMimeType.includes("png") || jobIssueMediaDialog.issue?.mediaMimeType.includes("jpeg") || jobIssueMediaDialog.issue?.mediaMimeType.includes("jpg")) ?
                                        jobIssueMediaDialog.issue?.mediaPath ? <img className='w-100' alt='issue' src={toAmazonUrl(jobIssueMediaDialog.issue?.mediaPath)} /> : null
                                        :
                                        <video style={{ width: '100%' }} controls>
                                            <source src={toAmazonUrl(jobIssueMediaDialog.issue?.mediaPath)} type={jobIssueMediaDialog.issue?.mediaMimeType} />
                                            Your browser does not support HTML video.
                                        </video> : <div className='d-flex h-100 align-items-center'><NoPhoto title='No Media' /></div>
                            }
                        </div>
                    </div>
                    :
                    null
            }
        </Modal.Body>
    </Modal>
    )
}

export default IssueMediaDialog;