import { IHost } from "../../interfaces/Host";

export const OPEN_ADD_HOST_DIALOG = "OPEN_ADD_HOST_DIALOG";
export interface OpenAddHostDialog {
    type: typeof OPEN_ADD_HOST_DIALOG,
    refetch: () => void
}
export const CLOSE_ADD_HOST_DIALOG = "CLOSE_ADD_HOST_DIALOG";
export interface CloseAddHostDialog {
    type: typeof CLOSE_ADD_HOST_DIALOG,
}
export const OPEN_HOST_EDIT_DIALOG = "OPEN_HOST_EDIT_DIALOG"
export interface OpenEditHostDialog {
    type: typeof OPEN_HOST_EDIT_DIALOG,
    host: IHost,
    refetch: () => void
}
export const CLOSE_HOST_EDIT_DIALOG = "CLOSE_HOST_EDIT_DIALOG"
export interface CloseEditHostDialog {
    type: typeof CLOSE_HOST_EDIT_DIALOG
}

export type HostAction =
    | OpenAddHostDialog
    | CloseAddHostDialog
    | OpenEditHostDialog
    | CloseEditHostDialog