import React, {Dispatch, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {HostAction} from '../../../redux/actionTypes/hostTypes'
import * as hostActionCreators from '../../../redux/actionCreators/hostActionCreators'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {HostAddForm} from '../../../umut-components/Forms/HostAddForm'

type Props = {}

const HostAddDialog: React.FC<Props> = () => {
  const {hostAddDialog} = useSelector((state: RedusxAppState) => state.host)
  const dispatch = useDispatch<Dispatch<HostAction>>()

  const handleClose = () => {
    dispatch(hostActionCreators.closeAddHostDialog())
  }

  return (
    <Modal show={hostAddDialog.modalOpen} onHide={handleClose} size='lg' centered>
      <ModalHeader closeButton>
        <Modal.Title>Add Client</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <HostAddForm />
      </Modal.Body>
    </Modal>
  )
}

export default HostAddDialog
