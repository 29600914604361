import React, { Dispatch, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteActions } from '../../redux/actionTypes/deleteDialogTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import * as deleteDialogActionCreators from '../../redux/actionCreators/deleteDialogActionCreators';

type Props = {
    modalOpen: boolean,
    handleClose: () => void,
    handleDisable: () => Promise<void>
}

const PaymentDialog: React.FC<Props> = ({ modalOpen, handleClose, handleDisable }) => {
    return (
        <Modal show={modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>Disable Stripe Account</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                Do you want to disable your Stripe account? You can reactivate it later.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleDisable(); handleClose(); }}>
                    Disable
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PaymentDialog;