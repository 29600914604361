import * as actions from '../actionTypes/jobAvailableTypes'

export interface JobAvailableState {
    modalOpen: boolean,
    jobAvailableId: number | null
    hostPropertyId: number | null,
    jobTimezone?: string,
    selectedStaffs: number[] | null
    refetch: () => void,
    roleId: number | null,
    cleaningPeriodStart: string | null
}

const initialState: JobAvailableState = {
    modalOpen: false,
    refetch: () => { },
    jobAvailableId: null,
    hostPropertyId: null,
    jobTimezone: undefined,
    selectedStaffs: null,
    roleId: null,
    cleaningPeriodStart: null
}

export default function jobAvailableReducer(
    state: JobAvailableState = initialState,
    action: actions.JobAvailableActions
): JobAvailableState {
    switch (action.type) {
        case actions.OPEN_JOB_AVAILABLE_DIALOG:
            return {
                ...state,
                modalOpen: true,
                jobAvailableId: action.jobAvailableId,
                cleaningPeriodStart: action.cleaningPeriodStart,
                refetch: action.refetch
            }
        case actions.CLOSE_JOB_AVAILABLE_DIALOG:
            return { ...state, modalOpen: false, jobAvailableId: null, cleaningPeriodStart: null }
        case actions.PUSH_HOST_PROPERTY:
            return { ...state, hostPropertyId: action.hostPropertyId, jobTimezone: action.jobTimezone }
        case actions.PUSH_SELECTED_STAFFS:
            return { ...state, selectedStaffs: action.selectedStaffs }
        case actions.PUSH_ROLEID:
            return { ...state, roleId: action.roleId }
        default:
            return state
    }
}
