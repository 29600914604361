import React, { VoidFunctionComponent } from 'react'
import { Modal } from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import ApiCalls from '../../network/ApiCalls'
import { Spinner } from 'react-bootstrap-v5'
import UserEditForm from '../../umut-components/Forms/UserEditForm'
import { decryptText } from '../../utils/util'

type Props = {
  modalOpen: boolean,
  modalClose: () => void
}

const StaffEditDialog: React.FC<Props> = ({ modalOpen, modalClose }) => {
  const history = useHistory()
  let { staffId }: any = useParams()
  staffId = decryptText(staffId);

  const {
    data: userDetailData,
    isLoading: userDetailLoading,
    error: isUserDetailError,
  } = useQuery(['Get Staff Details', Number(staffId)], () => ApiCalls.getUserDetailsById(staffId), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
    enabled: staffId ? true : false,
  })


  return (
    <Modal show={modalOpen} onHide={modalClose} size="lg" centered>
      <ModalHeader closeButton>
        <Modal.Title>Staff Edit</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {userDetailLoading ? (
          <Spinner animation='border' size='sm' />
        ) : (
          <UserEditForm
            user={userDetailData?.data}
            userId={Number(staffId)}
            successFunc={() => {
              modalClose()
            }}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default StaffEditDialog
