import { IGetAllVendors, IVendorService } from '../../interfaces/Vendors';
import * as actions from '../actionTypes/vendorTypes';

export function OpenVendorEditDialog(refetch: () => void, vendor: IGetAllVendors): actions.OpenVendorEditDialog {
    return {
        type: actions.OPEN_VENDOR_EDIT_DIALOG,
        refetch,
        vendor
    }
}

export function CloseVendorEditDialog(): actions.CloseVendorEditDialog {
    return {
        type: actions.CLOSE_VENDOR_EDIT_DIALOG
    }
}

export function OpenVendorOfferDialog(refetch: () => void): actions.OpenVendorOfferDialog {
    return {
        type: actions.OPEN_VENDOR_OFFER_DIALOG,
        refetch,
    }
}

export function CloseVendorOfferDialog(): actions.CloseVendorOfferDialog {
    return {
        type: actions.CLOSE_VENDOR_OFFER_DIALOG
    }
}

export function OpenVendorEditOfferDialog(vendorService: IVendorService): actions.OpenVendorEditOfferDialog {
    return {
        type: actions.OPEN_VENDOR_EDIT_OFFER_DIALOG,
        vendorService
    }
}

export function CloseVendorEditOfferDialog(): actions.CloseVendorEditOfferDialog {
    return {
        type: actions.CLOSE_VENDOR_EDIT_OFFER_DIALOG
    }
}