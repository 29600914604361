import {
    Column,
    DataGrid,
    HeaderFilter,
    Paging,
    Scrolling,
    FilterRow,
    Export,
    SearchPanel,
    Summary,
    TotalItem,
} from 'devextreme-react/data-grid'
import { FC, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { IStaff } from '../../../interfaces/Staff'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import ProfilePhotoTemp from '../../../umut-components/Icons/ProfilePhotoTemp'
import { encryptText, onExporting, toAmazonUrl } from '../../../utils/util'
import PendingCheckDialog from './PendingCheckDialog'

type Props = {
    data: IStaff[],
    refetch: () => void
}
const StaffStatusTable: FC<Props> = ({ data, refetch }) => {
    const history = useHistory()
    const [show, setShow] = useState(false)
    const [approvaledData, setApprovaledData] = useState<any>({})
    const tableRef = useRef<DataGrid>(null)
    const toStaffProfile = (cellData: any) => {
        history.push({
            pathname: '/vendor-details/' + encryptText(cellData?.data?.id)
        })
    }

    const closeModal = () => {
        setShow(false)
        refetch()
    }

    const calculateFullName = (cellValue: any) => {
        return cellValue?.firstName + ' ' + cellValue?.lastName
    }
    const renderProfilePhoto = (cellValue: any) => {
        return (
            cellValue?.data?.profilePhotoPath ?
                <img alt='pp' style={{ width: 45, height: 45, borderRadius: '50%' }} src={cellValue?.data?.profilePhotoPath ? toAmazonUrl(cellValue?.data?.profilePhotoPath) : toAbsoluteUrl('/media/icons/noImage.png')} />
                : <ProfilePhotoTemp firstName={cellValue?.data?.firstName} lastName={cellValue?.data?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }
    const renderCheckButton = (cellData: any) => {
        
        return (
            <button className="btn btn-success" onClick={() => { setApprovaledData({ data: cellData?.data, statusId: 1 }); setShow(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                </svg>
            </button>
        )
    }

    const renderDeclineButton = (cellData: any) => {
        return (
            <button className="btn btn-danger" onClick={() => { setApprovaledData({ data: cellData?.data, statusId: 2 }); setShow(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
            </button>
        )
    }
    if (data?.length === 0) {
        return <EmptyTable title="No Pending Staff" />
      }
    return (
    <div className="position-relative">
              <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
         Clear Filter
        </span>
      </div>
        <DataGrid
            id='pendingVendors'
            keyExpr='id'
            onRowPrepared={(e) => {
                e.rowElement.classList.add('data-grid-row')
            }}
            dataSource={data}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            hoverStateEnabled={true}
            height={'calc(54vh - 60px)'}
            ref={tableRef}
            allowColumnResizing={true}
            selection={{ mode: 'single' }}
            onExporting={(e) => onExporting(e, "Pending Staff")}
            onCellDblClick={toStaffProfile}
        >
            <Export enabled />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />
            <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
            <Paging defaultPageSize={50} />
            <Summary>
                <TotalItem cssClass='absolute-right' displayFormat="Total Vendors: {0}" column='name' summaryType='count' />
            </Summary>

            <Column minWidth={100} cellRender={renderProfilePhoto} cssClass='cls' />
            <Column
                dataField='firstName'
                calculateCellValue={calculateFullName}
                caption='Fullname'
                minWidth={280}
                dataType='string'
                cssClass='cls'
            />
            <Column dataField='email' width={200} cssClass='cls' />
            <Column dataField='phoneNumber' dataType='string' width={180} cssClass='cls' />
            <Column dataField='roleName' caption="Role" dataType='string' width={170} cssClass='cls' />
            <Column width={100} cellRender={renderCheckButton} caption="Approve" cssClass='cls' />
            <Column width={100} cellRender={renderDeclineButton} caption="Decline" cssClass='cls' />
        </DataGrid>

        <PendingCheckDialog show={show} handleClose={closeModal} data={approvaledData} />
    </div>
    )
}


export default StaffStatusTable