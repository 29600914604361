import React from 'react'
import { Tab } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import ApiCalls from '../../../../network/ApiCalls'
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading'
import { decryptText } from '../../../../utils/util'
import JobTable from './JobTable'

const Jobs: React.FC = () => {
  let { staffId }: any = useParams()
  staffId = decryptText(staffId); const {
    data: jobData,
    isLoading: jobLoading,
    error: jobError,
  } = useQuery('Job History', () => ApiCalls.getJobsByStaffId(staffId), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  if (jobLoading) {
    return <SkeletonTableLoading />
  }

  return (
    <div>
      <JobTable data={jobData?.data?.data} />
    </div>
  )
}

export default Jobs
