import * as jobAvailableCreators from '../../redux/actionCreators/JobAvailableActionCreators'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import JobsAvailableWizard from '../../umut-components/JobsAvailableWizard/JobsAvailableWizard'
import {Dispatch} from 'react'
import {JobAvailableActions} from '../../redux/actionTypes/jobAvailableTypes'
import {Dialog, DialogContent} from '@material-ui/core'

const JobsAvailableModal = () => {
  const {modalOpen} = useSelector((state: RedusxAppState) => state.jobAvailable)
  const dispatch = useDispatch<Dispatch<JobAvailableActions>>()

  const closeModal = () => {
    dispatch(jobAvailableCreators.closeJobAvailableDialog())
  }

  return (
    <>
      <Dialog
        open={modalOpen}
        scroll={'paper'}
        onClose={closeModal}
        maxWidth='lg'
        fullWidth={true}
        style={{overflowY: 'unset'}}
      >
        <DialogContent style={{overflowY: 'scroll'}}>
          {modalOpen && <JobsAvailableWizard />}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default JobsAvailableModal
