import {ReactJSXElement} from '@emotion/react/types/jsx-namespace'
import {FC, ReactComponentElement} from 'react'
import Select, {components} from 'react-select'
import {FixedSizeList as List} from 'react-window'
import {selectStyles} from '../../config/selectStyle'

const MenuList = ({...props}) => {
  const {options, children, maxHeight, maxWidth, getValue} = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * 35
  return (
    <List
      height={maxHeight}
      width={maxWidth}
      itemCount={children.length}
      itemSize={35}
      initialScrollOffset={initialOffset}
    >
      {({index, style}: any) => <div style={style}>{children[index]}</div>}
    </List>
  )
}
type Props = {
  isLoading: boolean
  onChange: (value: any) => void
  options: Array<any>
  errorMessage: string
  onInputChange: (value: any) => void
}
const VirtualSelect: FC<Props> = ({options, isLoading, onChange, errorMessage, onInputChange}) => {
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{errorMessage}</span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <div>
      <Select
        components={options?.length == 0 ? {NoOptionsMessage} : {MenuList}}
        styles={selectStyles}
        onInputChange={onInputChange}
        isLoading={isLoading}
        onChange={onChange}
        options={options}
      />
    </div>
  )
}

export default VirtualSelect
