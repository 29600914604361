import {Dispatch, FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core'

type Props = {
  modalOpen: boolean
  modalClose: () => void
  sendRequest: () => void
}
const ConfirmationDialog: FC<Props> = ({modalOpen, modalClose, sendRequest}) => {
  const closeModal = () => {
    modalClose()
  }

  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
        Assign
        <button type='button' className='close' onClick={closeModal}>
          <span aria-hidden='true'>×</span>
          <span className='sr-only'>Close</span>
        </button>
      </DialogTitle>
      <DialogContent className='d-flex flex-column'>
        <p className='text-warning fs-5 my-10'>
          The program date you selected has passed the cleaning period end date, do you want to make
          an assignment?
        </p>
        <div className='d-flex justify-content-end w-100'>
          <Button className='d-flex mx-5' variant='link' onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              sendRequest()
              closeModal()
            }}
          >
            Assign
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmationDialog
