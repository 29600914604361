import {debounce, Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {CheckBox} from 'devextreme-react'
import React, {FC, useEffect, useState} from 'react'
import {Card, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {AccountStatusObj} from '../../../../enums/AccountEnum'
import {IJobDetail} from '../../../../interfaces/Job'
import {IStaff} from '../../../../interfaces/Staff'
import ApiCalls from '../../../../network/ApiCalls'
import {AssignStaffJobRequest} from '../../../../network/PostRequestModels/JobRequests'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import DateTimePicker from '../../../../umut-components/Inputs/DateTimePicker'
import {TextInput} from '../../../../umut-components/Inputs/TextInput'
import {convertUTCDateToLocalDate, toRequestTimeFormat} from '../../../../utils/util'

type Props = {
  closeModal: () => void
  modalOpen: boolean
  refetch: () => void
  jobDetail: IJobDetail
}

const AssingmentModal: FC<Props> = ({modalOpen, closeModal, refetch, jobDetail}) => {
  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)

  const [reqLoading, setReqLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [scheduleStart, setScheduleStart] = useState(
    jobDetail.scheduledStart
      ? toRequestTimeFormat(jobDetail.hostPropertyCityTimeZone, jobDetail.scheduledStart)
      : ''
  )
  const [staffList, setStaffList] = useState<Array<number>>([])
  const [searchText, setSearchText] = useState<string>('')
  const [searchedStaffs, setSearchedStaffs] = useState<IStaff[]>([])

  const {
    data: staffs,
    isLoading,
    error,
  } = useQuery(
    ['Get Assignment Staff', jobDetail.vendorId, scheduleStart],
    () => ApiCalls.getStaffForAssignJob(jobDetail?.vendorId),
    {cacheTime: 50000, enabled: jobDetail.vendorId ? true : false}
  )
  const {data: notVerifiedStaff} = useQuery<AxiosResponse<IStaff>>(
    ['Get Staff Email Not Verified', jobDetail?.vendorId],
    () =>
      ApiCalls.getStaffEmailNotVerified(jobDetail?.vendorId, AccountStatusObj['EmailNotVerified']),
    {enabled: jobDetail?.vendorId ? true : false}
  )

  const debounceFunc = debounce(setSearchText, 400)

  const saveReAssign = async () => {
    try {
      let reAssignRequest: AssignStaffJobRequest = {
        jobId: jobDetail?.id,
        vendorId: vendorId,
        cleanerIds: [...staffList],
        scheduledStart: scheduleStart,
      }
      setReqLoading(true)
      await ApiCalls.putReSchedule(reAssignRequest)
      await refetch()
      setStaffList([])
      closeModal()
      setReqLoading(false)
    } catch (err: any) {
      setReqLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }
  const deleteByIdStaffList = (userId: number) => {
    const reduceList = [...staffList]
    reduceList.splice(reduceList.indexOf(userId), 1)
    setStaffList(reduceList)
  }

  useEffect(() => {
    if (staffs) {
      const searchedStaffs = staffs?.data?.data.filter((item: IStaff) =>
        item?.firstName.toLowerCase().includes(searchText.toLowerCase(), 0)
      )
      setSearchedStaffs(searchedStaffs)
    }
  }, [searchText, staffs])

  type StaffProps = {
    item: any
  }
  const StaffBox: FC<StaffProps> = (item) => {
    let isChecked = staffList.some((data: any) => data == item.item.userId)
    return (
      <Card
        style={{width: '24rem'}}
        className={`border border-secondary mt-10 gap-3 shadow-sm p-2`}
      >
        <div className='d-flex justify-content-end'>
          {
            <CheckBox
              value={isChecked}
              onValueChange={(check: boolean) => {
                isChecked
                  ? deleteByIdStaffList(item.item.userId)
                  : setStaffList([...staffList, item.item.userId])
              }}
            />
          }
        </div>
        <div className='d-flex px-10 '>
          <ProfilePhotoTemp
            width={75}
            height={75}
            fontSize={25}
            borderRadius='50%'
            profilePhotoPath={item?.item.profilePhotoPath}
            firstName={item.item.firstName ?? ''}
            lastName={item?.item.lastName ?? ''}
          />
          <div className='d-flex flex-column justify-content-center align-items-center ms-5'>
            <Card.Title>
              {item.item.firstName} {item.item.lastName}
            </Card.Title>
            <span className='d-flex text-muted text-left w-100'>{item.item.roleName}</span>
          </div>
        </div>
        <Card.Body>
          <div className='mb-7'>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span>Email:</span>
              <a
                href={`mailto:${item.item.email}`}
                className='text-muted d-flex align-items-center text-wrap'
              >
                {item.item.email}
              </a>
            </div>
            <div className='d-flex justify-content-between align-items-center my-3'>
              <span className='text-dark-75 font-weight-bolder mr-2'>Phone:</span>
              <a href={`tel:${item.item.phoneNumber}`} className='text-muted'>
                {item.item.phoneNumber}
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
    )
  }

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center w-100 mt-10'>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <div>
      <Dialog open={modalOpen} onClose={closeModal} fullScreen={true} style={{overflowY: 'unset'}}>
        <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
          Available Staff
          <button type='button' className='close btn-sm' onClick={closeModal}>
            <span aria-hidden='true'>×</span>
            <span className='sr-only'>Close</span>
          </button>
        </DialogTitle>
        <DialogContent style={{overflowY: 'scroll'}} className='h-700px'>
          {isLoading ? (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
              <Spinner animation='border' />
            </div>
          ) : (
            <div className='row gap-10 d-flex justify-content-center'>
              <div className='d-flex justify-content-end mt-5'>
                <TextInput
                  onValueChange={debounceFunc}
                  classNames='sticky sticky-top border border-light'
                  placeholder='Search'
                />
              </div>
              {
                searchedStaffs?.length == 0 ? (
                  <EmptyTable title='No suitable staff found' />
                ) : (
                  searchedStaffs.map((item: IStaff) => (
                    <>
                      <StaffBox item={item} />
                    </>
                  ))
                )
                // <List
                //     height={1200}
                //     columnCount={searchedStaffs?.length}
                //     columnWidth={250}
                //     itemData={searchedStaffs}
                //     rowCount={1000}
                //     rowHeight={250}
                //     width={1200}

                // >
                //     {({data,rowIndex,style})=>{
                //         console.log(style);

                //         return (
                //             <StaffBox item={data[rowIndex]} />
                //         )
                //     }}
                // </List>
              }
            </div>
          )}
        </DialogContent>
        <DialogContent style={{overflowY: 'unset'}}>
          <div className='d-flex justify-content-end w-100 gap-5 align-items-center'>
            <p className='text-danger'>{errorMessage}</p>
            <div>
              <strong className='text-muted'>Schedule start time</strong>
              <DateTimePicker
                dateOutOfRangeMessage='Time cannot be earlier than job start time.'
                onChange={(date: any) => {
                  setScheduleStart(date)
                }}
                min={dayjs(
                  convertUTCDateToLocalDate(
                    jobDetail?.scheduledStart
                      ? jobDetail?.scheduledStart
                      : jobDetail?.cleaningPeriodStart,
                    jobDetail.hostPropertyCityTimeZone
                  )
                ).toString()}
                defaultValue={dayjs(
                  convertUTCDateToLocalDate(
                    jobDetail?.scheduledStart
                      ? jobDetail?.scheduledStart
                      : jobDetail?.cleaningPeriodStart,
                    jobDetail.hostPropertyCityTimeZone
                  )
                ).toString()}
              />
              <small className='text-muted'>
                Job starts at{' '}
                {convertUTCDateToLocalDate(
                  jobDetail?.scheduledStart
                    ? jobDetail?.scheduledStart
                    : jobDetail?.cleaningPeriodStart,
                  jobDetail.hostPropertyCityTimeZone
                )}
              </small>
            </div>

            <div>
              <button
                type='button'
                disabled={reqLoading || staffList.length === 0}
                onClick={saveReAssign}
                className='btn btn-success'
              >
                ✔ Save Assignment {reqLoading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AssingmentModal
