import {AxiosResponse} from 'axios'
import {Dispatch, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import AddVendorService from './AddVendorService'
import VendorServiceCard from './VendorServiceCard'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {IVendorServiceListResponse} from '../../network/NetworkResponses/NetworkResponses'
import {VendorAction} from '../../redux/actionTypes/vendorTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {IVendorServiceCard} from '../../interfaces/Vendors'
import {EmptyCard} from '../../umut-components/Cards/EmptyCard'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import DeleteDialog from '../../umut-components/Modals/DeleteDialog'

const VendorServices = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [showAddDialog, setShowAddDialog] = useState(false)

  const {
    data: offeredServiceData,
    isLoading: servicesLoading,
    error: servicesError,
    refetch,
  } = useQuery<AxiosResponse<IVendorServiceCard[]>>(
    ['Vendor Services', user.userSection.vendorId],
    () => ApiCalls.getVendorServices(user.userSection.vendorId),
    {
      enabled: user.userSection.vendorId ? true : false,
      cacheTime: 500000,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <PageTitle>Services</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Body>
            <div className='d-flex flex-row flex-wrap'>
              {offeredServiceData?.data.length == 0 ? (
                <EmptyTable title='There is no services' classNames='mb-3' />
              ) : (
                <>
                  {offeredServiceData?.data.map((service: any) => (
                    <VendorServiceCard vendorService={service} refetch={refetch} />
                  ))}
                </>
              )}
            </div>
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-success mt-6'
                onClick={() => {
                  setShowAddDialog(true)
                }}
              >
                Add Service
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <AddVendorService
        visible={showAddDialog}
        handleClose={() => {
          setShowAddDialog(false)
        }}
        refetch={refetch}
      />
      <DeleteDialog />
    </>
  )
}

export default VendorServices
