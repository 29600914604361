import {
  Column,
  DataGrid, Export, HeaderFilter,
  Paging,
  Scrolling, SearchPanel
} from 'devextreme-react/data-grid'
import { Dispatch, FC, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import config from '../../config/config.json'
import * as violationActionCreators from '../../redux/actionCreators/violationActionCreators'
import { ViolationDetailActions } from '../../redux/actionTypes/violationTypes'
import ViewButton from '../../umut-components/Buttons/ViewButton'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import { getDateFilter, setDxFilterPlaceholder } from '../../utils/util'

type Props = {
  data: any
}
const ReferenceTable: FC<Props> = ({ data }) => {
  const showViewDispatch = useDispatch<Dispatch<ViolationDetailActions>>()
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)

  const renderViewButton = (cellData: any) => {
    return (
      <ViewButton
        classNames='btn-sm'
        iconWidth='15'
        iconHeight='15'
        viewFn={() => {
          showViewDispatch(violationActionCreators.openViolationView(cellData?.data))
        }}
      />
    )
  }
  const calculateFullName = (cellValue: any) => {
    return cellValue?.creatorFirstName + ' ' + cellValue?.creatorLastName
  }

  if (data?.length === 0) {
    return <EmptyTable title="Hooray! You've not received any violations, great job!" />
  }

  return (
    <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
         Clear Filter
        </span>
      </div>
        <DataGrid
          id='violationTable'
          keyExpr='id'
          onRowPrepared={(e) => {
            e.rowElement.classList.add('data-grid-row-violation')
          }}
          dataSource={data}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          ref={tableRef}
          hoverStateEnabled={true}
          height={'calc(60vh - 60px)'}
          allowColumnResizing={true}
          onEditorPreparing={setDxFilterPlaceholder}
          selection={{ mode: 'single' }}
        >
          <Export enabled={true} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
          <Paging defaultPageSize={50} />


          <Column dataField='description' minWidth={250} cssClass='cls' />
          <Column
            dataField='creatorFirstName'
            calculateCellValue={calculateFullName}
            caption='Creator'
            minWidth={180}
            dataType='string'
            cssClass='cls'
          />
          <Column
            dataField='violationTypeName'
            alignment='center'
            caption='Type Name'
            minWidth={150}
            cssClass='cls'
          />
          <Column
            dataField='violationStatusTypeName'
            alignment='center'
            caption='Status'
            minWidth={80}
            cssClass='cls'
          />
          <Column
            dataField='jobDate'
            
            dataType='date' selectedFilterOperation='between'
            format={config.date_time_format_dev_extreme}
            caption='Job Date'
            minWidth={80}
            cssClass='cls'
          >
             <HeaderFilter dataSource={getDateFilter('jobDate')} />
                </Column>

          <Column width={100} cellRender={renderViewButton} cssClass='cls' />
        </DataGrid>

    </div>
  )
}

export default ReferenceTable
