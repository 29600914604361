import React, { useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { encryptText, convertUTCDateToLocalDate, onExporting, getDateFilter, toConvertedDate, setDxFilterPlaceholder } from '../../../../../utils/util';
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable';
import ViewButton from '../../../../../umut-components/Buttons/ViewButton';
import { JobStatusType } from '../../../../../enums/JobEnum';
import { IJob } from '../../../../../interfaces/Job';
import config from '../../../../../config/config.json';

type Props = {
    data?: IJob[]
}

const JobTable: React.FC<Props> = ({ data }) => {
    const history = useHistory();
    const tableRef = useRef<DataGrid>(null)

    const displayStatusType = (cellData: any) => {
        const jobStatusType = cellData?.data?.jobStatusTypeName;
        return (<strong className={`text-${jobStatusType === JobStatusType.COMPLETED ? "success" : jobStatusType === JobStatusType.PENDING ? "warning" : "danger"}`}>{jobStatusType === "Started" ? "In Progress" : jobStatusType}</strong>)
    }

    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone);
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone);
    }
    const toJobDetail = (cellValue: any) => {
        if (cellValue?.data?.id) {
            // history.push({
            //     pathname:`/job-details/` + encryptText(cellValue?.data?.id)
            // })
            window.open(`/job-details/` + encryptText(cellValue?.data?.id))
        }



        if (data?.length === 0) {
            return <EmptyTable title='No Jobs' />
        }
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id={"jobTableHost"}
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                onCellClick={toJobDetail}
                hoverStateEnabled={true}
                height={'calc(63vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                className='mt-6'
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Jobs")}
            >
                <Export enabled />
                <SearchPanel visible={true} />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={10} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Jobs: {0}" column='hostPropertyAddress' summaryType='count' />
                </Summary>

                <Column dataField="hostPropertyAddress" minWidth={450} caption="Property" cssClass='cls' />
                <Column dataField="scheduledStart" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledStart} minWidth={150} cssClass='cls'  >
                    <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
                </Column>
                <Column dataField="jobStatusTypeName" alignment='center' caption="Status" cellRender={displayStatusType} minWidth={250} cssClass='cls' />
                <Column dataField="vendorName" caption="Vendor" minWidth={200} cssClass='cls' />

                <Column dataField="scheduledFinish" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledFinish} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
                </Column>
                <Column dataField="hostName" caption='Client Name' minWidth={200} cssClass='cls' />
                <Column dataField="hostPropertyCityName" caption="City" minWidth={150} cssClass='cls' />
                <Column dataField="hostPropertyStateName" caption="State" minWidth={200} cssClass='cls' />
                <Column dataField="hostPropertyCountryName" caption="Country" minWidth={200} cssClass='cls' />

            </DataGrid>

        </div>
    )
}

export default JobTable;