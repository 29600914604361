import {
  Column,
  DataGrid,
  Export,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {Dispatch} from 'redux'
import {JobStatusType} from '../../enums/JobEnum'
import ApiCalls from '../../network/ApiCalls'
import {openInspectionDetail} from '../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {decryptText, encryptText, onExporting, toConvertedDate} from '../../utils/util'

const VirtualInspection = () => {
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  let {jobId}: any = useParams()
  jobId = decryptText(jobId)
  const [jobInspections, setJobInspections] = useState([])

  const {data, isLoading, error, refetch} = useQuery(
    ['Get Virtual Inspections', jobId],
    () => ApiCalls.getVirtualInspections(jobId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false}
  )

  useEffect(() => {
    if (data?.data?.data.length > 0) {
      const taskListRaw = data?.data?.data
      const taskLists = taskListRaw.filter(
        (tl: any) =>
          tl.jobVirtualInspectionStatusTypeId == 2 || tl.jobVirtualInspectionStatusTypeId == 3
      )

      setJobInspections(taskLists)
    }
  }, [data])

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobVirtualInspectionStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === 'Approved'
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const toJobDetail = (cellData: any) => {
    if (cellData?.column?.name !== 'inspect-button' && cellData?.data?.jobId)
      history.push({
        pathname: `/job-details/` + encryptText(cellData?.data?.jobId),
      })
  }

  const displayVirtualInspectionButton = (cellData: any) => {
    return (
      <button
        className='btn btn-sm bg-info text-white'
        onClick={() => {
          dispatch(openInspectionDetail(refetch, cellData?.data?.id))
        }}
      >
        Inspect
      </button>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }
  const calculateFullName = (cellValue: any) => {
    return cellValue?.cancelUser?.firstName + ' ' + cellValue?.cancelUser?.lastName
  }
  const calculateDescription = (cellValue: any) => {
    return (
      cellValue?.questionDescription?.en ??
      cellValue?.questionDescription[Object.keys(cellValue?.questionDescription)[0]]
    )
  }

  if (isLoading) {
    return (
      <div className='mt-6'>
        <SkeletonTableLoading />
      </div>
    )
  }

  if (error) {
    return <div className='mt-6'>Error</div>
  }

  if (jobInspections.length === 0) {
    return <EmptyTable title='No Pending Inspection Photos' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span className='badge badge-primary badge me-3'>{jobId}</span>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id={'NotCompletedJobTable'}
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={jobInspections}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(55vh - 60px)'}
        onCellClick={toJobDetail}
        columnAutoWidth={true}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        className='mt-6'
        onExporting={(e) => onExporting(e, 'Cancelled Jobs')}
      >
        <Export enabled />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Tasks: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>

        <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
        <Column
          dataField='questionDescription.en'
          calculateCellValue={calculateDescription}
          caption='Description'
          minWidth={200}
          cssClass='cls'
        />
        <Column
          dataField='jobVirtualInspectionStatusTypeName'
          alignment='center'
          caption='Inspection Status'
          cellRender={displayStatusType}
          minWidth={150}
          cssClass='cls'
        />

        <Column
          width={110}
          name='inspect-button'
          alignment='center'
          cellRender={displayVirtualInspectionButton}
          cssClass='cls'
        />
      </DataGrid>
    </div>
  )
}

export default VirtualInspection
