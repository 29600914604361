import * as vendorDetailActionCreators from '../../../redux/actionCreators/vendorDetailActionCreators'
import {Card} from 'react-bootstrap-v5'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import ReferenceTable from './VendorReferenceTable'
import ApiCalls from '../../../network/ApiCalls'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import VendorReferenceDialog from './VendorReferenceDialog'
import {Dispatch} from 'react'
import {VendorDetailActions} from '../../../redux/actionTypes/vendorDetailActionTypes'
import SkeletonTableLoading from './../../../umut-components/Loading/SkeletonTableLoading'

const VendorReference = () => {
  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const {
    data,
    isLoading,
    error,
    refetch: refetchReferences,
  } = useQuery(['Get Reference'], () => ApiCalls.getVendorReference(vendorId), {
    refetchOnWindowFocus: false,
  })
  const showViewDispatch = useDispatch<Dispatch<VendorDetailActions>>()

  return (
    <>
      <PageTitle>References</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <ReferenceTable refetchReferences={refetchReferences} data={data?.data?.data} />
            )}
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <button
              className='btn btn-success'
              onClick={() => showViewDispatch(vendorDetailActionCreators.openReferenceView(null))}
              style={{height: '100%'}}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Vendor Reference
            </button>
          </Card.Footer>
        </Card>
      </div>
      <DeleteDialog />
      <VendorReferenceDialog refetchReferences={refetchReferences} />
    </>
  )
}

export default VendorReference
