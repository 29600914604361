import React, { useRef, useState } from 'react';
import { Column, DataGrid, Export, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { encryptText, onExporting } from '../../../../utils/util';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';
import EmptyTable from '../../../../umut-components/Icons/EmptyTable';
import { useHistory } from 'react-router';
import { IJobDetail } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { ExcludedStaffRequest } from '../../../../network/PostRequestModels/JobRequests';
import { useSelector } from 'react-redux';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { PostPermissionRequest } from '../../../../network/PostRequestModels/PermissionsRequests';
import { Tooltip } from 'devextreme-react';
import { OverlayTrigger, Spinner } from 'react-bootstrap-v5';

type Props = {
    data: any
    jobDetail: IJobDetail
    refetch: () => void

}

const renderTooltip = (props: any, description: string) => (
    <Tooltip id="button-tooltip" {...props}>
        {description}
    </Tooltip>
);

const StaffTable: React.FC<Props> = ({ data, jobDetail, refetch }) => {
    const { user } = useSelector((state: RedusxAppState) => state.user)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState({ idx: 0, boolean: false })

    const handleExclude = async (cellData: any) => {
        if (cellData && jobDetail)
            try {
                let excludesReuqest: ExcludedStaffRequest = {
                    hostId: jobDetail?.hostId,
                    hostPropertyId: jobDetail?.hostPropertyId,
                    description: '',
                    userId: cellData?.data?.userId,
                    roleId: cellData?.data?.roleId,
                    vendorId: user?.userSection?.vendorId
                }
                setIsLoading({ idx: cellData?.data?.id, boolean: true })
                await ApiCalls.postExcludedStaffToJob(excludesReuqest)
                refetch()
                setIsLoading({ idx: cellData?.data?.id, boolean: false })
            } catch (error) {
                setIsLoading({ idx: cellData?.data?.id, boolean: false })
            }
    }
    
    const handleAsssignPriority = async (cellData: any, staffQueueIndex: number) => {
        if (cellData && jobDetail)
            try {
                let assignPriority: PostPermissionRequest = {
                    staffQueueIndex: staffQueueIndex,
                    userId: cellData?.data?.userId,
                    hostPropertyId: jobDetail?.hostPropertyId,
                    vendorId: user?.userSection?.vendorId,
                    roleId: cellData?.data?.roleId
                }
                setIsLoading({ idx: cellData?.data?.id, boolean: true })
                await ApiCalls.postAssignPriority(assignPriority)
                setIsLoading({ idx: cellData?.data?.id, boolean: false })
            } catch (error) {
                setIsLoading({ idx: cellData?.data?.id, boolean: false })


            }
    }

    const renderProfilePhoto = (cellValue: any) => {
        return (
            <ProfilePhotoTemp profilePhotoPath={cellValue?.data?.profilePhotoPath} firstName={cellValue?.data?.firstName} lastName={cellValue?.data?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }
    const tableRef = useRef<DataGrid>(null)
    const toStaffProfile = (cellValue: any) => {
        if (cellValue?.data?.userId && cellValue?.name?.column !== "first.line.button" && cellValue?.name?.column !== "bypass-button" && cellValue?.name?.column !== "exclude-button") {
            history.push({
                pathname: '/staff-details/' + encryptText(cellValue?.data?.userId) + '/' + encryptText(cellValue?.data?.id),
            })
        }
    }
    const renderCheckExcluded = (cellData: any) => {

        if (cellData?.data?.excludedStaffId) {
            return (<span className="align-items-center">Excluded</span>)
        }
        return <span>Approved</span>
    }

    // const renderFirstLineButton = (cellData: any) => {
    //     return (
    //         <OverlayTrigger
    //             placement='top'
    //             overlay={props => renderTooltip(props, 'asd')}>
    //             <button disabled={cellData?.data?.excludedStaffId} onClick={() => { handleAsssignPriority(cellData, 1) }} className="btn btn-sm btn-success">Priority {(isLoading.idx === cellData?.data?.id && isLoading.boolean) && <Spinner animation="border" size="sm" />}</button>
    //         </OverlayTrigger>

    //     )
    // }
    // const renderByPassButton = (cellData: any) => {
    //     return (
    //         <button disabled={cellData?.data?.excludedStaffId} onClick={() => { handleAsssignPriority(cellData, 0) }} className="btn btn-sm btn-warning">Bypass {(isLoading.idx === cellData?.data?.id && isLoading.boolean) && <Spinner animation="border" size="sm" />}</button>
    //     )
    // }

    const renderExcludeButton = (cellData: any) => {
        return (
            <button disabled={cellData?.data?.excludedStaffId} onClick={() => { handleExclude(cellData) }} className="btn btn-sm btn-danger">Exclude {(isLoading.idx === cellData?.data?.id && isLoading.boolean) && <Spinner animation="border" size="sm" />}</button>
        )

    }
    const calculateFullName = (cellValue: any) => {
        return cellValue?.firstName + " " + cellValue?.lastName;
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Staff" />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="jobStaffs"
                keyExpr="userId"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                ref={tableRef}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                className='mt-6'
                allowColumnResizing={true}
                onCellClick={toStaffProfile}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Staff")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Staff: {0}" column='firstName' summaryType='count' />
                </Summary>

                <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />
                <Column dataField="firstName" calculateCellValue={calculateFullName} caption='Fullname' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="phoneNumber" caption='Phone' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="email" caption='Email' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="excludedStaffId" caption="Permission Status" cellRender={renderCheckExcluded} minWidth={180} dataType='string' alignment="center" cssClass='cls' />
                {/* <Column name="first-line-button" cellRender={renderFirstLineButton} width={100} cssClass="cls" />
                <Column name="bypass-button" cellRender={renderByPassButton} width={100} cssClass="cls" /> */}
                {/* <Column name="exclude-button" cellRender={renderExcludeButton} width={100} cssClass="cls" /> */}

            </DataGrid>

        </div>
    )
}

export default StaffTable;