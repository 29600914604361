import { IChecklistItem, IHostPropertyCheckList, IQuestion, ISection } from "../../interfaces/Checklist";
import * as actions from "../actionTypes/masterChecklistTypes";

export interface ChecklistState {
    checklistItems: IChecklistItem | null,
    loading: boolean,
    errorText: string | null,
    questionCollapse: {
        collapseIndex: number | null,
        selectedSection: ISection | null
    },
    sectionEditDialog: {
        selectedSection: ISection | null,
        dialogOpen: boolean
    },
    questionAddDialog: {
        dialogOpen: boolean,
        selectedSection: ISection | null
    },
    questionEditDialog: {
        selectedQuestion: IQuestion | null,
        dialogOpen: boolean
    },
    checklistEditDialog: {
        selectedChecklist: IHostPropertyCheckList | null,
        dialogOpen: boolean
    },
    questionPhotoDialog: {
        selectedQuestion: IQuestion | null,
        dialogOpen: boolean
    }
}

const initialState: ChecklistState = {
    checklistItems: null,
    loading: false,
    errorText: null,
    questionCollapse: {
        collapseIndex: null,
        selectedSection: null
    },
    sectionEditDialog: {
        selectedSection: null,
        dialogOpen: false
    },
    questionAddDialog: {
        dialogOpen: false,
        selectedSection: null
    },
    questionEditDialog: {
        selectedQuestion: null,
        dialogOpen: false
    },
    checklistEditDialog: {
        selectedChecklist: null,
        dialogOpen: false
    },
    questionPhotoDialog: {
        selectedQuestion: null,
        dialogOpen: false
    }
}

export default function masterChecklistReducer(
    state: ChecklistState = initialState,
    action: actions.ChecklistAction
): ChecklistState {
    switch (action.type) {
        case actions.GET_CHECKLIST_ITEMS:
            return { ...state, loading: true, errorText: null, questionCollapse: { collapseIndex: null, selectedSection: null }, sectionEditDialog: { dialogOpen: false, selectedSection: null } }
        case actions.GET_CHECKLIST_ITEMS_SUCCESS:
            return { ...state, loading: false, errorText: null, checklistItems: action.checklistItems, questionCollapse: { collapseIndex: null, selectedSection: null }, sectionEditDialog: { dialogOpen: false, selectedSection: null } }
        case actions.GET_CHECKLIST_ITEMS_FAILURE:
            return { ...state, loading: false, errorText: action.errorText, checklistItems: null, questionCollapse: { collapseIndex: null, selectedSection: null }, sectionEditDialog: { dialogOpen: false, selectedSection: null } }
        case actions.SET_CHECKLIST_ITEMS:
            return { ...state, loading: false, errorText: null, checklistItems: action.checklistItems }
        case actions.OPEN_CHECKLIST_EDIT_DIALOG:
            return { ...state, checklistEditDialog: { dialogOpen: true, selectedChecklist: action.checklist } }
        case actions.CLOSE_CHECKLIST_EDIT_DIALOG:
            return { ...state, checklistEditDialog: { dialogOpen: false, selectedChecklist: null } }
        case actions.OPEN_QUESTION_DIALOG:
            return { ...state, questionCollapse: { collapseIndex: action.section.id, selectedSection: action.section } }
        case actions.CLOSE_QUESTION_DIALOG:
            return { ...state, questionCollapse: { collapseIndex: null, selectedSection: null } }
        case actions.SET_QUESTION_ORDER:
            return { ...state, questionCollapse: { ...state.questionCollapse, selectedSection: action.section } }
        case actions.OPEN_SECTION_EDIT_DIALOG:
            return { ...state, sectionEditDialog: { dialogOpen: true, selectedSection: action.section } }
        case actions.CLOSE_SECTION_EDIT_DIALOG:
            return { ...state, sectionEditDialog: { dialogOpen: false, selectedSection: null } }
        case actions.OPEN_QUESTION_EDIT_DIALOG:
            return { ...state, questionEditDialog: { dialogOpen: true, selectedQuestion: action.question } }
        case actions.CLOSE_QUESTION_EDIT_DIALOG:
            return { ...state, questionEditDialog: { dialogOpen: false, selectedQuestion: null } }
        case actions.OPEN_QUESTION_ADD_DIALOG:
            return { ...state, questionAddDialog: { dialogOpen: true, selectedSection: action.section } }
        case actions.CLOSE_QUESTION_ADD_DIALOG:
            return { ...state, questionAddDialog: { dialogOpen: false, selectedSection: null } }
        case actions.OPEN_QUESTION_PHOTO_DIALOG:
            return { ...state, questionPhotoDialog: { dialogOpen: true, selectedQuestion: action.question } }
        case actions.CLOSE_QUESTION_PHOTO_DIALOG:
            return { ...state, questionPhotoDialog: { dialogOpen: false, selectedQuestion: null } }
        default:
            return state;
    }
}