import * as vendorDetailActionCreators from '../../../redux/actionCreators/vendorDetailActionCreators'
import { Dispatch, FC, useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap-v5'
import { useDispatch, useSelector } from 'react-redux'
import { VendorDetailActions } from '../../../redux/actionTypes/vendorDetailActionTypes'
import { RedusxAppState } from '../../../redux/reducers/rootReducer'
import SelectInput from '../../../umut-components/Inputs/SelectInput'
import ApiCalls from '../../../network/ApiCalls'

type Props = {
  refetch: () => void,
  existingDocuments: number[]
}

const VendorDocumentDialog: FC<Props> = ({ refetch, existingDocuments }) => {
  const {
    user: {
      userSection: { vendorId },
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const {
    documentsState: { ModalOpen },
    documentsState: { ModalData },
  } = useSelector((state: RedusxAppState) => state.vendorDetail)
  const showViewDispatch = useDispatch<Dispatch<VendorDetailActions>>()
  const [selectedDocument, setSelectedDocument] = useState<any>()
  const [documentType, setDocumentType] = useState<any>(0)
  const [documents, setDocuments] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const imageAccept = "image/png, image/jpeg";
  const pdfAccept = "application/pdf,application"

  const handleCloseModal = () => {
    showViewDispatch(vendorDetailActionCreators.closeDocumentView())
    setDocumentType(null)
    setErrorMessage('')
  }

  const handleDocumentSend = async () => {
    try {
      setLoading(true)
      var formData = new FormData()
      formData.append('vendorId', vendorId)
      formData.append('documentTypeId', documentType)
      formData.append('documentFile', selectedDocument)
      if (vendorId && documentType != 0 && selectedDocument) {
        await ApiCalls.createVendorDocuments(formData)
        setLoading(false)
        handleCloseModal()
        refetch()
      } else {
        setLoading(false)
        setErrorMessage('All fields are required.')
      }
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error?.response?.data.message)
    }
  }
  const documentChange = (input: any) => {
    try {
      let file = input.target.files[0]
      if (file) {
        setSelectedDocument(file)
      } else {
        setSelectedDocument(null)
      }
    }
    catch (err: any) {
      //Handle error
    }
  }

  useEffect(() => {
    if (ModalData) {
      setDocuments(ModalData.filter((type: any) => !existingDocuments?.includes(type.id)).map((type: any) => ({ label: type.name, value: type.id })))
    }
  }, [ModalData])

  useEffect(() => {
    if (documents && documents?.length === 1) {
      setDocumentType(documents[0].value)
    }
  }, [documents])

  useEffect(() => {
    if (!ModalOpen) {
      setDocumentType(null)
      setSelectedDocument(null)
    }
  }, [ModalOpen])
  return (
    <div>
      <Modal show={ModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Document View</Modal.Title>
        </Modal.Header>

        <Modal.Body className='d-flex flex-column gap-8 '>
          <div>
            <Form.Label>Document type</Form.Label>
            <SelectInput
              defaultValue={documentType}
              disabledText='Select Document Type'
              options={documents}
              onValueChanged={(value) => {
                setDocumentType(value)

              }}
            />
          </div>

          <div>
            <Form.Label className='text-muted'>Please select file</Form.Label>
            <Form.Control
              onChange={documentChange}
              disabled={documentType ? false : true}
              type='file'
              accept={documentType == 1 ? imageAccept : pdfAccept}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='col mt-3'>
            <div className='d-flex col gap-3 justify-content-end'>
              <button type='button' className='btn btn-light' onClick={handleCloseModal}>
                Cancel
              </button>
              <button
                type='button'
                disabled={loading || !selectedDocument}
                className='btn btn-success'
                onClick={handleDocumentSend}
              >
                Send {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col text-end'>
              <strong style={{ color: 'red' }}>{errorMessage}</strong>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default VendorDocumentDialog
