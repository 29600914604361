import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {useEffect, useState} from 'react'
import {Accordion, Card, ListGroup, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {useDialog} from '../../contexts/DialogContext'
import {IMarketplaceOffer, INewMarketplaceOffer} from '../../interfaces/Marketplace'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import CurrencyInput from '../../umut-components/Inputs/CurrencyInput'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {decryptText, getStaticMap} from '../../utils/util'

const MarketplaceDetail = () => {
  let {marketplaceOfferId}: any = useParams()
  marketplaceOfferId = decryptText(marketplaceOfferId)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessDialog, showFailureDialog, openLoadingModal} = useDialog()
  const [newOfferHasError, setNewOfferHasError] = useState<boolean>(false)
  const [showCancelOfferModal, setShowCancelOfferModal] = useState<boolean>(false)
  const [showNewOfferModal, setShowNewOfferModal] = useState<boolean>(false)
  const [selectedOffer, setSelectedOffer] = useState<IMarketplaceOffer | undefined>()
  const [newOfferData, setNewOfferData] = useState<INewMarketplaceOffer>({
    vendorId: 0,
    userId: 0,
    marketplaceId: 0,
    id: 0,
    offerType: '',
    offerDuration: 1,
    offerPrice: 0,
    vendorNote: '',
  })

  const {
    data: marketplaceData,
    isFetching: marketplaceLoading,
    error: marketplaceError,
    refetch: refetchData,
  } = useQuery<AxiosResponse<IMarketplaceOffer>>(
    'Get Marketplace Offer Detail',
    () => ApiCalls.getMarketplaceOfferDetail({id: marketplaceOfferId}),
    {cacheTime: 500000, enabled: marketplaceOfferId ? true : false}
  )

  useEffect(() => {
    if (user != undefined) {
      setNewOfferData({
        ...newOfferData,
        vendorId: user.userSection.vendorId,
        userId: user.user.id,
      })
    }
  }, [user])

  useEffect(() => {
    if (marketplaceData?.data != undefined) {
      var data = marketplaceData?.data
      setNewOfferData({
        ...newOfferData,
        marketplaceId: +data.marketplaceId,
        id: +marketplaceOfferId,
        offerType: data.offerType == undefined ? '' : data.offerType,
        offerDuration: data.offerDuration == undefined ? 1 : data.offerDuration,
        offerPrice: data.offerPrice == undefined ? 0 : data.offerPrice,
        vendorId: user.userSection.vendorId,
        userId: user.user.id,
      })
    }
  }, [marketplaceData])

  const cancelOffer = (offer: any) => {
    var request = {id: offer.id}
    ApiCalls.cancelMarketplaceOffer(request)
      .then((res: any) => {
        if (res?.data?.success) {
          showSuccessDialog('Your marketplace offer is successfully cancelled.')
          setSelectedOffer(undefined)
          setShowCancelOfferModal(false)
          refetchData()
        } else {
          setSelectedOffer(undefined)
          setShowCancelOfferModal(false)
          showFailureDialog('There is an error occur during cancelling marketplace offer')
        }
      })
      .catch((err: any) => {
        setSelectedOffer(undefined)
        setShowCancelOfferModal(false)
        showFailureDialog(err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message)
      })
  }

  const makeOffer = () => {
    if (
      newOfferData.vendorId == 0 ||
      newOfferData.userId == 0 ||
      newOfferData.offerType == '' ||
      newOfferData.marketplaceId == 0
    ) {
      setNewOfferHasError(true)
    } else {
      if (newOfferData.vendorNote != '' && newOfferData.vendorNote != null) {
        const mailReg =
          /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
        const phoneReg =
          /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/gim

        if (mailReg.test(newOfferData.vendorNote) || phoneReg.test(newOfferData.vendorNote)) {
          showFailureDialog(
            'Please do not enter e-mail, telephone numbers or special characters in the offer note!'
          )
        } else {
          setNewOfferHasError(false)
          var request: INewMarketplaceOffer = newOfferData
          ApiCalls.sendMarketplaceOffer(request)
            .then((res: any) => {
              if (res?.data?.success) {
                showSuccessDialog('Your marketplace offer is successfully sended.')
                setNewOfferData({...newOfferData, offerType: '', offerDuration: 1, offerPrice: 0})
                setShowNewOfferModal(false)
                refetchData()
              } else {
                setNewOfferData({...newOfferData, offerType: '', offerDuration: 1, offerPrice: 0})
                setShowNewOfferModal(false)
                showFailureDialog('There is an error occur during sending marketplace offer')
              }
            })
            .catch((err: any) => {
              setNewOfferData({...newOfferData, offerType: '', offerDuration: 1, offerPrice: 0})
              setShowNewOfferModal(false)
              showFailureDialog(
                err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
              )
            })
        }
      } else {
        setNewOfferHasError(false)
        var request: INewMarketplaceOffer = newOfferData
        ApiCalls.sendMarketplaceOffer(request)
          .then((res: any) => {
            if (res?.data?.success) {
              showSuccessDialog('Your marketplace offer is successfully sended.')
              setNewOfferData({...newOfferData, offerType: '', offerDuration: 1, offerPrice: 0})
              setShowNewOfferModal(false)
              refetchData()
            } else {
              setNewOfferData({...newOfferData, offerType: '', offerDuration: 1, offerPrice: 0})
              setShowNewOfferModal(false)
              showFailureDialog('There is an error occur during sending marketplace offer')
            }
          })
          .catch((err: any) => {
            setNewOfferData({...newOfferData, offerType: '', offerDuration: 1, offerPrice: 0})
            setShowNewOfferModal(false)
            showFailureDialog(
              err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
            )
          })
      }
    }
  }

  const setOfferPrice = (priceRaw: string) => {
    var price = priceRaw.replace('$', '')
    setNewOfferData({...newOfferData, offerPrice: +price})
  }

  if (marketplaceError) {
    return <div>An error occur during getting marketplace detail request!</div>
  }

  return (
    <>
      <PageTitle>Client Request Details</PageTitle>
      <div className='pt-3'>
        {marketplaceLoading ? (
          <SkeletonTableLoading />
        ) : (
          <>
            {marketplaceData?.data && marketplaceData?.data.marketplaceOfferStatusTypeId > 1 && (
              <div className='card mb-5'>
                <div className='card-header'>
                  <div className='card-title'>Your offer</div>
                </div>
                <div className='card-body'>
                  <div
                    className='d-flex align-items-center p-3'
                    style={{backgroundColor: '#f0f0f0', borderRadius: 6}}
                  >
                    <div className='symbol symbol-40 symbol-light-primary me-5'>
                      <span className='symbol-label'>
                        <span className='svg-icon svg-icon-2x svg-icon-danger'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                            version='1.1'
                          >
                            <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                              <polygon points='0 0 24 0 24 24 0 24' />
                              <path
                                d='M3.52270623,14.028695 C2.82576459,13.3275941 2.82576459,12.19529 3.52270623,11.4941891 L11.6127629,3.54050571 C11.9489429,3.20999263 12.401513,3.0247814 12.8729533,3.0247814 L19.3274172,3.0247814 C20.3201611,3.0247814 21.124939,3.82955935 21.124939,4.82230326 L21.124939,11.2583059 C21.124939,11.7406659 20.9310733,12.2027862 20.5869271,12.5407722 L12.5103155,20.4728108 C12.1731575,20.8103442 11.7156477,21 11.2385688,21 C10.7614899,21 10.3039801,20.8103442 9.9668221,20.4728108 L3.52270623,14.028695 Z M16.9307214,9.01652093 C17.9234653,9.01652093 18.7282432,8.21174298 18.7282432,7.21899907 C18.7282432,6.22625516 17.9234653,5.42147721 16.9307214,5.42147721 C15.9379775,5.42147721 15.1331995,6.22625516 15.1331995,7.21899907 C15.1331995,8.21174298 15.9379775,9.01652093 16.9307214,9.01652093 Z'
                                fill='currentColor'
                                fill-rule='nonzero'
                                opacity='1'
                              />
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div className='d-flex flex-column flex-grow-1 font-weight-bold'>
                      <div className='text-dark text-hover-danger mb-1 font-size-lg'>
                        {'$' + marketplaceData?.data?.offerPrice}
                        {' / '}
                        {marketplaceData?.data?.offerType == 'hourly' ? 'hour(s)' : 'job(s)'}
                      </div>
                      <div className='text-start text-muted'>
                        Offer Date : {dayjs(marketplaceData?.data?.createdOn).format('MM DD, YYYY')}
                      </div>
                    </div>
                    {marketplaceData?.data?.marketplaceOfferStatusTypeId == 2 && (
                      <>
                        <div className='text-dark text-bold me-3'>Waiting for client answer..</div>
                        <button
                          className='btn btn-sm btn-danger p-1'
                          onClick={() => {
                            setSelectedOffer(marketplaceData?.data)
                            setShowCancelOfferModal(true)
                          }}
                        >
                          Cancel Offer
                        </button>
                      </>
                    )}
                    {marketplaceData?.data?.marketplaceOfferStatusTypeId == 3 && (
                      <div className='text-success text-bold'>Offer Accepted</div>
                    )}
                    {marketplaceData?.data?.marketplaceOfferStatusTypeId == 4 && (
                      <div className='text-danger text-bold'>Offer Declined</div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {marketplaceData?.data?.marketplaceOfferStatusTypeId == 1 && (
              <div className='row mb-5'>
                <div className='col-12 text-end'>
                  <button
                    className='btn btn-sm btn-danger'
                    onClick={() => {
                      setShowNewOfferModal(true)
                    }}
                  >
                    Make an offer
                  </button>
                </div>
              </div>
            )}
            <div className='row'>
              <div className='col-lg-6 col-12 mb-4'>
                <Card className='h-100'>
                  <Card.Header>
                    <Card.Title>Property Info</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className='row'>
                      <div className='col-lg-4 col-12 mb-3'>
                        <img
                          className='w-100 h-100'
                          style={{objectFit: 'cover'}}
                          alt='map'
                          src={getStaticMap(38.721873, 27.72823)}
                        />
                      </div>
                      <div className='col-lg-8 col-12 mb-3'>
                        <ListGroup>
                          <ListGroup.Item className='border-0'>
                            <strong>Address</strong> : {marketplaceData?.data?.marketplace.address}
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>Country</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.countryInfo?.name}
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>State</strong> :
                            {marketplaceData?.data?.marketplace?.stateInfo?.name}
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>City</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.cityInfo?.name}
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>Zip Code</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.zipCode}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className='col-lg-6 col-12 mb-4'>
                <Card>
                  <Card.Header>
                    <Card.Title>Requested Quote Parameters</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className='row'>
                      <div className='col-lg-6 col-12 mb-3'>
                        <ListGroup>
                          <ListGroup.Item className='border-0'>
                            <strong>Unit Size</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.propertySize} sqft.
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>On-Site Laundry</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.cleanLinenAndTowel
                              ? 'Required'
                              : 'Not Required'}
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>Off-Site Laundry</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.offSiteLinenAndTowel
                              ? 'Required'
                              : 'Not Required'}
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>Supplies</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.hostProvideCleaningSupplies
                              ? 'Provided'
                              : 'Not Provided'}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                      <div className='col-lg-6 col-12 mb-3'>
                        <ListGroup.Item className='border-0'>
                          <strong>Estimated Duration</strong> :{' '}
                          {marketplaceData?.data?.marketplace?.cleaningDuration} hours
                        </ListGroup.Item>
                        <ListGroup.Item className='border-0'>
                          <strong>Job Frequency</strong> :{' '}
                          {marketplaceData?.data?.marketplace?.jobsPerMonth} jobs / month
                        </ListGroup.Item>
                        <ListGroup.Item className='border-0'>
                          <strong>Bedrooms</strong> :{' '}
                          {marketplaceData?.data?.marketplace?.numberOfBedrooms}
                        </ListGroup.Item>
                        <ListGroup.Item className='border-0'>
                          <strong>Bathrooms</strong> :{' '}
                          {marketplaceData?.data?.marketplace?.numberOfBathrooms}
                        </ListGroup.Item>
                      </div>
                      <div className='col-12 mb-3'>
                        <ListGroup>
                          <ListGroup.Item className='border-0'>
                            <strong>Requested Service</strong> :{' '}
                            {marketplaceData?.data?.serviceTypeInfo.name}
                          </ListGroup.Item>
                          <ListGroup.Item className='border-0'>
                            <strong>Client Note</strong> :{' '}
                            {marketplaceData?.data?.marketplace?.note == ''
                              ? '-'
                              : marketplaceData?.data?.marketplace?.note}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              {marketplaceData?.data.checklistInfo && (
                <div className='col-12 mb-4'>
                  <Card>
                    <Card.Header>
                      <Card.Title>
                        {marketplaceData.data.serviceTypeInfo.name} Checklist of Property
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className='row mb-4'>
                        <div className='col-12'>
                          <h6>Checklist : {marketplaceData?.data.checklistInfo.name.en}</h6>
                        </div>
                      </div>
                      <div className='row'>
                        <Accordion defaultActiveKey='csection'>
                          {marketplaceData.data.checklistInfo.checklistSections.map((section) => {
                            return (
                              <div className='col-12 mb-3'>
                                <Card>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    className='border border-1'
                                    variant='link'
                                    eventKey={'section-' + section.roomIndex}
                                  >
                                    <Card.Title># {section.roomIndex}</Card.Title>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={'section-' + section.roomIndex}>
                                    <Card.Body>
                                      {section.questions.map((question, qi) => {
                                        return (
                                          <Card>
                                            <Card.Body className='ps-0'>
                                              <div className='row'>
                                                <div className='col-1'>
                                                  <strong>{'# Q' + (qi + 1)}</strong>
                                                </div>
                                                <div className='col-11'>
                                                  <div>
                                                    <strong>{question.title.en}</strong>
                                                  </div>
                                                  <div>{question.description.en}</div>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Card>
                                        )
                                      })}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </div>
                            )
                          })}
                        </Accordion>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
            <Modal
              show={showCancelOfferModal}
              onHide={() => {
                setSelectedOffer(undefined)
                setShowCancelOfferModal(false)
              }}
              centered
            >
              <ModalHeader closeButton>
                <Modal.Title>Warning!</Modal.Title>
              </ModalHeader>
              <Modal.Body>
                <p style={{fontSize: 15}}>
                  You are about to cancel your cleaning offer for the property at{' '}
                  <strong>{marketplaceData?.data?.marketplace.address}</strong>. Do you want to
                  contiune?
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className='btn btn-link text-muted me-5'
                  onClick={() => {
                    setSelectedOffer(undefined)
                    setShowCancelOfferModal(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={() => {
                    cancelOffer(selectedOffer)
                  }}
                >
                  Yes, cancel offer
                </button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showNewOfferModal}
              onHide={() => {
                setShowNewOfferModal(false)
                setNewOfferData({...newOfferData, offerType: '', offerDuration: 1, offerPrice: 0})
              }}
              centered
            >
              <ModalHeader closeButton>
                <Modal.Title>Make an Offer</Modal.Title>
              </ModalHeader>
              <Modal.Body>
                <div className='row'>
                  <div className='fv-row col-lg-12 mb-3'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Offer Service</label>
                    <input
                      className='form-control'
                      type='text'
                      disabled
                      value={marketplaceData?.data?.serviceTypeInfo.name}
                      onChange={(e) => {}}
                    />
                  </div>
                  <div className='fv-row col-lg-12 mb-3'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Offer Type</label>
                    <select
                      className={
                        newOfferHasError && newOfferData.offerType == ''
                          ? 'form-control error-input'
                          : 'form-control'
                      }
                      onChange={(e) => {
                        setNewOfferData({...newOfferData, offerType: e.target.value})
                      }}
                      required
                    >
                      <option value=''>Please select</option>
                      <option value='hourly'>Hourly Price</option>
                      <option value='job'>Price Per Job</option>
                    </select>
                  </div>
                  <div className='fv-row col-lg-12 mb-3'>
                    <div className='d-flex flex-column'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Offer Price ($)</label>
                      <small className='text-danger' style={{fontSize: 12}}>
                        Please enter the price as 0 to reject the offer.
                      </small>
                    </div>
                    <CurrencyInput
                      placeholder='$0.00'
                      type='text'
                      allowDecimal={true}
                      className={'form-control'}
                      onChange={(e) => {
                        setOfferPrice(e.target.value)
                      }}
                    />
                  </div>
                  <div className='fv-row col-lg-12 mb-3'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      Include a brief professional message to your potential client when you submit
                      your quote. This will help them understand why your company is the best choice
                      for their needs. (Optional)
                    </label>
                    <textarea
                      className='form-control'
                      rows={3}
                      value={newOfferData.vendorNote}
                      onChange={(e) => {
                        setNewOfferData({...newOfferData, vendorNote: e.target.value})
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <span className='fs-4 fw-bold text-danger'>
                      Attention! Once the customer accepts your offer, you will be charged a 5%
                      transaction fee per completed job.
                    </span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {newOfferHasError && newOfferData.offerType == '' && (
                  <span className='text-danger me-2'>Please select an offer type!</span>
                )}
                <button
                  className='btn btn-link text-muted me-5'
                  onClick={() => {
                    setShowNewOfferModal(false)
                    setNewOfferData({
                      ...newOfferData,
                      offerType: '',
                      offerDuration: 1,
                      offerPrice: 0,
                    })
                  }}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm btn-danger'
                  onClick={() => {
                    makeOffer()
                  }}
                >
                  Send Rate Proposal
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </>
  )
}

export default MarketplaceDetail
