import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = {
  width?: string | number
  height?: string | number
  title?: string
  classNames?: string
  marginTop?: number | string
  type?: string
}

const EmptyTable: React.FC<Props> = ({width, height, title, type, classNames, marginTop}) => {
  if (type == 'staff') {
    title =
      "You don't have any staff yet. Click on the bellow button to add your first staff on Turnify."
  }
  if (type == 'checklist') {
    title =
      'There is no any checklist yet. Click on the bellow button to add your first checklist on Turnify.'
  }
  if (type == 'payment') {
    title =
      "You don't have any payment card yet. Click on the bellow button to add your first payment card on Turnify."
  }

  return (
    <div
      style={{marginTop: marginTop ?? 30}}
      className={'d-flex align-items-center justify-content-center flex-column w-100 ' + classNames}
    >
      <img
        style={{width: width ?? '25%', height}}
        alt='no-data'
        src={toAbsoluteUrl('/media/logos/noDataTable.png')}
      />
      <strong className='text-muted'>{title}</strong>
    </div>
  )
}

export default EmptyTable
