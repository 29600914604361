import React, { Dispatch, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { IJob, IJobIssue } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { JobAction } from '../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { getTimeFromNow, toAmazonUrl } from '../../../../utils/util';
import { useDialog } from '../../../../contexts/DialogContext';
import { Spinner } from 'react-bootstrap-v5';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';

type Props = {
    issue: IJobIssue,
    refetch?: () => void,
    jobEventsActive: boolean;
}

const IssueItem: React.FC<Props> = ({ issue }) => {
    const dispatch = useDispatch<Dispatch<JobAction>>();

    return (
        <div className='row p-3 border-bottom pb-6'>
            <div className='col-12 d-flex flex-row justify-content-between'>
                <div className='d-flex align-items-center'>
                    <ProfilePhotoTemp classNames='me-2' width={35} height={35} borderRadius='50%' profilePhotoPath={issue.reporter.profilePhotoPath} firstName={issue.reporter.firstName} lastName={issue.reporter.lastName} />
                    <div className='d-flex flex-column align-items-start justify-content-center'>
                        <strong>{issue.reporter.firstName + ' ' + issue.reporter.lastName}</strong>
                        <small className='text-muted'>{getTimeFromNow(issue.reporter.reportOn)}</small>
                    </div>
                </div>
            </div>
            <div className='col-12 mt-6 d-flex justify-content-between flex-wrap'>
                <strong className='col-9 text-muted text-break d-flex align-items-center'>{issue.note} {issue.jobIssueTypeName ? '(' + issue.jobIssueTypeName + ')' : null}</strong>
                <button type='button' className='col-3 btn btn-link btn-sm text-end' onClick={() => { dispatch(jobActionCreator.openIssueDialog(issue)) }}>Show Details</button>
            </div>
        </div>
    )
}

export default IssueItem;