export enum JobStatus {
  PENDING = 1,
  SCHEDULED,
  STARTED,
  NOT_COMPLETED,
  COMPLETED,
  CANCELLED,
}

export const JobStatusText = {
  1: 'Pending',
  2: 'Scheduled',
  3: 'Started',
  4: 'Not Completed',
  5: 'Completed',
  6: 'Cancelled',
}

export const JobStatusObj = {
  Pending: 1,
  Scheduled: 2,
  Started: 3,
  'Not Completed': 4,
  Completed: 5,
  Cancelled: 6,
}

export enum JobStatusType {
  PENDING = 'Pending',
  SCHEDULED = 'Scheduled',
  STARTED = 'Started',
  NOT_COMPLETED = 'Not completed',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum JobRatingType {
  GOOD = 'Good',
  OKEY = 'Okey',
  POOR = 'Poor',
}
