export const OPEN_JOB_AVAILABLE_DIALOG = 'OPEN_JOB_AVAILABLE_DIALOG'
export interface OpenJobAvailableDialog {
  type: typeof OPEN_JOB_AVAILABLE_DIALOG
  jobAvailableId: number,
  cleaningPeriodStart: string,
  refetch: () => void

}

export const CLOSE_JOB_AVAILABLE_DIALOG = 'CLOSE_JOB_AVAILABLE_DIALOG'
export interface CloseJobAvailableDialog {
  type: typeof CLOSE_JOB_AVAILABLE_DIALOG
  refetch: () => void
}

export const PUSH_HOST_PROPERTY = 'PUSH_HOST_PROPERTY'
export interface PushHostProperty {
  type: typeof PUSH_HOST_PROPERTY
  hostPropertyId: number,
  jobTimezone: string
}
export const PUSH_ROLEID = 'PUSH_ROLEID'
export interface PushRoleId {
  type: typeof PUSH_ROLEID
  roleId: number
}
export const PUSH_SELECTED_STAFFS = 'PUSH_SELECTED_STAFFS'
export interface PushSelectedStaffs {
  type: typeof PUSH_SELECTED_STAFFS
  selectedStaffs: number[]
}



export type JobAvailableActions = OpenJobAvailableDialog | CloseJobAvailableDialog | PushHostProperty | PushSelectedStaffs | PushRoleId
