import React, { Dispatch, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IJob } from '../../../../../interfaces/Job';
import ApiCalls from '../../../../../network/ApiCalls';
import { JobAction } from '../../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog';
import TaskTable from './TaskTable';
import TaskDialog from './TaskDialog';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import { decryptText } from '../../../../../utils/util';

type Props = {
    jobEventsActive: boolean
}

const TasksTab: React.FC<Props> = ({ jobEventsActive }) => {
    const { jobAvailableId } = useSelector((state: RedusxAppState) => state.jobAvailable);
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { data, isLoading, error, refetch } = useQuery(['Get Job Tasks By Job Id', jobAvailableId], () => ApiCalls.getJobTasks(jobAvailableId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobAvailableId ? true : false });

    if (isLoading) {
        return <SkeletonTableLoading />
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <TaskTable data={data?.data?.data} refetch={refetch} jobEventsActive={jobEventsActive} />
            {
                jobEventsActive &&
                <>

                    <TaskDialog />
                </>
            }
        </>
    )
}

export default TasksTab;