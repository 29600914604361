import {IUser} from '../../interfaces/User'
import {LoginRequest} from '../../network/PostRequestModels'
import * as actions from '../actionTypes/userActionTypes'

export function login(loginRequest: LoginRequest, logoutFunc: () => void): actions.LoginAction {
  return {
    type: actions.LOGIN,
    loginRequest,
    logoutFunc,
  }
}

export function setLoginSuccess(token: string): actions.LoginSuccessAction {
  return {
    type: actions.LOGIN_SUCCESS,
    token,
  }
}

export function setLoginFailure(error: Error | string): actions.LoginFailureAction {
  return {
    type: actions.LOGIN_FAILURE,
    error,
  }
}

export function getUserDetails(token: string, logoutFunc: () => void): actions.GetUserDetailAction {
  return {
    type: actions.GET_USER_DETAILS,
    token,
    logoutFunc,
  }
}

export function getUserInfoSuccess(user: IUser): actions.GetUserInfoSuccess {
  return {
    type: actions.GET_USER_INFO_SUCCESS,
    user,
  }
}

export function getUserInfoFailure(error: Error | string): actions.GetUserInfoFailure {
  return {
    type: actions.GET_USER_INFO_FAILURE,
    error,
  }
}

export function clearLoading(): actions.ClearLoadingAction {
  return {
    type: actions.CLEAR_LOADING,
  }
}

export function logout(): actions.LogoutAction {
  return {
    type: actions.LOGOUT,
  }
}
