export const Endpoints = {
  Users: {
    Login: 'users/login/vendor-user',
    Register: 'vendor-users/vendor-initial-registration',
    ConfirmAccount: 'vendor-users/verify-vendor-user',
    GetUserDetails: 'users/me',
    EditUser: 'users/',
    GetUserDetailsById: 'users/',
    GetUserDetailsByVendorId: 'vendor-users/',
    ChangePassword: 'users/change-password/',
  },
  Vendor: {
    GetVendorDetails: 'vendors/',
    GetVendorDetailsById: 'vendors/vendor-info/',
    VerifyVendor: 'vendor-users/verify-vendor-user',
    UpdateVendorDescription: 'vendors/update-description',
    PostBillingInformation: 'vendors/billing-information',
    Forgotpassword: 'users/forgot-password',
    ResetPassword: 'users/reset-password',
  },
  Marketplace: {
    GetRequests: 'marketplace/vendor/',
    GetOfferDetail: 'marketplace-offer/detail/',
    SendOffer: 'marketplace-offer/new-offer',
    CancelOffer: 'marketplace-offer/cancel-offer',
    GetOffersByStatus: 'marketplace/vendor-offer-by-status',
  },
  Job: {
    GetJobs: 'jobs/web',
    GetAllJobs: 'jobs/vendor/',
    GetLiveJobs: 'jobs/live',
    GetJobsByStaffId: 'jobs/web?userId=',
    GetJobsByUserId: 'jobs/web?userId=',
    GetJobsByPropertyId: 'jobs/web?hostPropertyId=',
    GetJobByStatusTypeId: 'jobs/web?',
    AddJob: 'jobs',
    AddJobForVendor: 'jobs/create-by-vendor',
    GetJobServices: 'job-services?jobId=',
    GetJobDetails: 'jobs/',
    AddJobService: 'job-services',
    DeleteJobService: 'job-services/',
    GetJobNotes: 'jobs/notes/',
    AddJobNote: 'job-notes',
    GetJobTasks: 'job-tasks?jobId=',
    GetJobStaffs: 'job-staff?jobId=',
    AddJobTask: 'job-tasks',
    UpdateJobTask: 'job-tasks/',
    DeleteJobTask: 'job-tasks/',
    GetJobIssues: 'job-issue?jobId=',
    ResolveIssue: 'job-issue/resolve/',
    AddJobIssue: 'job-issue',
    UpdateJobIssue: 'job-issue/',
    DeleteJobIssue: 'job-issue/',
    GetChecklistPhotos: 'jobs/get-checklist-photos?jobId=',
    GetJobReview: 'job-review?jobId=',
    AddJobReview: 'job-review',
    GetJobsAvailable: 'jobs/available-jobs/',
    CreateAssignStaffToJob: 'job-assignment/assign-staff-to-job',
    ScheduleAssignStaffToJob: 'jobs/schedule',
    AssignStaffToJob: 'jobs/schedule',
    GetVirtualInspection: 'jobs/virtual-inspection',
    AssignStaffReSchedule: 'jobs/reschedule',
    ExcludeStaffToJob: 'excluded-staff',
    AssignPriority: 'user-host-property-permission',
    PostForceFinishReq: 'job-force-finish-requests',
    DeclineJobSchedule: 'jobs/cancel-schedule',
    GetJobsDailyByStaffId: 'jobs/daily/',
    ScheduleJob: 'jobs/schedule',
    ReSchedule: 'jobs/reschedule',
    CancelJob: 'jobs/',
  },
  Property: {
    GetProperties: 'host-properties/for-vendor',
    GetAllProperties: 'host-properties',
    GetPropertiesByHost: 'host-properties/for-vendor?hostId=',
    GetVendorRangeProperties: 'host-properties/vendor-range/',
    GetAllPropertiesByStatusId: 'host-properties?hostPropertyStatusTypeId=',
    GetPropertiesByStatusId: 'host-properties/for-vendor?hostPropertyStatusTypeId=',
    GetPropertiesFromGuesty: 'host-properties/get-properties-from-guesty',
    GetPropertiesFromHostify: 'host-properties/get-properties-from-hostaway',
    GetPropertiesFromMyVr: 'host-properties/get-properties-from-myvr',
    PostHostProperties: 'host-properties/create-many',
    PostHostProperty: 'host-properties',
    UpdateHostProperty: 'host-properties/',
    HostPropertyOtherQuestion: 'host-property-other-question',
    GetHostProperty: 'host-properties/for-vendor',
    GetHostPropertyDetail: 'host-properties/',
    AllowedProperties: 'user-host-property-permission/accepted-properties/',
    BannedProperties: '',
    AcceptedProperties: 'user-host-property-permission/accepted-staffs/',
    AddPropertyPermission: 'user-host-property-permission',
    DeletePropertyPermission: 'user-host-property-permission/',
    PostResetJobs: 'host-properties/reset-jobs/',
    PostResetHostJobs: 'host-properties/reset-host-jobs/',
    CreateHostProperty: 'host-properties/create-by-vendor',
    EditPropertyStatus: 'host-properties/',
    GetPropertyIsAutoEnabled: 'host-properties/is-auto-enabled/',
    PostRefreshCalendar: 'host-properties/sync/',
    GetExcludedStaff: 'excluded-staff/',
    DeleteExcludedStaff: 'excluded-staff/',
    GetHostPropertyServiceOffers: 'host-property-service-offer',
    GetHostPropertyServiceOffersForVendor: 'host-property-service-offer/for-vendor',
    GetHostPropertyServiceRatesForVendor: 'host-property-service-rate/vendor/',
  },
  Turnovers: {
    GetFutureTurnovers: 'hosts/get-future-turnovers/',
    GetPastTurnovers: 'hosts/get-past-turnovers/',
  },
  Checklist: {
    GetMasterChecklists: 'host-property-checklists',
    GetHostPropertyChecklists: 'host-property-checklists?hostPropertyId=',
    PostHostPropertyChecklist: 'host-property-checklists',
    EditChecklist: 'host-property-checklists/',
    GetChecklistItems: 'host-property-checklists/',
    AddChecklistSection: 'host-property-checklists/section',
    GetChecklistSectionTypes: 'checklist-section-types',
    SetSectionOrder: 'host-property-checklists/',
    SetQuestionOrder: 'host-property-checklists/',
    EditSection: 'host-property-checklists/section/',
    EditQuestion: 'host-property-checklists/question/',
    AddQuestion: 'host-property-checklists/section/question',
    EditQuestionPhoto: 'host-property-checklists/samplePhoto/',
    NewQuestionPhoto: 'host-property-checklists/samplePhoto',
    PostCloneChecklist: 'host-property-checklists/clone',
    UpdateMainChecklist: 'host-property-checklists/main-update/',
    DeleteChecklist: 'host-property-checklists/',
    UpdateFutureJobsChecklistsByProperty: 'host-property-checklists/update-jobs-checklists',
    DeleteSection: 'host-property-checklists/section/',
    DeleteQuestion: 'host-property-checklists/question/',
    DeleteSamplePhoto: 'host-property-checklists/delete-sample-photo/',
  },
  PMS: {
    GetPMSTypes: 'pms-types',
  },
  Countries: {
    GetCountries: 'countries',
  },
  CleaningProviders: {
    GetCleaningProviders: 'cleaning-providers',
  },
  AccessTypes: {
    GetAccessTypes: 'access-types',
  },
  Roles: {
    GetStaffRoles: 'roles/staff-roles',
  },
  Service: {
    GetServiceTypes: 'service-types',
  },
  ServiceGroup: {
    GetServiceGroupsWithServiceTypes: 'service-groups/with-service-types',
  },
  ChecklistAnswerTypes: {
    GetChecklistAnswerTypes: 'checklist-answer-types',
  },
  Languages: {
    GetLanguages: 'languages',
  },
  Address: {
    GetAddressByUserId: 'users/addresses?userId=',
    CreateAddress: 'users/',
    UpdateAddress: 'users/',
    DeleteAddress: 'users/address/',
    GetAddressTypes: 'user-address-type',
  },
  OfferedServices: {
    CreateOfferedService: 'staff-offered-service',
    GetOfferedServices: 'staff-offered-service?userId=',
    GetOfferedServicesByVendorId: 'staff-offered-service?vendorId=',
    GetOfferedServicesByHostId: 'staff-offered-service/available/',
    GetOfferedStaffs: 'staff-offered-service?serviceTypeId=',
    ChangeOffered: 'staff-offered-service/',
    DeleteOffered: 'staff-offered-service/',
  },
  VendorOfferedServices: {
    GetVendorService: 'vendor-offered-service/get-vendor-services/',
    AddVendorService: 'vendor-offered-service/create',
    GetVendorOfferedService: 'vendor-offered-service',
    DeleteVendorOffered: 'vendor-offered-service/delete',
    CreateVendorOfferedServices: 'vendor-offered-service',
    CreateVendorOfferedService: 'vendor-offered-service/create',
  },
  ServiceRateQuoteRequests: {
    GetVendorRates: 'host-property-service-rate/by-vendor/',
    GetServiceRateQuoteRequests: 'host-property-service-rate/vendor-requests',
    SendServiceRateQuoteRequestOffer: 'host-property-service-rate/send-vendor-offer',
    AddServiceRate: 'host-property-service-rate/add-vendor-rate',
  },
  JobRatingTypes: {
    GetJobRatingTypes: 'job-rating-types',
  },
  RateTypes: {
    GetRateTypes: 'rate-type',
  },
  OffDays: {
    GetStaffOffDays: 'staff-off-days?userId=',
    GetStaffOffTime: 'staff-off-time?userId=',
    PostStaffOffTime: 'staff-off-time/',
    DeleteStaffOffTime: 'staff-off-time/',
    PostStaffOffDays: 'staff-off-days',
    DeleteStaffOffDays: 'staff-off-days',
  },
  Reference: {
    GetReference: 'vendor-reference?vendorId=',
    DeleteReference: 'vendor-reference/',
    CreateReference: 'vendor-reference',
    UpdateReference: 'vendor-reference/',
  },
  Documents: {
    GetDocuments: 'vendor-documents',
    DeleteDocumentDetail: 'vendor-documents/',
    PostDocuments: 'vendor-documents',
    GetDocumentsTypes: 'vendor-document-type',
  },
  Staff: {
    GetStaffs: 'vendor-users',
    CreateStaff: 'vendor-users/vendor-staff-create',
    GetUserDocumentTypes: 'vendor-user-document-types',
    GetStaffsByVendorId: 'vendor-users?vendorId=',
    DeleteUserDocument: 'vendor-user-documents/',
    GetUserDocuments: 'vendor-user-documents?',
    CreateUserDocument: 'vendor-user-documents',
    GetPendingStaffs: 'vendor-users?',
    UpdatePendingStaffs: 'vendor-users/editStatus/',
    GetAvailableStaffs: 'vendor-users/available?',
    AcceptInvite: 'vendor-users/accept-vendor-staff-invite',
    StaffStatusTypes: 'account-status-types',
    GetStaffsForAddJob: 'vendor-users/available?vendorId=',
    UpdateVendorStaffStatus: 'vendor-users/editStatus',
    StaffServiceCities: 'staff-city-service',
    ReMailStaff: 'vendor-users/send-vendor-staff-invite',
    GetPermissionsStaffs: 'vendor-users/by-property/',
  },
  Violation: {
    GetViolation: 'vendor-violation',
    GetViolationById: 'vendor-violation/',
  },
  CityService: {
    GetVendorCityService: 'vendor-city-service/',
    CreateVendorCityService: 'vendor-city-service',
    DeleteVendorCityService: 'vendor-city-service/',
  },
  Account: {
    GetAccountStatusType: 'account-status-types',
  },
  Billings: {
    VerifyBilling: 'vendors/stripe-account-status',
    GetBillings: 'vendor-invoices?',
    AccountLinks: 'vendors/account-link',
    DisableStripe: 'vendors/stripe/disable',
    GetPaymentSource: 'vendors/list-source',
    GetPaymentAccounts: 'vendors/get-stripe-customer-account',
    CreatePaymentAccounts: 'vendors/create-stripe-customer-account',
    PostCustomerSource: 'vendors/customer-source',
    PutDefaultCustomerSource: 'vendors/set-default-source',
    DeleteCustomerSource: 'vendors/customer-source/',
  },
  Payment: {
    GetPayment: 'vendor-payments?',
    GetUnpaidPayment: 'vendor-payments/unpaid/',
  },
  Search: {
    GetSearch: 'search/search?keyword=',
    GetHostSearch: 'search/vendor-host?keyword=',
  },
  ICalValidation: {
    ValidateICall: 'host-properties/validate-ical',
  },
  OnBoarding: {
    GetOnBoardingInfo: 'vendors/on-boarding-info/',
  },
  VirtualInspection: {
    GetVirtualInspections: 'job-virtual-inspections?jobId=',
    GetVirtualInspectionsByJobId: 'job-virtual-inspections/',
    PostVirtualInspections: 'job-virtual-inspections',
    PutApproveVirtualInspections: 'job-virtual-inspections/',
    PutDeclineVirtualInspections: 'job-virtual-inspections/',
  },
  Notification: {
    GetNotifications: 'notifications',
    ReadNotification: 'notifications',
  },
  Subscription: {
    GetPacks: 'vendor-subscription-pack/active',
    SubscribeToPack: 'vendors/subscribe-to-pack',
    GetVendorSubscriptions: 'vendor-subscription/',
    GetVendorSuspendedInvoice: 'vendor-subscription/suspended-invoice/',
    GetVendorSubscriptionInvoiceDetail: 'vendor-subscription/invoice/',
    ChargeSubscriptionInvoice: 'vendor-subscription/invoice/charge/',
    CancelSubscription: 'vendor-subscription/cancel/',
    GetVendorSubscriptionStaffFee: 'vendor-subscription/staff-fee/',
  },
  Host: {
    GetHosts: 'hosts/for-vendor/',
    GetHostById: 'hosts/for-vendor/',
    GetHostUsers: 'host-users/',
    AddHost: 'host-users/host-registration-by-vendor',
    GetHostStatusTypes: 'host-status-type',
    UpdateHost: 'hosts/',
  },
  Vendors: {
    GetSuitableVendorsByJobId: 'vendor-users/suitable-vendor/',
    GetAvailableVendors: 'vendors/available/',
    GetSuitableVendorsableVendors: 'vendors/suitable/',
  },
}
