/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, useState } from 'react'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'

import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { Form } from 'react-bootstrap-v5'
import { useDialog } from '../../contexts/DialogContext';
import ApiCalls from '../../network/ApiCalls';
import { HostRegisterRequest } from '../../network/PostRequestModels';
import { useDispatch, useSelector } from 'react-redux';
import { HostAction } from '../../redux/actionTypes/hostTypes';
import * as hostActionCreator from '../../redux/actionCreators/hostActionCreators';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { isPhoneValid } from '../../utils/util';
import PhoneTextInput from '../Inputs/PhoneInput';

type InputType = {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    passwordAgain: string,
    companyName: string
}

const schema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
        .email('Please Enter Valid Email')
        .required('Email is required'),
    password: Yup.string()
        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, 'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number')
        .required('Password is required'),
    passwordAgain: Yup.string().oneOf([Yup.ref('password'), null], 'Password must be matched'),
    companyName: Yup.string().nullable()
})

const defaultValues: InputType = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordAgain: '',
    companyName: ''
}

export function HostAddForm() {
    const { hostAddDialog } = useSelector((state: RedusxAppState) => state.host);
    const dispatch = useDispatch<Dispatch<HostAction>>();
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState<string | undefined>('');
    const { showFailureDialog, showSuccessDialog } = useDialog();
    const { data: countries } = useQuery('Get Countries', () => ApiCalls.getCountries(), { retry: false, refetchOnWindowFocus: false });
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');

    const { control, formState, handleSubmit, reset, register } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { isValid, dirtyFields, errors } = formState;

    const onRegister = async (data: InputType) => {
        try {
            setErrorText(undefined);
            setLoading(true);
            if (phoneNumber && isPhoneValid(phoneNumber)) {
                const HostRegisterRequest: HostRegisterRequest = {
                    hostUserInfo: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        password: data.password,
                        phoneNumber: phoneNumber
                    },
                    hostInfo: {
                        name: data.companyName
                    }
                }
                await ApiCalls.addHost(HostRegisterRequest);
                hostAddDialog.refetch();
                dispatch(hostActionCreator.closeAddHostDialog());
                setErrorText('');
                setLoading(false);
            }
            else {
                setLoading(false);
                setErrorText('Phone is not valid!');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorText(err?.response?.data?.errors?.[1]?.message);
        }
    }

    const onCountryCodeChange = (e: any) => {
        setSelectedCountryCode(e.target.value);
    }

    useEffect(() => {
        if (countries) {
            setSelectedCountryCode("(+1)");
        }
    }, [countries])

    return (
        <form
            className='form w-100'
            onSubmit={handleSubmit(onRegister)}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Company Name <span style={{color:"red"}}>*</span></label>
                <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    autoComplete='off'
                    {...register("companyName")}
                    required
                />
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.companyName?.message}</p>
            </div>
            {/* end::Form group */}

            <div className='row'>
                {/* begin::Form group */}
                <div className='fv-row col-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                    <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        autoComplete='off'
                        {...register("firstName")}
                        required
                    />
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.firstName?.message}</p>
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row col-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
                    <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        autoComplete='off'
                        {...register("lastName")}
                        required
                    />
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.lastName?.message}</p>
                </div>
                {/* end::Form group */}
            </div>

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    className='form-control form-control-lg form-control-solid'
                    type='email'
                    autoComplete='off'
                    {...register("email")}
                    required
                />
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.email?.message}</p>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    {...register("password")}
                    type='password'
                    autoComplete='off'
                    className='form-control form-control-lg form-control-solid'
                    required
                />
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.password?.message}</p>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password Again</label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    {...register("passwordAgain")}
                    type='password'
                    autoComplete='off'
                    className='form-control form-control-lg form-control-solid'
                    required
                />
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.passwordAgain?.message}</p>
            </div>
            {/* end::Form group */}

            <div className='row mb-10'>
                {/* begin::Form group */}
                <div className='fv-row col-12 '>
                    <label className='form-label fs-6 fw-bolder text-dark'>Phone</label>
                    <PhoneTextInput onChange={setPhoneNumber} />
                </div>
            </div>

            {/* begin:: ErrorText */}
            <strong style={{ color: 'red' }}>{errorText}</strong>
            {/* end:: ErrorText */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className={`btn btn-lg btn-${errorText && !loading ? 'danger' : 'primary'} w-100 mb-5 ${errorText && !loading ? 'mt-5' : ''}`}
                    disabled={!isValid || loading}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </form>
    )
}
