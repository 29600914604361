import {Dispatch, FC, useEffect, useState} from 'react'
import {map} from 'rxjs/operators'
import {Modal, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {decryptText} from '../../utils/util'
import {IVendorServiceRequest} from '../../network/PostRequestModels/VendorRequestForm'
import {CloseVendorOfferDialog} from '../../redux/actionCreators/vendorActionCreators'
import {VendorAction} from '../../redux/actionTypes/vendorTypes'
import {AnyNsRecord} from 'dns'
import {GroupedCheckbox} from '../../umut-components/Inputs/GroupedCheckbox'

type Props = {
  handleClose: () => void
  refetch: () => void
}

type ServiceSubGroup = {
  label: string
  value: AnyNsRecord
}

type ServiceGroup = {
  label: string
  value: any
  sublist: ServiceSubGroup[]
}

const VendorServiceForm: FC<Props> = ({handleClose, refetch}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const vendorId = user.userSection.vendorId
  const dispatch = useDispatch<Dispatch<VendorAction>>()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [types, setTypes] = useState<ServiceGroup[]>([])
  const [serviceGroups, setServiceGroups] = useState<ServiceGroup[]>([])
  const [selectedServiceDescription, setSelectedServiceDescription] = useState<string | null>(null)
  const [unitSectionActive, setUnitSectionActive] = useState<boolean>(true)
  const {
    data: serviceGroupsWithTypes,
    isLoading,
    error,
  } = useQuery(
    'Services Groups with Service Types',
    () => ApiCalls.getServiceGroupsWithServiceTypes(),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
    }
  )
  const [vendorServiceRequest, setVendorServiceRequest] = useState<IVendorServiceRequest>({
    serviceTypeId: [],
  } as IVendorServiceRequest)

  const selectServiceTypes = (values: any[]) => {
    const raw = Object.assign({}, vendorServiceRequest)
    raw.serviceTypeId = values
    setVendorServiceRequest({...raw})
  }

  const handleSubmit = async () => {
    try {
      setErrorMessage(undefined)
      setLoading(true)
      const request: IVendorServiceRequest = {
        ...vendorServiceRequest,
        vendorId: parseInt(vendorId),
      }
      await ApiCalls.postVendorServices(request)
      setLoading(false)
      refetch()
      handleClose()
      dispatch(CloseVendorOfferDialog())
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message)
    }
  }

  useEffect(() => {
    if (serviceGroupsWithTypes) {
      const list: ServiceGroup[] = []
      serviceGroupsWithTypes?.data?.data.map((sg: any) => {
        list.push({
          label: sg.name,
          value: sg.id,
          sublist: sg.serviceTypes.map((sgt: any) => {
            return {label: sgt.name, value: sgt.id}
          }),
        })
      })
      setServiceGroups(list)
    }
  }, [serviceGroupsWithTypes])

  return (
    <form className='form w-100'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Service Types</label>
          <GroupedCheckbox
            data={serviceGroups}
            selectedValues={vendorServiceRequest.serviceTypeId}
            onValueChange={(v) => {
              selectServiceTypes(v)
            }}
          />

          <strong className='w-100 text-danger'></strong>

          <div className='col mt-8 '>
            <div className='d-flex col gap-3 justify-content-end'>
              <button
                type='button'
                className='btn btn-success '
                disabled={loading || !vendorServiceRequest.serviceTypeId}
                onClick={handleSubmit}
              >
                Save Services {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col text-end'>
              <strong style={{color: 'red'}}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default VendorServiceForm
