import React, {Dispatch} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ChecklistAction} from '../../../../../redux/actionTypes/checklistTypes'
import * as checklistActionCreators from '../../../../../redux/actionCreators/checklistActionCreators'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import ChecklistSectionEdit from '../../../../../umut-components/Forms/ChecklistForms/ChecklistSectionEdit'
import {decryptText} from '../../../../../utils/util'

type Props = {}

const SectionEditDialog: React.FC<Props> = () => {
  let {checklistId}: any = useParams()
  checklistId = decryptText(checklistId)
  const {sectionEditDialog} = useSelector((state: RedusxAppState) => state.checklist)
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()

  const closeModal = () => {
    dispatch(checklistActionCreators.closeSectionEditDialog())
  }

  return (
    <Modal show={sectionEditDialog.dialogOpen} onHide={closeModal} centered>
      <ModalHeader closeButton>
        <Modal.Title>Edit Checklist Room</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <ChecklistSectionEdit
          checklistId={checklistId}
          section={sectionEditDialog.selectedSection}
        />
      </Modal.Body>
    </Modal>
  )
}

export default SectionEditDialog
