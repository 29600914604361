import axios from 'axios'
import dayjs from 'dayjs'
import notify from 'devextreme/ui/notify'
import React, {useEffect} from 'react'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  IHostPropertyAutomation,
  IHostPropertyICal,
  IHostPropertyInput,
} from '../../../interfaces/Property'
import ApiCalls from '../../../network/ApiCalls'
import {decryptText} from '../../../utils/util'
import ArrowButton from '../../Buttons/ArrowButton'
import DeleteButton from '../../Buttons/DeleteButton'
import SwitchBox from '../../Inputs/SwitchBox'
import TimePicker from '../../Inputs/TimePicker'
const debounce = require('debounce')

type Props = {
  hostPropertyAutomation: IHostPropertyAutomation
  hostPropertyICals?: IHostPropertyICal[]
  pmsReferenceId?: string
  handleChangeHostProperty: (changedValues: IHostPropertyInput) => void
  sameDayBooking?: boolean
  nextStep?: () => void
  previousStep?: () => void
  goToStep?: (step: number) => void
  currentStep?: number
  type: 'pms' | 'manual'
  providerId: number
  editMode?: boolean
  missingField?: boolean
}

const AutomationStep: React.FC<Props> = ({
  type,
  missingField,
  providerId,
  goToStep,
  currentStep,
  hostPropertyAutomation,
  handleChangeHostProperty,
  nextStep,
  previousStep,
  hostPropertyICals,
  editMode,
  sameDayBooking,
}) => {
  const [selectedPropertyAutomation, setSelectedPropertyAutomation] =
    useState<IHostPropertyAutomation>(
      hostPropertyAutomation
        ? hostPropertyAutomation
        : {
            isAutomaticCalendarSync: true,
            isAutomaticPayment: false,
            isAutomaticSchedule: true,
            usePMS: false,
          }
    )
  const [selectedICalUrls, setSelectedICalUrls] = useState<IHostPropertyICal[]>(
    hostPropertyICals ? (hostPropertyICals.length > 0 ? hostPropertyICals : [{}]) : [{}]
  )
  const [hasSameDayBooking, setHasSameDayBooking] = useState<boolean>(
    sameDayBooking ? sameDayBooking : false
  )
  const [validTime, setValidTime] = useState<string | null>(null)
  const [icalErrorIndexes, setIcalErrorIndexes] = useState<number[]>([])
  const [deletedICals, setDeletedIcals] = useState<IHostPropertyICal[]>([])

  let {propertyId}: any = useParams()
  propertyId = Number(decryptText(propertyId))

  const changeScheduling = (check: boolean) => {
    setSelectedPropertyAutomation({
      ...selectedPropertyAutomation,
      isAutomaticSchedule: check,
      isAutomaticCalendarSync: check,
    })
  }

  const changePayments = (check: boolean) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, isAutomaticPayment: check})
  }

  const changeCalendarSync = (check: boolean) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, isAutomaticCalendarSync: check})
  }

  const changeCheckoutTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkOutTime: event})
  }

  const changeCheckinTime = (event: string) => {
    setSelectedPropertyAutomation({...selectedPropertyAutomation, checkInTime: event})
  }

  const checkICalValid = (value: string, changeIndex: number) => {
    ApiCalls.validateICal(value)
      .then((res) => {
        if (res.data) {
          setIcalErrorIndexes(icalErrorIndexes.filter((index) => index !== changeIndex))
        } else {
          setIcalErrorIndexes([...icalErrorIndexes, changeIndex])
        }
      })
      .catch((err) => setIcalErrorIndexes([...icalErrorIndexes, changeIndex]))
  }
  const checkICalValidDebounce = debounce(checkICalValid, 600)

  const changeICalUrl = (value: string, changeIndex: number) => {
    setSelectedICalUrls(
      selectedICalUrls.map((iCal, index) =>
        index === changeIndex ? {...iCal, icalUrl: value} : iCal
      )
    )
    checkICalValidDebounce(value, changeIndex)
  }

  const addNewICalUrl = () => {
    setSelectedICalUrls([...selectedICalUrls, {icalUrl: ''}])
  }
  const refreshJobSync = async () => {
    try {
      let request = {
        isWeekly: false,
        hostPropertyId: propertyId,
      }
      providerId == 2
        ? await ApiCalls.postRefreshPro(request)
        : providerId == 3
        ? await ApiCalls.postRefreshPlus(request)
        : await ApiCalls.postRefreshMyTeam(request)
      notify(
        {
          message: 'Job Sync refresh was successful',
          position: {
            my: 'center top',
            at: 'center top',
          },
        },
        'success',
        5000
      )
    } catch (error: any) {
      console.log(error?.response?.data?.message)
    }
  }
  const refreshCalender = async () => {
    try {
      await ApiCalls.postRefreshCalendar(propertyId).then((actualData) =>
        notify(
          {
            message: actualData,
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'success',
          5000
        )
      )
    } catch (error: any) {
      console.log(error?.response?.data?.message)
    }
  }

  const deleteICalUrl = (iCalx: IHostPropertyICal) => {
    const deletedList = deletedICals

    selectedICalUrls.map((iCal, index) => {
      if (iCal.id === iCalx?.id) {
        deletedList.push({...iCal, isDeleted: true})
      }
    })
    setDeletedIcals(deletedList)
    let newArray: any = selectedICalUrls.filter((iCal, index) => iCal.id !== iCalx?.id)
    setSelectedICalUrls(newArray.length > 0 ? newArray : [{icalUrl: ''}])
  }

  useEffect(() => {
    handleChangeHostProperty({
      sameDayBooking: hasSameDayBooking,
      hostPropertyAutomation: selectedPropertyAutomation,
      hostPropertyICals: selectedPropertyAutomation.isAutomaticCalendarSync
        ? [...selectedICalUrls, ...deletedICals]
        : [],
    })
  }, [selectedPropertyAutomation, selectedICalUrls, hasSameDayBooking])

  useEffect(() => {
    if (hasSameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          selectedPropertyAutomation?.checkInTime
            ? parseInt(selectedPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          selectedPropertyAutomation?.checkInTime
            ? parseInt(selectedPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          selectedPropertyAutomation?.checkOutTime
            ? parseInt(selectedPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          selectedPropertyAutomation?.checkOutTime
            ? parseInt(selectedPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        setValidTime(
          `Check-in Time cannot be earlier then Check-out Time. (${selectedPropertyAutomation.checkOutTime})`
        )
      } else {
        setValidTime(null)
      }
    } else {
      setValidTime(null)
    }
  }, [selectedPropertyAutomation, hasSameDayBooking])

  return (
    <form className='form w-100 mt-5'>
      {/* begin::Form group */}
      <div className='row'>
        <div className='col-lg-6'>
          <label className='form-label fs-6 fw-bolder text-dark'>Same Day Booking</label>
          <SwitchBox
            mutedText
            defaultChecked={hasSameDayBooking}
            text='When enabled, Check-in Time cannot be earlier then Check-out Time.'
            onCheckboxChange={setHasSameDayBooking}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-5'>Automatic Scheduling</label>
          <SwitchBox
            mutedText
            defaultChecked={
              selectedPropertyAutomation.isAutomaticSchedule !== undefined
                ? selectedPropertyAutomation.isAutomaticSchedule
                : true
            }
            text='When enabled, cleaners will be automatically sent cleaning requests for all of your checkouts.'
            onCheckboxChange={changeScheduling}
          />

          {!selectedPropertyAutomation?.usePMS && selectedPropertyAutomation.isAutomaticCalendarSync
            ? selectedICalUrls.map((iCal, index, arr) => {
                return (
                  <div className='row'>
                    <strong className='col-12 mb-3 mt-3'>
                      ICal URL #{index + 1} {<span style={{color: 'red'}}>*</span>}
                    </strong>
                    <div className='col-11'>
                      <input
                        value={iCal.icalUrl}
                        defaultValue={iCal.icalUrl}
                        onChange={(e: any) => {
                          changeICalUrl(e.target.value, index)
                        }}
                        className={'form-control '}
                        type={'text'}
                        autoComplete='off'
                        required
                      />
                      {missingField && (iCal.icalUrl === '' || !iCal.icalUrl) && (
                        <small className='text-danger'>
                          Missing Field
                          <br />
                        </small>
                      )}
                      {icalErrorIndexes.includes(index) && (
                        <small className='text-danger'>ICal is not valid!</small>
                      )}
                    </div>
                    <DeleteButton
                      deleteFn={() => {
                        deleteICalUrl(iCal)
                      }}
                      classNames='col-1 btn btn-link text-danger'
                    />
                    {index === arr.length - 1 ? (
                      <button
                        disabled={iCal.icalUrl === '' || !iCal.icalUrl}
                        type='button'
                        className='btn btn-success btn-sm w-100 m-3'
                        onClick={() => addNewICalUrl()}
                      >
                        Add new ICal
                      </button>
                    ) : null}
                  </div>
                )
              })
            : null}
        </div>

        <div className='d-flex flex-column col-lg-6'>
          <strong>Please select the check-in / check-out times of your property.</strong>
          <small className='text-muted'>
            Assignments will be made based on the times you have chosen while creating the cleaning
            schedule for your property.
          </small>
          <div className='row'>
            <div className='w-50'>
              {' '}
              <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                Check-out Time {<span style={{color: 'red'}}>*</span>}
              </label>
              <TimePicker
                onChange={changeCheckoutTime}
                defaultValue={selectedPropertyAutomation.checkOutTime ?? '11:00'}
              />
            </div>
            <div className='w-50'>
              <label className='form-label fs-6 fw-bolder text-dark mt-5'>
                Check-in Time {<span style={{color: 'red'}}>*</span>}
              </label>
              <TimePicker
                disabled={!selectedPropertyAutomation.checkOutTime}
                onChange={changeCheckinTime}
                defaultValue={selectedPropertyAutomation.checkInTime ?? '15:00'}
              />
              {validTime && <strong className='text-danger'>{validTime}</strong>}
            </div>
          </div>
          <div className='d-flex justify-content-end mt-4 gap-5'>
            <button
              type='button'
              onClick={refreshCalender}
              className='btn btn-sm btn-light-primary w-50'
            >
              Calendar Refresh
            </button>
            <button type='button' onClick={refreshJobSync} className='btn btn-sm btn-info w-50'>
              Job Sync
            </button>
          </div>
        </div>
      </div>
      {editMode ? null : (
        <div className='d-flex justify-content-between flex-row w-100 mt-6'>
          <ArrowButton type='back' func={previousStep} />
          <ArrowButton type='forward' func={nextStep} />
        </div>
      )}
    </form>
  )
}

export default AutomationStep
