import * as deleteDialogActionCreators from '../../../redux/actionCreators/deleteDialogActionCreators'
import {Dispatch, FC} from 'react'
import ApiCalls from '../../../network/ApiCalls'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import {toAmazonUrl} from '../../../utils/util'
import {useDispatch} from 'react-redux'
import {DeleteActions} from '../../../redux/actionTypes/deleteDialogTypes'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'

type Props = {
  data: any
  refetch: () => void
}
const DocumentsTable: FC<Props> = ({data, refetch}) => {
  const deleteDialogDispatch = useDispatch<Dispatch<DeleteActions>>()

  return (
    <div className='row gap-2'>
      {data.length !== 0 ? (
        data.map((item: any, idx: number) => (
          <div key={idx} className='col-xl-2 min-w-250px'>
            <div className={`card bg-${(item.documentTypeId %= 2) == 0 ? 'success' : 'primary'}`}>
              <div className='card-header border-0 d-flex justify-content-between pt-10'>
                <a href={toAmazonUrl(item.documentPath)}>
                  <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='14'
                      height='14'
                      fill='white'
                      className='bi bi-file-earmark-arrow-down'
                      viewBox='0 0 16 16'
                    >
                      <path d='M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z' />
                      <path d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z' />
                    </svg>
                  </span>

                  {/* <div>
                <DeleteButton
                  classNames='btn btn-icon btn-bg-white btn-active-color-danger btn-sm'
                  iconWidth='20'
                  iconHeight='20'
                  deleteFn={() => {
                    deleteDialogDispatch(
                      deleteDialogActionCreators.openDeleteDialog(
                        async () => {
                          await ApiCalls.deleteVendorDocument(item.id)
                          refetch()
                        },
                        `Are you sure you want to delete document ?`,
                        'Delete Document'
                      )
                    )
                  }}
                />
              </div> */}
                </a>
              </div>
              <a href={toAmazonUrl(item.documentPath)}>
                <div className='card-body ms-6'>
                  <div className='fw-bold text-inverse-danger fs-7 mt-5'>
                    {item.documentTypeName}
                  </div>
                  <div className='text-inverse-danger fw-bolder fs-2 mb-2 '>Document</div>
                </div>
              </a>
            </div>
          </div>
        ))
      ) : (
        <EmptyTable title='No Documents' />
      )}
    </div>
  )
}

export default DocumentsTable
