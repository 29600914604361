import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import ApiCalls from '../../../network/ApiCalls';
import SelectBox from '../../Inputs/SelectBox';
import { TextInput } from '../../Inputs/TextInput';
import SwitchBox from '../../Inputs/SwitchBox';
import { ChecklistEditRequest } from '../../../network/PostRequestModels/ChecklistRequests';
import { useSelector } from 'react-redux';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import { useDialog } from '../../../contexts/DialogContext';
import { useHistory } from 'react-router-dom';

type Props = {
    hostPropertyId: number,
    editSuccess: () => void
}

const ChecklistEditForm: React.FC<Props> = ({ hostPropertyId, editSuccess }) => {
    const { checklistEditDialog } = useSelector((state: RedusxAppState) => state.checklist);
    const history = useHistory();
    const { showSuccessDialog } = useDialog();
    const [checklistRequest, setChecklistRequest] = useState<ChecklistEditRequest>({ name: checklistEditDialog.selectedChecklist?.name, isDefault: checklistEditDialog.selectedChecklist?.isDefault } as ChecklistEditRequest);
    const [loading, setLoading] = useState(false);
    const [defaultChangeLoading, setDefaultChangeLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleChecklistNameEdit = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            const { name } = checklistRequest;
            if (name) {
                await ApiCalls.editChecklist({ name: checklistRequest.name }, checklistEditDialog.selectedChecklist?.id);
                setLoading(false);
                showSuccessDialog('Checklist Updated.');
                editSuccess();
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    const handleSetDefault = async () => {
        try {
            setDefaultChangeLoading(true);
            setErrorMessage(null);
            await ApiCalls.editChecklist({ isDefault: true }, checklistEditDialog.selectedChecklist?.id);
            setDefaultChangeLoading(false);
            showSuccessDialog('Checklist Set as Default.');
            editSuccess();

        }
        catch (err: any) {
            setDefaultChangeLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                    <TextInput defaultValue={checklistRequest.name} onValueChange={(text: string) => setChecklistRequest({ ...checklistRequest, name: text })} />

                    <div className='d-flex justify-content-between mt-6'>
                        {!checklistRequest.isDefault ? <button type='button' className='btn btn-success' disabled={defaultChangeLoading} onClick={handleSetDefault}>Set as Default Checklist {defaultChangeLoading ? <Spinner animation='border' size='sm' /> : null}</button> : <div />}
                        <button type='button' className='btn btn-success' disabled={loading} onClick={handleChecklistNameEdit}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                    </div>
                    <strong className='w-100 text-danger'>{errorMessage}</strong>
                </div>
            </div>
        </form>
    )
}

export default React.memo(ChecklistEditForm);