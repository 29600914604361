import React from 'react'
import PropertyDetails from '../../../../../umut-components/PropeprtyDetails/PropertyDetails'

type Props = {
    hostPropertyId: number;
    jobEventsActive: boolean;
}

const PropertyTab: React.FC<Props> = ({ hostPropertyId, jobEventsActive }) => {
    
    return (
        <div className='mt-6'>
            <PropertyDetails propertyId={hostPropertyId} />
        </div>
    )
}

export default PropertyTab;