export const VIOLATION_OPEN_VIEW = 'VIOLATION_OPEN_VIEW'
import { IViolation } from './../../interfaces/Violation';

export interface OpenViolationViewAction {
  type: typeof VIOLATION_OPEN_VIEW
  violation: IViolation | null
}

export const VIOLATION_CLOSE_VIEW = 'VIOLATION_CLOSE_VIEW'
export interface CloseViolationViewAction {
  type: typeof VIOLATION_CLOSE_VIEW
}

export type ViolationDetailActions =
  | OpenViolationViewAction
  | CloseViolationViewAction