import { FC } from 'react'
import ContentLoader from 'react-content-loader'
type Props = {
    boxQuantity?: number
}
const SkeletonBoxLoading: FC<Props> = ({ boxQuantity }) => {
    const piece = [1, 2, 3, 4]
    return (
        <>
            <div style={{ overflowX: 'hidden' }}>
                <ContentLoader
                    speed={0.6}
                    width={"100%"}
                    height={400}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    {piece.map((idx: number) => (
                        <rect x={`${((idx - 1) * 350) + 20}`} y="40" rx="7" ry="7" width="300" height="285" />
                    ))}
                </ContentLoader>
            </div></>

    )
}

export default SkeletonBoxLoading
