import { Button } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { Dispatch, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useDialog } from '../../contexts/DialogContext';
import ApiCalls from '../../network/ApiCalls';
import { JobTaskRequest } from '../../network/PostRequestModels/JobRequests';
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { decryptText, toAmazonUrl, convertUTCDateToLocalDate } from '../../utils/util';
import DateTimePicker from '../Inputs/DateTimePicker';
import { NumberInput } from '../Inputs/NumberInput';
import { TextInput } from '../Inputs/TextInput';

type Props = {
}

const JobTaskForm: React.FC<Props> = () => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { jobTaskDialog, jobDetail } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { showSuccessDialog } = useDialog();
    const [taskRequest, setTaskRequest] = useState<JobTaskRequest>({
        jobId: parseInt(jobId),
        scheduledStart: jobTaskDialog.taskData?.scheduledStart ? jobTaskDialog.taskData?.scheduledStart : jobDetail?.scheduledStart,
        taskDescription: jobTaskDialog.taskData?.taskDescription,
        taskDuration: jobTaskDialog.taskData?.taskDuration ? jobTaskDialog.taskData?.taskDuration : 1,
        notes: jobTaskDialog.taskData?.notes
    } as JobTaskRequest);
    const [imagePreview, setImagePreview] = useState<any>(jobTaskDialog.taskData?.mediaPath ? toAmazonUrl(jobTaskDialog.taskData?.mediaPath) : null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleTaskSend = async () => {
        const { jobId, scheduledStart, taskDescription, taskDuration, notes, jobTaskMedia } = taskRequest;
        if (jobId && scheduledStart && taskDescription && taskDuration) {
            try {
                setLoading(true);
                setErrorMessage(null);
                var formData = new FormData();
                formData.append('taskDescription', taskDescription);
                formData.append('taskDuration', taskDuration.toString());
                formData.append('jobId', jobId.toString());
                formData.append('scheduledStart', scheduledStart);
                notes && formData.append('notes', notes);
                jobTaskMedia && formData.append('jobTaskMedia', jobTaskMedia);
                jobTaskDialog.taskData ? await ApiCalls.updateJobTask(jobTaskDialog.taskData.id, formData) : await ApiCalls.addJobTasks(formData);
                setLoading(false);
                jobTaskDialog.refetch();
                dispatch(jobActionCreator.closeTaskDialog());
            }
            catch (err: any) {
                setLoading(false);
                setErrorMessage(err?.response?.data?.message);
            }
        }
        else {
            setErrorMessage('All fields are required.');
        }
    }

    const changeImage = (e: any) => {
        try {
            setErrorMessage(null);
            var file = e.target.files[0];
            if (file.size <= 2097152) {
                setTaskRequest({ ...taskRequest, jobTaskMedia: file })
                if (file) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        setImagePreview(reader.result);
                    };
                }
                else {
                    setImagePreview(null);
                }
            }
            else {
                setErrorMessage('Max file size is 2MB.');
            }
        }
        catch (err: any) {
            setErrorMessage('File cannot be uploaded.')
        }
    }

    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Task Photo</label>
                    <div className='row d-flex justify-content-center'>
                        {imagePreview ? <div className='d-flex justify-content-center'><img className='rounded' style={{ height: 200 }} alt='selected-sample' src={imagePreview} /></div> : <strong className='text-muted'>No Photo Available</strong>}
                        <Button
                            className='col-lg-9 mt-6'
                            variant="contained"
                            component="label"
                        >
                            Upload Photo
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={changeImage}
                                hidden
                            />
                        </Button>
                        (Max Photo Size 2MB)
                    </div>
                </div>
                <div className='col-1 mt-6' />
                <div className='col-lg-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Task Description</label>
                    <TextInput onValueChange={(text: string) => setTaskRequest({ ...taskRequest, taskDescription: text })} defaultValue={taskRequest?.taskDescription} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Task Duration (Minutes)</label>
                    <NumberInput min={1} max={1000} onValueChange={(text: number) => setTaskRequest({ ...taskRequest, taskDuration: text })} defaultValue={taskRequest?.taskDuration} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Notes (Optional)</label>
                    <TextInput onValueChange={(text: string) => setTaskRequest({ ...taskRequest, notes: text })} defaultValue={taskRequest?.notes} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Start Date</label>
                    <DateTimePicker onChange={(date: any) => { setTaskRequest({ ...taskRequest, scheduledStart: date }) }} min={dayjs(jobDetail?.scheduledStart).tz(jobDetail?.hostPropertyCityTimeZone).toString()} max={dayjs(jobDetail?.scheduledFinish).tz(jobDetail?.hostPropertyCityTimeZone).toString()} defaultValue={dayjs(jobDetail?.scheduledStart).tz(jobDetail?.hostPropertyCityTimeZone).toString()} />
                    <small>Job Time: {convertUTCDateToLocalDate(jobDetail?.scheduledStart, jobDetail?.hostPropertyCityTimeZone)} - {convertUTCDateToLocalDate(jobDetail?.scheduledFinish, jobDetail?.hostPropertyCityTimeZone)}</small>

                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading || !(taskRequest.jobId &&
                                taskRequest.scheduledStart && taskRequest.taskDescription &&
                                taskRequest.taskDuration)} onClick={handleTaskSend}>Save Task {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(JobTaskForm);