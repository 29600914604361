import {put, call, all, fork, takeLatest, delay, takeEvery, select} from 'redux-saga/effects'
import ApiCalls from '../../network/ApiCalls'
import {GetAddressesResponse} from '../../network/NetworkResponses/NetworkResponses'

import * as addressActionCreators from '../actionCreators/addressActionCreators'
import * as addressActionTypes from '../actionTypes/addressTypes'

function* onGetAddresses({userId}: addressActionTypes.GetUserAddresses) {
  try {
    const result: GetAddressesResponse = yield call(ApiCalls.getAddressesByUserId, userId)
    yield put(addressActionCreators.getUserAddressesSuccess(result.data.data))
  } catch (error: any) {
    yield put(addressActionCreators.getUserAddressesFailure(error?.response?.data?.message))
  }
}

function* watchOnGetAddresses() {
  yield takeLatest(addressActionTypes.GET_USER_ADDRESSES, onGetAddresses)
}

export default function* addressSaga() {
  yield all([fork(watchOnGetAddresses)])
}
