import React, {Dispatch} from 'react'
import {Card, Spinner} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {DragDropContext} from 'react-beautiful-dnd'
import {DropResult} from 'react-beautiful-dnd'
import ChecklistSection from './Section'
import SectionBoard from './SectionBoard'
import {useDispatch, useSelector} from 'react-redux'
import {ChecklistAction} from '../../../../../redux/actionTypes/checklistTypes'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import {useEffect} from 'react'
import * as checklistActionCreator from '../../../../../redux/actionCreators/checklistActionCreators'
import {useState} from 'react'
import SectionAddDialog from './SectionAddDialog'
import {IChecklistItem, ISection} from '../../../../../interfaces/Checklist'
import {ChecklistSetSectionOrderRequest} from '../../../../../network/PostRequestModels/ChecklistRequests'
import ApiCalls from '../../../../../network/ApiCalls'
import {useDialog} from '../../../../../contexts/DialogContext'
import SectionEditDialog from './SectionEditDialog'
import QuestionEditDialog from './Questions/QuestionEditDialog'
import QuestionAddDialog from './Questions/QuestionAddDialog'
import QuestionPhotoDialog from './Questions/QuestionPhotoDialog'
import {decryptText, encryptText} from '../../../../../utils/util'
import {useQuery} from 'react-query'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'

const ChecklistSections: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const {checklistItems, loading} = useSelector((state: RedusxAppState) => state.checklist)

  const {showSuccessDialog} = useDialog()
  let {checklistId}: any = useParams()
  checklistId = decryptText(checklistId)
  const [addSectionModalOpen, setAddSectionModalOpen] = useState<boolean>(false)
  const [saveChangesActive, setSaveChangeActive] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)

  const handleCloseModal = () => {
    setAddSectionModalOpen(false)
  }

  const handleAddSectionSuccess = () => {
    setAddSectionModalOpen(false)
    dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)))
  }

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result
    const sourceIndex = source.index
    const destinationIndex = destination?.index
    if (
      sourceIndex !== undefined &&
      destinationIndex !== undefined &&
      sourceIndex !== destinationIndex
    ) {
      setSaveChangeActive(true)
      let newSections: ISection[] = checklistItems?.sections ? [...checklistItems?.sections] : []
      let [removedSection] = newSections.splice(sourceIndex, 1)
      newSections.splice(destinationIndex, 0, removedSection)
      dispatch(
        checklistActionCreator.setChecklistItems(
          checklistItems ? {...checklistItems, sections: newSections} : ({} as IChecklistItem)
        )
      )
    }
  }
  const {data: propertyDetailData} = useQuery(
    ['Get Property Detail', checklistItems?.hostPropertyId],
    () => ApiCalls.getHostPropertyById(checklistItems!.hostPropertyId)
  )

  const handleChangeSectionList = async () => {
    setSendLoading(true)
    try {
      const sectionOrderRequest: ChecklistSetSectionOrderRequest[] = checklistItems?.sections
        ? checklistItems?.sections.map((section, index): ChecklistSetSectionOrderRequest => {
            return {
              id: section.id,
              orderIndex: index + 1,
            }
          })
        : []
      await ApiCalls.setSectionOrder(sectionOrderRequest, checklistItems?.id)
      dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)))
      setSaveChangeActive(false)
      setSendLoading(false)
      showSuccessDialog('Orders are changed successfully.')
    } catch (err) {
      setSaveChangeActive(false)
      setSendLoading(false)
    }
  }

  useEffect(() => {
    dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)))
  }, [])

  return (
    <>
      <PageTitle>{checklistItems?.name?.en}</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='w-100'>
          <button
            className='btn btn-link text-primary'
            onClick={() => {
              history.push(
                '/clients/property/checklists/' + encryptText(checklistItems!.hostPropertyId)
              )
            }}
          >
            Back to property checklists
          </button>
        </div>
        <div>
          {loading ? (
            <div className='d-flex justify-content-center'>Loading...</div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              {checklistItems && checklistItems?.sections.length > 0 ? (
                <strong className='text-muted p-4 mb-6'>Drag and Drop Rooms to Change Order</strong>
              ) : (
                <strong className='text-muted p-4 mb-6 text-center'>
                  Start by adding all your rooms to your checklist.
                </strong>
              )}
              <SectionBoard droppableId='section-container'>
                {checklistItems?.sections.map((section, index) => (
                  <ChecklistSection index={index} section={section} />
                ))}
              </SectionBoard>
            </DragDropContext>
          )}
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <div />
          {saveChangesActive ? (
            <button
              className='btn btn-success'
              disabled={sendLoading}
              style={{height: '100%'}}
              onClick={handleChangeSectionList}
            >
              Save Changes {sendLoading ? <Spinner animation='border' size='sm' /> : null}
            </button>
          ) : (
            <button
              className='btn btn-success'
              style={{height: '100%'}}
              onClick={() => {
                setAddSectionModalOpen(true)
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Room
            </button>
          )}
        </div>
      </div>
      <SectionAddDialog
        modalOpen={addSectionModalOpen}
        handleClose={handleCloseModal}
        handleAddSectionSuccess={handleAddSectionSuccess}
      />
      <SectionEditDialog />
      <QuestionAddDialog propertyDetailData={propertyDetailData} />
      <QuestionEditDialog propertyDetailData={propertyDetailData} />
      <QuestionPhotoDialog />
      <DeleteDialog />
    </>
  )
}

export default ChecklistSections
