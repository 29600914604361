import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {IMarketplaceOffer} from '../../interfaces/Marketplace'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  Editing,
  Button,
} from 'devextreme-react/data-grid'
import {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {encryptText} from '../../utils/util'

type Props = {
  data: IMarketplaceOffer[] | undefined
  title: string
}

interface OfferItem {
  id: number
  address: string | undefined
  city: string | undefined
  state: string | undefined
  host: string | undefined
  service: string
}

const MarketplaceQutoeRequestList: React.FC<Props> = ({data, title}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const [offerList, setOfferList] = useState<OfferItem[]>([])

  useEffect(() => {
    var list: OfferItem[] = []
    if (data != undefined) {
      data.map((d) => {
        if (d.serviceTypeInfo) {
          var item: OfferItem = {
            id: d.id,
            address: d.marketplace.address,
            city: d.marketplace.cityInfo?.name,
            state: d.marketplace.stateInfo?.name,
            host: d.marketplace.host?.name,
            service: d.serviceTypeInfo.name,
          }
          list.push(item)
        }
      })

      setOfferList(list)
    }
  }, [data])

  const toOfferDetail = (data: any) => {
    history.push('/quote-requests/detail/' + encryptText(data.id))
  }

  const renderEditButton = (cell: any) => {
    return (
      <button
        className='btn btn-sm btn-icon btn-danger'
        onClick={() => {
          toOfferDetail(cell.data)
        }}
      >
        <span className='svg-icon svg-icon-1'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24px'
            height='24px'
            viewBox='0 0 24 24'
            version='1.1'
          >
            <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
              <rect x='0' y='0' width='24' height='24' />
              <path
                d='M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z'
                fill='#fff'
                fill-rule='nonzero'
                opacity='0.4'
              />
              <path
                d='M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z'
                fill='#fff'
                opacity='0.55'
              />
            </g>
          </svg>
        </span>
      </button>
    )
  }

  if (data && data.length == 0) {
    return <EmptyTable title='There is no quote request' />
  }

  return (
    <div className='mt-10 mb-10'>
      <DataGrid
        id='offerTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        ref={tableRef}
        dataSource={offerList}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(65vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
      >
        <Export enabled={false} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat={'Total ' + title + ' : {0}'}
            column='address'
            summaryType='count'
          />
        </Summary>
        <Column
          dataField='address'
          caption='Address'
          minWidth={180}
          dataType='string'
          cssClass='cls'
        />
        <Column dataField='city' caption='City' dataType='string' cssClass='cls' />
        <Column dataField='state' caption='State' dataType='string' cssClass='cls' />
        <Column dataField='host' caption='Host' dataType='string' cssClass='cls' />
        <Column
          dataField='service'
          caption='Service'
          dataType='string'
          minWidth={180}
          cssClass='cls'
        />
        <Column type='buttons' width={120}>
          <Button render={renderEditButton} />
        </Column>
      </DataGrid>
    </div>
  )
}

export default MarketplaceQutoeRequestList
