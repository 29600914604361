import {
    Column,
    DataGrid,
    HeaderFilter,
    Paging,
    Scrolling,
} from 'devextreme-react/data-grid'
import { FC } from 'react'
import { IJobDetail } from '../../../../interfaces/Job'
import { getDateFilter } from '../../../../utils/util'

type Props = {
    data: IJobDetail[]
}
const OffDayEffectedJobTable: FC<Props> = ({ data }) => {
    return (
        <>
            <DataGrid
                id='jobTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
            >

                <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={50} />

                <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
                <Column dataField='scheduledStart' caption='scheduled start' minWidth={200} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
                </Column>
                <Column dataField='scheduledFinish' caption='scheduled finish' minWidth={200} cssClass='cls'>
                    <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
                </Column>
                <Column dataField='duration' caption='duration' minWidth={200} cssClass='cls' />


            </DataGrid>
        </>
    )
}

export default OffDayEffectedJobTable
