import React, { Dispatch, useState } from 'react'
import { Card, Tab, Tabs } from 'react-bootstrap-v5'
import { PageTitle } from '../../../../_metronic/layout/core'
import { StaffAction } from '../../../redux/actionTypes/staffActionTypes'
import * as staffActionCreators from '../../../redux/actionCreators/staffActionCreators'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ProfileTab from './Tabs/ProfileTab'
import Jobs from './Tabs/Jobs'
import OffDayTab from './Tabs/OffDayTab'
import StaffDocuments from './Tabs/staff-documents/StaffDocuments'
import { decryptText } from '../../../utils/util'
import StaffEditDialog from '../StaffEditDialog'
import StaffService from './Tabs/staff-cities/StaffService'

type TabNames =
  | 'profile'
  | 'property-list'
  | 'staff-offered-service'
  | 'jobs'
  | 'off-days'
  | 'documents'
  | 'staff-cities'

const StaffDetail: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<TabNames>('profile')
  let { staffId }: any = useParams()
  staffId = decryptText(staffId);
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const [editDialogOpen, setEditDialogOpen] = useState(false);


  const closeModal = () => {
    setEditDialogOpen(false)
  }
  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  useEffect(() => {
    dispatch(staffActionCreators.getStaffDetail(parseInt(staffId)))
  }, [])

  return (
    <>
      <PageTitle>Staff Detail</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>

          <Card.Body>

            <Tabs
              defaultActiveKey='profile'
              onSelect={handleChangeTab}
              id='uncontrolled-tab-example'
              className='mb-3'
            >
              <Tab eventKey='profile' title='Profile'>
                {selectedTab === 'profile' ? <ProfileTab modalOpen={setEditDialogOpen} /> : null}
              </Tab>
              {/* <Tab eventKey='property-list' title='Properties'>
                <div className='row'>
                  {selectedTab === 'property-list' ? (
                    <>
                      <div className='col-lg-6'>
                        <AllowedProperties />
                      </div>
                      <div className='col-lg-6'>
                        <BannedProperties />
                      </div>
                    </>
                  ) : null}
                </div>
              </Tab> */}
              {/*<Tab eventKey='staff-offered-service' title='Offered Services'>
                {selectedTab === 'staff-offered-service' ? <OfferedServices /> : null}
              </Tab>*/}
              <Tab eventKey='jobs' title='Jobs'>
                {selectedTab === 'jobs' ? <Jobs /> : null}
              </Tab>
              <Tab eventKey='off-days' title='Off Days'>
                {selectedTab === 'off-days' ? <OffDayTab /> : null}
              </Tab>
              <Tab eventKey='documents' title='Documents'>
                {selectedTab === 'documents' ? <StaffDocuments /> : null}
              </Tab>
              <Tab eventKey='staff-cities' title='Service Cities'>
                {selectedTab === 'staff-cities' ? <StaffService /> : null}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
      <StaffEditDialog modalOpen={editDialogOpen} modalClose={closeModal}/>

    </>
  )
}

export default StaffDetail
