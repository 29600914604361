import {
  Column,
  DataGrid,
  Export,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid'
import React, { Dispatch, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import config from '../../../../config/config.json'
import ApiCalls from '../../../../network/ApiCalls'
import * as deleteDialogActionCreators from '../../../../redux/actionCreators/deleteDialogActionCreators'
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators'
import { DeleteActions } from '../../../../redux/actionTypes/deleteDialogTypes'
import { JobAction } from '../../../../redux/actionTypes/jobTypes'
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton'
import UpdateButton from '../../../../umut-components/Buttons/UpdateButton'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import { decryptText, getDateFilter, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../../../utils/util'

type Props = {
  data: any
  refetch: () => void
  jobEventsActive: boolean
}

const TaskTable: React.FC<Props> = ({ data, refetch, jobEventsActive }) => {
  let { jobId }: any = useParams()
  jobId = decryptText(jobId)
  const history = useHistory()
  const jobDispatch = useDispatch<Dispatch<JobAction>>()
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()
  const tableRef = useRef<DataGrid>(null)

  const displayEditButton = (cellData: any) => {
    return (
      cellData?.data?.jobTaskStatusTypeName === 'Created' && (
        <UpdateButton
          iconWidth='20'
          iconHeight='20'
          updateFn={() => {
            jobDispatch(jobActionCreator.openTaskDialog(refetch, cellData?.data))
          }}
        />
      )
    )
  }

  const displayDeleteButton = (cellData: any) => {
    return (
      cellData?.data?.jobTaskStatusTypeName === 'Created' && (
        <DeleteButton
          iconWidth='20'
          iconHeight='20'
          deleteFn={() => {
            deleteDispatch(
              deleteDialogActionCreators.openDeleteDialog(
                async () => {
                  try {
                    await ApiCalls.deleteJobTask(cellData?.data?.id)
                    refetch()
                    deleteDispatch(deleteDialogActionCreators.closeDeleteDialog())
                  } catch (err: any) { }
                },
                `Are you sure you want to delete task ${cellData?.data?.taskDescription} ?`,
                'Delete Task'
              )
            )
          }}
        />
      )
    )
  }

  const calculateStartOn = (cellValue: any) => {
    return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const renderProfilePhoto = (cellValue: any) => {
    return (
      <ProfilePhotoTemp
        profilePhotoPath={cellValue?.data?.staff?.profilePhotoPath}
        firstName={cellValue?.data?.staff?.firstName}
        lastName={cellValue?.data?.staff?.lastName}
        width={45}
        height={45}
        borderRadius='50%'
      />
    )
  }

  const calculateFullName = (cellValue: any) => {
    return cellValue?.staff?.firstName + ' ' + cellValue?.staff?.lastName
  }

  const calculateStatus = (cellValue: any) => {
    if (cellValue.jobTaskStatusTypeName === "Finished") return "Completed";

    return cellValue.jobTaskStatusTypeName;
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Tasks' />
  }

  return (
    <div className="position-relative">
      <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='jobTasks'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(55vh - 60px)'}
        className='mt-6'
        allowColumnResizing={true}
        selection={{ mode: 'single' }}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Job Tasks')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Tasks: {0}'
            column='taskDescription'
            summaryType='count'
          />
        </Summary>

        <Column dataField='taskDescription' caption='Description' minWidth={200} cssClass='cls' />
        <Column
          dataField='taskDuration'
          caption='Minutes'
          minWidth={100}
          cssClass='cls'
          alignment='center'
        />
        <Column dataField="jobTaskStatusTypeName" calculateCellValue={calculateStatus} caption='Status' minWidth={200} cssClass='cls' alignment='center' />
        <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />
        <Column dataField='notes' caption='Note' minWidth={200} cssClass='cls' alignment='center' />
        <Column
          dataField='startedOn'
          dataType='date' selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateStartOn}
          minWidth={150}
          cssClass='cls'
          
        >
          <HeaderFilter dataSource={getDateFilter('startedOn')} />
        </Column>
        <Column
          dataField='finishedOn'
          dataType='date' selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateFinishedOn}
          minWidth={150}
          cssClass='cls'
          
        >
          <HeaderFilter dataSource={getDateFilter('finishedOn')} />
        </Column>
   
      </DataGrid>

    </div>
  )
}

export default TaskTable
