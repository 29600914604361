import * as actions from '../actionTypes/changePasswordTypes'

export interface ChangePasswordState {
    changePassFn?: (data:any) => Promise<void>
    modalOpen: boolean
    bodyText: string | null
    title: string | null
}

const initialState: ChangePasswordState = {
    changePassFn: undefined,
    modalOpen: false,
    bodyText: null,
    title: null,
}

export default function changePasswordReducer(
    state: ChangePasswordState = initialState,
    action: actions.ChangePasswordActions
): ChangePasswordState {
    switch (action.type) {
        case actions.OPEN_CHANGE_PASSWORD:
            return {
                ...state,
                changePassFn: action.changePassFn,
                bodyText: action.bodyText,
                title: action.title,
                modalOpen: true,
            }
        case actions.CLOSE_CHANGE_PASSWORD:
            return { ...state, modalOpen: false, changePassFn: undefined, bodyText: null, title: null }
        default:
            return state
    }
}
