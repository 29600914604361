import React, { useState } from 'react'
import { useQuery } from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from './../../../umut-components/Loading/SkeletonTableLoading'
import { useSelector } from 'react-redux'
import { RedusxAppState } from '../../../redux/reducers/rootReducer'
import StaffOfferedTable from './StaffOfferedTable'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Card } from 'react-bootstrap-v5'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import { AxiosResponse } from 'axios';
import { GetOffered } from '../../../network/NetworkResponses/NetworkResponses'

const StaffOffered: React.FC = () => {
    const {
        user: {
            userSection: { vendorId },
        },
    } = useSelector((state: RedusxAppState) => state.user)
    const [show, setShow] = useState(false)
    const {
        data: offeredServiceData,
        isLoading: servicesLoading,
        error: servicesError,
        refetch
    } = useQuery<AxiosResponse<GetOffered>>(['Offered Services', vendorId], () => ApiCalls.getVendorsOfferedServices(vendorId), {
        enabled: vendorId ? true : false,
        cacheTime: 10000,
        refetchOnWindowFocus: false,
    })
    

    const closeModal = () => {
        setShow(false)
        refetch()
    }

    return (
        <>
            <PageTitle>Staff Offered</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card >
                    <Card.Body >

                        {servicesLoading ? <SkeletonTableLoading /> : <StaffOfferedTable refetch={refetch} data={offeredServiceData?.data?.data} />}

                    </Card.Body>

                </Card>
            </div>
            <DeleteDialog />
        </>
    )
}

export default StaffOffered
