import React, {useEffect, useState} from 'react'

interface INumberInput {
  onValueChange: (number: number) => void
  placeholder?: string
  defaultValue?: string | number
  classNames?: string
  min?: number
  max?: number
  disabled?: boolean
}
export const NumberInput: React.FC<INumberInput> = ({
  onValueChange,
  placeholder,
  defaultValue,
  classNames,
  min,
  max,
  disabled,
}) => {
  const [textValue, setTextValue] = useState<string | number | undefined>(defaultValue)

  const textValueChange = (event: any) => {
    let newValue = parseInt(event.target.value)
    setTextValue(newValue)
    onValueChange(newValue)
  }

  useEffect(() => {
    if (defaultValue) {
      setTextValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className='w-100 d-flex flex-column'>
      <input
        value={textValue}
        onChange={textValueChange}
        min={min}
        max={max}
        placeholder={placeholder}
        className={'form-control ' + classNames}
        type='number'
        autoComplete='off'
        disabled={disabled}
        required
      />
      {!disabled && min !== undefined && textValue !== undefined && +min > +textValue && (
        <small className='text-danger d-flex'>Must be greater than {min}</small>
      )}
      {!disabled && max !== undefined && textValue !== undefined && +max < +textValue && (
        <small className='text-danger d-flex'>Must be less than {max}</small>
      )}
    </div>
  )
}
