import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {Dispatch, FC, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import config from '../../config/config.json'
import * as jobAvailableCreators from '../../redux/actionCreators/JobAvailableActionCreators'
import {JobAvailableActions} from '../../redux/actionTypes/jobAvailableTypes'
import ViewButton from '../../umut-components/Buttons/ViewButton'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {
  getDateFilter,
  getJobDateFilter,
  setDxFilterPlaceholder,
  toConvertedDate,
} from '../../utils/util'

type Props = {
  data: any
  refetch: () => void
}
const JobAvailableTable: FC<Props> = ({data, refetch}) => {
  const dispatch = useDispatch<Dispatch<JobAvailableActions>>()
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)

  const renderViewButton = (cellValue: any) => {
    return (
      <ViewButton
        classNames='btn-sm invisible'
        iconWidth='15'
        iconHeight='15'
        viewFn={() => {
          dispatch(
            jobAvailableCreators.openJobAvailableDialog(
              cellValue?.data?.id,
              cellValue?.data?.cleaningPeriodStart,
              refetch
            )
          )
        }}
      />
    )
  }
  const toAvailableModal = (cellValue: any) => {
    if (cellValue?.data?.id) {
      dispatch(
        jobAvailableCreators.openJobAvailableDialog(
          cellValue?.data?.id,
          cellValue?.data?.cleaningPeriodStart,
          refetch
        )
      )
    }
  }

  const calculateScheduledStart = (cellValue: any) => {
    var date = cellValue.cleaningPeriodStart
    var dt = toConvertedDate(date, cellValue.hostPropertyCityTimeZone)
    return dt
  }
  const calculateScheduledFinish = (cellValue: any) => {
    var endDay = cellValue.cleaningPeriodEnd
    var dt = toConvertedDate(endDay, cellValue.hostPropertyCityTimeZone)
    return dt
  }

  if (data?.length === 0) {
    return (
      <EmptyTable title='You have no pending jobs at this time. Check back later for new job opportunities.' />
    )
  }
  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>

      <DataGrid
        id='JobsAvailable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        ref={tableRef}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        columnAutoWidth={true}
        onCellClick={toAvailableModal}
        height={'calc(60vh - 60px)'}
        allowColumnResizing={true}
        onEditorPreparing={setDxFilterPlaceholder}
        selection={{mode: 'single'}}
      >
        <HeaderFilter visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <SearchPanel visible={true} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Jobs: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>
        <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
        <Column
          dataField='cleaningPeriodStart'
          caption='job Start'
          dataType='date'
          selectedFilterOperation='between'
          calculateCellValue={calculateScheduledStart}
          format={config.date_time_format_dev_extreme}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getJobDateFilter('cleaningPeriodStart')} />
        </Column>
        <Column
          dataField='cleaningPeriodEnd'
          caption='job End'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getJobDateFilter('cleaningPeriodEnd')} />
        </Column>
        <Column
          dataField='serviceTypeName'
          caption='Service Type'
          minWidth={180}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='totalPrice'
          caption='price'
          dataType='number'
          minWidth={100}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='totalDuration'
          caption='total duration'
          minWidth={200}
          dataType='number'
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='hostPropertyNumberOfBedrooms'
          caption='number of bedrooms'
          minWidth={200}
          dataType='number'
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='hostPropertyNumberOfBathrooms'
          caption='number of bathrooms'
          minWidth={200}
          dataType='number'
          cssClass='cls'
          alignment='center'
        />
        <Column
          cellRender={renderViewButton}
          cssClass='cls'
          width={110}
          alignment='center'
        ></Column>
      </DataGrid>
    </div>
  )
}

export default JobAvailableTable
