import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {Link, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import * as hostActionCreators from '../../../redux/actionCreators/hostActionCreators'
import UpdateButton from '../../../umut-components/Buttons/UpdateButton'
import {
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  getDateFilter,
  setDxFilterPlaceholder,
} from '../../../utils/util'
import {IHost} from '../../../interfaces/Host'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import {HostAction} from '../../../redux/actionTypes/hostTypes'
import dayjs from 'dayjs'
import config from '../../../config/config.json'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'

type Props = {
  refetch: () => void
  data: IHost[]
}

const HostTable: React.FC<Props> = ({data, refetch}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<HostAction>>()
  const vendorId = user.userSection.vendorId

  const renderEditButton = (cellValue: any) => {
    return (
      <UpdateButton
        iconWidth='20'
        iconHeight='20'
        updateFn={() => {
          dispatch(hostActionCreators.openHostEditDialog(refetch, cellValue?.data))
        }}
      />
    )
  }

  // const renderViewButton = (cellValue: any) => {
  //     return (
  //         <Link to={'/host-details/' + cellValue?.data?.id} target="_blank">
  //             <ViewButton iconWidth="20" iconHeight="20" viewFn={() => { }} />
  //         </Link>

  //     )
  // }
  const tableRef = useRef<DataGrid>(null)

  const toHostDetail = (cellData: any) => {
    if (cellData?.data?.id) {
      window.open(`/clients/detail/` + encryptText(cellData?.data?.id))
    }
  }
  // const calculatelastLoginOn = (cellValue: any) => {

  //   return toConvertedDate(cellValue?.data?.lastLoginOn);
  // }

  const calculateRegisteredOn = (cellValue: any) => {
    var createdOn = dayjs(cellValue?.data?.createdOn).format(config.date_format)
    return createdOn
  }

  const getClientPhone = (cellValue: any) => {
    if (vendorId == cellValue?.data?.vendorId) {
      return cellValue?.data?.contactUser?.phoneNumber
    } else {
      return '-'
    }
  }
  const getClientEmail = (cellValue: any) => {
    if (vendorId == cellValue?.data?.vendorId) {
      return cellValue?.data?.contactUser?.email
    } else {
      return '-'
    }
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Clients' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='hosts'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(71vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onCellClick={toHostDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Clients')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={10} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Clients: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='name'
          caption='Client Name'
          minWidth={200}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='contactUser.phoneNumber'
          caption='Phone'
          cellRender={getClientPhone}
          minWidth={200}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='contactUser.email'
          caption='Email'
          cellRender={getClientEmail}
          minWidth={250}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='hostStatusTypeName'
          caption='Status'
          width={180}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='contactUser.lastLoginOn'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          caption='Last Login'
          minWidth={200}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('lastLoginOn')} />
        </Column>
        <Column
          dataField='createdOn'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          cellRender={calculateRegisteredOn}
          caption='Registered On'
          minWidth={200}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('createdOn')} />
        </Column>
        <Column
          dataField='totalPropertyCount'
          dataType='number'
          caption='Property Count'
          alignment='center'
          minWidth={200}
          cssClass='cls'
        />
        <Column
          dataField='staffCount'
          dataType='number'
          caption='#of Staff'
          alignment='center'
          minWidth={150}
          cssClass='cls'
        />
        <Column
          dataField='completedJobCount'
          dataType='number'
          caption='#of Jobs(Completed)'
          alignment='center'
          minWidth={230}
          cssClass='cls'
        />
        <Column
          dataField='scheduledJobCount'
          caption='#of Jobs(Scheduled)'
          dataType='number'
          alignment='center'
          minWidth={200}
          cssClass='cls'
        />
      </DataGrid>
    </div>
  )
}

export default React.memo(HostTable)
