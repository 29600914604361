import { Column, DataGrid, Export, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import React, { Dispatch, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ApiCalls from '../../../../../network/ApiCalls';
import * as deleteDialogActionCreators from '../../../../../redux/actionCreators/deleteDialogActionCreators';
import { DeleteActions } from '../../../../../redux/actionTypes/deleteDialogTypes';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton';
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable';
import { decryptText, onExporting, toConvertedDate } from '../../../../../utils/util';

type Props = {
    data: any,
    refetch: () => void,
    jobEventsActive: boolean
}

const ServiceTable: React.FC<Props> = ({ data, refetch, jobEventsActive }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);

    const tableRef = useRef<DataGrid>(null)
    const { jobDetail } = useSelector((state: RedusxAppState) => state.job);

    const dispatch = useDispatch<Dispatch<DeleteActions>>();

    const displayDeleteButton = (cellData: any) => {
        return (
            <DeleteButton iconWidth="20" iconHeight="20" deleteFn={() => {
                dispatch(deleteDialogActionCreators.openDeleteDialog(
                    async () => {
                        try {
                            await ApiCalls.deleteJobService(cellData?.data?.id);
                            dispatch(deleteDialogActionCreators.closeDeleteDialog());
                            refetch();
                        }
                        catch (err: any) {

                        }
                    },
                    `Are you sure you want to delete ${cellData?.data?.serviceTypeName + ' (' + cellData?.data?.serviceNote + ')'}?`,
                    'Delete Service?'
                ))
            }} />
        )
    }

    const calculateStartOn = (cellValue: any) => {
        return toConvertedDate(cellValue.startedOn, jobDetail?.hostPropertyCityTimeZone);
    }

    const calculateFinishedOn = (cellValue: any) => {
        return toConvertedDate(cellValue.finishedOn, jobDetail?.hostPropertyCityTimeZone);
    }


    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledStart, jobDetail?.hostPropertyCityTimeZone);
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledFinish, jobDetail?.hostPropertyCityTimeZone);
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Services" />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>

            <DataGrid
                id={"services" + jobId}
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                ref={tableRef}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                className='mt-6'
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Job Services")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Services: {0}" column='serviceTypeName' summaryType='count' />
                </Summary>

                <Column dataField="serviceTypeName" caption='Type' minWidth={200} cssClass='cls' />
                <Column dataField='serviceTypeDescription' caption='Description' minWidth={150} cssClass='cls' />
                <Column dataField="jobServiceStatusTypeName" caption='Status' minWidth={250} cssClass='cls' />

            </DataGrid>

        </div>
    )
}

export default ServiceTable;