import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { PageTitle } from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import ViolationTable from './ViolationTable'
import { AxiosResponse } from 'axios';
import { GetViolations } from '../../network/NetworkResponses/NetworkResponses'
import ViolationDetail from './ViolationDetail'
import EmptyTable from '../../umut-components/Icons/EmptyTable'

const Violation = () => {
  const {
    user: {
      userSection: { vendorId },
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const { data, isFetching, error } = useQuery<AxiosResponse<GetViolations>>(
    ['Get Violation'],
    () => ApiCalls.getViolation(vendorId),
    { cacheTime: 50000, refetchOnWindowFocus: false }
  )

  return (
    <>
      <PageTitle>Violations</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isFetching ? <SkeletonTableLoading /> : <ViolationTable data={data?.data?.data} />}
          </Card.Body>
        </Card>
      </div>
      <ViolationDetail />
    </>
  )
}

export default Violation
