import {Dispatch, FC, useEffect, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  ColumnHeaderFilter,
  MasterDetail,
} from 'devextreme-react/data-grid'
import {
  convertUTCDateToLocalDate,
  decryptText,
  onExporting,
  toConvertedTimeString,
  toRequestTimeFormat,
} from '../../../../utils/util'
import StaffAccordionTab from './StaffAccordionTab'
import DateTimePicker from '../../../../umut-components/Inputs/DateTimePicker'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import dayjs from 'dayjs'
import {Spinner} from 'react-bootstrap-v5'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import {useParams} from 'react-router-dom'
import {IReVendorStaffAssign} from '../../../../network/PostRequestModels/ReAssignRequests'
import React from 'react'
import ConfirmationDialog from './ConfirmationDialog'
import {useDialog} from '../../../../contexts/DialogContext'
import notify from 'devextreme/ui/notify'
import {JobAction} from '../../../../redux/actionTypes/jobTypes'
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators'
import {AssignStaffJobRequest} from '../../../../network/PostRequestModels/JobRequests'

type Props = {
  data: any
  checkOut?: any
}
const AssignStaffTable: FC<Props> = ({data, checkOut}) => {
  if (checkOut == undefined) {
    checkOut = '10:00'
  }
  let {jobId}: any = useParams()

  const {jobDetail}: any = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()

  jobId = jobId ?? jobDetail?.id
  const {scheduleStartTime}: any = useSelector((state: RedusxAppState) => state.assign)

  const ref = useRef<{getAssingObject: () => IReVendorStaffAssign}>()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [showTable, setShowTable] = useState<boolean>(false)
  const {showSuccessDialog} = useDialog()

  const [loading, setLoading] = useState(false)
  const [scheduleStart, setScheduleStart] = useState<string>(
    toRequestTimeFormat(jobDetail?.hostProperty!.city.timezone, jobDetail?.cleaningPeriodStart)
  )

  const [errorMessage, setErrorMessage] = useState()
  const queryClient = useQueryClient()
  const {mutateAsync: scheduledJob} = useMutation(ApiCalls.scheduleJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })
  const {mutateAsync: reScheduledJob} = useMutation(ApiCalls.putReSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })

  const checkScheduledEndTime = () => {
    let day1 = dayjs(scheduleStart)
    let day2 = dayjs(jobDetail?.cleaningPeriodEnd)
    if (day2.diff(day1) <= 0) {
      setConfirmationModal(true)
    } else {
      sendRequest()
    }
  }

  useEffect(() => {
    if (checkOut) {
      setScheduleStart(dayjs().format('YYYY-MM-DDT' + checkOut))
    }
  }, [checkOut])

  const sendRequest = async () => {
    try {
      if (ref.current) {
        const reAssignObject: AssignStaffJobRequest = {
          ...ref.current.getAssingObject(),
          scheduledStart: scheduleStart,
        }
        setLoading(true)
        {
          jobDetail.jobStatusType.name == 'Pending' && (await scheduledJob(reAssignObject))
        }
        {
          jobDetail.jobStatusType.name == 'Scheduled' && (await reScheduledJob(reAssignObject))
        }
        dispatch(jobActionCreator.closeJobDialog())
        showSuccessDialog('Assignment Successful.')
        setLoading(false)
      }
    } catch (error: any) {
      notify(
        {
          message: error?.response?.data?.message,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        },
        'warning',
        5000
      )
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowTable(true)
    }, 2000)
  }, [])

  return (
    <>
      {showTable && data.length > 0 && (
        <>
          <VendorTable data={data} ref={ref} />

          <div className='d-flex justify-content-between align-items-center'>
            <div className='me-3 w-100 d-flex justify-content-end'>
              <div>
                <strong className='text-muted'>Schedule start time</strong>
                <DateTimePicker
                  className='h-40px'
                  dateOutOfRangeMessage='Time cannot be earlier than job start time.'
                  onChange={(date: any) => {
                    setScheduleStart(date)
                  }}
                  defaultValue={toConvertedTimeString(
                    dayjs()
                      .subtract(30, 'minutes')
                      .format('YYYY-MM-DDT' + checkOut)
                      .toString(),
                    jobDetail?.hostProperty!.city.timezone
                  )}
                />
                <small className='text-muted'>
                  Job starts at{' '}
                  {convertUTCDateToLocalDate(
                    jobDetail?.cleaningPeriodStart,
                    jobDetail?.hostProperty!.city.timezone
                  )}
                </small>
              </div>
              <div className='my-auto mx-3'>
                <button
                  disabled={loading}
                  type='button'
                  onClick={() => {
                    checkScheduledEndTime()
                  }}
                  className='btn btn-success'
                >
                  ✔ Save Assignment {loading && <Spinner animation='border' size='sm' />}
                </button>
              </div>
            </div>
          </div>

          <ConfirmationDialog
            modalOpen={confirmationModal}
            modalClose={() => setConfirmationModal(false)}
            sendRequest={sendRequest}
          />
        </>
      )}
    </>
  )
}

export default AssignStaffTable

type VendorTableProps = {
  data: any
}
const VendorTable = React.memo(
  React.forwardRef(({data}: VendorTableProps, ref) => {
    return (
      <DataGrid
        id='vendorsTable'
        keyExpr='vendorId'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(64vh - 60px)'}
        selection={{mode: 'single'}}
        onExporting={(e) => onExporting(e, 'Suitable Vendors')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Suitable Vendors: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='vendorName'
          caption='Company Name'
          dataType='string'
          width={200}
          cssClass='cls'
        />
        <Column
          dataField='duration'
          caption='Duration'
          dataType='number'
          minWidth={250}
          cssClass='cls'
        />
        <Column dataField='price' caption='Price' dataType='number' minWidth={150} cssClass='cls' />
        <Column dataField='' dataType='number' minWidth={150} cssClass='cls' />
        <MasterDetail
          autoExpandAll={true}
          enabled={true}
          render={(data: any) => <StaffAccordionTab ref={ref} data={data} />}
        />
      </DataGrid>
    )
  })
)
