import {all, fork} from 'redux-saga/effects'
import addressSaga from './addressSaga'
import userSaga from './userSaga'
import hostPropertySaga from './hostPropertySaga'
import checklistSaga from './checklistSaga'
import masterChecklistSaga from './masterChecklistSaga'
export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(addressSaga),
    fork(hostPropertySaga),
    fork(checklistSaga),
    fork(masterChecklistSaga),
  ])
}
