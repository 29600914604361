import React, {Dispatch} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IJobService} from '../../../../../interfaces/Staff'
import * as jobActionCreator from '../../../../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../../../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import ProfilePhotoTemp from '../../../../../umut-components/Icons/ProfilePhotoTemp'
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton'

type Props = {
  jobService: IJobService
  index: number
}

type StaffProps = {
  jobService: IJobService
}

const StaffSection: React.FC<StaffProps> = ({jobService}) => {
  return (
    <div className='d-flex flex-row align-items-center mt-1 me-5'>
      <ProfilePhotoTemp
        profilePhotoPath={jobService.profilePhotoPath}
        firstName={jobService.firstName ?? ''}
        lastName={jobService.lastName ?? ''}
        width={25}
        height={25}
        borderRadius={'50%'}
      />
      <strong className='text-muted ms-3'>{jobService.firstName}</strong>
    </div>
  )
}

const ServiceItem: React.FC<Props> = ({jobService, index}) => {
  const {
    jobDialog: {jobForm},
  } = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()

  const handleDeleteService = () => {
    dispatch(
      jobActionCreator.setJobForm({
        ...jobForm,
        jobServices: jobForm?.jobServices?.filter(
          (service: any, serviceIndex: number) => serviceIndex !== index
        ),
      })
    )
  }

  return (
    <div className='d-flex flex-column mb-6 p-3 pb-4 border-bottom'>
      <div className='d-flex flex-row justify-content-between'>
        <strong>{jobService.serviceTypeName}</strong>
        <span className='badge badge-success'>${jobService.servicePrice}</span>
      </div>
      <strong className='text-muted'>
        {jobService.hostPropertyChecklistName ?? 'Default Checklist'}
      </strong>
      <small className='text-muted'>{jobService.serviceNote}</small>
      <div className='d-flex flex-row justify-content-between align-items-center'>
        {jobService.firstName ? (
          <div className='d-flex flex-wrap flex-row mt-3'>
            <StaffSection jobService={jobService} />
          </div>
        ) : (
          <div>
            <strong className='text-muted'>{jobService.vendorName}</strong>
          </div>
        )}
        <DeleteButton
          iconWidth='20'
          iconHeight='20'
          deleteFn={() => {
            handleDeleteService()
          }}
        />
      </div>
    </div>
  )
}

export default ServiceItem
