import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Dispatch } from 'redux'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import ApiCalls from '../../../../network/ApiCalls'
import { openChangePassword } from '../../../../redux/actionCreators/changePasswordActionCreators'
import { ChangePasswordActions } from '../../../../redux/actionTypes/changePasswordTypes'
import { RedusxAppState } from '../../../../redux/reducers/rootReducer'
import ChangePasswordButton from '../../../../umut-components/Buttons/ChangePasswordButton'
import UpdateButton from '../../../../umut-components/Buttons/UpdateButton'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import SkeletonCircle from '../../../../umut-components/Loading/SkeletonCircleLoading'
import ChangePasswordModal from '../../../../umut-components/Modals/ChangePasswordModal'
import { decryptText, toAmazonUrl } from '../../../../utils/util'

type Props = {
  modalOpen: (arg0: boolean) => void
}

const ProfileTab: React.FC<Props> = ({ modalOpen }) => {
  let { staffId }: any = useParams()
  staffId = decryptText(staffId)

  const {
    data: userDetailData,
    isLoading: userDetailLoading,
    error: isUserDetailError,
  } = useQuery(['Get Staff Details', staffId], () => ApiCalls.getUserDetailsById(staffId), {
    refetchOnWindowFocus: false,
    enabled: staffId ? true : false,
  })
  const changePasswordDispatch = useDispatch<Dispatch<ChangePasswordActions>>()



  return (
    <div className='d-flex p-6 flex-nowrap justify-content-between'>
      {userDetailLoading ? (
        <SkeletonCircle />
      ) : (
        <>

          <div className="d-flex">
            <div className='d-flex me-6'>
              <ProfilePhotoTemp width={150} height={150} fontSize={40} borderRadius='50%' profilePhotoPath={userDetailData?.data?.profilePhotoPath} firstName={userDetailData?.data?.firstName} lastName={userDetailData?.data?.lastName} />

            </div>
            <div className='row mt-6'>
              <div className='col-lg-12 h-auto'>
                <span className={`badge badge-${userDetailData?.data?.accountStatusName ? "success" : "danger"} mb-1`}>{userDetailData?.data?.accountStatusName}</span>
              </div>
              <div className='col-lg-2 h-auto'>
                <strong>Fullname:</strong>
              </div>

              <div className='col-lg-10 h-auto'>
                <strong className='text-muted'>
                  {userDetailData?.data?.firstName + ' ' + userDetailData?.data?.lastName}
                </strong>
              </div>
              <div className='col-lg-2 h-auto'>
                <strong>Email: &nbsp;</strong>
              </div>
              <div className='col-lg-10 h-auto'>
                <strong className='text-muted'>{userDetailData?.data?.email}</strong>
              </div>
              <div className='col-lg-2 h-auto'>
                <strong>Phone: &nbsp;</strong>
              </div>
              <div className='col-lg-10 h-auto'>
                <strong className='text-muted'>{userDetailData?.data?.phoneNumber}</strong>
              </div>

            </div>
          </div>
          <div className="d-flex justify-content-end h-40px gap-5">
            <UpdateButton classNames='btn-sm' iconWidth="15" iconHeight="15" updateFn={() => { modalOpen(true) }} />
            <ChangePasswordButton
              classNames='btn-sm'
              iconWidth='15'
              iconHeight='20' changePassFn={() => {
                changePasswordDispatch(
                  openChangePassword(
                    async (data: any) => {
                      await ApiCalls.changePassword(Number(staffId), data)
                    },
                    'You can change staff password below',
                    'Edit Password'
                  )
                )
              }} />
          </div>

        </>
      )}
      <ChangePasswordModal />

    </div>
  )
}

export default ProfileTab
