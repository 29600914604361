import * as vendorDetailActionCreators from '../../../redux/actionCreators/vendorDetailActionCreators'
import { Dispatch, FC } from 'react'
import { Button, Modal } from 'react-bootstrap-v5'
import { useDispatch, useSelector } from 'react-redux'
import { VendorDetailActions } from '../../../redux/actionTypes/vendorDetailActionTypes'
import { RedusxAppState } from '../../../redux/reducers/rootReducer'
import VendorReferenceForm from './VendorReferenceForm'

type Props = {
  refetchReferences: () => void
}
const VendorReferenceDialog: FC<Props> = ({ refetchReferences }) => {
  const {
    referenceState: { ModalOpen },
    referenceState: { ModalData },
  } = useSelector((state: RedusxAppState) => state.vendorDetail)
  const showViewDispatch = useDispatch<Dispatch<VendorDetailActions>>()

  const handleCloseModal = () => {
    showViewDispatch(vendorDetailActionCreators.closeReferenceView())
  }

  return (
    <div>
      <Modal show={ModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reference</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <VendorReferenceForm refetchReferences={refetchReferences} selectedVendor={ModalData} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default VendorReferenceDialog
