/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Dispatch, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import {UserAction} from '../../../redux/actionTypes/userActionTypes'
import * as actionCreator from '../../../redux/actionCreators/userActionCreators'
import {LoginRequest} from '../../../network/PostRequestModels'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'

type InputType = {
  email: string
  password: string
}

const schema = Yup.object().shape({
  email: Yup.string().email('Please Enter Valid Email').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 character.')
    .required('Password is required'),
})

const defaultValues: InputType = {
  email: '',
  password: '',
}

export function Login() {
  const {errorText, isAuthenticated, loading} = useSelector((state: RedusxAppState) => state.user)
  const dispatch = useDispatch<Dispatch<UserAction>>()

  const logoutFunc = () => {
    dispatch(actionCreator.logout())
  }

  const {control, formState, handleSubmit, reset, register} = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {isValid, dirtyFields, errors} = formState

  const onLogin = (data: InputType) => {
    const loginRequest: LoginRequest = {
      email: data.email,
      password: data.password,
    }
    dispatch(actionCreator.login(loginRequest, logoutFunc))
  }

  return (
    <form
      className='form w-100'
      onSubmit={handleSubmit(onLogin)}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In as Turnify Vendor</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          New Here? <Link to='/auth/register'>Sign up as a Vendor</Link>
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
        <input
          className='form-control form-control-lg form-control-solid'
          type='email'
          autoComplete='off'
          {...register('email')}
          required
        />
        <p style={{color: 'red', fontWeight: 'bold'}}>{errors.email?.message}</p>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          {...register('password')}
          type='password'
          autoComplete='off'
          className='form-control form-control-lg form-control-solid'
          required
        />
        <p style={{color: 'red', fontWeight: 'bold'}}>{errors.password?.message}</p>
      </div>
      {/* end::Form group */}

      {/* begin:: ErrorText */}
      <strong style={{color: 'red'}}>{errorText}</strong>
      {/* end:: ErrorText */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!isValid || loading}
        >
          {!loading && <span className='indicator-label'>Sign in as a Vendor</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
