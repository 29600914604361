import React, {Dispatch, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {UserAction} from '../../redux/actionTypes/userActionTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import * as actionCreators from '../../redux/actionCreators/userActionCreators'
import TurnifyLoading from '../../umut-components/Loading/TurnifyLoading'

type Props = {}

const AuthRemind: React.FC<Props> = ({children}) => {
  const {isAuthenticated, loading, token, user} = useSelector((state: RedusxAppState) => state.user)
  const {rehydrated} = useSelector((state: RedusxAppState) => state._persist)
  const dispatch = useDispatch<Dispatch<UserAction>>()
  const logout = () => {
    dispatch(actionCreators.logout())
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actionCreators.getUserDetails(token, logout))
    }
  }, [rehydrated])

  return <>{isAuthenticated && loading ? <TurnifyLoading /> : children}</>
}

export default AuthRemind
