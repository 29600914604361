import dayjs from 'dayjs'
import React, { Dispatch, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { IHost } from '../../../../interfaces/Host'
import ApiCalls from '../../../../network/ApiCalls'
import { openHostEditDialog } from '../../../../redux/actionCreators/hostActionCreators'
import { HostAction } from '../../../../redux/actionTypes/hostTypes'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import SkeletonCircleLoading from '../../../../umut-components/Loading/SkeletonCircleLoading'
import { decryptText } from '../../../../utils/util'

const ProfileTab: React.FC = () => {
  const dispatch = useDispatch<Dispatch<HostAction>>()
  let {hostId}: any = useParams()
  hostId = decryptText(hostId)
  const [hostDetail, setHostDetail] = useState<IHost | null>(null)
  const {data, isLoading, error, refetch} = useQuery(
    ['Get Host Details by Id', hostId],
    () => ApiCalls.getHostById(hostId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: hostId ? true : false}
  )

  useEffect(() => {
    if (data) {
      setHostDetail(data.data)
    }
  }, [data])

  const handleClickEdit = () => {
    if (hostDetail) {
      dispatch(openHostEditDialog(refetch, hostDetail))
    }
  }

  if (isLoading) {
    return <SkeletonCircleLoading />
  }

  return (
    <div className='d-flex w-100 p-6 ps-0 flex-wrap' style={{position: 'relative'}}>
      <div className='d-flex flex-column me-6'>
        {hostDetail && (
          <ProfilePhotoTemp
            width={75}
            height={75}
            borderRadius={'50%'}
            fontSize={25}
            firstName={hostDetail?.name.split(' ')[0] ?? ''}
            lastName={hostDetail?.name.split(' ')[1] ?? ''}
          />
        )}
      </div>
      <div className='row d-flex align-items-center'>
        <div className='d-flex flex-column me-6'>
          <div className='col-lg-12 h-auto'>
            <strong>{hostDetail?.name}</strong>
            <span
              className={`badge badge-${
                hostDetail?.hostStatusTypeName === 'Active' ? 'success' : 'warning'
              } badge-sm ms-2`}
            >
              {hostDetail?.hostStatusTypeName}
            </span>
          </div>
          <div className='col-lg-12 h-auto'>
            <strong className='text-muted fs-7'>
              Phone: {hostDetail?.contactUser.phoneNumber}
            </strong>
          </div>
          <div className='col-lg-12 h-auto'>
            <strong className='text-muted'>E-mail: {hostDetail?.contactUser.email}</strong>
          </div>
        </div>
        <div className='col-lg-12 h-auto'>
          <strong className='text-muted fs-7'>
            Last Login Date: {dayjs(hostDetail?.contactUser?.lastLoginOn).toString()}
          </strong>
        </div>
        <div className='col-lg-12 h-auto'>
          <strong className='text-muted fs-7'>
            Registration Date: {dayjs(hostDetail?.createdOn).toString()}
          </strong>
        </div>
        <div className='col-lg-12 h-auto'>
          <strong className='text-muted fs-7'>
            Scheduled Jobs: {hostDetail?.scheduledJobCount}
          </strong>
        </div>
      </div>

      <button
        type='button'
        className='btn btn-primary btn-sm'
        onClick={handleClickEdit}
        style={{position: 'absolute', top: 20, right: 0}}
      >
        Edit
      </button>
    </div>
  )
}

export default ProfileTab
