import React, { Dispatch, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import * as changePasswordActionCreators from '../../redux/actionCreators/changePasswordActionCreators';
import { useHistory } from 'react-router-dom';
import { TextInput } from '../../umut-components/Inputs/TextInput';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { ChangePasswordActions } from '../../redux/actionTypes/changePasswordTypes';

const ProfileChangePassword: React.FC = () => {
    const { title, bodyText, modalOpen, changePassFn } = useSelector((state: RedusxAppState) => state.changePassword);
    const dispatch = useDispatch<Dispatch<ChangePasswordActions>>();
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [password, setPassword] = useState({ oldPassword: "", newPassword: "", passwordConfirm: "" })
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [matchError, setMatchError] = useState<string | null>(null)

    const handleClose = () => {
        dispatch(changePasswordActionCreators.closeChangePassword());
        setErrorMessage('')
        setPassword({...password,oldPassword:""})
    }


    const handleChangePass = async () => {
        let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
        if (password.newPassword !== password.passwordConfirm) {
            setMatchError("* passwords do not match")
        } else
            try {
                setMatchError("")
                setLoading(true);
                setErrorMessage(null);
                changePassFn && await changePassFn({ oldPassword: password.oldPassword, newPassword: password.newPassword });
                setLoading(false);
                handleClose()

            }
            catch (err: any) {
                setLoading(false);
                setErrorMessage(err?.response?.data?.errors?.[0]?.message ?? err?.response?.data?.message);
            }
    }

    return (
        <Modal show={modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>{title}</Modal.Title>
            </ModalHeader>
            <Modal.Body>{bodyText}
                <form className='form w-100 my-10'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-12'>

                            <label className='form-label fs-6 fw-bolder text-dark'>Old Password {<span style={{ color: "red" }}>*</span>}</label><TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, oldPassword: text })} />

                            <label className='form-label fs-6 fw-bolder text-dark mt-3'>New Password {<span style={{ color: "red" }}>*</span>}</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, newPassword: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                            <label className='form-label fs-6 fw-bolder text-dark mt-6'>New Password (confirm) {<span style={{ color: "red" }}>*</span>}</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, passwordConfirm: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex flex-column">
                    <div className="d-flex flex">
                        <Button className="d-flex mx-5" variant="link" onClick={() => { handleClose(); setMatchError("") }}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleChangePass} disabled={loading || password.oldPassword == ""}>
                            Change {loading ? <Spinner animation='border' size='sm' /> : null}
                        </Button>
                    </div>
                    <strong className='text-danger text-end'>{errorMessage}</strong>
                </div>

            </Modal.Footer>
        </Modal>
    )
}

export default ProfileChangePassword;