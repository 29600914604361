import {AxiosResponse} from 'axios'
import {useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {ICity} from '../../../../../interfaces/Cities'
import ApiCalls from '../../../../../network/ApiCalls'
import {IListResponse} from '../../../../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'
import {decryptText} from '../../../../../utils/util'
import StaffReduceTable from './StaffReduceCities'
import {StaffServicesTable} from './StaffServicesTable'

const StaffService = () => {
  let {staffId}: any = useParams()
  staffId = Number(decryptText(staffId))
  const [outOfServices, setOutOfServices] = useState<ICity[]>([])

  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)

  const {
    isLoading: serviceLoading,
    refetch,
    data: servicesData,
  } = useQuery<AxiosResponse<IListResponse<ICity>>>(
    ['Vendor Cities'],
    () => ApiCalls.getVendorCityService(vendorId),
    {refetchOnWindowFocus: false}
  )
  const {
    data: staffCityService,
    isLoading,
    error,
    refetch: staffRefetch,
  } = useQuery<AxiosResponse<IListResponse<ICity>>>(['Get Staff City Service', staffId], () =>
    ApiCalls.getStaffCityService(Number(staffId), vendorId)
  )

  useEffect(() => {
    if (servicesData && staffCityService) {
      setOutOfServices(
        servicesData?.data.data.filter(
          (city: any) => !staffCityService?.data?.data.some((i: any) => i.cityId == city.cityId)
        )
      )
    }
  }, [servicesData, , staffCityService])

  if (isLoading) {
    return <Spinner animation='border' />
  }
  return (
    <div>
      <div className='row gap-10 mt-10 justify-content-center w-full'>
        <div style={{borderRadius: 7}} className='col-5 p-5 border'>
          {staffCityService && servicesData && (
            <StaffServicesTable
              data={staffCityService.data.data}
              vendorId={vendorId}
              userId={staffId}
              staffRefetch={staffRefetch}
            />
          )}
        </div>
        <div style={{borderRadius: 7}} className='col-5 p-5 border'>
          {staffCityService && servicesData && outOfServices && (
            <StaffReduceTable
              data={outOfServices}
              userId={staffId}
              vendorId={vendorId}
              staffRefetch={staffRefetch}
            />
          )}
        </div>
      </div>
      <DeleteDialog />
    </div>
  )
}

export default StaffService
