import {IAddress} from '../../interfaces/Address'
import {
  IChecklistItem,
  IHostPropertyCheckList,
  IPhoto,
  IQuestion,
  ISection,
} from '../../interfaces/Checklist'

export const GET_USER_ADDRESSES = 'GET_USER_ADDRESSES'
export interface GetUserAddresses {
  type: typeof GET_USER_ADDRESSES
  userId: number
}

export const GET_USER_ADDRESSES_SUCCESS = 'GET_USER_ADDRESSES_SUCCESS'
export interface GetUserAddressesSuccess {
  type: typeof GET_USER_ADDRESSES_SUCCESS
  addressList: IAddress[]
}

export const GET_USER_ADDRESSES_FAILURE = 'GET_USER_ADDRESSES_FAILURE'
export interface GetUserAddressesFailure {
  type: typeof GET_USER_ADDRESSES_FAILURE
  errorText: string
}

export const OPEN_ADDRESS_DIALOG = 'OPEN_ADDRESS_DIALOG'
export interface OpenAddressDialog {
  type: typeof OPEN_ADDRESS_DIALOG
  address: IAddress | null
}

export const CLOSE_ADDRESS_DIALOG = 'CLOSE_ADDRESS_DIALOG'
export interface CloseAddressDialog {
  type: typeof CLOSE_ADDRESS_DIALOG
}

export type AddressAction =
  | GetUserAddresses
  | GetUserAddressesSuccess
  | GetUserAddressesFailure
  | OpenAddressDialog
  | CloseAddressDialog
