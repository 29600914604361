import React, {Dispatch} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {IVendorServiceCard} from '../../interfaces/Vendors'
import ApiCalls from '../../network/ApiCalls'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../redux/actionCreators/deleteDialogActionCreators'
import {OpenVendorEditOfferDialog} from '../../redux/actionCreators/vendorActionCreators'
import {DeleteActions} from '../../redux/actionTypes/deleteDialogTypes'
import {VendorAction} from '../../redux/actionTypes/vendorTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'

type Props = {
  vendorService: IVendorServiceCard
  refetch: () => void
}

const VendorServiceCard: React.FC<Props> = ({vendorService, refetch}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()

  const executeMutation = async () => {
    deleteVendorServiceMutate()
      .then((res) => {
        deleteDispatch(closeDeleteDialog())
        return res
      })
      .catch((err) => err)
  }

  const deleteVendorService = () => {
    deleteDispatch(
      openDeleteDialog(
        executeMutation,
        `Are you sure you want to delete service?`,
        `Delete Vendor Service?`
      )
    )
  }

  const queryClient = useQueryClient()
  const {mutateAsync: deleteVendorServiceMutate} = useMutation(
    () => ApiCalls.deleteVendorService(vendorService.id),
    {
      onSuccess: () => {
        refetch()
        queryClient.invalidateQueries(['Vendor Offered Services', user.userSection.vendorId])
      },
    }
  )

  return (
    <div
      className='w-100 w-sm-250px border rounded p-6 pb-0 d-flex flex-column justify-content-between bg-white mt-3 me-3 align-items-center position-relative shadow-sm'
      style={{height: 180}}
    >
      <div className='d-flex flex-column align-items-center'>
        <h2 style={{fontWeight: 400}}>{vendorService.serviceType.name}</h2>
        <small className='text-muted text-center' style={{fontWeight: 400}}>
          {vendorService.serviceType.description}
        </small>
      </div>
      <div className='d-flex flex-column w-100'>
        <div className='d-flex justify-content-end align-items-center mt-6 pt-2 border-top'>
          <button
            type='button'
            className='btn btn-link btn-sm text-danger'
            onClick={deleteVendorService}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default VendorServiceCard
