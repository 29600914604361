import React, { Dispatch } from 'react';
import { useState } from 'react';
import { IHostPropertyBedroom, IHostPropertyInput } from '../../../interfaces/Property';
import { Card, Spinner } from 'react-bootstrap-v5';
import { useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import ArrowButton from '../../Buttons/ArrowButton';

type Props = {
    numberOfBathrooms?: number,
    numberOfBedrooms?: number,
    numberOfBeds?: number,
    hostPropertyBedrooms?: IHostPropertyBedroom[],
    pmsReferenceId?: string,
    handleChangeHostProperty: (changedValues: IHostPropertyInput) => void,
    handlePostProperty?: () => void,
    currentStep?: number,
    totalSteps?: number,
    nextStep?: () => void,
    previousStep?: () => void,
    hostProperty?: IHostPropertyInput,
    editMode?: boolean,
    loading?: boolean,
    disabled?: boolean
}

type CardProps = {
    index: number,
    hostPropertyBedroom: IHostPropertyBedroom,
    changeHostPropertyBedroom: (index: number, hostPropertyBedroom: IHostPropertyBedroom) => void,
    disabled?: boolean
}

const BedroomCard: React.FC<CardProps> = ({ index, hostPropertyBedroom, changeHostPropertyBedroom, disabled }) => {
    const [numberOfBeds, setNumberOfBeds] = useState(hostPropertyBedroom.numberOfBeds ? hostPropertyBedroom.numberOfBeds : 1);

    return (
        <Card className="col-lg-2 m-3 shadow">
            <strong>Bedroom #{index + 1}</strong>
            <div className="d-flex flex-row justify-content-start align-items-center flex-wrap">
                <img alt="bed" src={toAbsoluteUrl("/media/icons/duotone/Home/Bed.svg")} className="me-3" />
                <small className={disabled ? 'w-100' : ''}>Number of Beds</small>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    {!disabled && <button type="button" className="btn btn-outline-danger" onClick={() => {
                        const newValue: IHostPropertyBedroom = { numberOfBeds: numberOfBeds > 0 ? numberOfBeds - 1 : 0 }
                        setNumberOfBeds(numberOfBeds > 0 ? numberOfBeds - 1 : 0);
                        changeHostPropertyBedroom(index, newValue);
                    }
                    }>-</button>}
                    <strong>{numberOfBeds}</strong>
                    {!disabled && <button type="button" className="btn btn-outline-success" onClick={() => {
                        const newValue: IHostPropertyBedroom = { numberOfBeds: numberOfBeds + 1 }
                        setNumberOfBeds(numberOfBeds + 1);
                        changeHostPropertyBedroom(index, newValue);
                    }
                    }>+</button>}
                </div>
            </div>
        </Card>
    )
}

const RoomBedStep: React.FC<Props> = ({ disabled, numberOfBathrooms, numberOfBedrooms, hostPropertyBedrooms, numberOfBeds, handleChangeHostProperty, nextStep, previousStep, editMode, handlePostProperty, loading }) => {
    const [selectedNumberOfBathRooms, setSelectedNumberOfBathRooms] = useState<number>(numberOfBathrooms ? numberOfBathrooms : 1);
    const [selectedNumberOfBedRooms, setSelectedNumberOfBedRooms] = useState<number>(numberOfBedrooms ? numberOfBedrooms : 0);
    const [selectedNumberOfBeds, setSelectedNumberOfBeds] = useState<number>(numberOfBeds ? numberOfBeds : 0);
    const [selectedHostPropertyBedrooms, setSelectedHostPropertyBedrooms] = useState<IHostPropertyBedroom[]>(hostPropertyBedrooms && hostPropertyBedrooms.length > 0 ? hostPropertyBedrooms : []);

    const handleBedroomChange = (index: number, hostPropertyBedroom: IHostPropertyBedroom) => {
        setSelectedHostPropertyBedrooms(selectedHostPropertyBedrooms.map((bedroom, currentIndex) => currentIndex === index ? hostPropertyBedroom : bedroom))
    }

    useEffect(() => {
        if (selectedNumberOfBedRooms > 0 && (!hostPropertyBedrooms || hostPropertyBedrooms?.length === 0)) {
            let arr: IHostPropertyBedroom[] = [];
            let obj: IHostPropertyBedroom = { numberOfBeds: 1 }
            for (let i = 0; i < selectedNumberOfBedRooms; i++) {
                arr.push(obj);
            }
            setSelectedHostPropertyBedrooms(arr);
        }
    }, [selectedNumberOfBedRooms])

    useEffect(() => {
        let count = 0;
        selectedHostPropertyBedrooms.forEach(room => {
            count += room?.numberOfBeds ? room?.numberOfBeds : 0;
        })
        handleChangeHostProperty({
            numberOfBedrooms: selectedNumberOfBedRooms,
            numberOfBathrooms: selectedNumberOfBathRooms,
            numberOfBeds: selectedNumberOfBedRooms ? count : selectedNumberOfBeds,
            hostPropertyBedrooms: selectedHostPropertyBedrooms
        })
    }, [selectedNumberOfBathRooms, selectedNumberOfBedRooms, selectedNumberOfBeds, selectedHostPropertyBedrooms])

    return (
        <form
            className='form w-100 mt-5'
        >
            {/* begin::Form group */}
            <div>
                <div className='row'>
                    <div className=''>
                        <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{ width: 200 }}>Number of Bedrooms</label>
                        {<button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => {
                            setSelectedNumberOfBedRooms(selectedNumberOfBedRooms > 0 ? selectedNumberOfBedRooms - 1 : 0)
                            if (selectedHostPropertyBedrooms.length > 1) {
                                setSelectedHostPropertyBedrooms(selectedHostPropertyBedrooms.filter((bedroom, index) => index !== selectedHostPropertyBedrooms.length - 1));
                            }
                            else {
                                setSelectedHostPropertyBedrooms([]);
                            }
                        }
                        }>-</button>}
                        <strong>{selectedNumberOfBedRooms ? selectedNumberOfBedRooms : 'Studio'}</strong>
                        {<button type="button" className="btn btn-outline-success btn-sm ms-2" onClick={() => {
                            let hostPropertyBedroom: IHostPropertyBedroom = { numberOfBeds: 1 };
                            setSelectedNumberOfBedRooms(selectedNumberOfBedRooms + 1);
                            setSelectedHostPropertyBedrooms([...selectedHostPropertyBedrooms, hostPropertyBedroom])
                        }}>+</button>}
                    </div>
                    {
                        selectedNumberOfBedRooms ?
                            null
                            :
                            <div className=''>
                                <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{ width: 200 }}>Number of Beds</label>
                                {<button type="button" className="btn btn-outline-danger btn-sm" onClick={() => setSelectedNumberOfBeds(selectedNumberOfBeds > 0 ? selectedNumberOfBeds - 1 : 0)}>-</button>}
                                <strong>{selectedNumberOfBeds}</strong>
                                {<button type="button" className="btn btn-outline-success btn-sm" onClick={() => setSelectedNumberOfBeds(selectedNumberOfBeds + 1)}>+</button>}
                            </div>
                    }
                    <div className=''>
                        <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{ width: 200 }}>Number of Bathrooms</label>
                        {<button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => {
                            if (selectedNumberOfBathRooms > 1) {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 0.5);
                            } else if (selectedNumberOfBathRooms === 1) {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms - 1)
                            }

                        }}>-</button>}
                        <strong>{selectedNumberOfBathRooms}</strong>
                        {<button type="button" className="btn btn-outline-success btn-sm ms-2" onClick={() => {
                            if (selectedNumberOfBathRooms === 0) {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 1)

                            } else {
                                setSelectedNumberOfBathRooms(selectedNumberOfBathRooms + 0.5)
                            }

                        }}>+</button>}
                    </div>
                </div>
                <div className='row mt-10'>
                    {selectedHostPropertyBedrooms.map((bedroom, index) => <BedroomCard disabled={disabled} index={index} hostPropertyBedroom={bedroom} changeHostPropertyBedroom={handleBedroomChange} />)}
                </div>
                {editMode ? null :
                    <div className="d-flex justify-content-between flex-row w-100 mt-6">
                        <ArrowButton type="back" func={previousStep} />
                        <button type="button" className="btn btn-success" onClick={handlePostProperty} disabled={loading}>Add Property {loading ? <Spinner size="sm" animation="border" /> : null}</button>
                    </div>
                }
            </div>

        </form>
    )
}

export default RoomBedStep;