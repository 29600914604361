import {
    Column,
    DataGrid, Export, FilterRow, HeaderFilter,
    Paging,
    Scrolling, SearchPanel,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid'
import { FC, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import config from '../../config/config.json'
import { JobStatusType } from '../../enums/JobEnum'
import { IJob } from '../../interfaces/Job'
import ViewButton from '../../umut-components/Buttons/ViewButton'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import { encryptText, getDateFilter, getJobDateFilter, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../utils/util'

type Props = {
    data: IJob[] | undefined
}
const VendorsJobsTable: FC<Props> = ({ data }) => {
    const history = useHistory()
    const tableRef = useRef<DataGrid>(null)


    const displayStatusType = (cellData: any) => {
        const jobStatusType = cellData?.data?.jobStatusTypeName
        return (
            <strong
                className={`text-${jobStatusType === JobStatusType.COMPLETED
                    ? 'success'
                    : jobStatusType === JobStatusType.PENDING
                        ? 'warning'
                        : 'danger'
                    }`}
            >
                {jobStatusType ? jobStatusType : 'Unknown'}
            </strong>
        )
    }
    const renderViewButton = (cellValue: any) => {
        return (
            <ViewButton

                classNames='btn-sm invisible'
                iconWidth='15'
                iconHeight='15'
                viewFn={() => { }}
            />
        )
    }
    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
    }

    const calculateCleaningPeriodStart = (cellValue: any) => {
        return toConvertedDate(cellValue.cleaningPeriodStart, cellValue.hostPropertyCityTimeZone)
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
    }

    const calculateFinishedOn = (cellValue: any) => {
        return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
    }
    const calculateStartedOn = (cellValue: any) => {
        return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
    }
    const calculateCleaningPeriodEnd = (cellValue: any) => {
        return toConvertedDate(cellValue.cleaningPeriodEnd, cellValue.hostPropertyCityTimeZone)
    }

    const calculateAddress = (cellValue: any) => {
        //Should be refactored
        return cellValue.hostPropertyAddress
            ?.replace(`${cellValue.hostPropertyCityName}, `, '')
            ?.replace(', USA', '')
    }

    const toJobDetail = (cellValue: any) => {
        if (cellValue?.data?.id) {
            // history.push({
            //     pathname: `/job-details/` + encryptText(cellValue?.data?.id)
            // })
            window.open(`/job-details/` + encryptText(cellValue?.data?.id))
        }
    }

    if (data?.length === 0) {
        return <EmptyTable title='No Jobs' />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>

            <DataGrid
                id='staffTable'
                keyExpr='id'
                ref={tableRef}
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}

                showColumnLines={false}
                showRowLines={true}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                height={'calc(70vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onCellClick={toJobDetail}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, 'Vendor Jobs')}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <FilterRow showOperationChooser={false} visible={true} />
                <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                <Summary>
                    <TotalItem cssClass='absolute-left' displayFormat="Total Jobs: {0}" column='jobs' summaryType='count' />
                </Summary>
                <Paging defaultPageSize={50} />


                <Column
                    dataField='hostPropertyAddress' minWidth={450}
                    calculateCellValue={calculateAddress}
                    caption='Property'
                    dataType='string'
                    cssClass='cls'
                />
                <Column
                    dataField='scheduledStart'
                    caption='Scheduled Start'
                    dataType='date' selectedFilterOperation='between'
                    calculateCellValue={calculateScheduledStart}
                    format={config.date_time_format_dev_extreme}
                    minWidth={200}
                    cssClass='cls'

                >
                    <HeaderFilter dataSource={getJobDateFilter('scheduledStart')} />
                </Column>
                <Column
                    dataField='jobStatusTypeName'
                    caption='Job Status'
                    cellRender={displayStatusType}
                    dataType='string'
                    width={150}
                    cssClass='cls'

                />
                <Column
                    dataField='startedOn'
                    caption='Started at'
                    dataType='date' selectedFilterOperation='between'
                    format={config.date_time_format_dev_extreme}
                    calculateCellValue={calculateStartedOn}
                    minWidth={150}
                    cssClass='cls'

                >
                    <HeaderFilter dataSource={getJobDateFilter('startedOn')} />
                </Column>
                <Column
                    dataField='scheduledFinish'
                    caption='Scheduled Finish'
                    dataType='date' selectedFilterOperation='between'
                    format={config.date_time_format_dev_extreme}
                    calculateCellValue={calculateScheduledFinish}
                    minWidth={150}
                    cssClass='cls'

                >
                    <HeaderFilter dataSource={getJobDateFilter('scheduledFinish')} />
                </Column>
                <Column
                    dataField='finishedOn'
                    caption='Finished at'
                    dataType='date' selectedFilterOperation='between'
                    format={config.date_time_format_dev_extreme}
                    calculateCellValue={calculateFinishedOn}
                    minWidth={150}
                    cssClass='cls'

                >
                    <HeaderFilter dataSource={getJobDateFilter('finishedOn')} />
                </Column>
                <Column dataField='hostPropertyCityName' caption='City' minWidth={150} cssClass='cls' />
                <Column dataField='hostPropertyStateName' caption='State' minWidth={150} cssClass='cls' />

                <Column
                    dataField='hostPropertyCountryName'
                    caption='Country'
                    minWidth={150}
                    cssClass='cls'

                />
                <Column dataType='string' caption="Forced Finish" alignment="center" dataField="isForceFinish" />
                <Column cellRender={renderViewButton} cssClass='cls' width={110} alignment='center' />

            </DataGrid>

        </div>
    )
}

export default VendorsJobsTable
