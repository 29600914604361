import {useEffect, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import ApiCalls from '../../network/ApiCalls'
import {IServiceRateQuoteRequests} from '../../interfaces/Offered'
import OfferedRateList from './OfferedRateList'
import OfferedRateAddDialog from './OfferedRateAddDialog'

const ServiceRateQuoteRequests = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [showAddServiceRate, setShowAddServiceRate] = useState<boolean>(false)
  const [serviceRateQuoteRequests, setServiceRateQuoteRequests] = useState<
    IServiceRateQuoteRequests[] | any
  >([])

  useEffect(() => {
    getRequest()
  }, [])

  const getRequest = () => {
    ApiCalls.getVendorServiceRates(user.userSection.vendorId)
      .then((d) => {
        const rates = d?.data?.data.filter((rt: any) => rt.offeredRate)
        setServiceRateQuoteRequests(rates)
      })
      .catch(() => {
        setServiceRateQuoteRequests([])
      })
  }

  const refetch = () => {
    getRequest()
  }

  return (
    <>
      <PageTitle>Service Rates</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Body>
            <OfferedRateList
              data={serviceRateQuoteRequests}
              title='Services & Rates'
              refetch={refetch}
            />
          </Card.Body>
          {/* <Card.Footer className='d-flex align-items-center justify-content-between'>
            <div />
            <button
              className='btn btn-success'
              style={{height: '100%'}}
              onClick={() => setShowAddServiceRate(true)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add Service Rate
            </button>
          </Card.Footer> */}
        </Card>
        {/* <OfferedRateAddDialog
          visible={showAddServiceRate}
          handleClose={() => {
            setShowAddServiceRate(false)
          }}
          refetch={refetch}
        /> */}
      </div>
    </>
  )
}

export default ServiceRateQuoteRequests
