import { IHostPropertyOffer, IHostPropertyOffered, IPMSProperty } from "../../interfaces/Property";

export const GET_HOST_PROPERTY_LIST = "GET_HOST_PROPERTY_LIST";
export interface GetHostPropertyList {
    type: typeof GET_HOST_PROPERTY_LIST
}

export const SET_HOST_PROPERTY_LIST = "SET_HOST_PROPERTY_LIST";
export interface SetHostPropertyList {
    type: typeof SET_HOST_PROPERTY_LIST,
    hostPropertyList: IPMSProperty[]
}

export const CLEAR_HOST_PROPERTY_LIST = "CLEAR_HOST_PROPERTY_LIST";
export interface ClearHostPropertyList {
    type: typeof CLEAR_HOST_PROPERTY_LIST
}

export const SET_HOST_PROPERTY = "SET_HOST_PROPERTY";
export interface SetHostProperty {
    type: typeof SET_HOST_PROPERTY,
    hostPropertyInput: IPMSProperty
}

export const SET_HOST_PROPERTY_SUCCESS = "SET_HOST_PROPERTY_SUCCESS";
export interface SetHostPropertySuccess {
    type: typeof SET_HOST_PROPERTY_SUCCESS,
    hostPropertyList: IPMSProperty[]
}

export const SET_HOST_PROPERTY_FAILURE = "SET_HOST_PROPERTY_FAILURE";
export interface SetHostPropertyFailure {
    type: typeof SET_HOST_PROPERTY_FAILURE
}

export const POST_HOST_PROPERTY_FORM = "POST_HOST_PROPERTY_FORM";
export interface PostHostPropertyForm {
    type: typeof POST_HOST_PROPERTY_FORM
}

export const POST_HOST_PROPERTY_FORM_SUCCESS = "POST_HOST_PROPERTY_FORM_SUCCESS";
export interface PostHostPropertyFormSuccess {
    type: typeof POST_HOST_PROPERTY_FORM_SUCCESS
}

export const POST_HOST_PROPERTY_FORM_FAILURE = "POST_HOST_PROPERTY_FORM_FAILURE";
export interface PostHostPropertyFormFailure {
    type: typeof POST_HOST_PROPERTY_FORM_FAILURE,
    error: string
}

export const GET_SELECTED_CLEANING_PROVIDER = "GET_SELECTED_CLEANING_PROVIDER";
export interface GetSelectedCleaningProvider {
    type: typeof GET_SELECTED_CLEANING_PROVIDER
}

export const SET_SELECTED_CLEANING_PROVIDER = "SET_SELECTED_CLEANING_PROVIDER";
export interface SetSelectedCleaningProvider {
    type: typeof SET_SELECTED_CLEANING_PROVIDER,
    cleaningProviderId: number
}

export const CLEAR_SELECTED_CLEANING_PROVIDER = "CLEAR_SELECTED_CLEANING_PROVIDER";
export interface ClearSelectedCleaningProvider {
    type: typeof CLEAR_SELECTED_CLEANING_PROVIDER
}

export const OPEN_PROPERTY_OFFERED_DIALOG = "OPEN_PROPERTY_OFFERED_DIALOG";
export interface OpenPropertyOfferedDialog {
    type: typeof OPEN_PROPERTY_OFFERED_DIALOG,
    refetch: () => void,
    offeredService?: IHostPropertyOffer
}

export const CLOSE_PROPERTY_OFFERED_DIALOG = "CLOSE_PROPERTY_OFFERED_DIALOG";
export interface ClosePropertyOfferedDialog {
    type: typeof CLOSE_PROPERTY_OFFERED_DIALOG,
}

export const SET_PROPERTY_APPROVE_ENABLED = "SET_PROPERTY_APPROVE_ENABLED";
export interface SetPropertyApproveEnabled {
    type: typeof SET_PROPERTY_APPROVE_ENABLED,
    approveEnabled: boolean
}
export const OPEN_DELETE_PROPERTY_MODAL = "OPEN_DELETE_PROPERTY_MODAL"
export interface OpenDeletePropertyModal {
    type: typeof OPEN_DELETE_PROPERTY_MODAL
    propertyId: number
}
export const CLOSE_DELETE_PROPERTY_MODAL = "CLOSE_DELETE_PROPERTY_MODAL"
export interface CloseDeletePropertModal {
    type: typeof CLOSE_DELETE_PROPERTY_MODAL
}

export type HostPropertyAction =
    | GetHostPropertyList
    | SetHostPropertyList
    | ClearHostPropertyList
    | SetHostProperty
    | SetHostPropertySuccess
    | SetHostPropertyFailure
    | PostHostPropertyForm
    | PostHostPropertyFormSuccess
    | PostHostPropertyFormFailure
    | GetSelectedCleaningProvider
    | SetSelectedCleaningProvider
    | ClearSelectedCleaningProvider
    | OpenPropertyOfferedDialog
    | ClosePropertyOfferedDialog
    | SetPropertyApproveEnabled
    | OpenDeletePropertyModal
    | CloseDeletePropertModal