import {Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import Scheduler, {Editing, Resource} from 'devextreme-react/scheduler'
import {Dispatch, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import ApiCalls from '../../../../network/ApiCalls'
import {AssignAction} from '../../../../redux/actionTypes/assignTypes'
import {AssignState} from '../../../../redux/reducers/assignReducer'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {convertUTCDateToLocalDate} from '../../../../utils/util'
import * as assignActionCreators from '../../../../redux/actionCreators/assignActionCreators'
import dayjs from 'dayjs'
import React from 'react'
import {Spinner} from 'react-bootstrap-v5'

const resourcesData = [
  {
    text: 'Buffer',
    id: 2,
    color: '#C70039',
  },
  {
    text: 'Job',
    id: 1,
    color: '#0096FF',
  },
]
let func = (e: any) => {}

const SchedulerDialog = () => {
  const {assignDialog}: AssignState = useSelector((state: RedusxAppState) => state.assign)
  const {jobDetail}: any = useSelector((state: RedusxAppState) => state.job)
  const assignDispatch = useDispatch<Dispatch<AssignAction>>()

  const [data, setData] = useState([])
  const currentDate = dayjs(
    convertUTCDateToLocalDate(
      jobDetail?.cleaningPeriodStart,
      jobDetail?.hostProperty!.city.timezone
    )
  )

  const {
    data: staffJobs,
    isLoading,
    error,
  } = useQuery(
    ['Get Daily Detail ByStaffId', assignDialog?.staffDetail?.userId],
    () => ApiCalls.getJobsDailyByStaffId(assignDialog?.staffDetail?.userId),
    {enabled: assignDialog?.staffDetail?.userId ? true : false, cacheTime: 50000}
  )

  const modalClose = () => assignDispatch(assignActionCreators.closeAddAssignDialog())

  useEffect(() => {
    const bufferedJobs: any = []
    if (staffJobs?.data) {
      staffJobs.data?.data.map((jobs: any) => {
        bufferedJobs.push({
          text: jobs.hostPropertyName,
          startDate: dayjs(jobs.scheduledStart).toDate(),
          endDate: dayjs(jobs.scheduledFinish).toDate(),
          ownerId: [1],
        })
        bufferedJobs.push({
          text: '✖ Buffer',
          startDate: dayjs(jobs.scheduledFinish).toDate(),
          endDate: dayjs(jobs.scheduledFinish).add(15, 'm').toDate(),
          ownerId: [2],
        })
      })
    }
    setData(bufferedJobs)
  }, [staffJobs])

  useEffect(() => {
    func = (e: any) => {
      assignDispatch(assignActionCreators.scheduleStartChange(e))
    }
  }, [])

  const renderDateCell = (itemData: any) => {
    return <DateCell itemData={itemData} offDays={assignDialog.staffDetail?.offDays} />
  }

  return (
    <Dialog open={assignDialog?.modalOpen} onClose={modalClose} maxWidth='xl' fullWidth>
      <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
        <h2>Staff Daily Details</h2>
        <button type='button' className='close' onClick={modalClose}>
          <span aria-hidden='true'>×</span>
          <span className='sr-only'>Close</span>
        </button>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Spinner animation='border' />
        ) : (
          <Scheduler
            timeZone={jobDetail?.hostProperty!.city.timezone}
            dataSource={data}
            views={['timelineDay']}
            defaultCurrentView='timelineDay'
            defaultCurrentDate={currentDate}
            showAllDayPanel={false}
            dataCellRender={renderDateCell}
            // onAppointmentFormOpening={onAppointmentFormOpening}
            height={280}
            groups={['']}
            cellDuration={30}
            startDayHour={8}
            endDayHour={24}
          >
            <Resource
              fieldExpr='ownerId'
              allowMultiple={true}
              dataSource={resourcesData}
              label='Owner'
              useColorAsDefault={true}
            />
            <Editing
              allowDragging={false}
              allowResizing={false}
              allowUpdating={false}
              allowDeleting={false}
              allowAdding={false}
            />
          </Scheduler>
        )}
      </DialogContent>
    </Dialog>
  )
}

const DateCell = React.memo((props: any) => {
  const {itemData, offDays} = props
  const today = itemData.startDate.getDay()
  const isDisableDate = offDays?.some((day: number) => today == day)
  return <div className={isDisableDate && 'disable-date'} />
})

export default SchedulerDialog
