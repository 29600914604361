import React, { useEffect } from 'react';
import { useState } from 'react';
import { IHostPropertyInput, ILocation } from '../../../interfaces/Property';
import { getStaticMap } from '../../../utils/util';

type Props = {
    location: ILocation,
    pmsReferenceId?: string,
    handleChangeHostProperty: (changedValues: IHostPropertyInput) => void,
    currentStep?: number,
    totalSteps?: number,
    nextStep?: () => void,
    hostProperty?: IHostPropertyInput,
    editMode?: boolean,
    staticMapUrl?: string
}

const LocationStep: React.FC<Props> = ({ location, staticMapUrl, handleChangeHostProperty, nextStep, editMode }) => {
    const [selectedLocation, setSelectedLocation] = useState<ILocation>(location);

    const onMarkerChange = (changedLocation: ILocation) => {
        setSelectedLocation({ ...changedLocation, suite: selectedLocation.suite });
    }

    const onSuiteChange = (e: any) => {
        setSelectedLocation({ ...selectedLocation, suite: e.target.value })
    }

    useEffect(() => {
        handleChangeHostProperty({
            address: selectedLocation.address,
            city: selectedLocation.city,
            state: selectedLocation.state,
            suite: selectedLocation.suite,
            country: selectedLocation.country,
            latitude: selectedLocation.latitude,
            longitude: selectedLocation.longitude,
            zipCode: selectedLocation.zipCode
        })
    }, [selectedLocation])

    return (
        <form
            className='form w-100 mt-5'
        >
            <div className='row'>
                {/* begin::Form group */}
                <div className='fv-row col-lg-4'>
                    {location.latitude && location.longitude && <img className='w-100 h-100' style={{ objectFit: 'cover' }} alt='map' src={staticMapUrl ? staticMapUrl : getStaticMap(location.latitude, location.longitude)} />}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row col-lg-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Address</label>
                    <input
                        value={selectedLocation.address}
                        className='form-control'
                        type='text'
                        autoComplete='off'
                        disabled
                        required
                    />
                    <label className='form-label fs-6 fw-bolder text-dark mt-3'>Suite/Apt #</label>
                    <input
                        value={selectedLocation.suite}
                        onChange={onSuiteChange}
                        className='form-control'
                        type='text'
                        autoComplete='off'
                        disabled
                        required
                    />
                    <label className='form-label fs-6 fw-bolder text-dark mt-3'>Country</label>
                    <input
                        value={selectedLocation.country}
                        className='form-control'
                        type='text'
                        autoComplete='off'
                        disabled
                        required
                    />
                    <div className='row mt-5'>
                        <div className='fv-row col-lg-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>State</label>
                            <input
                                value={selectedLocation.state}
                                className='form-control'
                                type='text'
                                autoComplete='off'
                                disabled
                                required
                            />
                        </div>
                        <div className='fv-row col-lg-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>City</label>
                            <input
                                value={selectedLocation.city}
                                className='form-control'
                                type='text'
                                autoComplete='off'
                                disabled
                                required
                            />
                        </div>
                    </div>
                    <label className='form-label fs-6 fw-bolder text-dark mt-3'>Zip Code</label>
                    <input
                        value={selectedLocation.zipCode}
                        className='form-control'
                        type='text'
                        autoComplete='off'
                        disabled
                        required
                    />
                </div>
            </div>
        </form>
    )
}

export default LocationStep;