import {IAddress} from '../../interfaces/Address'
import * as actions from '../actionTypes/addressTypes'

export function getUserAddresses(userId: number): actions.GetUserAddresses {
  return {
    type: actions.GET_USER_ADDRESSES,
    userId,
  }
}

export function getUserAddressesSuccess(addressList: IAddress[]): actions.GetUserAddressesSuccess {
  return {
    type: actions.GET_USER_ADDRESSES_SUCCESS,
    addressList,
  }
}

export function getUserAddressesFailure(errorText: string): actions.GetUserAddressesFailure {
  return {
    type: actions.GET_USER_ADDRESSES_FAILURE,
    errorText,
  }
}

export function openAddressDialog(address: IAddress | null): actions.OpenAddressDialog {
  return {
    type: actions.OPEN_ADDRESS_DIALOG,
    address,
  }
}

export function closeAddressDialog(): actions.CloseAddressDialog {
  return {
    type: actions.CLOSE_ADDRESS_DIALOG,
  }
}
