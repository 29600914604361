import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { JobAction } from '../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { encryptText, toAmazonUrl } from '../../../../utils/util';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useHistory } from 'react-router';
import NoPhoto from '../../../../umut-components/Icons/NoPhoto';

type Props = {
}

const PhotosDialog: React.FC<Props> = () => {
    const { checklistPhotoDialog } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const history = useHistory()

    const handleClose = () => {
        dispatch(jobActionCreator.closeChecklistPhotoDialog());
    }

    return (
        <Modal show={checklistPhotoDialog.modalOpen} onHide={handleClose} size="lg" centered>
            <ModalHeader closeButton>
                <Modal.Title>Checklist Photo</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {checklistPhotoDialog.checklistPhoto ?
                    <div className='row'>
                        <div className='col-sm-6 d-flex flex-column p-0 pe-sm-3'>
                            <strong>Sample Photo</strong>
                            <div className='h-100 d-flex align-items-center border'>
                                {
                                    checklistPhotoDialog.checklistPhoto.samplePhotoUrl ?
                                        <img alt='sample' src={toAmazonUrl(checklistPhotoDialog.checklistPhoto.samplePhotoUrl)} className='w-100' />
                                        :
                                        <NoPhoto title='No Sample Photo' classNames='m-6' />
                                }
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex flex-column p-0 ps-sm-3'>
                            <strong>Staff Taken Photo</strong>
                            <div className='h-100 d-flex align-items-center border'>
                                {
                                    checklistPhotoDialog.checklistPhoto.jobUserPhotoUrl ?
                                        <img alt='answer' className='w-100' src={toAmazonUrl(checklistPhotoDialog.checklistPhoto.jobUserPhotoUrl)} />
                                        :
                                        <NoPhoto title='No Answer Photo' classNames='m-6' />
                                }
                            </div>
                        </div>
                        <div className='col-12 d-flex flex-column p-6 mt-6 bg-light-primary'>
                            <div className='d-flex justify-content-start'>
                                <span className='badge badge-info me-3'>{checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionRoomIndex}</span>
                                <span className='badge badge-primary me-3'>{checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionTitle?.en ?? checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionTitle[Object.keys(checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionTitle)[0]]}</span>
                                <span className={`badge badge-${checklistPhotoDialog.checklistPhoto.JobChecklistDetailQuestionStatusTypeName === "Completed" ? "success" : "danger"}`}>{checklistPhotoDialog.checklistPhoto.JobChecklistDetailQuestionStatusTypeName}</span>
                            </div>
                            <span className='mt-6' style={{ fontWeight: 400 }}>{checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionDescription?.en}</span>
                            <span className='text-muted'>{checklistPhotoDialog.checklistPhoto.questionAnswer ? checklistPhotoDialog.checklistPhoto.questionAnswer === "undefined" ? "" : checklistPhotoDialog.checklistPhoto.questionAnswer : 'Not answered yet'}</span>
                            {
                                checklistPhotoDialog.checklistPhoto.hostPropertyChecklistSectionQuestionHasVirtualInspection &&
                                <>
                                    <span className='mt-6' style={{ fontWeight: 400 }}>Is Virtual Inspection Complete</span>
                                    {
                                        checklistPhotoDialog.checklistPhoto.isVirtualInspectionComplete
                                            ?
                                            <img alt='completed' style={{ width: 18, height: 18 }} src={toAbsoluteUrl('/media/icons/success.png')} />
                                            :
                                            <img alt='not-completed' style={{ width: 18, height: 18 }} src={toAbsoluteUrl('/media/icons/danger.png')} />
                                    }
                                </>
                            }
                        </div>
                        

                    </div>
                    :
                    null}
            </Modal.Body>
        </Modal>
    )
}

export default PhotosDialog;