import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Select from 'react-select';
import { selectStyles } from '../../config/selectStyle';
import { useDialog } from '../../contexts/DialogContext';
import { IChecklistItem } from '../../interfaces/Checklist';
import ApiCalls from '../../network/ApiCalls';
import { JobServiceAddRequest } from '../../network/PostRequestModels/JobRequests';
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { decryptText, convertUTCDateToLocalDate } from '../../utils/util';
import DateTimePicker from '../Inputs/DateTimePicker';
import { TextInput } from '../Inputs/TextInput';

type Props = {
    hostPropertyId: number,
    roleId: number,
}

type DateTime = {
    date?: string | null,
    time?: string | null
}

const JobServiceAddForm: React.FC<Props> = ({ hostPropertyId, roleId }) => {
    const { serviceAddDialog, jobDetail } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { showSuccessDialog } = useDialog();
    const serviceRef = useRef<any>(null);
    const staffRef = useRef<any>(null);
    const checklistRef = useRef<any>(null);
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState<string | null>(null);
    const [staffSectionActive, setStaffSectionActive] = useState(false);
    const [checklistErrorText, setChecklistErrorText] = useState<string | null>(null);
    const [services, setServices] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [checklists, setChecklists] = useState([]);
    const [selectedService, setSelectedService] = useState<any>({});
    const [selectedServiceType, setSelectedServiceType] = useState<any>(null);
    const [selectedChecklistId, setSelectedChecklistId] = useState<any>(null);
    const [selectedNote, setSelectedNote] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const { data: serviceData, isLoading: serviceLoading, error: serviceError } = useQuery('Get Services for Job', () => ApiCalls.getOfferedServicesByHostId(user.userSection.hostId), { cacheTime: 500000, refetchOnWindowFocus: false });
    const { data: staffData, isLoading: staffLoading, error: staffError, refetch: refetchStaffs } = useQuery('Get Staffs for Job', () => ApiCalls.getOfferedStaffs(user.userSection.hostId, hostPropertyId, selectedServiceType?.serviceTypeId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: hostPropertyId && selectedServiceType?.serviceTypeId && roleId ? true : false });
    const { data: checklistData, isLoading: checklistLoading, isFetching: checklistFetching, error: checklistError, refetch: refetchChecklists } = useQuery('Get Checklist for Job', () => ApiCalls.getHostPropertyChecklists(hostPropertyId, roleId, selectedServiceType?.serviceTypeId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: hostPropertyId && roleId && selectedServiceType?.serviceTypeId ? true : false });
    const { data: hostPropertyData, isLoading: hostPropertyLoading, error: hostPropertyError } = useQuery(['Get Host Property By Id', hostPropertyId], () => ApiCalls.getHostPropertyById(hostPropertyId), { refetchOnWindowFocus: false });

    const handleAddService = async () => {
        setLoading(true);
        setErrorText(null);
        try {
            const serviceRequest: JobServiceAddRequest = {
                serviceTypeId: selectedServiceType?.serviceTypeId,
                staffOfferedServiceId: selectedService?.staffOfferedServiceId ? selectedService?.staffOfferedServiceId : undefined,
                hostPropertyChecklistId: selectedChecklistId ? selectedChecklistId : undefined,
                serviceNote: selectedNote ? selectedNote : undefined,
                scheduledStart: startDate ? startDate : undefined,
                jobId: parseInt(jobId),
                roleId
            }
            await ApiCalls.addJobService(serviceRequest);
            showSuccessDialog('Service added.');
            setLoading(false);
            serviceAddDialog.refetch();
            dispatch(jobActionCreator.closeServiceAddDialog());
        }
        catch (err: any) {
            setLoading(false);
            if (err?.response?.data?.message === "Host PropertyChecklist was not found in the database!") {
                setErrorText('Cannot found checklist for this service type. Please add checklist.');
            }
            else {
                setErrorText(err?.response?.data?.message);
            }
        }
    }

    const handleSelectStaff = (value: any) => {
        setSelectedService(value?.value);
    }

    const handleNoteChange = (text: any) => {
        setSelectedNote(text);
    }

    const handleChangeService = (value: any) => {
        value?.value && setSelectedServiceType({ serviceTypeId: value.value.serviceTypeId, serviceTypeName: value.value.serviceTypeName });
    }

    const handleChangeChecklist = (value: any) => {
        value?.value && setSelectedChecklistId(parseInt(value.value.id));
    }

    useEffect(() => {
        if (serviceData) {
            setServices(serviceData?.data?.data.map((type: any) => { return { value: type, label: type.serviceTypeName } }));
            setSelectedService({});
        }
    }, [serviceData])

    useEffect(() => {
        if (staffData) {
            setStaffs(staffData?.data?.data.map((staffOfferedService: any) => {
                return {
                    value: staffOfferedService,
                    label: staffOfferedService.firstName + " " + staffOfferedService.lastName
                }
            }))
            staffRef?.current?.select?.clearValue();
        }
    }, [staffData])

    useEffect(() => {
        if (checklistData) {
            setChecklists(checklistData?.data.map((checklist: IChecklistItem) => {
                return {
                    value: checklist,
                    label: checklist.name?.en ? checklist.name?.en : checklist.name[Object.keys(checklist.name)[0]]
                }
            }))
            setSelectedChecklistId(null);
            setChecklistErrorText(checklistData?.data?.length > 0 ? null : 'Cannot found checklist for this service type and role. Please add checklist first.');
            checklistRef.current?.select?.clearValue();
        }
    }, [checklistData])

    useEffect(() => {
        if (selectedServiceType?.serviceTypeId && roleId && hostPropertyId) {
            refetchStaffs();
            setSelectedService(null);
        }
    }, [selectedServiceType, roleId, hostPropertyId])

    useEffect(() => {
        if (roleId && hostPropertyId && selectedServiceType?.serviceTypeId) {
            refetchChecklists();
            setSelectedChecklistId(null);
        }
    }, [roleId, hostPropertyId, selectedServiceType])


    useEffect(() => {
        if (hostPropertyData) {
            setStaffSectionActive(hostPropertyData?.data?.hostProperty?.cleaningProviderId === 1);
        }
    }, [hostPropertyData])


    if (serviceLoading || staffLoading || checklistLoading || hostPropertyLoading) {
        return <div className='d-flex justify-content-between'>Loading...</div>
    }

    return (
        <div className='row d-flex flex-wrap'>
            <div className='row'>
                
                <label className='form-label fs-6 fw-bolder text-dark'>Services</label>
                <div className='col-12'>
                    <Select styles={selectStyles} ref={serviceRef} onChange={handleChangeService} options={services} />
                </div>
                {staffSectionActive ?
                    <>
                        <label className='form-label fs-6 fw-bolder text-dark mt-6'>Staff</label>
                        <div className='col-12'>
                            <Select styles={selectStyles} ref={staffRef} onChange={handleSelectStaff} options={staffs} />
                        </div>
                    </> : null}
                <label className='form-label fs-6 fw-bolder text-dark mt-6'>Checklist (Optional)</label>
                <div className='col-12'>
                    {checklistFetching ? 'Loading...' : <Select styles={selectStyles} ref={checklistRef} onChange={handleChangeChecklist} options={checklists} />}
                </div>
                {checklistErrorText ? <strong className='text-danger w-100 text-break'>{checklistErrorText}</strong> : null}
                <label className='form-label fs-6 fw-bolder text-dark mt-6'>Note (Optional)</label>
                <div className='col-12'>
                    <TextInput onValueChange={handleNoteChange} />
                </div>
                {staffSectionActive ?
                    <>
                        <div className='fv-row col-12 mt-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Start Date</label>
                            <DateTimePicker onChange={setStartDate} min={jobDetail?.scheduledStart} max={jobDetail?.scheduledFinish} />
                            <small className='text-muted'>Job Time: {convertUTCDateToLocalDate(jobDetail?.scheduledStart)} - {convertUTCDateToLocalDate(jobDetail?.scheduledFinish)}</small>
                        </div>
                    </> : null}
            </div>
            <div className='d-flex justify-content-end mt-6'>
                <button type='button' className='btn btn-success' disabled={loading || !selectedServiceType?.serviceTypeId || (staffSectionActive && !selectedService?.staffOfferedServiceId) || checklists?.length === 0 || checklistFetching} onClick={handleAddService}>Save Service {loading ? <Spinner animation='border' size='sm' /> : null}</button>
            </div>
            <div className='d-flex justify-content-end mt-3'>
                <strong className='text-danger'>{errorText}</strong>
            </div>
        </div>
    )
}

export default JobServiceAddForm;