import {DateBox} from 'devextreme-react'
import config from '../../config/config.json'
import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'

type Props = {
  onChange: (data: any) => void
  defaultValue?: string
  min?: string
  max?: string
  dateOutOfRangeMessage?: string
  className?: string
}

const DateTimePicker: React.FC<Props> = ({
  onChange,
  defaultValue,
  min,
  max,
  dateOutOfRangeMessage,
  className,
}) => {
  const [value, setValue] = useState(defaultValue ? defaultValue : undefined)

  const handleOnValueChange = (data: string) => {
    setValue(data)
    onChange(dayjs(data).format('YYYY-MM-DDTHH:mm:ss.sss'))
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <DateBox
      type='datetime'
      value={value}
      openOnFieldClick={true}
      min={min}
      max={max}
      className={className}
      dateOutOfRangeMessage={dateOutOfRangeMessage}
      onValueChange={handleOnValueChange}
    />
  )
}

export default DateTimePicker
