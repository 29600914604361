import { IStaff, IStaffDetail } from '../../interfaces/Staff'

export const GET_STAFF_LIST = 'GET_STAFF_LIST'
export interface GetStaffList {
  type: typeof GET_STAFF_LIST
  hostId: number
}

export const GET_STAFF_LIST_SUCCESS = 'GET_STAFF_LIST_SUCCESS'
export interface GetStaffListSuccess {
  type: typeof GET_STAFF_LIST_SUCCESS
  staffList: IStaff[]
}

export const GET_STAFF_LIST_FAILURE = 'GET_STAFF_LIST_FAILURE'
export interface GetStaffListFailure {
  type: typeof GET_STAFF_LIST_FAILURE
  errorText: string
}

export const GET_STAFF_DETAIL = 'GET_STAFF_DETAIL'
export interface GetStaffDetail {
  type: typeof GET_STAFF_DETAIL
  userId: number
}

export const GET_STAFF_DETAIL_SUCCESS = 'GET_STAFF_DETAIL_SUCCESS'
export interface GetStaffDetailSuccess {
  type: typeof GET_STAFF_DETAIL_SUCCESS
  staff: IStaffDetail
}

export const GET_STAFF_DETAIL_FAILURE = 'GET_STAFF_DETAIL_FAILURE'
export interface GetStaffDetailFailure {
  type: typeof GET_STAFF_DETAIL_FAILURE
  errorText: string
}

export const OPEN_STAFF_ADD_DIALOG = 'OPEN_STAFF_ADD_DIALOG'
export interface OpenStaffAddDialog {
  type: typeof OPEN_STAFF_ADD_DIALOG
  staff: IStaff | null
}

export const CLOSE_STAFF_ADD_DIALOG = 'CLOSE_STAFF_ADD_DIALOG'
export interface CloseStaffAddDialog {
  type: typeof CLOSE_STAFF_ADD_DIALOG
}

export const OPEN_STAFF_STATUS_DIALOG = 'OPEN_STAFF_STATUS_DIALOG'
export interface OpenStaffStatusDialog {
  type: typeof OPEN_STAFF_STATUS_DIALOG,
  refetch: () => void,
  userId: number,
  accountStatusId: number
}

export const CLOSE_STAFF_STATUS_DIALOG = 'CLOSE_STAFF_STATUS_DIALOG'
export interface CloseStaffStatusDialog {
  type: typeof CLOSE_STAFF_STATUS_DIALOG,
}

export type StaffAction =
  | OpenStaffAddDialog
  | CloseStaffAddDialog
  | GetStaffList
  | GetStaffListSuccess
  | GetStaffListFailure
  | GetStaffDetail
  | GetStaffDetailSuccess
  | GetStaffDetailFailure
  | OpenStaffStatusDialog
  | CloseStaffStatusDialog