import { IStaff, IStaffDetail } from '../../interfaces/Staff'
import * as actions from '../actionTypes/staffActionTypes'

export interface StaffState {
  staffList: IStaff[]
  listLoading: boolean
  listErrorText: string

  staffAddDialog: {
    dialogOpen: boolean
    staff: IStaff | null
  }
  staffDetail: {
    staff: IStaffDetail | null
    loading: boolean
    errorText: string
  },
  staffStatusDialog: {
    modalOpen: boolean,
    userId: number | null,
    accountStatusId: number | null,
    refetch: () => void
  }
}

const initialState: StaffState = {
  staffList: [],
  listLoading: false,
  listErrorText: '',

  staffAddDialog: {
    dialogOpen: false,
    staff: null,
  },
  staffDetail: {
    staff: null,
    loading: false,
    errorText: '',
  },
  staffStatusDialog: {
    modalOpen: false,
    userId: null,
    accountStatusId: null,
    refetch: () => { }
  }
}

export default function staffReducer(
  state: StaffState = initialState,
  action: actions.StaffAction
): StaffState {
  switch (action.type) {
    case actions.GET_STAFF_LIST:
      return { ...state, listErrorText: '', listLoading: true }
    case actions.GET_STAFF_LIST_SUCCESS:
      return { ...state, listErrorText: '', listLoading: false, staffList: action.staffList }
    case actions.GET_STAFF_LIST_FAILURE:
      return { ...state, listErrorText: action.errorText, listLoading: false, staffList: [] }
    case actions.GET_STAFF_DETAIL:
      return { ...state, staffDetail: { loading: true, errorText: '', staff: null } }
    case actions.GET_STAFF_DETAIL_SUCCESS:
      return { ...state, staffDetail: { loading: false, errorText: '', staff: action.staff } }
    case actions.GET_STAFF_DETAIL_FAILURE:
      return { ...state, staffDetail: { loading: false, errorText: action.errorText, staff: null } }
    case actions.OPEN_STAFF_ADD_DIALOG:
      return { ...state, staffAddDialog: { dialogOpen: true, staff: action.staff } }
    case actions.CLOSE_STAFF_ADD_DIALOG:
      return { ...state, staffAddDialog: { dialogOpen: false, staff: null } }
    case actions.OPEN_STAFF_STATUS_DIALOG:
      return { ...state, staffStatusDialog: { modalOpen: true, userId: action.userId, accountStatusId: action.accountStatusId, refetch: action.refetch } }
    case actions.CLOSE_STAFF_STATUS_DIALOG:
      return { ...state, staffStatusDialog: { modalOpen: false, userId: null, accountStatusId: null, refetch: () => { } } }
    default:
      return state
  }
}
