import { IStaff, IStaffDetail } from '../../interfaces/Staff'
import * as actions from '../actionTypes/staffActionTypes'

export function getStaffList(hostId: number): actions.GetStaffList {
  return {
    type: actions.GET_STAFF_LIST,
    hostId,
  }
}

export function getStaffListSuccess(staffList: IStaff[]): actions.GetStaffListSuccess {
  return {
    type: actions.GET_STAFF_LIST_SUCCESS,
    staffList,
  }
}

export function getStaffListFailure(errorText: string): actions.GetStaffListFailure {
  return {
    type: actions.GET_STAFF_LIST_FAILURE,
    errorText,
  }
}

export function getStaffDetail(userId: number): actions.GetStaffDetail {
  return {
    type: actions.GET_STAFF_DETAIL,
    userId,
  }
}

export function getStaffDetailSuccess(staff: IStaffDetail): actions.GetStaffDetailSuccess {
  return {
    type: actions.GET_STAFF_DETAIL_SUCCESS,
    staff,
  }
}

export function getStaffDetailFailure(errorText: string): actions.GetStaffDetailFailure {
  return {
    type: actions.GET_STAFF_DETAIL_FAILURE,
    errorText,
  }
}

export function openStaffAddDialog(staff: IStaff | null): actions.OpenStaffAddDialog {
  return {
    type: actions.OPEN_STAFF_ADD_DIALOG,
    staff,
  }
}

export function closeStaffAddDialog(): actions.CloseStaffAddDialog {
  return {
    type: actions.CLOSE_STAFF_ADD_DIALOG,
  }
}

export function openStaffStatusDialog(userId: number, accountStatusId: number, refetch: () => void): actions.OpenStaffStatusDialog {
  return {
    type: actions.OPEN_STAFF_STATUS_DIALOG,
    userId,
    accountStatusId,
    refetch
  }
}

export function closeStaffStatusDialog(): actions.CloseStaffStatusDialog {
  return {
    type: actions.CLOSE_STAFF_STATUS_DIALOG,
  }
}
