import {FC, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {IHostPropertyCheckList} from '../../../../interfaces/Checklist'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Editing,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import ApiCalls from '../../../../network/ApiCalls'

type Props = {
  modalOpen: boolean
  modalClose: () => void
  masterCheckLists: IHostPropertyCheckList[]
  hostPropertyId: number
}
const ChecklistCloneDialog: FC<Props> = ({
  modalOpen,
  modalClose,
  masterCheckLists,
  hostPropertyId,
}) => {
  const [selectChecklist, setselectChecklist] = useState<IHostPropertyCheckList[]>([])
  const [loading, setloading] = useState(false)

  const injectClone = async () => {
    setloading(true)
    for (let index = 0; index < selectChecklist.length; index++) {
      let selectChecklistId = selectChecklist?.[index]
      try {
        await ApiCalls.postCloneCheckLists({
          hostPropertyIds: [Number(hostPropertyId)],
          hostPropertyChecklistId: selectChecklistId.id,
        })
      } catch (error: any) {
        setloading(false)
        console.log(error?.response.data.message)
      }
    }
    setloading(false)
    modalClose()
  }

  const calculateChecklistName = (cellValue: any) => {
    return cellValue?.name?.en
      ? cellValue?.name?.en
      : cellValue?.name[Object.keys(cellValue?.name)[0]]
  }
  const onSelectionChanged = (cellValue: any) => {
    setselectChecklist(cellValue.selectedRowsData.map((element: any) => element))
  }
  return (
    <Modal show={modalOpen} onHide={modalClose} centered size='xl'>
      <ModalHeader closeButton>
        <Modal.Title>Master Checklist</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <DataGrid
          id='checklistTable'
          keyExpr='id'
          onRowPrepared={(e) => {
            e.rowElement.classList.add('data-grid-row')
          }}
          dataSource={masterCheckLists}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          hoverStateEnabled={true}
          height={'calc(55vh - 60px)'}
          allowColumnResizing={true}
          onSelectionChanged={onSelectionChanged}
        >
          <Export enabled />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          <Selection mode='multiple' showCheckBoxesMode='always' />
          <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
          <Paging defaultPageSize={50} />
          <Summary>
            <TotalItem
              cssClass='absolute-right'
              displayFormat='Total Checklists: {0}'
              column='name'
              summaryType='count'
            />
          </Summary>

          <Column
            dataField='name'
            calculateCellValue={calculateChecklistName}
            caption='Checklist Name'
            minWidth={150}
            cssClass='cls'
          />
          <Column dataField='roleName' caption='Role' minWidth={150} cssClass='cls' />
          <Column dataField='serviceTypeName' caption='Service' minWidth={150} cssClass='cls' />
        </DataGrid>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={loading}
          className='btn btn-success ms-3'
          style={{height: '100%'}}
          onClick={injectClone}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='currentColor'
            className='bi bi-plus'
            viewBox='0 0 16 16'
          >
            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
          </svg>
          Save Checklist
          {loading && <Spinner animation='border' size='sm' className='ms-2' />}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChecklistCloneDialog
