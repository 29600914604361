import * as deleteDialogActionCreators from '../../../redux/actionCreators/deleteDialogActionCreators'
import { Dispatch, FC, useRef } from "react"
import {
    Column,
    DataGrid,
    HeaderFilter,
    Paging,
    Scrolling,
    FilterRow,
    Export,
    SearchPanel,
} from 'devextreme-react/data-grid'
import { encryptText, onExporting, toAmazonUrl } from "../../../utils/util"
import { useHistory } from 'react-router-dom';
import DeleteButton from "../../../umut-components/Buttons/DeleteButton";
import { useDispatch } from 'react-redux';
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes';
import ApiCalls from '../../../network/ApiCalls';
import { IOffered } from '../../../interfaces/Offered';
import ProfilePhotoTemp from '../../../umut-components/Icons/ProfilePhotoTemp';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';
import Button from 'devextreme-react/button';
import { useMutation, useQueryClient } from 'react-query';

type Props = {
    data: IOffered[] | undefined
    refetch: () => void
}
const StaffOfferedTable: FC<Props> = ({ data, refetch }) => {
    const history = useHistory()
    const deleteDialogDispatch = useDispatch<Dispatch<DeleteActions>>()
    const tableRef = useRef<DataGrid>(null)
    const queryClient = useQueryClient()
    const { mutateAsync } = useMutation((serviceId: number) => ApiCalls.deleteStaffsOfferedService(serviceId), { onSuccess: () => queryClient.invalidateQueries(['Offered Services']) })


    const calculateDuration = (cellData: any) => {
        return cellData?.duration + ' Minute'
    }

    const calculatePrice = (cellData: any) => {
        return '$' + cellData?.servicePrice
    }
    const toStaffProfile = (cellData: any) => {
        history.push({
            pathname: '/staff-details/' + encryptText(cellData?.data?.userId) + '/' + encryptText(cellData?.data?.vendorUserId)
        })
    }
    const displayDeleteButton = (cellData: any) => {
        return (
            <DeleteButton
                iconWidth='20'
                iconHeight='20'
                deleteFn={() => {
                    deleteDialogDispatch(
                        deleteDialogActionCreators.openDeleteDialog(
                            async () => {
                                await mutateAsync(cellData?.data?.staffOfferedServiceId)
                                refetch()
                            },
                            `Are you sure you want to delete ?`,
                            'Delete Offered'
                        )
                    )
                }}
            />
        )
    }
    const renderProfilePhoto = (cellValue: any) => {
        return (
            cellValue?.data?.profilePhotoPath ?
                <img alt='pp' style={{ width: 45, height: 45, borderRadius: '50%' }} src={cellValue?.data?.profilePhotoPath ? toAmazonUrl(cellValue?.data?.profilePhotoPath) : toAbsoluteUrl('/media/icons/noImage.png')} />
                : <ProfilePhotoTemp firstName={cellValue?.data?.firstName} lastName={cellValue?.data?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }
    const calculateFullName = (cellValue: any) => {
        return cellValue?.firstName + ' ' + cellValue?.lastName
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Offered" />
    }
    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>

            <DataGrid
                id='propertyTable'
                keyExpr='staffOfferedServiceId'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                ref={tableRef}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(54vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Staff Offers")}
                onCellDblClick={toStaffProfile}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />

                <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />

                <Column
                    dataField='firstName'
                    calculateCellValue={calculateFullName}
                    caption='Fullname'
                    minWidth={180}
                    dataType='string'
                    cssClass='cls'
                />

                <Column dataField='serviceTypeName' caption='Service Type' minWidth={150} cssClass='cls' />
                <Column dataField='serviceTypeDescription' caption='Description' minWidth={150} cssClass='cls' />
                <Column
                    dataField='duration'
                    caption="duration (Minutes)"
                    calculateCellValue={calculateDuration}
                    minWidth={150}
                    cssClass='cls'
                />
                <Column
                    dataField='servicePrice'
                    caption='Price'
                    calculateCellValue={calculatePrice}
                    minWidth={150}
                    cssClass='cls'
                />
                <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />
            </DataGrid>

        </div>

    )
}

export default StaffOfferedTable
