import {
  Column,
  DataGrid, Export, FilterRow, HeaderFilter,
  Paging,
  Scrolling, SearchPanel
} from 'devextreme-react/data-grid'
import React, { useRef } from 'react'
import { useHistory } from 'react-router'
import config from '../../../../config/config.json'
import { JobStatusType } from '../../../../enums/JobEnum'
import { IJob } from '../../../../interfaces/Job'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import { encryptText, getDateFilter, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../../../utils/util'

type Props = {
  data: IJob[]
}

const JobTable: React.FC<Props> = ({ data }) => {
  const history = useHistory();

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${jobStatusType === JobStatusType.COMPLETED
          ? 'success'
          : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
          }`}
      >
        {jobStatusType ? jobStatusType : 'Unknown'}
      </strong>
    )
  }
  const tableRef = useRef<DataGrid>(null)
  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const toJobDetail = (cellData: any) => {
    if (cellData?.data?.id) {
      history.push('/job-details/' + encryptText(cellData?.data?.id))
    }
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  if (data?.length === 0) {
    return <EmptyTable title="No Jobs" />
  }

  return (
    <div className="position-relative">
      <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='jobTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        ref={tableRef}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        columnAutoWidth={true}
        height={'calc(54vh - 60px)'}
        allowColumnResizing={true}
        selection={{ mode: 'single' }}
        onCellClick={toJobDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, "Jobs")}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
        <Paging defaultPageSize={50} />

        <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
        <Column
          dataField='scheduledStart'
          dataType='date' selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={150}
          cssClass='cls'
          
        >
          <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
        </Column>
        <Column
          dataField='jobStatusTypeName'
          cellRender={displayStatusType}
          caption='Status'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        {/*<Column
          dataField='hostName'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />*/}
        <Column
          dataField='scheduledFinish'
          dataType='date' selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={150}
          cssClass='cls'

        >
          <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
        </Column>
      </DataGrid>
    </div>

  )
}

export default JobTable
