import { FC } from "react"

type Props = {
    classNames?: string,
    declineFn: () => void,
    type?: 'submit' | 'button',
    iconWidth?: string,
    iconHeight?: string,
    disabled?: boolean
}
const DeclineButton: FC<Props> = ({ declineFn, type, iconHeight, iconWidth, classNames,disabled }) => {
    return (

        <button className="btn btn-danger" disabled={disabled} type={type ? type : "button"} onClick={() => { declineFn() }}>
            <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth ? iconWidth : "16"} height={iconHeight ? iconHeight : "16"} fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
            </svg>
        </button>

    )
}

export default DeclineButton
