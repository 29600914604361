import React, {Dispatch, FC, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useDialog} from '../../../contexts/DialogContext'
import ApiCalls from '../../../network/ApiCalls'
import {HostPropertyAction} from '../../../redux/actionTypes/hostPropertyTypes'
import {
  CloseDeletePropertyModal,
  ClosePropertyOfferedDialog,
} from '../../../redux/actionCreators/hostPropertyActionCreators'
import {HostPropertyStatus} from '../../../enums/PropertyEnum'
import {JobStatusType} from '../../../enums/JobEnum'
import {
  convertUTCDateToLocalDate,
  decryptText,
  getDateFilter,
  setDxFilterPlaceholder,
  toConvertedDate,
} from '../../../utils/util'
import {Modal, Spinner} from 'react-bootstrap-v5'
import {DataGrid} from 'devextreme-react'
import {
  Column,
  HeaderFilter,
  Paging,
  Scrolling,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {useParams, useHistory} from 'react-router'
import config from '../../../config/config.json'

type Props = {}
const PropertyDeleteDialog: FC<Props> = () => {
  let {propertyId}: any = useParams()
  propertyId = decryptText(propertyId)
  const history = useHistory()
  const {propertyDelete} = useSelector((state: RedusxAppState) => state.hostProperty)
  const deleteDispatch = useDispatch<Dispatch<HostPropertyAction>>()
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [reqLoading, setreqLoading] = useState(false)
  const {data, isFetching, error, refetch} = useQuery(
    ['Get Related Jobs'],
    () => ApiCalls.getPropertyRelatedJobs(propertyId),
    {enabled: propertyId ? true : false}
  )

  const handleCloseModal = () => {
    deleteDispatch(CloseDeletePropertyModal())
  }
  const deleteProperty = async () => {
    try {
      setreqLoading(true)
      await ApiCalls.editPropertyStatus(propertyId, HostPropertyStatus['Deleted'])
      setreqLoading(false)
      showSuccessDialog('Property deleted')
      history.replace('/properties/active')
      handleCloseModal()
    } catch (err: any) {
      showFailureDialog(err?.response?.data?.message)
    }
  }
  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }
  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateCleaningPeriodStart = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateCleaningPeriodEnd = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodEnd, cellValue.hostPropertyCityTimeZone)
  }
  return (
    <>
      <Modal
        show={propertyDelete.modalOpen}
        onHide={handleCloseModal}
        centered
        size={data?.data?.data.length == 0 ? 'sm' : 'xl'}
      >
        <Modal.Header>
          <Modal.Title className='text-danger'>Delete property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data?.data?.data.length == 0 ? (
            <p> Are you sure you want to delete property?</p>
          ) : (
            <div>
              <p className='text-warning'>
                Are you sure you want to delete property? Please keep in mind that if this property
                has any future jobs, they will be deleted as well.
              </p>
              <DataGrid
                id={'jobTable' + propertyId}
                keyExpr='id'
                onRowPrepared={(e) => {
                  e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data?.data?.data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                allowColumnResizing={true}
                onEditorPreparing={setDxFilterPlaceholder}
                selection={{mode: 'single'}}
                className='mt-6'
              >
                <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <Summary>
                  <TotalItem
                    cssClass='absolute-right'
                    displayFormat='Total Jobs: {0}'
                    column='hostPropertyAddress'
                    summaryType='count'
                  />
                </Summary>
                <Column dataField='id' caption='Job Id' minWidth={50} cssClass='cls' />
                <Column
                  dataField='jobStatusTypeName'
                  alignment='center'
                  caption='Status'
                  cellRender={displayStatusType}
                  minWidth={150}
                  cssClass='cls'
                />
                <Column
                  dataField='hostPropertyCityName'
                  caption='City'
                  alignment='center'
                  minWidth={200}
                  cssClass='cls'
                />
                <Column
                  dataField='hostPropertyStateName'
                  caption='State'
                  minWidth={150}
                  cssClass='cls'
                />

                <Column
                  dataField='hostPropertyCountryName'
                  caption='Country'
                  minWidth={200}
                  cssClass='cls'
                />

                <Column
                  dataField='scheduledStart'
                  caption='Scheduled Start'
                  dataType='date'
                  selectedFilterOperation='between'
                  format={config.date_time_format_dev_extreme}
                  calculateCellValue={calculateScheduledStart}
                  minWidth={150}
                  cssClass='cls'
                >
                  <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
                </Column>
                <Column
                  dataField='startedOn'
                  caption='Started at'
                  dataType='date'
                  selectedFilterOperation='between'
                  format={config.date_time_format_dev_extreme}
                  calculateCellValue={calculateStartedOn}
                  minWidth={150}
                  cssClass='cls'
                >
                  <HeaderFilter dataSource={getDateFilter('startedOn')} />
                </Column>
                <Column
                  dataField='scheduledFinish'
                  caption='Scheduled Finish'
                  dataType='date'
                  selectedFilterOperation='between'
                  format={config.date_time_format_dev_extreme}
                  calculateCellValue={calculateScheduledFinish}
                  minWidth={150}
                  cssClass='cls'
                >
                  <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
                </Column>
                <Column
                  dataField='finishedOn'
                  caption='Finished at'
                  dataType='date'
                  selectedFilterOperation='between'
                  format={config.date_time_format_dev_extreme}
                  calculateCellValue={calculateFinishedOn}
                  minWidth={150}
                  cssClass='cls'
                  alignment='center'
                >
                  <HeaderFilter dataSource={getDateFilter('finishedOn')} />
                </Column>
              </DataGrid>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseModal} className='btn btn-sm text-danger'>
            cancel
          </button>
          <button onClick={deleteProperty} className='btn btn-sm btn-info'>
            {reqLoading ? <Spinner animation='border' size='sm' /> : 'Save'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default PropertyDeleteDialog
