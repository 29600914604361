import {Dispatch, FC, useEffect, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {JobStatusType} from '../../../enums/JobEnum'
import ApiCalls from '../../../network/ApiCalls'
import * as jobAvailableCreators from '../../../redux/actionCreators/JobAvailableActionCreators'
import {JobAvailableActions} from '../../../redux/actionTypes/jobAvailableTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import ArrowButton from '../../../umut-components/Buttons/ArrowButton'
import {convertUTCDateToLocalDate} from '../../../utils/util'
import ChecklistTab from './Tabs/ChecklistTab/ChecklistTab'
import NotesTab from './Tabs/NotestTab/NotesTab'
import PropertyTab from './Tabs/PropertyTab/PropertyTab'
import ServiceTab from './Tabs/ServiceTab/ServiceTab'
import TasksTab from './Tabs/TasksTab/TasksTab'

type TabNames = 'services' | 'notes' | 'tasks' | 'property' | 'checklist'

type Props = {
  nextStep?: () => void
  goToStep: (key: any) => void
}
const AvailableJobDetails: FC<Props> = ({nextStep, goToStep}) => {
  const [selectedTab, setSelectedTab] = useState<TabNames>('services')
  const [jobEventsActive, setJobEventsActive] = useState<boolean>(false)
  const {jobAvailableId} = useSelector((state: RedusxAppState) => state.jobAvailable)
  // const { cleaningPeriodStart } = useSelector((state: RedusxAppState) => state.jobAvailable)
  const {data, isLoading, error} = useQuery(
    ['Get Available Jobs Detail', jobAvailableId],
    () => ApiCalls.getJobDetails(jobAvailableId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobAvailableId ? true : false}
  )
  const dispatch = useDispatch<Dispatch<JobAvailableActions>>()

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  useEffect(() => {
    if (data) {
      setJobEventsActive(
        !(
          data?.data?.jobStatusTypeName === JobStatusType.COMPLETED ||
          data?.data?.jobStatusTypeName === JobStatusType.CANCELLED
        )
      )
    }
  }, [data])

  useEffect(() => {
    if (data) {
      dispatch(
        jobAvailableCreators.pushHostPropertyId(
          data?.data?.hostPropertyId,
          data?.data?.hostPropertyCityTimeZone
        )
      )
      dispatch(jobAvailableCreators.pushRoleId(data?.data?.roleId))
    }
  }, [data])

  if (isLoading) {
    return <div className='d-flex justify-content-center p-6'>Loading...</div>
  }

  if (error) {
    return <div className='d-flex justify-content-center p-6'>Error</div>
  }

  return (
    <>
      <div className='row g-8 g-xl-5'>
        <Card>
          <Card.Header
            style={{borderBottom: '0px'}}
            className='d-flex align-items-start flex-column  justify-content-start'
          >
            <div className='p-2 '>
              <strong className='fs-4'>{data?.data?.hostProperty?.address}</strong>
            </div>
            <div className='p-2'>
              {/* <span className='badge badge-primary badge me-3'>{jobAvailableId}</span> */}
              <span className='badge badge-info me-3'>
                {data?.data?.jobStatusType?.ame === 'Started'
                  ? 'In Progress'
                  : data?.data?.jobStatusType?.name}
              </span>
              {/* <span className='badge badge-warning me-3'>{data?.data?.cleaningProviderId}</span> */}
              <span className='badge badge-success me-3'>
                {convertUTCDateToLocalDate(
                  data?.data?.cleaningPeriodStart,
                  data?.data?.hostPropertyCityTimeZone
                )}
              </span>
              {/* <span className='badge badge-danger me-3'>{convertUTCDateToLocalDate(data?.data?.cleaningPeriodEnd, data?.data?.hostPropertyCityTimeZone)}</span> */}
            </div>
          </Card.Header>
          <Card.Body>
            <Tabs defaultActiveKey='services' onSelect={handleChangeTab}>
              <Tab eventKey='services' title='Services'>
                {selectedTab === 'services' ? (
                  <ServiceTab jobDetail={data?.data} jobEventsActive={jobEventsActive} />
                ) : null}
              </Tab>
              <Tab eventKey='checklist' title='Checklist'>
                {selectedTab === 'checklist' ? (
                  <ChecklistTab
                    checklistData={data?.data?.jobServiceChecklist?.hostPropertyChecklist}
                  />
                ) : null}
              </Tab>

              <Tab eventKey='notes' title='Notes'>
                {selectedTab === 'notes' ? <NotesTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='tasks' title='Tasks'>
                {selectedTab === 'tasks' ? <TasksTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>

              <Tab eventKey='property' title='Property Detail'>
                {selectedTab === 'property' ? (
                  <PropertyTab
                    jobEventsActive={jobEventsActive}
                    hostPropertyId={data?.data?.hostPropertyId}
                  />
                ) : null}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
        <div className='d-flex justify-content-end flex-row w-100 mt-6'>
          <button
            className='btn btn-sm btn-success'
            onClick={() => {
              goToStep(2)
            }}
          >
            Schedule Job
          </button>
          {/* <DataView calendarDetails={'2021-10-1T12:13:13.969Z'} /> */}
        </div>
      </div>
    </>
  )
}

export default AvailableJobDetails
