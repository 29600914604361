import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useDialog} from '../../../contexts/DialogContext'
import ApiCalls from '../../../network/ApiCalls'
import {ChecklistSectionAddRequest} from '../../../network/PostRequestModels/ChecklistRequests'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import SelectInput from '../../Inputs/SelectInput'
import {TextInput} from '../../Inputs/TextInput'

type Props = {
  hostPropertyChecklistId: string
  handleAddSectionSuccess: () => void
}

const ChecklistSectionForm: React.FC<Props> = ({
  hostPropertyChecklistId,
  handleAddSectionSuccess,
}) => {
  const {checklistItems} = useSelector((state: RedusxAppState) => state.checklist)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [sectionRequest, setSectionRequest] = useState({
    hostPropertyChecklistId: parseInt(hostPropertyChecklistId),
    orderIndex: checklistItems?.sections.length ?? 1,
  } as ChecklistSectionAddRequest)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [sectionTypes, setSectionTypes] = useState([])
  const {
    data: sectionTypesData,
    isLoading: sectionTypesLoading,
    error: sectionTypesError,
  } = useQuery('Get Section Types', () => ApiCalls.getChecklistSectionTypes(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })

  const handleChecklistSectionAdd = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      const {roomIndex, checklistSectionTypeId} = sectionRequest
      if (checklistSectionTypeId) {
        await ApiCalls.addChecklistSection(sectionRequest)
        setLoading(false)
        handleAddSectionSuccess()
      } else {
        setLoading(false)
        setErrorMessage('Room is required.')
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  useEffect(() => {
    if (sectionTypesData) {
      setSectionTypes(
        sectionTypesData?.data?.data?.map((type: any) => ({label: type.name, value: type.id}))
      )
    }
  }, [sectionTypesData])

  if (sectionTypesLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark'>Select Room</label>
          <SelectInput
            options={sectionTypes}
            onValueChanged={(value: any) =>
              setSectionRequest({...sectionRequest, checklistSectionTypeId: value})
            }
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6 w-100'>
            Room Name (Optional)
          </label>
          <small className='text-muted'>
            Add a name for this room so the assigned staff can locate it easier.{' '}
          </small>
          <TextInput
            onValueChange={(text: string) =>
              setSectionRequest({...sectionRequest, roomIndex: text})
            }
          />
          <small className='text-muted'>(e.g. Bathroom #1, Large Bedroom, etc.)</small>

          <div className='row mt-6'>
            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={loading}
                onClick={handleChecklistSectionAdd}
              >
                Add {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col-12 text-end'>
              <strong style={{color: 'red'}}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default React.memo(ChecklistSectionForm)
