import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useSelector} from 'react-redux'
import {useDialog} from '../../../contexts/DialogContext'
import {IVendorReviews} from '../../../interfaces/Vendors'
import ApiCalls from '../../../network/ApiCalls'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import StarRatingView from '../../../umut-components/Rating/StarRatingView'

type Props = {
  reviews: IVendorReviews[]
  isVisible: boolean
  modalClose: () => void
}

const VendorReviewsModal: React.FC<Props> = ({reviews, isVisible, modalClose}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)

  return (
    <Modal show={isVisible} onHide={modalClose} size='xl' centered>
      <ModalHeader closeButton>
        <Modal.Title>Vendor Reviews ({reviews != undefined ? reviews.length : 0})</Modal.Title>
      </ModalHeader>
      <Modal.Body style={{height: '50vh', overflowY: 'scroll'}}>
        {reviews &&
          reviews.length > 0 &&
          reviews.map((d: IVendorReviews, index) => {
            return (
              <>
                <div
                  className={
                    'row ' + (index + 1 < reviews.length ? 'border-bottom border-bottom-2' : '')
                  }
                >
                  <div className='col-12'>
                    <ul className='review-detail-row'>
                      <li className='score'>
                        <strong>Score : </strong> <StarRatingView rate={d.score} /> ({d.score})
                      </li>
                      {d.note && <li>{d.note}</li>}
                    </ul>
                  </div>
                </div>
              </>
            )
          })}
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-link text-muted me-4'
          onClick={() => {
            modalClose()
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default VendorReviewsModal
