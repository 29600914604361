import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {IMarketplaceOffer} from '../../interfaces/Marketplace'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  Editing,
  Button,
} from 'devextreme-react/data-grid'
import {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {encryptText} from '../../utils/util'
import {IServiceRateQuoteRequests} from '../../interfaces/Offered'
import OfferedRateEditDialog from './OfferedRateEditDialog'

type Props = {
  data: IServiceRateQuoteRequests[] | any
  title: string
  refetch: () => void
}

const OfferedRateList: React.FC<Props> = ({data, title, refetch}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const [selectedData, setSelectedData] = useState<IServiceRateQuoteRequests | null>(null)
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const closeEditModal = () => {
    setModalVisible(false)
    refetch()
  }

  const openEditModal = (event: any) => {
    setSelectedData(event.data)
    setModalVisible(true)
  }

  if (data && data.length == 0) {
    return <EmptyTable title='There is no quote request' />
  }

  return (
    <div className='mt-10 mb-10'>
      <DataGrid
        id='offerTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        ref={tableRef}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(65vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onRowClick={(e) => {
          openEditModal(e)
        }}
      >
        <Export enabled={false} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat={'Total ' + title + ' : {0}'}
            column='address'
            summaryType='count'
          />
        </Summary>
        <Column
          dataField='address'
          caption='Property'
          minWidth={300}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='client'
          caption='Client'
          dataType='string'
          cssClass='cls'
          minWidth={160}
        />
        <Column
          dataField='serviceType'
          caption='Service Type'
          dataType='string'
          cssClass='cls'
          minWidth={160}
        />
        <Column
          dataField='duration'
          caption='Duration (min)'
          dataType='string'
          cssClass='cls'
          minWidth={160}
        />
        <Column
          dataField='offeredRate'
          caption='Service Rate ($)'
          dataType='number'
          minWidth={220}
          alignment={'center'}
          cssClass='cls'
        />
        <Column
          dataField='rateType'
          caption='Rate Type'
          dataType='number'
          cssClass='cls'
          alignment={'center'}
          minWidth={260}
        />
      </DataGrid>
      <OfferedRateEditDialog
        data={selectedData}
        visible={modalVisible}
        handleClose={closeEditModal}
        editable={title == 'New' ? true : false}
      />
    </div>
  )
}

export default OfferedRateList
