import * as actions from '../actionTypes/violationTypes'
import { IViolation } from './../../interfaces/Violation';

export function openViolationView(
    violation: IViolation | null

  ): actions.OpenViolationViewAction {
    return {
      type: actions.VIOLATION_OPEN_VIEW,
      violation,
    }
  }
  
  export function closeViolationView(): actions.CloseViolationViewAction {
    return {
      type: actions.VIOLATION_CLOSE_VIEW,
    }
  }

export function CloseViolationViewAction(): actions.ViolationDetailActions {
    throw new Error('Function not implemented.');
}
