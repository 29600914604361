import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {IBillingInformation} from '../../interfaces/Billing'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {IAddressResponse} from '../../network/NetworkResponses/NetworkResponses'
import PaymentDialog from './PaymentDialog'
import {AxiosResponse} from 'axios'
import {IStripeInfo} from '../../interfaces/Stripe'
import {Spinner} from 'react-bootstrap-v5'

type SelectType = {
  label: string
  value: number
}

type InputType = {
  account_number: string
  routing_number: string
}

const schema = Yup.object().shape({
  account_number: Yup.string().required('Account number is required'),
  routing_number: Yup.string()
    .length(9, 'Must have 9 digits')
    .required('Routing number is required'),
})

const defaultValues: InputType = {
  account_number: '',
  routing_number: '',
}

const BillingInformation = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [errorText, setErrorText] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [disableModalOpen, setDisabledModalOpen] = useState<boolean>(false)

  const {
    data: billingInformation,
    isLoading,
    error,
    refetch,
  } = useQuery<AxiosResponse<IStripeInfo>>(
    ['Get Billing Information'],
    () => ApiCalls.getVendorById(user?.userSection?.vendorId),
    {cacheTime: 100000, refetchOnWindowFocus: false}
  )
  const {
    data: paymentUrlData,
    isLoading: paymentUrlLoading,
    error: paymentUrlError,
  } = useQuery(
    ['Retry payment info', billingInformation?.data?.isVerifiedStripeAccount],
    () => ApiCalls.getStripeAccountLink(),
    {
      cacheTime: 100000,
      refetchOnWindowFocus: false,
      enabled: billingInformation?.data?.isVerifiedStripeAccount === false,
    }
  )

  const {control, formState, handleSubmit, reset, register} = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const {isValid, dirtyFields, errors} = formState

  const submitPayment = async (data: InputType) => {
    try {
      setLoading(true)
      setErrorText(null)
      let request: IBillingInformation = {
        ...data,
      }
      const res = await ApiCalls.postBillingInformation(request)
      if (res.data) {
        location.href = res?.data?.url
      }
    } catch (err: any) {
      setErrorText(err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message)
      setLoading(false)
    }
  }

  /*const handleChangeDob = (value: any) => {
    let date = dayjs(value)
    setSelectedDOB({
      day: date.date(),
      month: date.month() + 1,
      year: date.year(),
    })
  }
  const isValidHttpUrl = (http: string) => {
    var res = http.match("^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$");
    if (res == null)
      return false;
    else
      return true;
  }*/

  const navigateToStripe = () => {
    if (paymentUrlData?.data) {
      location.href = paymentUrlData?.data?.url
    }
  }

  const disableStripe = async () => {
    try {
      await ApiCalls.disabledBilling()
      refetch()
    } catch (err: any) {
      //Error handling
    }
  }

  if (isLoading) {
    return (
      <div>
        <Spinner animation='border' />
      </div>
    )
  }

  if (billingInformation?.data.isVerifiedStripeAccount && billingInformation.data.stripeAccountId) {
    return (
      <>
        <PageTitle>Payment</PageTitle>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='h-300px w-250px d-flex justify-content-center'>
            <div className='d-flex flex-column align-items-center'>
              <img
                style={{width: 200, objectFit: 'contain'}}
                src='/media/logos/stripeLogo.png'
                alt='stripe'
              />
              <img
                style={{width: 150, objectFit: 'contain'}}
                src='/media/icons/success.png'
                alt='success'
              />
            </div>
          </div>
          <h4 className='text-success text-center mt-5'>Your stripe account is verified.</h4>
          <button
            type='button'
            className='btn btn-info btn-sm mt-6'
            onClick={() => {
              setDisabledModalOpen(true)
            }}
          >
            Disable Stripe Account
          </button>
        </div>
        <PaymentDialog
          modalOpen={disableModalOpen}
          handleClose={() => {
            setDisabledModalOpen(false)
          }}
          handleDisable={disableStripe}
        />
      </>
    )
  }

  if (
    billingInformation?.data.isVerifiedStripeAccount === false &&
    billingInformation.data.stripeAccountId
  ) {
    return (
      <>
        <PageTitle>Payment</PageTitle>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='h-300px w-250px d-flex justify-content-center'>
            <div className='d-flex flex-column align-items-center'>
              <img
                style={{width: 200, objectFit: 'contain'}}
                src='/media/logos/stripeLogo.png'
                alt='stripe'
              />
              <img
                style={{width: 150, objectFit: 'contain'}}
                src='/media/icons/danger.png'
                alt='success'
              />
            </div>
          </div>
          <h4 className='text-danger text-center mt-5'>
            Stripe cannot verify your account. Please try again.
          </h4>
          <button
            type='button'
            className='btn btn-info btn-sm mt-6'
            onClick={navigateToStripe}
            disabled={!paymentUrlData?.data?.url}
          >
            Go to Stripe
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <PageTitle>Payment</PageTitle>
      <div className='w-100 d-flex justify-content-center'>
        <form
          className='card shadow-sm p-6 form w-100 w-sm-750px'
          onSubmit={handleSubmit(submitPayment)}
          noValidate
          id='payment-form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <img
              className='w-100 w-sm-100px'
              alt='stripe'
              src={toAbsoluteUrl('/media/logos/stripeLogo.png')}
            />
            <h3 className='text-dark mb-3'>Payment Information</h3>
            <small className='text-muted'>
              Turnify payouts are powered by Stripe, one of the most secure platforms in the world.
              Start by entering your payout account details, and then you'll be taken directly to
              stripe's website to finish setting up your company's account. Once you complete a
              cleaning, with the required photos, and after your client pays, you'll receive a
              deposit in this account
            </small>
          </div>
          {/* begin::Heading */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label fs-6 fw-bolder text-dark'>Account Number</label>
            <input
              className='form-control '
              type='text'
              autoComplete='off'
              {...register('account_number')}
              required
            />
            <p style={{color: 'red', fontWeight: 'bold'}}>{errors.account_number?.message}</p>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label fs-6 fw-bolder text-dark'>Routing Number</label>
            <input
              className='form-control '
              type='text'
              autoComplete='off'
              {...register('routing_number')}
              required
            />
            <p style={{color: 'red', fontWeight: 'bold'}}>{errors.routing_number?.message}</p>
          </div>
          {/* end::Form group */}

          {/* begin:: ErrorText */}
          <strong style={{color: 'red'}}>{errorText}</strong>
          {/* end:: ErrorText */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={!isValid || loading}
            >
              {!loading && <span className='indicator-label'>Save Payment</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
    </>
  )
}

export default BillingInformation

/*

          <div className='fv-row mb-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Select Address</label>
            <Select styles={selectStyles} options={addresses ? addresses : []} onChange={(value) => setSelectedAddressId(value?.value)} />
          </div >


          <div className='fv-row mb-3'>
            <label className='form-label fs-6 fw-bolder text-dark'>Last 4 Number of Your SSN</label>
            <input
              className='form-control '
              type='text'
              autoComplete='off'
              {...register("ssn_last_4")}
              required
            />
            <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.ssn_last_4?.message}</p>
          </div>



          <div className='fv-row mb-3'>
            <label className='form-label fs-6 fw-bolder text-dark'>Tax ID</label>
            <input
              className='form-control '
              type='text'
              autoComplete='off'
              {...register("tax_id")}
              required
            />
            <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.tax_id?.message}</p>
          </div>

                    <div className='fv-row mb-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Website URL</label>
            <input
              className='form-control '
              type='text'
              autoComplete='off'
              {...register("url")}
              required
            />
            <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.url?.message}</p>
          </div>

                    <div className='fv-row mb-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Select Your Birthday</label>
            <DateBox max={dayjs().add(-18, 'year').toString()} className='w-100' style={{ height: 40 }} displayFormat='MM/dd/yyyy' onValueChange={handleChangeDob} pickerType="rollers" />
          </div >

 */
