import React, { Dispatch, useEffect, useRef, useState } from 'react'
import * as changePasswordActionCreators from '../../redux/actionCreators/changePasswordActionCreators'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import { StaffAction } from '../../redux/actionTypes/staffActionTypes'
import UpdateButton from '../../umut-components/Buttons/UpdateButton'
import { encryptText, onExporting, toAmazonUrl } from '../../utils/util'
import { IStaff } from '../../interfaces/Staff'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp'
import ChangePasswordButton from '../../umut-components/Buttons/ChangePasswordButton'
import ApiCalls from '../../network/ApiCalls'
import { ChangePasswordActions } from '../../redux/actionTypes/changePasswordTypes'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import { AccountStatusTypes } from '../../enums/AccountEnum'
import { openStaffStatusDialog } from '../../redux/actionCreators/staffActionCreators'
import AccountDialog from './AccountDialog'
import { useDialog } from '../../contexts/DialogContext'

type Props = {
  data: IStaff[],
  refetch: () => void
}

const StaffTable: React.FC<Props> = ({ data, refetch }) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const changePasswordDispatch = useDispatch<Dispatch<ChangePasswordActions>>()
  const dispatch = useDispatch<Dispatch<StaffAction>>();
  const { showSuccessDialog }: any = useDialog()

  const renderProfilePhoto = (cellValue: any) => {
    return (
      cellValue?.data?.profilePhotoPath ?
        <img alt='pp' style={{ width: 45, height: 45, borderRadius: '50%' }} src={cellValue?.data?.profilePhotoPath ? toAmazonUrl(cellValue?.data?.profilePhotoPath) : toAbsoluteUrl('/media/icons/noImage.png')} />
        : <ProfilePhotoTemp firstName={cellValue?.data?.firstName} lastName={cellValue?.data?.lastName} width={45} height={45} borderRadius='50%' />
    )
  }

  const toStaffDetail = (cellValue: any) => {
    if (cellValue?.column?.name !== "change-password-button" && cellValue?.column?.name !== "status-button" && cellValue?.data?.id) {
      history.push({
        pathname: '/staff-details/' + encryptText(cellValue?.data?.userId) + '/' + encryptText(cellValue?.data?.id),
      })
    }

  }
  const reMail = async (cellValue: any) => {
    try {
      ApiCalls.postReMailStaff({ email: cellValue.data.email, vendorUserId: cellValue.data.id })
      showSuccessDialog('Mail confirmation is sent to')
    } catch (error: any) {
      console.log(error.response.data.message)
    }
  }

  const calculateFullName = (cellValue: any) => {
    return cellValue?.firstName + ' ' + cellValue?.lastName
  }

  const renderStatusButton = (cellValue: any) => {
    const accountStatusId = cellValue?.data?.accountStatusId;
    switch (accountStatusId) {
      case AccountStatusTypes.INACTIVE:
        return <button className='btn btn-success btn-sm' onClick={() => { dispatch(openStaffStatusDialog(cellValue?.data?.userId, AccountStatusTypes.ACTIVE, refetch)) }}>Activate staff</button>
      case AccountStatusTypes.ACTIVE:
        return <button className='btn btn-danger btn-sm ' onClick={() => { dispatch(openStaffStatusDialog(cellValue?.data?.userId, AccountStatusTypes.INACTIVE, refetch)) }}>Deactivate staff</button>
      case AccountStatusTypes.EMAIL_NOT_VERIFIED:
        return <button className='btn btn-warning btn-sm' onClick={() => { reMail(cellValue) }}><span className="svg-icon svg-icon-primary svg-icon-2x ps-2"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="20" height="20" />
            <path d="M5,9 L19,9 C20.1045695,9 21,9.8954305 21,11 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,11 C3,9.8954305 3.8954305,9 5,9 Z M18.1444251,10.8396467 L12,14.1481833 L5.85557487,10.8396467 C5.4908718,10.6432681 5.03602525,10.7797221 4.83964668,11.1444251 C4.6432681,11.5091282 4.77972206,11.9639747 5.14442513,12.1603533 L11.6444251,15.6603533 C11.8664074,15.7798822 12.1335926,15.7798822 12.3555749,15.6603533 L18.8555749,12.1603533 C19.2202779,11.9639747 19.3567319,11.5091282 19.1603533,11.1444251 C18.9639747,10.7797221 18.5091282,10.6432681 18.1444251,10.8396467 Z" fill="#000000" />
            <path d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(11.959697, 3.661508) rotate(-90.000000) translate(-11.959697, -3.661508) " />
          </g>
        </svg></span></button>

      default:
        return <strong></strong>
    }
  }

  if (data?.length === 0) {
    return <EmptyTable title="No Staff" />
  }

  return (

    <div className="position-relative">
      <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>

      <DataGrid
        id='staffTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        ref={tableRef}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(65vh - 60px)'}
        allowColumnResizing={true}
        selection={{ mode: 'single' }}
        onCellClick={toStaffDetail}
        onExporting={(e) => onExporting(e, "Staff")}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem cssClass='absolute-right' displayFormat="Total Staff: {0}" column='firstName' summaryType='count' />
        </Summary>
        <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />
        <Column
          dataField='firstName'
          calculateCellValue={calculateFullName}
          caption='Fullname'
          minWidth={180}
          dataType='string'
          cssClass='cls'
        />
        <Column
          dataField='roleName'
          caption='Role'
          minWidth={200}
          dataType='string'
          cssClass='cls'
        />
        <Column dataField='email' dataType='string' minWidth={300} cssClass='cls' />
        <Column dataField='phoneNumber' dataType='string' minWidth={180} cssClass='cls' />
        <Column dataField='accountStatusName' caption="Account Status" dataType='string' width={180} cssClass='cls' />
        <Column name='status-button' minWidth={150} alignment="center" cellRender={renderStatusButton} cssClass='cls' />


      </DataGrid>
      <AccountDialog />
    </div>
  )
}

export default React.memo(StaffTable)


