export enum HostPropertyStatusType {
    ACTIVE = 'Active',
    OFFER = 'Offer Pending',
    PROPERTY_FIELDS_NOT_SET = 'Property Fields Not Set',
    PASSIVE = 'Passive',
    DECLINED = 'Declined',
    DELETED = 'Deleted'
}

export const HostPropertyStatusId = {
    "active": 1,
    "offer-pending": 2,
    "passive": 3,
    "fields-not-set": 4,
    "declined": 5,
    "deleted": 6
}

export const HostPropertyStatus = {
    "Active": 1,
    "Offer Pending": 2,
    "Passive": 3,
    "Property Fields Not Set": 4,
    "Declined": 5,
    "Deleted": 6
}

export enum CleaningProviders {
    MY_TEAM = 1,
    TURNIFY_PRO = 2,
    TURNIFY_PLUS = 3
}