import * as vendorDetailActionCreators from '../../../redux/actionCreators/vendorDetailActionCreators'
import { Dispatch, FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RedusxAppState } from '../../../redux/reducers/rootReducer'
import { Spinner } from 'react-bootstrap-v5'
import ApiCalls from '../../../network/ApiCalls'
import { VendorDetailActions } from '../../../redux/actionTypes/vendorDetailActionTypes'
import { TextInput } from '../../../umut-components/Inputs/TextInput'
import PhoneTextInput from '../../../umut-components/Inputs/PhoneInput'

type Props = {
  selectedVendor: any
  refetchReferences: () => void
}

const VendorReferenceForm: FC<Props> = ({ selectedVendor, refetchReferences }) => {
  const {
    user: {
      userSection: { vendorId },
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const [vendor, setVendor] = useState<any>({
    email: selectedVendor?.email,
    phone: selectedVendor?.phone,
    name: selectedVendor?.name,
    vendorId: vendorId,
  })
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const showViewDispatch = useDispatch<Dispatch<VendorDetailActions>>()
  const phoneNumberRef = useRef<string | null>(null)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      selectedVendor !== null
        ? await ApiCalls.updateVendorReference(selectedVendor?.id, vendor)
        : await ApiCalls.createVendorReference(vendor)
      refetchReferences()
      setLoading(false)
      handleCloseModal()
    } catch (error: any) {
      setLoading(false)
      setErrorMessage("Please check the required fields again.");
    }
  }

  const handleCloseModal = () => {
    showViewDispatch(vendorDetailActionCreators.closeReferenceView())
  }
  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark'>Name {<span style={{ color: "red" }}>*</span>}</label>
          <TextInput
            placeholder='Name'
            onValueChange={(text: string) => setVendor({ ...vendor, name: text })}
            defaultValue={vendor?.name}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Email {<span style={{ color: "red" }}>*</span>}</label>
          <TextInput
            placeholder='Email'
            onValueChange={(text: string) => setVendor({ ...vendor, email: text })}
            defaultValue={vendor?.email}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Phone {<span style={{ color: "red" }}>*</span>}</label>
          <PhoneTextInput onChange={(text: string) => setVendor({ ...vendor, phone: text })} defaultValue={vendor?.phone} />

          <strong className='w-100 text-danger'></strong>

          <div className='col mt-8 '>
            <div className='d-flex col gap-3 justify-content-end'>
              <button type='button' className='btn btn-light' onClick={handleCloseModal}>
                Cancel
              </button>

              <button
                type='button'
                className='btn btn-success '
                disabled={loading || !vendor?.email || !vendor?.phone || !vendor?.name}
                onClick={handleSubmit}
              >
                Save {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col text-end'>
              <strong style={{ color: 'red' }}>{errorMessage}</strong>
            </div>
          </div>
        </div >
      </div >
    </form >
  )
}

export default VendorReferenceForm
