import React from 'react';
type Props = {
    type: "back" | "forward",
    size?: "sm" | "lg",
    func?: () => void,
    disabled?: boolean,
    classNames?: string
}

const ArrowButton: React.FC<Props> = ({ type, size, func, disabled, classNames }) => {
    return (
        
        <button type='button'  className={`btn btn-outline-success btn btn-sm btn-${size ? size : 'lg'} text-${type === "back" ? " bg-danger text-white  danger" : " bg-success text-white success"} ${classNames}`} onClick={func} disabled={disabled}>
            {
                
                type === "back" ?
                    <svg   xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                    :
                    <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                    </svg>
            }
        </button>
    )
}

export default ArrowButton;