import React, { Dispatch, useState } from 'react'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import StaffDialog from './StaffDialog'
import { StaffAction } from '../../redux/actionTypes/staffActionTypes'
import * as staffActionCreators from '../../redux/actionCreators/staffActionCreators'
import { useDispatch, useSelector } from 'react-redux'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import { useEffect } from 'react'
import StaffTable from './StaffTable'
import { useParams } from 'react-router'
import StaffEditDialog from './StaffEditDialog'
import { useQuery } from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import ChangePasswordModal from '../../umut-components/Modals/ChangePasswordModal'
import { decryptText } from '../../utils/util'

const Staffs: React.FC = () => {
  let { userId }: any = useParams()
  userId = decryptText(userId);
  const {
    user: {
      userSection: { vendorId },
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const { data, isLoading, error, refetch } = useQuery(
    ['Get Staffs'],
    () => ApiCalls.getStaff(vendorId),
    { cacheTime: 50000, refetchOnWindowFocus: false }
  )
  const dispatch = useDispatch<Dispatch<StaffAction>>()
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const closeModal = () => {
    setEditDialogOpen(false)
  }

  useEffect(() => {
    if (userId) {
      setEditDialogOpen(true)
    } else {
      setEditDialogOpen(false)
    }
  }, [userId])

  return (
    <>
      <PageTitle>Staff</PageTitle>
      <div className='row g-5 g-xl-8 '>
        <Card className='shadow'>
          <Card.Body>
            {isLoading ? <SkeletonTableLoading /> : <StaffTable data={data?.data?.data} refetch={refetch} />}
          </Card.Body>

          <div className='d-flex justify-content-end p-10'>
            <button
              className='btn btn-success'
              style={{ height: '100%' }}
              onClick={() => dispatch(staffActionCreators.openStaffAddDialog(null))}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Staff
            </button>
          </div>
        </Card>
      </div>
      <StaffDialog refetch={refetch} />
      <StaffEditDialog modalOpen={editDialogOpen} modalClose={closeModal}/>
    </>
  )
}

export default Staffs
