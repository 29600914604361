import {combineReducers} from 'redux'
import userReducer from './userReducer'
import localforage from 'localforage'
import {persistReducer} from 'redux-persist'
import staffReducer from './staffReducer'
import addressReducer from './addressReducer'
import deleteDialogReducer from './deleteDialogReducer'
import jobReducer from './jobReducer'
import vendorDetailReducer from './vendorDetailReducer'
import violationReducer from './violationReducer'
import changePasswordReducer from './changePasswordReducer'
import jobAvailableReducer from './JobAvailableReducer'
import vendorReducer from './vendorReducer'
import hostPropertyReducer from './hostPropertyReducer'
import hostReducer from './hostReducer'
import masterChecklistReducer from './masterChecklistReducer'
import checklistReducer from './checklistReducer'
import assignReducer from './assignReducer'

const persistConfig = {
  key: 'root',
  storage: localforage,
  blacklist: ['user', 'staff', 'address', 'deleteDialog', 'job', 'jobAvailable'],
}

const userPersistConfig = {
  key: 'user',
  storage: localforage,
  whitelist: ['token', 'isAuthenticated', 'user'],
  blacklist: ['loading', 'errorText', 'loginError'],
}

const rootReducer = combineReducers({
  user: persistReducer<any>(userPersistConfig, userReducer),
  staff: staffReducer,
  address: addressReducer,
  job: jobReducer,
  jobAvailable: jobAvailableReducer,
  deleteDialog: deleteDialogReducer,
  vendorDetail: vendorDetailReducer,
  violation: violationReducer,
  changePassword: changePasswordReducer,
  vendorOffer: vendorReducer,
  hostProperty: hostPropertyReducer,
  host: hostReducer,
  checklist: checklistReducer,
  masterChecklist: masterChecklistReducer,
  assign: assignReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RedusxAppState = ReturnType<typeof persistedReducer>

export default persistedReducer
