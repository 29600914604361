import React, {Dispatch} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ChecklistAction} from '../../../../../../redux/actionTypes/checklistTypes'
import * as checklistActionCreators from '../../../../../../redux/actionCreators/checklistActionCreators'
import {RedusxAppState} from '../../../../../../redux/reducers/rootReducer'
import QuestionAddForm from '../../../../../../umut-components/Forms/ChecklistForms/QuestionAddForm'
import {decryptText} from '../../../../../../utils/util'

type Props = {
  propertyDetailData: any
}

const QuestionAddDialog: React.FC<Props> = ({propertyDetailData}) => {
  let {checklistId}: any = useParams()
  checklistId = decryptText(checklistId)
  const {questionAddDialog} = useSelector((state: RedusxAppState) => state.checklist)
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const cleaningProviderName = propertyDetailData?.data?.hostProperty?.cleaningProviderName
  const closeModal = () => {
    dispatch(checklistActionCreators.closeQuestionAddDialog())
  }

  return (
    <Modal show={questionAddDialog.dialogOpen} size='lg' onHide={closeModal} centered>
      <ModalHeader closeButton>
        <Modal.Title>Add Checklist Item</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <QuestionAddForm cleaningProviderName={cleaningProviderName} checklistId={checklistId} />
      </Modal.Body>
    </Modal>
  )
}

export default QuestionAddDialog
