import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useRef} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import config from '../../config/config.json'
import {JobStatusType} from '../../enums/JobEnum'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {
  encryptText,
  getDateFilter,
  onExporting,
  setDxFilterPlaceholder,
  toConvertedDate,
} from '../../utils/util'

const HasVirtualInspectionTable = () => {
  const history = useHistory()
  const {
    user: {
      userSection: {vendorId},
    },
  }: any = useSelector((state: RedusxAppState) => state.user)
  const tableRef = useRef<DataGrid>(null)
  const {data, isLoading, error} = useQuery(
    ['Get Has Virtual Inspection Jobs'],
    () => ApiCalls.getHasVirtualInspectionsJobs(vendorId),
    {cacheTime: 50000}
  )

  const toVirtualInspection = (cellData: any) => {
    if (
      cellData?.column?.name !== 'cleaningPeriod' &&
      cellData?.column?.name !== 'cleaningPeriodFinish' &&
      cellData?.data?.id
    ) {
      history.push('/jobs/virtual-inspection/' + encryptText(cellData?.data?.id))
    }
  }

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === 'Approved'
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
  }

  if (isLoading) {
    return <SkeletonTableLoading />
  }

  if (data?.data?.data.length === 0) {
    return <EmptyTable title='No Virtual Inspections to be made at this time' />
  }

  return (
    <div className='row position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='jobTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data?.data}
        showBorders={false}
        showColumnLines={false}
        ref={tableRef}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(50vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onCellClick={toVirtualInspection}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Jobs')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Inspections: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>

        <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
        <Column
          dataField='jobStatusTypeName'
          cellRender={displayStatusType}
          caption='Status'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='hostName'
          caption='Client Name'
          minWidth={200}
          alignment='center'
          cssClass='cls'
        />
        <Column
          dataField='hostPropertyCityName'
          caption='City'
          minWidth={150}
          alignment='center'
          cssClass='cls'
        />
        <Column
          dataField='hostPropertyStateName'
          caption='State'
          minWidth={150}
          alignment='center'
          cssClass='cls'
        />
        <Column
          dataField='hostPropertyCountryName'
          caption='Country'
          minWidth={200}
          alignment='center'
          cssClass='cls'
        />

        <Column
          dataField='startedOn'
          caption='Started At'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={250}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('cleaningPeriodStart')} />
        </Column>
        <Column
          dataField='finishedOn'
          caption='Finished At'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={250}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('cleaningPeriodFinish')} />
        </Column>
      </DataGrid>
    </div>
  )
}

export default HasVirtualInspectionTable
