import React, { useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { IHostPropertyInput } from '../../interfaces/Property';
import { useState } from 'react';
import LocationStep from './StepTabs/LocationStep';
import AutomationStep from './StepTabs/AutomationStep';
import PropertyDetailStep from './StepTabs/PropertyDetailStep';
import RoomBedStep from './StepTabs/RoomBedStep';
import { Card, Spinner, Tab, Tabs } from 'react-bootstrap-v5';
import ApiCalls from '../../network/ApiCalls';
import { useSelector } from 'react-redux';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { useDialog } from '../../contexts/DialogContext';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { PropertyFormProvider } from '../../contexts/PropertyFormContext';

type Props = {
    propertyId?: number,
    editModalClose?: () => void
}

type TabNames =
    | "location"
    | "automation"
    | "property-detail"
    | "rooms"

const PropertyDetails: React.FC<Props> = ({ propertyId, editModalClose }) => {
    const history = useHistory();
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { showSuccessDialog, showFailureDialog } = useDialog();
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState<TabNames>("location");
    const [savedValues, setSavedValues] = useState<IHostPropertyInput | null>(null);
    const { data: hostPropertyData, error: hostPropertyError, isLoading: hostPropertyLoading } = useQuery(['Get Host Property By Id', propertyId], () => ApiCalls.getHostPropertyById(propertyId ? propertyId : 0), { refetchOnWindowFocus: false, enabled: propertyId ? true : false });

    const handleChangeHostProperty = (changedValues: IHostPropertyInput) => {
        setSavedValues({ ...savedValues, ...changedValues });
    }

    const handleChangeTab = (key: any) => {
        setSelectedTab(key);
    }

    useEffect(() => {
        if (hostPropertyData) {
            setSavedValues({ ...hostPropertyData?.data?.hostProperty, ...hostPropertyData?.data, hostProperty: undefined, numberOfBathrooms: parseFloat(hostPropertyData?.data?.numberOfBathrooms ? hostPropertyData?.data?.numberOfBathrooms : 0) })
        }
    }, [hostPropertyData])

    if (hostPropertyLoading) {
        return <div className="d-flex justify-content-center flex-column"><Spinner className="align-self-center" animation="border" /></div>
    }

    if (propertyId && !hostPropertyData?.data?.hostProperty) {
        return (
            <div className="d-flex justify-content-center flex-column">
                <strong>Property cannot found.</strong>
                <button className="btn btn-danger mt-6" onClick={editModalClose} >
                    Back to Property List
                </button>
            </div>
        )
    }

    return (
        <>
            <PropertyFormProvider>
                <Card>
                    <Card.Body className='p-0 mt-6'>
                        <Tabs defaultActiveKey="location" id="uncontrolled-tab-example" className="mb-3" onSelect={handleChangeTab} >

                            <Tab eventKey="location" title="Location">
                                <LocationStep editMode={true} location={{
                                    address: hostPropertyData?.data?.hostProperty?.address,
                                    city: hostPropertyData?.data?.hostProperty?.city,
                                    state: hostPropertyData?.data?.hostProperty?.state,
                                    country: hostPropertyData?.data?.hostProperty?.country,
                                    latitude: hostPropertyData?.data?.hostProperty?.latitude,
                                    longitude: hostPropertyData?.data?.hostProperty?.longitude,
                                    suite: hostPropertyData?.data?.hostProperty?.suite,
                                    zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
                                }} pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId} staticMapUrl={hostPropertyData?.data?.hostProperty?.staticMapUrl} handleChangeHostProperty={handleChangeHostProperty} />
                            </Tab>

                            <Tab eventKey="rooms" title="Rooms">
                                <RoomBedStep editMode={true} handleChangeHostProperty={handleChangeHostProperty} hostPropertyBedrooms={hostPropertyData?.data?.hostPropertyBedrooms} numberOfBeds={hostPropertyData?.data?.hostProperty?.numberOfBeds} numberOfBedrooms={hostPropertyData?.data?.hostProperty?.numberOfBedrooms} numberOfBathrooms={parseFloat(hostPropertyData?.data?.hostProperty?.numberOfBathrooms)} pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId} />
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </PropertyFormProvider>
        </>
    )
}

export default PropertyDetails;