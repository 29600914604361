import * as actions from '../actionTypes/changePasswordTypes'

export function openChangePassword(
    changePassFn: (data:any) => Promise<void>,
    bodyText: string,
    title: string
): actions.OpenChangePassword {
    return {
        type: actions.OPEN_CHANGE_PASSWORD,
        changePassFn,
        bodyText,
        title,
    }
}

export function closeChangePassword(): actions.CloseChangePassword {
    return {
        type: actions.CLOSE_CHANGE_PASSWORD,
    }
}
