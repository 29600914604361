import React from 'react';
import { useSelector } from 'react-redux';
import { IJobNote } from '../../../../../interfaces/Job';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import ProfilePhotoTemp from '../../../../../umut-components/Icons/ProfilePhotoTemp';
import { convertUTCDateToLocalDate } from '../../../../../utils/util';

type Props = {
    note: IJobNote,
    noteType: "job" | "service" | "task";
}

const NoteItem: React.FC<Props> = ({ note, noteType }) => {
    const { user: { user } } = useSelector((state: RedusxAppState) => state.user);
    const { jobDetail } = useSelector((state: RedusxAppState) => state.job);
    return (
        <div style={{ borderRadius: "8px" }} className='row p-3 pb-6 mt-3 border w-75'>
            <div className='col-12 d-flex flex-row justify-content-between '>
                <div className='d-flex align-items-center flex-grow-1 '>
                    <ProfilePhotoTemp classNames='me-2' width={45} height={45} borderRadius='50%' profilePhotoPath={user?.profilePhotoPath} firstName={note?.creatorFirstName} lastName={note?.creatorLastName} />
                    <div className="d-flex flex-column mt-2">
                        <strong>{note?.creatorFirstName + ' ' + note?.creatorLastName}</strong>
                        <p className='text-success text-break'>{note.serviceTypeName ? note.serviceTypeName : '--'}</p>
                    </div>
                </div>
                <span className="text-gray-400 w-60px fs-9 mt-2 text-wrap">{convertUTCDateToLocalDate(note?.createdOn, jobDetail?.hostPropertyCityTimeZone)}</span>
            </div>
            <div className='col-12 mt-3'>
                <p className="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start">{note.note}</p>
            </div>
        </div>
    )
}

export default NoteItem;