import React, {Dispatch, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import JobDetails from '../../pages/job-details/JobDetails'
import {useParams} from 'react-router'
import {decryptText} from '../../utils/util'
import {useQuery} from 'react-query'
import {AxiosResponse} from 'axios'
import {IJobDetail} from '../../interfaces/Job'
import ApiCalls from '../../network/ApiCalls'
import SwitchBox from '../../umut-components/Inputs/SwitchBox'
import {TextInput} from '../../umut-components/Inputs/TextInput'
import {Button} from '@material-ui/core'
import {useDialog} from '../../contexts/DialogContext'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  refetch: () => void
  redirect: () => void
}

const DeclineScheduleDialog: React.FC<Props> = ({modalOpen, handleClose, refetch, redirect}) => {
  const {jobDetail} = useSelector((state: RedusxAppState) => state.job)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessDialog}: any = useDialog()
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [imagePreview, setImagePreview] = useState<any>(null)
  const [technicalDiff, setTechnicalDiff] = useState(false)
  const [note, setNote] = useState('')

  const [loading, setLoading] = useState<boolean>(false)

  let {jobId}: any = useParams()
  jobId = decryptText(jobId)

  const declineRequest = async () => {
    if (jobDetail) {
      try {
        setLoading(true)
        var request = {
          jobId: +jobId,
          vendorId: user.userSection.vendorId,
          note: note,
          cancelledBy: user.user.id,
        }

        await ApiCalls.declineSchedule(request)
        await refetch()
        showSuccessDialog('Job schedule declined successfully')
        setLoading(false)
        handleClose()
        setTimeout(() => {
          redirect()
        }, 1000)
      } catch (err: any) {
        setLoading(false)
        setErrorMessage(err?.response?.data?.errors?.[0].message ?? err?.response?.data?.message)
      }
    }
  }

  return (
    <>
      <Modal show={modalOpen} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Decline Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-danger'>
          Are you sure you want to decline the schedule for this job?
        </Modal.Body>
        <div className='d-flex justify-content-center flex-column p-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            Note
            {
              <span style={{color: 'red', fontSize: 10, marginLeft: 4}}>
                (Please briefly explain your reason for canceling.)
              </span>
            }
          </label>
          <TextInput onValueChange={(text: string) => setNote(text)} placeholder='Note' />
        </div>
        <Modal.Footer className='d-flex flex-column align-items-end'>
          <div>
            <span className='text-danger'>{errorMessage}</span>
          </div>
          <div>
            <button className='btn btn-sm btn-danger' onClick={handleClose}>
              Close
            </button>
            <button
              disabled={loading}
              className='btn btn-success btn-sm ms-2'
              onClick={declineRequest}
            >
              Yes, decline {loading && <Spinner animation='border' size='sm' />}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default DeclineScheduleDialog
