import React, {Dispatch} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ChecklistAction} from '../../../../redux/actionTypes/checklistTypes'
import * as checklistActionCreator from '../../../../redux/actionCreators/checklistActionCreators'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import ChecklistForm from '../../../../umut-components/Forms/ChecklistForms/ChecklistForm'
import ChecklistEditForm from '../../../../umut-components/Forms/ChecklistForms/ChecklistEditForm'
import {decryptText} from '../../../../utils/util'

type Props = {
  refetchChecklists: () => void
}

const ChecklistEditDialog: React.FC<Props> = ({refetchChecklists}) => {
  let {hostPropertyId}: any = useParams()
  hostPropertyId = decryptText(hostPropertyId)
  const {checklistEditDialog} = useSelector((state: RedusxAppState) => state.checklist)
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()

  const handleClose = () => {
    dispatch(checklistActionCreator.closeChecklistEditDialog())
  }

  const editSuccess = () => {
    dispatch(checklistActionCreator.closeChecklistEditDialog())
    refetchChecklists()
  }

  return (
    <Modal show={checklistEditDialog.dialogOpen} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>Edit Checklist</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        <ChecklistEditForm hostPropertyId={parseInt(hostPropertyId)} editSuccess={editSuccess} />
      </Modal.Body>
    </Modal>
  )
}

export default ChecklistEditDialog
