import {
    Column,
    DataGrid,
    Export,
    HeaderFilter, Paging,
    Scrolling,
    SearchPanel
} from 'devextreme-react/data-grid'
import { FC, useRef } from 'react'
import { useHistory } from 'react-router'
import { IPayments } from '../../interfaces/Payment'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import { encryptText, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../utils/util'

type Props = {
    data: IPayments[] | undefined,
    refetch: () => void
}
const PaymentTable: FC<Props> = ({ data, refetch }) => {
    const history = useHistory();
    const tableRef = useRef<DataGrid>(null)


    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue?.scheduledStart)
    }

    const calculateAmount = (cellValue: any) => {
        return '$' + cellValue.totalAmount;
    }

    const calculateJobLink = (cellValue: any) => {
        return <button className='btn btn-link btn-sm text-primary' onClick={() => {
            history.push('/job-details/' + encryptText(cellValue?.data?.job?.id));
        }}>Show Job</button>
    }

    if (data?.length === 0) {
        return <EmptyTable title='No Billings' />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>

            <DataGrid
                id='jobTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Payment")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />

                <Column dataField='id' caption="Id" minWidth={150} dataType="string" cssClass='cls' />
                <Column
                    dataField='vendor.name'
                    alignment='center'
                    caption='Vendor'
                    minWidth={150}
                    cssClass='cls'
                />
                <Column
                    dataField='host.name'
                    caption='Host'
                    minWidth={150}
                    cssClass='cls'
                    alignment='center'
                />
                <Column
                    dataField='totalAmount'
                    caption="amount(Net)"
                    calculateCellValue={calculateAmount}
                    minWidth={150}
                    cssClass='cls'
                    alignment='center'
                />
                <Column dataField='job.startedOn' caption="Job Start" calculateCellValue={calculateScheduledStart} minWidth={100} cssClass='cls' />
                <Column caption="Job" cellRender={calculateJobLink} minWidth={200} dataType="string" cssClass='cls' />
            </DataGrid>
        </div>
    )
}

export default PaymentTable
