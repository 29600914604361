import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IJobReview } from '../../../../../interfaces/Job';
import ApiCalls from '../../../../../network/ApiCalls';
import { JobAction } from '../../../../../redux/actionTypes/jobTypes';
import * as jobActionCreator from '../../../../../redux/actionCreators/jobActionCreators';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import ReviewItem from './ReviewItem';
import { MasterDetailTemplateData } from 'devextreme/ui/data_grid';
import { decryptText, onExporting } from '../../../../../utils/util';
import ProfilePhotoTemp from '../../../../../umut-components/Icons/ProfilePhotoTemp';
import { AngryFace, LoveFace, NeutralFace, SadFace, SmileFace } from '../../../../../umut-components/Icons/Face';
import EmptyData from '../../../../../umut-components/Icons/EmptyData';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import { JobStatusObj } from '../../../../../enums/JobEnum';

type Props = {
    jobEventsActive: boolean;
    data: MasterDetailTemplateData;
}

const ReviewTab: React.FC<Props> = ({ jobEventsActive, data }) => {
    const { jobDetail } = useSelector((state: RedusxAppState) => state.job);
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const tableRef = useRef<DataGrid>(null)
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { data: reviewData, isLoading, isFetching, error, refetch } = useQuery(['Get Job Review By Id', jobId, data.data.id], () => ApiCalls.getJobReview(jobId, data.data.id), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId && data?.data?.id ? true : false });

    const addButtonClicked = () => {
        dispatch(jobActionCreator.openReviewDialog(refetch, data?.data?.id));
    }

    const renderStaffProfilePhoto = (cellValue: any) => {
        return (
            <ProfilePhotoTemp profilePhotoPath={cellValue?.data?.staff?.profilePhotoPath} firstName={cellValue?.data?.staff?.firstName} lastName={cellValue?.data?.staff?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }

    const renderReviewerProfilePhoto = (cellValue: any) => {
        return (
            <ProfilePhotoTemp profilePhotoPath={cellValue?.data?.reviewer?.profilePhotoPath} firstName={cellValue?.data?.reviewer?.firstName} lastName={cellValue?.data?.reviewer?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }

    const calculateStaffFullName = (cellValue: any) => {
        return cellValue?.staff?.firstName + " " + cellValue?.staff?.lastName;
    }

    const calculateReviewerFullName = (cellValue: any) => {
        return cellValue?.reviewer?.firstName + " " + cellValue?.reviewer?.lastName;
    }

    const displayRateIcon = (cellValue: any) => {
        switch (cellValue?.data?.rate) {
            case "Terrible":
                return <AngryFace width="20" height="20" />
            case "Poor":
                return <SadFace width="20" height="20" />
            case "Ok":
                return <NeutralFace width="20" height="20" />
            case "Good":
                return <SmileFace width="20" height="20" />
            case "Excellent":
                return <LoveFace width="20" height="20" />
            default:
                return cellValue?.data?.rate;
        }
    }

    if (isLoading) {
        return <SkeletonTableLoading />
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <div className="position-relative">
            <strong>{data?.data?.serviceTypeName} Reviews</strong>
            {
                reviewData?.data?.data?.length === 0 ?
                    null :
                    <DataGrid
                        id="staffTable"
                        keyExpr="id"
                        ref={tableRef}
                        onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                        dataSource={reviewData?.data?.data}
                        showBorders={false}
                        showColumnLines={false}
                        showRowLines={true}
                        hoverStateEnabled={true}
                        height={'calc(25vh)'}
                        allowColumnResizing={true}
                        selection={{ mode: 'single' }}
                        onExporting={(e) => onExporting(e, "Job Reviews")}
                    >
                        <Export enabled />
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true} />
                        <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                        <Paging defaultPageSize={50} />
                        <Summary>
                            <TotalItem cssClass='absolute-right' displayFormat="Total Reviews: {0}" column='staff.firstName' summaryType='count' />
                        </Summary>

                        <Column width={100} cellRender={renderStaffProfilePhoto} cssClass='cls' />
                        <Column dataField="staff.firstName" calculateCellValue={calculateStaffFullName} caption='Staff' minWidth={180} dataType='string' cssClass='cls' />
                        <Column width={100} cellRender={renderReviewerProfilePhoto} cssClass='cls' />
                        <Column dataField="reviewer.firstName" calculateCellValue={calculateReviewerFullName} caption='Reviewer' minWidth={180} dataType='string' cssClass='cls' />
                        <Column dataField="rate" caption='Rate' cellRender={displayRateIcon} minWidth={180} dataType='string' cssClass='cls' />
                        <Column dataField="reviewNote" caption='Note' dataType='string' width={250} cssClass='cls' />
                    </DataGrid>
                    
            }
             
            {
                jobDetail?.jobStatusTypeId === JobStatusObj["Completed"] ?
                    <div className={`w-100 d-flex align-items-center justify-content-${reviewData?.data?.data?.length === 0 ? 'start' : 'end'} mt-3`}>
   {reviewData?.data?.data?.length < 1 && <strong className='text-muted'>No Reviews Found</strong>}                    </div> :
                    <div className={`w-100 d-flex align-items-center justify-content-start mt-3`}>
                        <strong className='text-muted'>Job is not completed</strong>
                    </div>
            }
        </div>
    )
}

export default ReviewTab;