  import {
    Column,
    DataGrid,
    HeaderFilter, Scrolling,
    SearchPanel,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import { Dispatch, FC, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ApiCalls from '../../network/ApiCalls';
import * as deleteDialogActionCreators from '../../redux/actionCreators/deleteDialogActionCreators';
import { DeleteActions } from '../../redux/actionTypes/deleteDialogTypes';
import DeleteButton from '../../umut-components/Buttons/DeleteButton';
import EmptyTable from '../../umut-components/Icons/EmptyTable';

type Props = {
    data: any[]
    refetch: () => void,
    vendorId: number
}
const ServiceCitiesTable: FC<Props> = ({ data, vendorId, refetch }) => {

    const dispatch = useDispatch<Dispatch<DeleteActions>>();
    const tableRef = useRef<DataGrid>(null)
    const displayDeleteButton = (cellData: any) => {
        return (
            <DeleteButton iconWidth="20" iconHeight="20" deleteFn={() => {
                dispatch(deleteDialogActionCreators.openDeleteDialog(
                    async () => {
                        try {
                            await ApiCalls.deleteVendorCityService(cellData?.data?.id)
                            dispatch(deleteDialogActionCreators.closeDeleteDialog());
                            refetch();
                        }
                        catch (err: any) {
                            console.log(err)
                        }
                    },
                    `Are you sure you want to delete?`,
                    'Delete Service City?'
                ))
            }} />
        )
    }
    if (data?.length === 0) {
        return <EmptyTable title="No Service Cities" />
    }
    return (
        <div className="position-relative">
            <DataGrid
                id='ServiceCitiesTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                hoverStateEnabled={true}
                height={'calc(50vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
            >
                <HeaderFilter visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <SearchPanel visible={true} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Cities: {0}" column='cityName' summaryType='count' />
                </Summary>             
                   <Column dataField='cityName' minWidth={150} cssClass='cls' caption="CITY" />
                <Column
                    dataField='stateName'
                    alignment='center'
                    caption='STATE'
                    minWidth={150}
                    cssClass='cls'
                />
                <Column dataField='zipCode' alignment='center' caption='ZIPCODE' minWidth={80} cssClass='cls' />

                <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />
            </DataGrid>
        </div>
    )
}

export default ServiceCitiesTable
