import React, {Dispatch, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {IServiceRateQuoteRequests} from '../../interfaces/Offered'
import SelectInput from '../../umut-components/Inputs/SelectInput'
import {NumberInput} from '../../umut-components/Inputs/NumberInput'
import {TextInput} from '../../umut-components/Inputs/TextInput'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ApiCalls from '../../network/ApiCalls'
import {useDialog} from '../../contexts/DialogContext'

type Props = {
  data: IServiceRateQuoteRequests | null
  editable: boolean
  visible: boolean
  handleClose: () => void
}

const OfferedRateEditDialog: React.FC<Props> = ({data, editable, visible, handleClose}) => {
  const [offerData, setOfferData] = useState(data)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [rateTypeOptions, setRateTypeOptions] = useState<any>([])
  const [selectedRateType, setSelectedRateType] = useState(2)
  const {showFailureDialog, showSuccessDialog} = useDialog()

  const {data: rateTypes, isLoading} = useQuery(
    ['Get Rate Types'],
    () => ApiCalls.getAllRateTypes(),
    {
      cacheTime: 50000,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (data != offerData) {
      if (data != null) {
        data.rateTypeId = 2
      }
      setOfferData(data)
    }
  }, [data])

  useEffect(() => {
    if (rateTypes) {
      const opt = []
      for (const rt of rateTypes.data) {
        opt.push({label: rt.name, value: rt.id})
      }

      setRateTypeOptions(opt)
    }
  }, [rateTypes])

  const changeServiceTypeId = () => {}

  const changeRateType = (e: any) => {
    if (editable) {
      var dt = offerData
      if (offerData) {
        offerData.rateTypeId = e
      }

      setOfferData(dt)
    }
  }

  const changeDuration = (e: any) => {
    if (editable) {
      var dt = offerData
      if (offerData) {
        offerData.duration = e
      }

      setOfferData(dt)
    }
  }

  const changeOfferedRate = (e: any) => {
    if (editable) {
      var dt = offerData
      if (offerData) {
        offerData.offeredRate = e
      }

      setOfferData(dt)
    }
  }

  const handleSubmit = async () => {
    if (editable) {
      if (offerData?.duration == 0) {
        setErrorMessage('Offer duration must be greater than 15 min.')
      } else if (offerData?.offeredRate == 0) {
        setErrorMessage('Offer rate must be greater than $1.')
      } else {
        try {
          const data = offerData
          await ApiCalls.sendServiceRateQuoteRequestOffer(data)
          showSuccessDialog(
            'Your offer has been successfully submitted. It will be evaluated by the administration as soon as possible.'
          )
          handleClose()
        } catch (error: any) {
          showFailureDialog(error?.response?.data?.message)
        }
      }
    }
  }

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>{offerData?.address}</Modal.Title>
      </ModalHeader>
      {offerData && (
        <Modal.Body>
          {offerData?.numberOfBedrooms && (
            <h6 className='text-dark'>{'Number of Bedrooms : ' + offerData?.numberOfBedrooms}</h6>
          )}
          {offerData?.numberOfBathrooms && (
            <h6 className='text-dark'>{'Number of Bathrooms : ' + offerData?.numberOfBathrooms}</h6>
          )}
          {offerData?.propertySize && (
            <h6 className='text-dark'>{'Property Size : ' + offerData?.propertySize + ' sqft.'}</h6>
          )}
          <form className='form w-100'>
            <div className='row d-flex justify-content-center'>
              <div className='col-lg-12'>
                <div className='row mt-6'>
                  <div className='col-12 d-flex flex-column'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Service Type</label>
                    <TextInput
                      defaultValue={offerData?.serviceType}
                      onValueChange={changeServiceTypeId}
                      disabled={true}
                    />
                  </div>
                  <div className='col-12 d-flex flex-column mt-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Rate Type</label>
                    <SelectInput
                      options={rateTypeOptions}
                      defaultValue={selectedRateType}
                      onValueChanged={changeRateType}
                      disabled={!editable}
                    />
                  </div>
                  <div className='col-12 d-flex flex-column mt-6'>
                    <label className='form-label text-dark'>Duration (mins.)</label>
                    <div className='d-flex align-items-center'>
                      <img
                        alt='clock'
                        src={toAbsoluteUrl('/media/icons/duotone/Home/Clock.svg')}
                        className='me-3'
                        style={{width: 25, height: 25}}
                      />
                      <NumberInput
                        min={15}
                        max={300}
                        defaultValue={offerData?.duration}
                        onValueChange={(e) => changeDuration(e)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                  <div className='col-12 d-flex flex-column mt-6'>
                    <label className='form-label text-dark'>Service Rate</label>
                    <div className='d-flex align-items-center'>
                      <img
                        alt='dollar'
                        src={toAbsoluteUrl('/media/icons/duotone/Shopping/Dollar.svg')}
                        className='me-3'
                        style={{width: 25, height: 25}}
                      />
                      <NumberInput
                        min={1}
                        max={3000}
                        defaultValue={offerData?.offeredRate}
                        onValueChange={(e) => changeOfferedRate(e)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </div>

                <strong className='w-100 text-danger'></strong>
                {editable && (
                  <div className='col mt-8 '>
                    <div className='d-flex col gap-3 justify-content-end'>
                      <button
                        type='button'
                        className='btn btn-success '
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        Save Offer {loading ? <Spinner animation='border' size='sm' /> : null}
                      </button>
                    </div>
                    <div className='col text-end'>
                      <strong style={{color: 'red'}}>{errorMessage}</strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      )}
    </Modal>
  )
}

export default OfferedRateEditDialog
