import { IScheduleStaff } from '../../interfaces/Staff';
import * as actions from '../actionTypes/assignTypes';

export function openAddAssignDialog(staffDetail: IScheduleStaff): actions.OpenAssignDialog {
      return {
            type: actions.OPEN_ASSIGN_DIALOG,
            staffDetail: staffDetail
      }
}

export function closeAddAssignDialog(): actions.CloseAssignDialog {
      return {
            type: actions.CLOSE_ASSIGN_DIALOG,
      }
}
export function scheduleStartChange(scheduleStartTime: any): actions.ScheduleStartTime {
      return {
            type: actions.SCHEDULE_START_TIME,
            scheduleStartTime: scheduleStartTime
      }
}