import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
} from 'devextreme-react/data-grid'
import * as deleteDialogActionCreators from '../../../redux/actionCreators/deleteDialogActionCreators'
import * as vendorDetailActionCreators from '../../../redux/actionCreators/vendorDetailActionCreators'
import { Dispatch, FC, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { IVendorReference } from '../../../interfaces/Reference'
import ApiCalls from '../../../network/ApiCalls'
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import UpdateButton from '../../../umut-components/Buttons/UpdateButton'
import { onExporting } from '../../../utils/util'
import { VendorDetailActions } from '../../../redux/actionTypes/vendorDetailActionTypes'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'

type Props = {
  data: IVendorReference[]
  refetchReferences: () => void
}
const ReferenceTable: FC<Props> = ({ data, refetchReferences }) => {
  const deleteDialogDispatch = useDispatch<Dispatch<DeleteActions>>()
  const showViewDispatch = useDispatch<Dispatch<VendorDetailActions>>()
  const tableRef = useRef<DataGrid>(null)

  const displayEditButton = (cellData: any) => {
    return (
      <UpdateButton
        iconWidth='20'
        iconHeight='20'
        updateFn={() =>
          showViewDispatch(vendorDetailActionCreators.openReferenceView(cellData?.data))
        }
      />
    )
  }

  const displayDeleteButton = (cellData: any) => {
    return (
      <DeleteButton
        iconWidth='20'
        iconHeight='20'
        deleteFn={() => {
          deleteDialogDispatch(
            deleteDialogActionCreators.openDeleteDialog(
              async () => {
                await ApiCalls.deleteVendorReference(cellData?.data?.id)
                refetchReferences()
              },
              `Are you sure you want to delete ${cellData?.data?.name} ?`,
              'Delete Reference'
            )
          )
        }}
      />
    )
  }

  if (data?.length === 0) {
    return <EmptyTable title="No Vendor Reference" />
  }
  return (
    <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
         Clear Filter
        </span>
      </div>

      <DataGrid
        id='referenceTable'
        keyExpr='name'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        ref={tableRef}
        height={'calc(60vh - 60px)'}
        allowColumnResizing={true}
        selection={{ mode: 'single' }}
        onExporting={(e) => onExporting(e, "Vendor References")}
      >
        <Export enabled={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' />
        <Paging enabled={false} />

        <Column dataField='name' minWidth={350} cssClass='cls' />
        <Column
          dataField='email'
          alignment='center'
          caption='Email'
          minWidth={150}
          cssClass='cls'
        />
        <Column dataField='phone' alignment='center' caption='Phone' minWidth={80} cssClass='cls' />

        <Column width={110} alignment='center' cellRender={displayEditButton} cssClass='cls' />
        <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />
      </DataGrid>
    </div>
  )
}

export default ReferenceTable
