import React, { Dispatch, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDialog } from '../../contexts/DialogContext';
import { AccountStatusTypes } from '../../enums/AccountEnum';
import ApiCalls from '../../network/ApiCalls';
import { closeStaffStatusDialog } from '../../redux/actionCreators/staffActionCreators';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';

type Props = {
}

const AccountDialog: React.FC<Props> = () => {
    const { staffStatusDialog } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const [loading, setLoading] = useState(false);
    const { showFailureDialog } = useDialog();

    const handleClose = () => {
        dispatch(closeStaffStatusDialog());
    }

    const handleChangeStatus = async () => {
        try {
            setLoading(true);
            await ApiCalls.updateVendorStaffStatus({ userId: staffStatusDialog.userId, accountStatusId: staffStatusDialog.accountStatusId }, staffStatusDialog.userId);
            setLoading(false);
            staffStatusDialog.refetch();
            handleClose();
        }
        catch (err: any) {
            setLoading(false);
            handleClose();
            showFailureDialog(err?.response?.data?.message);
        }
    }

    return (
        <Modal show={staffStatusDialog.modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>{staffStatusDialog.accountStatusId === AccountStatusTypes.ACTIVE ? 'Activate' : 'Deactivate'} Staff</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <span>
                    Do you want to change status to "{staffStatusDialog.accountStatusId === AccountStatusTypes.ACTIVE ? 'Activate' : 'Deactivate'}"?
                </span>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-end'>
                {loading ?
                    <Spinner animation='border' size='sm' />
                    :
                    <div className='d-flex flex-row'>
                        <button className='btn btn-link btn-sm text-danger' onClick={handleClose}>Cancel</button>
                        <button className='btn btn-info btn-sm ms-6' onClick={handleChangeStatus}>{staffStatusDialog.accountStatusId === AccountStatusTypes.ACTIVE ? 'Activate' : 'Deactivate'} Staff</button>
                    </div>}
            </Modal.Footer>
        </Modal>
    )
}

export default AccountDialog;