import {FormControlLabel, Radio, RadioGroup, TextField} from '@material-ui/core'
import React, {Dispatch, useEffect} from 'react'
import {useState} from 'react'
import {
  IHostPropertyAccess,
  IHostPropertyInput,
  IHostPropertyOther,
  IHostPropertyStorage,
} from '../../../interfaces/Property'
import {TextInput} from '../../Inputs/TextInput'
import SelectBox from '../../Inputs/SelectBox'
import {usePropertyForm} from '../../../contexts/PropertyFormContext'
import DeleteButton from '../../Buttons/DeleteButton'
import SwitchBox from '../../Inputs/SwitchBox'
import ArrowButton from '../../Buttons/ArrowButton'
import SelectInput from '../../Inputs/SelectInput'
import {NumberInput} from '../../Inputs/NumberInput'

interface IValues {
  hasStorage?: boolean
  hasParking?: boolean
  hasLaundry?: boolean
  hasDryer?: boolean
  cleaningProviderId?: number
  sameDayBooking?: boolean
  parkingDescription?: string
  numberOfCleaner?: number
  missingField?: boolean
  isMarketplace?: boolean
  hasProQuoteRequest?: boolean
  hasPlusQuoteRequest?: boolean
}

type Props = {
  hostPropertyAccess?: IHostPropertyAccess
  hostPropertyStorage?: IHostPropertyStorage[]
  hostPropertyOthers?: IHostPropertyOther[]
  values?: IValues
  handleChangeHostProperty: (changedValues: IHostPropertyInput) => void
  currentStep?: number
  totalSteps?: number
  nextStep?: () => void
  previousStep?: () => void
  goToStep?: (step: number) => void
  hostProperty?: IHostPropertyInput
  editMode?: boolean
  missingField?: boolean
  propertyType?: any
}

const booleanOptions = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
]

const PropertyDetailStep: React.FC<Props> = ({
  missingField,
  propertyType,
  hostPropertyAccess,
  editMode,
  values,
  hostPropertyStorage,
  hostPropertyOthers,
  handleChangeHostProperty,
  nextStep,
  previousStep,
}) => {
  const {accessTypes, cleanerProviders, otherQuestions} = usePropertyForm()
  const [selectedValues, setSelectedValues] = useState<IValues>(
    values ? values : {hasDryer: false, hasParking: false, hasStorage: false, hasLaundry: false}
  )
  const [selectedAccess, setSelectedAccess] = useState<IHostPropertyAccess>(
    hostPropertyAccess ? hostPropertyAccess : {}
  )
  const [selectedStorages, setSelectedStorages] = useState<IHostPropertyStorage[]>(
    hostPropertyStorage ? (hostPropertyStorage.length > 0 ? hostPropertyStorage : [{}]) : [{}]
  )
  const storageTextChange = (value: any) => {
    setSelectedStorages([{storageDescription: value}])
  }
  const [selectedOthers, setSelectedOthers] = useState<IHostPropertyOther[]>(
    hostPropertyOthers ? hostPropertyOthers : []
  )
  const [selectedProvider, setSelectedProvider] = useState(
    values?.isMarketplace ? 4 : values?.cleaningProviderId
  )

  const onProviderChange = (value: number) => {
    setSelectedValues({...selectedValues, cleaningProviderId: value})
  }

  const a = otherQuestions?.filter((question: any) => {
    hostPropertyOthers?.some(
      (d) => d.hostPropertyOtherQuestionId == question.hostPropertyOtherQuestionId
    )
  })

  const numberOfCleanersChange = (value: number) => {
    setSelectedValues({...selectedValues, numberOfCleaner: value})
  }

  const unitAccessIdChange = (value: number) => {
    setSelectedAccess({...selectedAccess, unitAccessTypeId: value})
  }

  const buildingAccessIdChange = (value: number) => {
    setSelectedAccess({...selectedAccess, buildingAccessTypeId: value})
  }

  const unitAccessTextChange = (value: string) => {
    setSelectedAccess({...selectedAccess, unitAccessValue: value})
  }

  const buildingAccessTextChange = (value: string) => {
    setSelectedAccess({...selectedAccess, buildingAccessValue: value})
  }

  const changeHostPropertyDescription = (value: string, searchIndex: number) => {
    let newArray = selectedStorages.map((storage, index) =>
      index === searchIndex ? {...storage, storageDescription: value} : storage
    )
    setSelectedStorages([{storageDescription: value}])
  }

  const addNewStorageDescription = () => {
    setSelectedStorages([...selectedStorages, {}])
  }
  const [otherQuestionsFiltered, setOtherQuestionFiltered] = useState<IHostPropertyStorage[]>()

  const deleteStorageDescription = (deleteIndex: number) => {
    let newArray: any = selectedStorages
      .map((storage, index) => {
        if (storage.id && index === deleteIndex) {
          return {...storage, isDeleted: true}
        }
        if (index === deleteIndex) {
          return null
        }
        return storage
      })
      .filter((storage) => storage !== null)
    setSelectedStorages(newArray?.length > 0 ? newArray : [{storageDescription: ''}])
  }

  const changeOtherQuestionAnswer = (id: number, value: string) => {
    let newValue: IHostPropertyOther = {
      hostPropertyOtherQuestionId: id,
      value,
    }
    setSelectedOthers((prevState: IHostPropertyOther[]) =>
      prevState.map((otherQuestionAnswer) => {
        if (
          otherQuestionAnswer.hostPropertyOtherQuestionId === newValue.hostPropertyOtherQuestionId
        ) {
          return newValue
        }
        return otherQuestionAnswer
      })
    )
  }

  useEffect(() => {
    const filteredQuestions = otherQuestions?.filter((question: any) =>
      hostPropertyOthers?.some((d) => d.hostPropertyOtherQuestionId == question.id)
    )
    setOtherQuestionFiltered(filteredQuestions)
  }, [])

  useEffect(() => {
    if (selectedValues.isMarketplace) {
      setSelectedProvider(4)
    } else {
      setSelectedProvider(selectedValues.cleaningProviderId || 1)
    }
  }, [selectedValues])

  useEffect(() => {
    handleChangeHostProperty({
      ...selectedValues,
      hostPropertyAccess: selectedAccess,
      hostPropertyStorages: selectedStorages,
      hostPropertyOthers: selectedOthers,
    })
  }, [selectedValues, selectedAccess, selectedStorages, selectedOthers])

  return (
    <form className='form w-100 mt-5'>
      <div className='row mb-6'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark w-100'>
            The cleaning of this property is managed by: {<span style={{color: 'red'}}>*</span>}
          </label>
          <small className='text-muted'>
            Selecting My Team indicates that you'll use your own cleaning team to complete your
            turnovers. After selecting My Team, you'll then add your cleaners (Settings - Add A
            Cleaner), and they'll be ready to receive the cleaning schedule. If you're in need of a
            cleaning team, selecting Turnify Pro will prompt our Onboarding Experts to assign a
            member of our team to clean your listing(s), automatically, after every checkout.
          </small>
          <SelectInput
            options={cleanerProviders}
            classNames='mt-3'
            onValueChanged={onProviderChange}
            defaultValue={selectedProvider}
          />
          {missingField && !selectedValues.cleaningProviderId && (
            <strong className='text-danger'>Missing Field</strong>
          )}
        </div>
      </div>

      {/*<div className='row mb-6'>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>Number of Cleaner</label>
                <small className='text-muted col-lg-12 mb-3'>Enter the number of cleaners you require per cleaning.</small>
                <div className='col-lg-12'>
                    <NumberInput min={1} max={1000} onValueChange={numberOfCleanersChange} defaultValue={selectedValues?.numberOfCleaner} />
                    {(missingField && !selectedValues.numberOfCleaner) && <strong className='text-danger'>Missing Field</strong>}
                </div>
            </div>*/}

      <div className='row mb-6'>
        <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
          How do your cleaners to access the unit?{' '}
          {selectedProvider === 2 && <span className='text-danger'>*</span>}
        </label>
        <small className='text-muted col-lg-12 mb-3'>
          This information will be kept strictly confidential and will not be shared with anyone
          except the assigned cleaner(s). The access information will only be made available to the
          cleaner on the day of cleaning to ensure the highest level of security.
        </small>
        <div className='col-lg-3'>
          <SelectInput
            options={accessTypes}
            onValueChanged={unitAccessIdChange}
            defaultValue={selectedAccess?.unitAccessTypeId}
          />
          {missingField &&
            (selectedAccess.unitAccessTypeId == 0 || selectedAccess.unitAccessTypeId == null) &&
            (selectedValues.cleaningProviderId === 2 || selectedAccess.unitAccessValue != null) && (
              <strong className='text-danger'>Missing Field</strong>
            )}
        </div>
        <div className='col-lg-9'>
          <TextInput
            onValueChange={unitAccessTextChange}
            defaultValue={selectedAccess.unitAccessValue}
          />
          {missingField &&
            !selectedAccess.unitAccessValue &&
            (selectedValues.cleaningProviderId === 2 || selectedAccess.unitAccessTypeId != 0) && (
              <strong className='text-danger'>Missing Field</strong>
            )}
        </div>
      </div>

      <div className='row mb-6'>
        <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
          How do your cleaners want access the building?{' '}
          {selectedValues.cleaningProviderId === 2 && <span className='text-danger'>*</span>}
        </label>
        <small className='text-muted col-lg-12 mb-3'>
          This information will be kept strictly confidential and will not be shared with anyone
          except the assigned cleaner(s). The access information will only be made available to the
          cleaner on the day of cleaning to ensure the highest level of security.
        </small>
        <div className='col-lg-3'>
          <SelectInput
            options={accessTypes}
            onValueChanged={buildingAccessIdChange}
            defaultValue={selectedAccess?.buildingAccessTypeId}
          />
          {missingField &&
            (selectedAccess.buildingAccessTypeId == 0 ||
              selectedAccess.buildingAccessTypeId == null) &&
            (selectedValues.cleaningProviderId === 2 ||
              selectedAccess.buildingAccessValue != null) && (
              <strong className='text-danger'>Missing Field</strong>
            )}
        </div>
        <div className='col-lg-9'>
          <TextInput
            onValueChange={buildingAccessTextChange}
            defaultValue={selectedAccess.buildingAccessValue}
          />
          {missingField &&
            !selectedAccess.buildingAccessValue &&
            (selectedValues.cleaningProviderId === 2 ||
              selectedAccess.buildingAccessTypeId != 0) && (
              <strong className='text-danger'>Missing Field</strong>
            )}
        </div>
      </div>

      <div className='row mb-6'>
        <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
          Do you have storage?
        </label>
        {selectedValues.hasStorage && (
          <small className='text-muted mb-3'>
            Please give more information about the location and access details of your storage for
            the staff.
          </small>
        )}
        {missingField &&
        selectedValues.hasStorage &&
        (selectedStorages.length === 0 ||
          !selectedStorages?.[0]?.storageDescription ||
          selectedStorages?.[0]?.storageDescription === '') ? (
          <strong className='text-danger'>Provide at least 1 storage description.</strong>
        ) : null}
        <div className='col-lg-3'>
          <SelectInput
            options={booleanOptions}
            onValueChanged={(value) =>
              setSelectedValues({...selectedValues, hasStorage: value === 1 ? true : false})
            }
            defaultValue={selectedValues.hasStorage ? 1 : 2}
          />
        </div>
        <div className='col-lg-9'>
          {selectedValues.hasStorage ? (
            <TextInput
              onValueChange={storageTextChange}
              defaultValue={selectedStorages[0]?.storageDescription}
            />
          ) : null}
        </div>
      </div>

      <div className='row mb-6'>
        <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
          Do you have parking?
        </label>
        {selectedValues.hasParking && (
          <small className='text-muted mb-3'>Please give more information about the parking</small>
        )}
        <div className='col-lg-3'>
          <SelectInput
            options={booleanOptions}
            onValueChanged={(value) =>
              setSelectedValues({...selectedValues, hasParking: value === 1 ? true : false})
            }
            defaultValue={selectedValues.hasParking ? 1 : 2}
          />
        </div>
        <div className='col-lg-9'>
          {selectedValues.hasParking && (
            <>
              <TextInput
                onValueChange={(text) =>
                  setSelectedValues({...selectedValues, parkingDescription: text})
                }
                defaultValue={selectedValues.parkingDescription}
              />
              {missingField &&
                selectedValues.hasParking &&
                (!selectedValues.parkingDescription ||
                  selectedValues.parkingDescription === '') && (
                  <strong className='text-danger'>Missing Field</strong>
                )}
            </>
          )}
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-lg-6'>
          <div className='col-lg-12'>
            <SwitchBox
              text='Do you have a laundry in the unit?'
              onCheckboxChange={(check) =>
                setSelectedValues({...selectedValues, hasLaundry: check})
              }
              defaultChecked={selectedValues.hasLaundry ? selectedValues.hasLaundry : false}
            />
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        {selectedValues.hasLaundry && (
          <div className='col-lg-6'>
            <div className='col-lg-12'>
              <SwitchBox
                text='Do you have a dryer?'
                onCheckboxChange={(check) =>
                  setSelectedValues({...selectedValues, hasDryer: check})
                }
                defaultChecked={selectedValues.hasDryer ? selectedValues.hasDryer : false}
              />
            </div>
          </div>
        )}
      </div>

      <div className='row mb-6'>
        {otherQuestionsFiltered &&
          otherQuestionsFiltered?.length > 0 &&
          otherQuestionsFiltered?.map((question: any) => {
            return (
              <div>
                <label className='form-label fs-6 fw-bolder text-dark col-lg-12'>
                  {question?.name}
                </label>
                <div className='col-lg-12'>
                  <input
                    className='form-control mb-6'
                    type='text'
                    onChange={(e) => {
                      changeOtherQuestionAnswer(question?.id, e.target.value)
                    }}
                  />
                </div>
              </div>
            )
          })}
      </div>
      {editMode ? null : (
        <div className='d-flex justify-content-between flex-row w-100 mt-6'>
          <ArrowButton type='back' func={previousStep} />
          <ArrowButton type='forward' func={nextStep} />
        </div>
      )}
    </form>
  )
}

export default PropertyDetailStep
