import React, {useState, useEffect} from 'react'

const useWindowSize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768)
  }

  useEffect(() => {
    // Set initial screen size
    setIsMobile(window.innerWidth <= 768)

    // Event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

export default useWindowSize
