import {IVendorReference} from '../../interfaces/Reference'

export const REFERENCE_OPEN_VIEW = 'REFERENCE_OPEN_VIEW'
export interface OpenReferenceViewAction {
  type: typeof REFERENCE_OPEN_VIEW
  vendor: IVendorReference | null
}

export const REFERENCE_CLOSE_VIEW = 'REFERENCE_CLOSE_VIEW'
export interface CloseReferenceViewAction {
  type: typeof REFERENCE_CLOSE_VIEW
}
export const DOCUMENT_OPEN_VIEW = 'DOCUMENT_OPEN_VIEW'
export interface OpenDocumentViewAction {
  type: typeof DOCUMENT_OPEN_VIEW
  documentTypes: any
}

export const DOCUMENT_CLOSE_VIEW = 'DOCUMENT_CLOSE_VIEW'
export interface CloseDocumentViewAction {
  type: typeof DOCUMENT_CLOSE_VIEW
}

export type VendorDetailActions =
  | OpenReferenceViewAction
  | CloseReferenceViewAction
  | OpenDocumentViewAction
  | CloseDocumentViewAction
