import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import src from 'react-select/dist/declarations/src';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { useDialog } from '../../contexts/DialogContext';
import { IStaffAddress } from '../../interfaces/Address';
import { ILocation } from '../../interfaces/Property';
import { IUserDetail } from '../../interfaces/User';
import ApiCalls from '../../network/ApiCalls';
import { UserEditRequest } from '../../network/PostRequestModels/UserRequest';
import { getLocationByAddress, getLocationByAddressForStaff, toAmazonUrl } from '../../utils/util';
import AutocompleteMap from '../Inputs/AutocompleteMap';
import PhoneTextInput from '../Inputs/PhoneInput';
import { TextInput } from '../Inputs/TextInput';

type Props = {
    user: IUserDetail,
    userId: any,
    successFunc?: () => void,
}

const UserEditForm: React.FC<Props> = ({ user, userId, successFunc }) => {
    const { showSuccessDialog } = useDialog();
    const [editRequest, setEditRequest] = useState<UserEditRequest>({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
    } as UserEditRequest);
    const [imagePreview, setImagePreview] = useState<any>(user?.profilePhotoPath ? toAmazonUrl(user?.profilePhotoPath) : null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [text, setText] = useState<string>('');
    const [selectedLocation, setSelectedLocation] = useState<ILocation>({ address: ' ' });
    const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)

    const { data: staffAddress, isLoading: addressLoading, error: addressError } = useQuery(['Get Staff Address', userId], () => ApiCalls.getAddressesByUserId(userId), { onSuccess: (data: any) => setText(data.data.data?.[0]?.address) })
    const homeAddress = staffAddress?.data.data.find((address: IStaffAddress) => address.userAddressTypeId == 2)

    const queryClient = useQueryClient()
    const { mutateAsync } = useMutation(ApiCalls.editUser, { onSuccess: () => queryClient.invalidateQueries(['Get Staff Details'], userId) })

    const handleUserEdit = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            var formData = new FormData();
            formData.append("firstName", editRequest.firstName);
            formData.append("lastName", editRequest.lastName);
            formData.append("phoneNumber", editRequest.phoneNumber);
            formData.append("profilePhoto", editRequest.profilePhoto);
            const { firstName, lastName, phoneNumber } = editRequest;
            if (fullLocation) {
                { homeAddress ? await ApiCalls.updateAddress(Number(userId), Number(homeAddress.userAddressId), fullLocation) : await ApiCalls.createAddress(Number(userId), { ...fullLocation, userAddressTypeId: 2 }) }
            }
            if (firstName && lastName && phoneNumber) {
                await mutateAsync({ formData: formData, userId: userId })
                setLoading(false);
                showSuccessDialog('User Updated.');
                successFunc && successFunc()
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    const handleSelectAddress = (e: string) => {
        setSelectedLocation({ ...selectedLocation, address: e });
        setText(e)
    }

    const changeImage = (e: any) => {
        try {
            setErrorMessage(null);
            var file = e.target.files[0];
            if (file.size <= 2097152) {
                setEditRequest({ ...editRequest, profilePhoto: file })
                if (file) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        setImagePreview(reader.result);
                    };
                }
                else {
                    setImagePreview(null);
                }
            }
            else {
                setErrorMessage('Max file size is 2MB.');
            }
        }
        catch (err: any) {
            setErrorMessage('File cannot be uploaded.')
        }
    }

    const handleAutocompleteError = () => {
        setText(selectedLocation.address ?? '');
    }

    useEffect(() => {
        getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
    }, [selectedLocation])


    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>


                <div className='col-lg-6'>

                    <label className='form-label fs-6 fw-bolder text-dark'>First Name {<span style={{ color: "red" }}>*</span>}</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, firstName: text })} defaultValue={editRequest?.firstName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Last Name {<span style={{ color: "red" }}>*</span>}</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, lastName: text })} defaultValue={editRequest?.lastName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>City {<span style={{ color: "red" }}>*</span>}</label>
                    <AutocompleteMap text={text} setText={setText} handleSelectAddress={handleSelectAddress} handleOnError={handleAutocompleteError} />
                    {!selectedLocation.address || fullLocation?.zipCode == "" && <><strong className='text-danger fw-6'>Please enter a valid address</strong><br /></>}

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Phone {<span style={{ color: "red" }}>*</span>}</label>
                    <PhoneTextInput onChange={(text: string) => setEditRequest({ ...editRequest, phoneNumber: text })} defaultValue={editRequest?.phoneNumber} />


                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleUserEdit}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        {imagePreview ? <div className='d-flex justify-content-center'><img className='rounded' style={{ height: 200 }} alt='selected-sample' src={imagePreview} /></div> :
                            <div className="d-flex flex-column align-items-center max-w-250px">
                                <img style={{ width: 250, height: 250 }} src={toAbsoluteUrl('/media/icons/noPhoto.png')} alt="noPhoto" />
                                <strong className='text-muted text-center'>No Photo Available</strong>
                            </div>}
                        <Button
                            className='col-lg-6 mt-6'
                            variant="contained"
                            component="label"
                        >
                            Upload Photo
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={changeImage}
                                hidden
                            />
                        </Button>
                        (Max Photo Size 2MB)

                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(UserEditForm);

