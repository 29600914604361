import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import React, {FC} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../../_metronic/layout/core'
import ApiCalls from '../../../network/ApiCalls'
import {LiveJobResponse} from '../../../network/NetworkResponses/NetworkResponses'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import PropertyTable from './PropertyTable'
import {useHistory} from 'react-router-dom'

type Props = {
  provider: 'my-team' | 'turnify' | 'turnify-plus' | 'marketplace'
}

const PropertyList: FC<Props> = ({provider}) => {
  const localTime = dayjs().utc().format()
  const history = useHistory()

  const {
    data: propertyData,
    isFetching: propertyLoading,
    error: propertyError,
    refetch: propertyRefetch,
  } = useQuery<AxiosResponse<LiveJobResponse>>(
    ['Get Properties', provider],
    () => ApiCalls.getProperties(),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const onAddPropertyHandle = () => {
    history.push('/clients/property/add-a-property')
  }

  if (propertyError) {
    return <div>Error</div>
  }

  return (
    <>
      <PageTitle>Properties</PageTitle>
      <Card>
        <Card.Body>
          {propertyLoading ? (
            <SkeletonTableLoading />
          ) : (
            propertyData && (
              <PropertyTable data={propertyData?.data?.data} refetch={propertyRefetch} />
            )
          )}
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end'>
          <button
            className='btn btn-success'
            style={{height: '100%'}}
            onClick={onAddPropertyHandle}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className='bi bi-plus'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
            </svg>
            Add Property
          </button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default PropertyList
