/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../app/redux/reducers/rootReducer'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../app/network/ApiCalls'

export function AsideMenuMain() {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {data, isLoading, refetch} = useQuery(
    ['Get OnBoarding Info', user.userSection.vendorId],
    () => ApiCalls.getOnBoarding(user.userSection.vendorId),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const [progress, setProgress] = useState<boolean>(false)
  const conditionDoc = data?.data?.vendorDocumentCount < 2
  const conditionCities = data?.data?.vendorServiceCityCount == 0
  const conditionStaff = data?.data?.staff?.length == 0
  const conditionManageService = data?.data?.vendorOfferedServiceCount == 0
  const conditionPayments = !data?.data?.isVerifiedStripeAccount
  const conditions = [
    conditionDoc,
    conditionCities,
    conditionStaff,
    conditionPayments,
    conditionManageService,
  ]

  useEffect(() => {
    const prog = conditions.every((item) => item == false)
    setProgress(prog)
  }, [data])

  return (
    <>
      {user.vendor.subscribed && (
        <>
          {user.vendor.suspended ? (
            <>
              <AsideMenuItem
                to='/suspended'
                title='Suspended'
                icon='/media/icons/duotone/Code/Stop.svg'
              />
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItem
                to='/logout'
                title='Logout'
                icon='/media/icons/duotone/Navigation/Sign-out.svg'
              />
            </>
          ) : (
            <>
              {!progress && (
                <>
                  <AsideMenuItem
                    to='/dashboard'
                    title='Dashboard'
                    fontIcon='bi-app-indicator'
                    icon='/media/icons/duotone/Tools/Pantone.svg'
                  />
                  <div className='menu-item'>
                    <div className='menu-content'>
                      <div className='separator'></div>
                    </div>
                  </div>
                </>
              )}
              <AsideMenuItemWithSub
                to='/jobs'
                icon='/media/icons/duotone/Design/Brush.svg'
                title='Jobs'
              >
                <AsideMenuItem to='/jobs/live' title='Live Jobs' />
                <AsideMenuItem to='/jobs/pending' title='Pending Jobs' />
                <AsideMenuItem to='/jobs/scheduled' title='Scheduled Jobs' />
                <AsideMenuItem to='/jobs/completed' title='Completed Jobs' />
                <AsideMenuItem to='/jobs/cancelled' title='Cancelled Jobs' />
                <AsideMenuItem to='/jobs/virtual-inspections' title='Virtual Inspections' />
              </AsideMenuItemWithSub>
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItemWithSub
                to='/staff-management'
                icon='/media/icons/duotone/General/User.svg'
                title='Staff Management'
              >
                <AsideMenuItem to='/staff-management/list' title='Manage Staff' />
                {/* <AsideMenuItem
              to='/staff-management/staff-performance'
              title='Staff Performance'
             
            /> */}
              </AsideMenuItemWithSub>
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItemWithSub
                to='/clients'
                icon='/media/icons/duotone/Communication/Group.svg'
                title='Client Management'
              >
                <AsideMenuItem to='/clients/properties' title='Properties' />
                <AsideMenuItem to='/clients/list' title='Client Directory' />
              </AsideMenuItemWithSub>
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItemWithSub
                to='/payments'
                icon='/media/icons/duotone/Shopping/Credit-card.svg'
                title='Payments'
              >
                <AsideMenuItem to='/payments/stripe-integration' title='Stripe Integration' />
                <AsideMenuItem to='/payments/subscription' title='Subscription' />
                <AsideMenuItemWithSub to='/payments/payouts' title='Payouts'>
                  <AsideMenuItem to='/payments/payouts/paid' title='Completed Payouts' />
                  <AsideMenuItem to='/payments/payouts/unpaid' title='Upcoming Payouts' />
                </AsideMenuItemWithSub>
              </AsideMenuItemWithSub>
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItemWithSub
                to='/services'
                icon='/media/icons/duotone/Design/Interselect.svg'
                title='Services'
              >
                <AsideMenuItem to='/services/my-services' title='My Services' />
                <AsideMenuItem to='/services/service-areas' title='Service Areas' />
                <AsideMenuItem to='/services/service-rates' title='Services & Rates' />
              </AsideMenuItemWithSub>
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItemWithSub
                to='/messaging'
                icon='/media/icons/duotone/Communication/Chat.svg'
                title='Messaging'
              >
                <AsideMenuItem to='/messaging/inbox' title='Inbox' />
                <AsideMenuItem to='/messaging/notifications' title='Notifications' />
              </AsideMenuItemWithSub>
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItem
                to='/quote-requests'
                title='Quote Requests'
                icon='/media/icons/duotone/Shopping/Cart2.svg'
              />
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItemWithSub
                to='/vendor-details'
                title='Company Information'
                icon='/media/icons/duotone/Code/Compiling.svg'
              >
                <AsideMenuItem to='/vendor-details/account' title='Company' />
                {user?.userSection?.roleId === 9 && (
                  <AsideMenuItem to='/vendor-details/references' title='References' />
                )}
                <AsideMenuItem to='/vendor-details/documents' title='Documents' />
              </AsideMenuItemWithSub>
              <div className='menu-item'>
                <div className='menu-content'>
                  <div className='separator'></div>
                </div>
              </div>
              <AsideMenuItem
                to='/logout'
                title='Logout'
                icon='/media/icons/duotone/Navigation/Sign-out.svg'
              />
            </>
          )}
        </>
      )}
      {user.vendor.subscribed == false && (
        <>
          <AsideMenuItem
            to='/subscribe'
            title='Subscribe'
            icon='/media/icons/duotone/Shopping/Ticket.svg'
          />
          <div className='menu-item'>
            <div className='menu-content'>
              <div className='separator'></div>
            </div>
          </div>
          <AsideMenuItem
            to='/logout'
            title='Logout'
            icon='/media/icons/duotone/Navigation/Sign-out.svg'
          />
        </>
      )}
    </>
  )
}
