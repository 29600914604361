import { IHost } from '../../interfaces/Host';
import * as actions from '../actionTypes/hostTypes';

export function openAddHostDialog(refetch: () => void): actions.OpenAddHostDialog {
    return {
        type: actions.OPEN_ADD_HOST_DIALOG,
        refetch
    }
}

export function closeAddHostDialog(): actions.CloseAddHostDialog {
    return {
        type: actions.CLOSE_ADD_HOST_DIALOG,
    }
}
export function openHostEditDialog(refetch: () => void, host: IHost): actions.OpenEditHostDialog {
    return {
        type: actions.OPEN_HOST_EDIT_DIALOG,
        host,
        refetch
    }
}
export function closeEditHostDialog(): actions.CloseEditHostDialog {
    return {
        type: actions.CLOSE_HOST_EDIT_DIALOG,
    }
}