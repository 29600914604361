import {
  IJobChecklistPhoto,
  IJobDetail,
  IJobIssue,
  IJobTask,
  JobTabNames,
} from '../../interfaces/Job'
import {IStaffOfferedService} from '../../interfaces/Staff'
import {IVirtualInspection} from '../../interfaces/VirtualInspection'
import {ManualJobRequest} from '../../network/PostRequestModels/JobRequests'
import * as actions from '../actionTypes/jobTypes'

export interface JobState {
  jobDialog: {
    modalOpen: boolean
    jobForm?: IStaffOfferedService | null
  }
  serviceAddDialog: {
    modalOpen: boolean
    refetch: () => void
  }
  jobTaskDialog: {
    modalOpen: boolean
    refetch: () => void
    taskData?: IJobTask | null
  }
  jobIssueMediaDialog: {
    modalOpen: boolean
    issue: IJobIssue | null
  }
  checklistPhotoDialog: {
    modalOpen: boolean
    checklistPhoto: IJobChecklistPhoto | null
  }
  virtualInspectionDialog: {
    modalOpen: boolean
  }
  reviewDialog: {
    modalOpen: boolean
    refetch: () => void
    serviceTypeId: number | null
  }
  inspectionDetailDialog: {
    modalOpen: boolean
    refetch: () => void
    virtualInspectionId: number | null
  }
  jobDetail: IJobDetail | null
  jobId: number | null
  selectedJobTab: JobTabNames
  selectedJobProvider: number | null
}

const initialState: JobState = {
  jobDialog: {
    modalOpen: false,
    jobForm: null,
  },
  serviceAddDialog: {
    modalOpen: false,
    refetch: () => {},
  },
  jobTaskDialog: {
    modalOpen: false,
    refetch: () => {},
    taskData: null,
  },
  jobIssueMediaDialog: {
    modalOpen: false,
    issue: null,
  },
  checklistPhotoDialog: {
    modalOpen: false,
    checklistPhoto: null,
  },
  virtualInspectionDialog: {
    modalOpen: false,
  },
  inspectionDetailDialog: {
    modalOpen: false,
    refetch: () => {},
    virtualInspectionId: null,
  },
  reviewDialog: {
    modalOpen: false,
    refetch: () => {},
    serviceTypeId: null,
  },
  jobDetail: null,
  jobId: null,
  selectedJobTab: 'Pending',
  selectedJobProvider: 2,
}

export default function jobReducer(
  state: JobState = initialState,
  action: actions.JobAction
): JobState {
  switch (action.type) {
    case actions.RESPONSE_JOB_ID:
      return {...state, jobId: action.jobId}
    case actions.OPEN_JOB_DIALOG:
      return {...state, jobDialog: {modalOpen: true, jobForm: null}}
    case actions.CLOSE_JOB_DIALOG:
      return {...state, jobDialog: {modalOpen: false, jobForm: null}}
    case actions.SET_JOB_FORM:
      return {...state, jobDialog: {...state.jobDialog, jobForm: action.jobForm}}
    case actions.OPEN_SERVICE_ADD_DIALOG:
      return {...state, serviceAddDialog: {modalOpen: true, refetch: action.refetch}}
    case actions.CLOSE_SERVICE_ADD_DIALOG:
      return {...state, serviceAddDialog: {modalOpen: false, refetch: () => {}}}
    case actions.OPEN_TASK_DIALOG:
      return {
        ...state,
        jobTaskDialog: {modalOpen: true, refetch: action.refetch, taskData: action.taskData},
      }
    case actions.CLOSE_TASK_DIALOG:
      return {...state, jobTaskDialog: {modalOpen: false, refetch: () => {}, taskData: null}}
    case actions.OPEN_ISSUE_MEDIA_DIALOG:
      return {...state, jobIssueMediaDialog: {modalOpen: true, issue: action.issue}}
    case actions.CLOSE_ISSUE_MEDIA_DIALOG:
      return {...state, jobIssueMediaDialog: {modalOpen: false, issue: null}}
    case actions.OPEN_CHECKLIST_PHOTO_DIALOG:
      return {
        ...state,
        checklistPhotoDialog: {modalOpen: true, checklistPhoto: action.checklistPhoto},
      }
    case actions.CLOSE_CHECKLIST_PHOTO_DIALOG:
      return {...state, checklistPhotoDialog: {modalOpen: false, checklistPhoto: null}}
    case actions.OPEN_VIRTUAL_INSPECTION_DIALOG:
      return {...state, virtualInspectionDialog: {modalOpen: true}}
    case actions.CLOSE_VIRTUAL_INSPECTION_DIALOG:
      return {...state, virtualInspectionDialog: {modalOpen: false}}
    case actions.OPEN_INSPECTION_DETAIL_DIALOG:
      return {
        ...state,
        inspectionDetailDialog: {
          modalOpen: true,
          refetch: action.refetch,
          virtualInspectionId: action.virtualInspectionId,
        },
      }
    case actions.CLOSE_INSPECTION_DETAIL_DIALOG:
      return {
        ...state,
        inspectionDetailDialog: {modalOpen: false, refetch: () => {}, virtualInspectionId: null},
      }
    case actions.OPEN_REVIEW_DIALOG:
      return {
        ...state,
        reviewDialog: {
          modalOpen: true,
          refetch: action.refetch,
          serviceTypeId: action.serviceTypeId,
        },
      }
    case actions.CLOSE_REVIEW_DIALOG:
      return {...state, reviewDialog: {modalOpen: false, refetch: () => {}, serviceTypeId: null}}
    case actions.SET_JOB_DETAIL:
      return {...state, jobDetail: action.jobDetail}
    case actions.SET_SELECTED_JOB_PROVIDER:
      return {...state, selectedJobProvider: action.selectedJobProvider}
    default:
      return state
  }
}
