import { IHost } from "../../interfaces/Host";
import * as actions from "../actionTypes/hostTypes";

export interface HostState {
    hostAddDialog: {
        modalOpen: boolean;
        refetch: () => void
    }
    hostEditDialog: {
        modalOpen: boolean;
        host: IHost | null
        refetch: () => void
    }
}

const initialState: HostState = {
    hostAddDialog: {
        modalOpen: false,
        refetch: () => { }
    },
    hostEditDialog: {
        modalOpen: false,
        host: null,
        refetch: () => { }
    }
}

export default function hostReducer(
    state: HostState = initialState,
    action: actions.HostAction
): HostState {
    switch (action.type) {
        case actions.OPEN_ADD_HOST_DIALOG:
            return { ...state, hostAddDialog: { modalOpen: true, refetch: action.refetch } }
        case actions.CLOSE_ADD_HOST_DIALOG:
            return { ...state, hostAddDialog: { modalOpen: false, refetch: () => { } } }
        case actions.OPEN_HOST_EDIT_DIALOG:
            return { ...state, hostEditDialog: { modalOpen: true, refetch: action.refetch, host: action.host } }
        case actions.CLOSE_HOST_EDIT_DIALOG:
            return { ...state, hostEditDialog: { modalOpen: false, refetch: () => { }, host: null } }
        default:
            return state;
    }
}