import React, { Dispatch } from 'react';
import { useState } from 'react';
import { IHostPropertyBedroom, IHostPropertyInput } from '../../../interfaces/Property';
import { Card, Spinner } from 'react-bootstrap-v5';
import { useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import ArrowButton from '../../Buttons/ArrowButton';

type Props = {
    numberOfBathrooms?: number,
    numberOfBedrooms?: number,
    numberOfBeds?: number,
    hostPropertyBedrooms?: IHostPropertyBedroom[],
    pmsReferenceId?: string,
    handleChangeHostProperty: (changedValues: IHostPropertyInput) => void,
    handlePostProperty?: () => void,
    currentStep?: number,
    totalSteps?: number,
    nextStep?: () => void,
    previousStep?: () => void,
    hostProperty?: IHostPropertyInput,
    editMode?: boolean,
    loading?: boolean
}

type CardProps = {
    index: number,
    hostPropertyBedroom: IHostPropertyBedroom,
    changeHostPropertyBedroom: (index: number, hostPropertyBedroom: IHostPropertyBedroom) => void
}

const BedroomCard: React.FC<CardProps> = ({ index, hostPropertyBedroom, changeHostPropertyBedroom }) => {
    const [numberOfBeds, setNumberOfBeds] = useState(hostPropertyBedroom.numberOfBeds ? hostPropertyBedroom.numberOfBeds : 1);

    return (
        <Card className="col-lg-2 m-3 shadow">
            <strong>Bedroom #{index + 1}</strong>
            <div className="d-flex flex-row justify-content-start align-items-center flex-wrap">
                <img alt="bed" src={toAbsoluteUrl("/media/icons/duotone/Home/Bed.svg")} className="me-3" />
                <div className='d-flex flex-column'>
                    <small>Number of Beds</small>
                    <strong>{numberOfBeds}</strong>
                </div>
            </div>
        </Card>
    )
}

const RoomBedStep: React.FC<Props> = ({ numberOfBathrooms, numberOfBedrooms, hostPropertyBedrooms, numberOfBeds, handleChangeHostProperty, nextStep, previousStep, editMode, handlePostProperty, loading }) => {
    const [selectedNumberOfBathRooms, setSelectedNumberOfBathRooms] = useState<number>(numberOfBathrooms ? numberOfBathrooms : 0);
    const [selectedNumberOfBedRooms, setSelectedNumberOfBedRooms] = useState<number>(numberOfBedrooms ? numberOfBedrooms : 0);
    const [selectedNumberOfBeds, setSelectedNumberOfBeds] = useState<number>(numberOfBeds ? numberOfBeds : 0);
    const [selectedHostPropertyBedrooms, setSelectedHostPropertyBedrooms] = useState<IHostPropertyBedroom[]>(hostPropertyBedrooms && hostPropertyBedrooms.length > 0 ? hostPropertyBedrooms : []);

    const handleBedroomChange = (index: number, hostPropertyBedroom: IHostPropertyBedroom) => {
        setSelectedHostPropertyBedrooms(selectedHostPropertyBedrooms.map((bedroom, currentIndex) => currentIndex === index ? hostPropertyBedroom : bedroom))
    }

    useEffect(() => {
        if (selectedNumberOfBedRooms > 0 && !hostPropertyBedrooms) {
            let arr: IHostPropertyBedroom[] = [];
            let obj: IHostPropertyBedroom = { numberOfBeds: 1 }
            for (let i = 0; i < selectedNumberOfBedRooms; i++) {
                arr.push(obj);
            }
            setSelectedHostPropertyBedrooms(arr);
        }
    }, [selectedNumberOfBedRooms])

    useEffect(() => {
        let count = 0;
        selectedHostPropertyBedrooms.forEach(room => {
            count += room?.numberOfBeds ? room?.numberOfBeds : 0;
        })
        handleChangeHostProperty({
            numberOfBedrooms: selectedNumberOfBedRooms,
            numberOfBathrooms: selectedNumberOfBathRooms,
            numberOfBeds: selectedNumberOfBedRooms ? count : selectedNumberOfBeds,
            hostPropertyBedrooms: selectedHostPropertyBedrooms
        })
    }, [selectedNumberOfBathRooms, selectedNumberOfBedRooms, selectedNumberOfBeds, selectedHostPropertyBedrooms])

    return (
        <form
            className='form w-100 mt-5'
        >
            {/* begin::Form group */}
            <div className='row'>
                <div className='col-lg-12'>
                    <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{ width: 200 }}>Number of Bedrooms</label>
                    <strong>{selectedNumberOfBedRooms ? selectedNumberOfBedRooms : 'Studio'}</strong>
                </div>
                {
                    selectedNumberOfBedRooms ?
                        null
                        :
                        <div className='col-lg-12'>
                            <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{ width: 200 }}>Number of Beds</label>
                            <strong>{selectedNumberOfBeds}</strong>
                        </div>
                }
                <div className='col-lg-12'>
                    <label className='form-label fs-6 fw-bolder text-dark mt-5' style={{ width: 200 }}>Number of Bathrooms</label>
                    <strong>{selectedNumberOfBathRooms}</strong>
                </div>
            </div>
            <div className='row'>
                {selectedHostPropertyBedrooms.map((bedroom, index) => <BedroomCard index={index} hostPropertyBedroom={bedroom} changeHostPropertyBedroom={handleBedroomChange} />)}
            </div>
        </form>
    )
}

export default RoomBedStep;