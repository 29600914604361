import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {AxiosResponse} from 'axios'
import {IJobResponse} from '../../network/NetworkResponses/NetworkResponses'
import {IJob} from '../../interfaces/Job'
import JobsTable from './VendorsJobsTable'
import React, {useEffect, useState} from 'react'
import JobsAvailableTable from '../jobs-available/JobsAvailableTable'
import JobsAvailableModal from '../jobs-available/JobsAvailableModal'
import JobDialog from './add-job/JobDialog'
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators'
import {useHistory} from 'react-router-dom'

type TabNames = 'Pending' | 'Scheduled' | 'Not Completed' | 'Completed' | 'Cancelled'

type Props = {
  type: TabNames
}

const VendorsJobs: React.FC<Props> = ({type}) => {
  const [selectedTab, setSelectedTab] = useState<TabNames>(type)
  const [jobList, setJobList] = useState<IJob[]>()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    setSelectedTab(type)
    getFilteredJobList(type)
  }, [history.location])

  const {
    user: {
      userSection: {vendorId},
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const {
    data: allJobs,
    isLoading,
    error,
  } = useQuery<AxiosResponse<IJobResponse>>(['Get All Jobs'], () => ApiCalls.getJobs(vendorId), {
    cacheTime: 50000,
  })

  const getFilteredJobList = (key: any) => {
    const list = allJobs?.data.data
    if (list != undefined) {
      const filtered = list.filter((l) => l.jobStatusTypeName == key)
      setJobList(filtered)
    }
  }

  useState(() => {
    getFilteredJobList(selectedTab)
  }, [allJobs])

  const handleTabSelect = (key: any) => {
    setSelectedTab(key)
    getFilteredJobList(key)
  }

  if (error) {
    return (
      <div className='d-flex justify-content-center'>
        An error occur during loading page. Please reload page!
      </div>
    )
  }
  return (
    <div>
      <PageTitle>{type + ' Jobs'}</PageTitle>
      <Card>
        <Card.Title></Card.Title>
        <Card.Body>
          {isLoading ? (
            <SkeletonTableLoading />
          ) : (
            <>
              <JobsTable data={jobList} />
            </>
          )}
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-success'
            onClick={() => dispatch(jobActionCreator.openJobDialog())}
          >
            + Add Job
          </button>
        </Card.Footer>
      </Card>
      <JobDialog />
    </div>
  )
}

export default VendorsJobs
