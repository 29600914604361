import React, {Dispatch, FC, useEffect} from 'react'
import StepWizard from 'react-step-wizard'
import {IHostPropertyInput, IPropertyIsAutoEnabled} from '../../interfaces/Property'
import notify from 'devextreme/ui/notify'
import {useState} from 'react'
import LocationStep from './StepTabs/LocationStep'
import AutomationStep from './StepTabs/AutomationStep'
import PropertyDetailStep from './StepTabs/PropertyDetailStep'
import RoomBedStep from './StepTabs/RoomBedStep'
import {
  ButtonToolbar,
  Card,
  Modal,
  OverlayTrigger,
  Spinner,
  Tab,
  Tabs,
  Tooltip,
} from 'react-bootstrap-v5'
import ApiCalls from '../../network/ApiCalls'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {useDialog} from '../../contexts/DialogContext'
import {useQuery} from 'react-query'
import {Link, useHistory} from 'react-router-dom'
import StepNav from '../StepNav/StepNav'
import JobHistoryStep from './StepTabs/JobHistoryStep'
import {HostPropertyStatus} from '../../enums/PropertyEnum'
import dayjs from 'dayjs'
import StaffPriorityStep from './StepTabs/StaffPriorityStep'
import PermissionStep from './StepTabs/PermissionStep'
import {HostPropertyAction} from '../../redux/actionTypes/hostPropertyTypes'
import {OpenDeletePropertyModal} from '../../redux/actionCreators/hostPropertyActionCreators'
import {encryptText} from '../../utils/util'
import ReportedIssuesStep from './StepTabs/ReportedIssuesStep'
import TurnoversStep from './StepTabs/TurnoversStep'
import {AxiosResponse} from 'axios'
import {IListResponse} from '../../network/NetworkResponses/NetworkResponses'
import JobLogsStep from './StepTabs/JobLogsStep'

type Props = {
  propertyId?: void
  type: 'pms' | 'manual'
  propertyType?: 'active' | 'pending'
  editModalClose?: () => void
  editDisabled?: boolean
}

type TabNames =
  | 'location'
  | 'automation'
  | 'property-detail'
  | 'rooms'
  | 'job-history'
  | 'property-offered-services'
  | 'permission'
  | 'staff-priority'
  | 'reported-issues'
  | 'turnovers'
  | 'logs'

const PropertyAddWizard: React.FC<Props> = ({
  propertyId,
  type,
  editModalClose,
  editDisabled,
  propertyType,
}) => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [selectedTab, setSelectedTab] = useState<TabNames | null>('property-detail')
  const [loading, setLoading] = useState(false)
  const [showResetJobsModal, setShowResetJobsModal] = useState(false)
  const [savedValues, setSavedValues] = useState<IHostPropertyInput | null>(null)
  const [missingField, setMissingField] = useState<boolean>(false)

  const {
    data: hostPropertyData,
    error: hostPropertyError,
    isLoading: hostPropertyLoading,
    isFetching: hostPropertyFetching,
  } = useQuery(
    ['Get Host Property By Id', propertyId],
    () => ApiCalls.getHostPropertyById(propertyId ? propertyId : 0),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: propertyId ? true : false}
  )
  const {
    data: isAutoEnabled,
    error: isAutoEnabledError,
    isLoading: isAutoEnabledLoading,
  } = useQuery<AxiosResponse<IPropertyIsAutoEnabled>>(
    ['Get is Auto Enabled', propertyId],
    () => ApiCalls.getPropertyIsAutoEnabled(propertyId ? propertyId : 0),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: propertyId ? true : false}
  )

  const handleChangeHostProperty = (changedValues: IHostPropertyInput) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  const isPropertyValid = () => {
    if (
      !savedValues?.address ||
      !savedValues?.zipCode ||
      !savedValues?.latitude ||
      !savedValues?.longitude ||
      !savedValues?.country ||
      !savedValues?.state ||
      !savedValues?.city
    ) {
      showFailureDialog('Please fill Location information')
      return false
    }

    if (savedValues.sameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        showFailureDialog(
          'Since same day booking is available, Check-in Time cannot be earlier then Check-out Time.'
        )
        return false
      }
    }

    if (!savedValues?.cleaningProviderId) {
      showFailureDialog('Please fill Cleaner information')
      return false
    }

    /* if ((savedValues?.cleaningProviderId === 2) && (!savedValues?.hostPropertyAccess?.unitAccessTypeId || !savedValues?.hostPropertyAccess?.unitAccessValue || !savedValues?.hostPropertyAccess?.buildingAccessTypeId || !savedValues?.hostPropertyAccess?.buildingAccessValue)) {
            showFailureDialog("Please fill Access information");
            return false;
        }

        if ((savedValues?.cleaningProviderId === 1) && ((savedValues?.hostPropertyAccess?.unitAccessTypeId && !savedValues?.hostPropertyAccess?.unitAccessValue) || (savedValues?.hostPropertyAccess?.buildingAccessTypeId && !savedValues?.hostPropertyAccess?.buildingAccessValue))) {
            showFailureDialog("Please fill Access information");
            return false;
        }

        if ((savedValues?.cleaningProviderId === 1) && ((!savedValues?.hostPropertyAccess?.unitAccessTypeId && savedValues?.hostPropertyAccess?.unitAccessValue) || (!savedValues?.hostPropertyAccess?.buildingAccessTypeId && savedValues?.hostPropertyAccess?.buildingAccessValue))) {
            showFailureDialog("Please fill Access information");
            return false;
        }*/

    if (
      savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync != undefined &&
      savedValues?.hostPropertyAutomation.isAutomaticCalendarSync &&
      !savedValues?.hostPropertyAutomation?.usePMS
    ) {
      if (
        savedValues.hostPropertyICals?.length === 0 ||
        savedValues.hostPropertyICals?.some((ical) => ical.icalUrl === '' || !ical.icalUrl)
      ) {
        showFailureDialog('Please provide ICal url')
        return false
      }
    }

    if (!savedValues.hostPropertyAutomation?.isAutomaticCalendarSync) {
      delete savedValues.hostPropertyICals
    }

    /*if ((savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync || savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync === undefined) && !savedValues.hostPropertyAutomation?.usePMS && (!savedValues.hostPropertyICals || savedValues.hostPropertyICals?.length === 0 || savedValues.hostPropertyICals?.some(ical => ical.icalUrl === '' || !ical.icalUrl))) {
            showFailureDialog("Please provide ICal url");
            return false;
        }*/

    if (
      savedValues.hasStorage &&
      (savedValues.hostPropertyStorages?.length === 0 ||
        !savedValues.hostPropertyStorages?.[0].storageDescription ||
        savedValues.hostPropertyStorages?.[0].storageDescription === '')
    ) {
      showFailureDialog('Please provide at least one storage description')
      return false
    }

    if (
      savedValues.hasParking &&
      (!savedValues.parkingDescription || savedValues.parkingDescription === '')
    ) {
      showFailureDialog('Please enter parking description.')
      return false
    }

    return true
  }

  const handlePostProperty = async () => {
    if (isPropertyValid()) {
      setLoading(true)
      setMissingField(false)
      let name =
        (savedValues?.address ? savedValues?.address : '') +
        (savedValues?.suite ? '#' + savedValues?.suite : '')
      const requestData: IHostPropertyInput = {
        ...savedValues,
        name,
        hostId: user.userSection.hostId,
        numberOfCleaner: 0,
        parkingDescription: savedValues?.hasParking ? savedValues.parkingDescription : undefined,
        hasParking: savedValues?.hasParking !== undefined ? savedValues?.hasParking : false,
        hasDryer: savedValues?.hasLaundry
          ? savedValues?.hasDryer !== undefined
            ? savedValues?.hasDryer
            : false
          : false,
        hasLaundry: savedValues?.hasLaundry !== undefined ? savedValues?.hasLaundry : false,
        hasStorage: savedValues?.hasStorage !== undefined ? savedValues?.hasStorage : false,
        sameDayBooking:
          savedValues?.sameDayBooking !== undefined ? savedValues?.sameDayBooking : false,
        numberOfBedrooms: savedValues?.numberOfBedrooms ? savedValues?.numberOfBedrooms : 0,
        numberOfBathrooms: savedValues?.numberOfBathrooms ? savedValues?.numberOfBathrooms : 0,
        numberOfBeds: savedValues?.numberOfBeds ? savedValues?.numberOfBeds : 0,
        hostPropertyStorages: savedValues?.hasStorage
          ? savedValues?.hostPropertyStorages
          : undefined,
        hostPropertyAccess:
          savedValues?.hostPropertyAccess && Object.keys(savedValues?.hostPropertyAccess).length > 0
            ? savedValues.hostPropertyAccess
            : undefined,
        hostPropertyAutomation: {
          ...savedValues?.hostPropertyAutomation,
          isAutomaticSchedule:
            savedValues?.hostPropertyAutomation?.isAutomaticSchedule !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticSchedule
              : true,
          isAutomaticPayment: false, //savedValues?.hostPropertyAutomation?.isAutomaticPayment !== undefined ? savedValues?.hostPropertyAutomation?.isAutomaticPayment : false
          isAutomaticCalendarSync:
            savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync
              : true,
          usePMS: false,
          checkInTime: savedValues?.hostPropertyAutomation?.checkInTime ?? '15:00',
          checkOutTime: savedValues?.hostPropertyAutomation?.checkOutTime ?? '11:00',
        },
        hostPropertyICals: !savedValues?.hostPropertyAutomation?.usePMS
          ? savedValues?.hostPropertyICals
          : [],
      }
      ApiCalls.postHostProperty(requestData)
        .then((res) => {
          setLoading(false)
          showSuccessDialog('Property is successfully added.')
          history.push('/properties')
        })
        .catch((err) => {
          setLoading(false)
          showFailureDialog(
            err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
          )
        })
    } else {
      setMissingField(true)
    }
  }

  const handleUpdateProperty = async () => {
    if (isPropertyValid() && propertyId) {
      setLoading(true)
      setMissingField(false)
      let name =
        (savedValues?.address ? savedValues?.address : '') +
        (savedValues?.suite ? '#' + savedValues?.suite : '')
      const requestData: IHostPropertyInput = {
        ...savedValues,
        cleaningProviderId:
          savedValues?.cleaningProviderId == 4 ? 2 : savedValues?.cleaningProviderId,
        name,
        isMarketplace: savedValues?.cleaningProviderId == 4 ? true : false,
        hostId: hostPropertyData?.data?.hostProperty?.hostId,
        numberOfCleaner: 0,
        propertySize: savedValues?.propertySize ? +savedValues?.propertySize : 0,
        parkingDescription: savedValues?.hasParking ? savedValues.parkingDescription : undefined,
        hasParking: savedValues?.hasParking !== undefined ? savedValues?.hasParking : false,
        hasDryer: savedValues?.hasLaundry
          ? savedValues?.hasDryer !== undefined
            ? savedValues?.hasDryer
            : false
          : false,
        hasLaundry: savedValues?.hasLaundry !== undefined ? savedValues?.hasLaundry : false,
        hasStorage: savedValues?.hasStorage !== undefined ? savedValues?.hasStorage : false,
        sameDayBooking:
          savedValues?.sameDayBooking !== undefined ? savedValues?.sameDayBooking : false,
        numberOfBedrooms: savedValues?.numberOfBedrooms ? savedValues?.numberOfBedrooms : 0,
        numberOfBathrooms: savedValues?.numberOfBathrooms ? savedValues?.numberOfBathrooms : 0,
        numberOfBeds: savedValues?.numberOfBeds ? savedValues?.numberOfBeds : 0,
        hostPropertyStorages: savedValues?.hasStorage
          ? savedValues?.hostPropertyStorages
          : undefined,
        hostPropertyAccess:
          savedValues?.hostPropertyAccess && Object.keys(savedValues?.hostPropertyAccess).length > 0
            ? savedValues.hostPropertyAccess
            : undefined,
        hostPropertyAutomation: {
          ...savedValues?.hostPropertyAutomation,
          isAutomaticSchedule:
            savedValues?.hostPropertyAutomation?.isAutomaticSchedule !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticSchedule
              : true,
          isAutomaticPayment: false, //savedValues?.hostPropertyAutomation?.isAutomaticPayment !== undefined ? savedValues?.hostPropertyAutomation?.isAutomaticPayment : false
          isAutomaticCalendarSync:
            savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync !== undefined
              ? savedValues?.hostPropertyAutomation?.isAutomaticCalendarSync
              : true,
          usePMS:
            savedValues?.hostPropertyAutomation?.usePMS !== undefined
              ? savedValues?.hostPropertyAutomation?.usePMS
              : false,
          checkInTime: savedValues?.hostPropertyAutomation?.checkInTime ?? '15:00',
          checkOutTime: savedValues?.hostPropertyAutomation?.checkOutTime ?? '11:00',
        },
        hostPropertyICals: !savedValues?.hostPropertyAutomation?.usePMS
          ? savedValues?.hostPropertyICals
          : [],
      }

      ApiCalls.updateHostProperty(propertyId, requestData)
        .then((res) => {
          setLoading(false)
          showSuccessDialog('Property is successfully updated.')
        })
        .catch((err) => {
          setLoading(false)
          if (
            err?.response?.data?.errors?.[0]?.message == 'icalUrl must be an URL address' ||
            err?.response?.data?.message == 'icalUrl must be an URL address'
          ) {
            showFailureDialog(
              'Error: Invalid iCal URL.',
              'Please enter a valid URL for the iCal feed. This should be a direct link to your calendar, ending with the file extension ".ics". Make sure there are no typos or extra characters in the URL.'
            )
          } else {
            showFailureDialog(
              err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
            )
          }
        })
    } else {
      setMissingField(true)
      showFailureDialog('Please fill all necessary fields')
    }
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  const refreshCalender = async () => {
    if (propertyId != undefined) {
      var propId = +propertyId
      try {
        await ApiCalls.postRefreshCalendar(propertyId).then((actualData) =>
          notify(
            {
              message: actualData?.data?.success
                ? 'Calendar sync successfully'
                : 'An error occurred while syncing the calendar',
              position: {
                my: 'center top',
                at: 'center top',
              },
            },
            actualData?.data?.success ? 'success' : 'error',
            5000
          )
        )
      } catch (error: any) {
        console.log('General Error : ', error?.response?.data)
      }
    }
  }

  const handleResetJobs = async () => {
    if (propertyId != undefined) {
      setShowResetJobsModal(false)
      var propId = +propertyId
      try {
        await ApiCalls.postResetPropertyJobs(propertyId).then((actualData) =>
          notify(
            {
              message: actualData?.data?.success
                ? 'All jobs and calendar records belonging to the property have been reset.'
                : 'An error occurred while reset jobs',
              position: {
                my: 'center top',
                at: 'center top',
              },
            },
            actualData?.data?.success ? 'success' : 'error',
            5000
          )
        )
      } catch (error: any) {
        console.log('General Error : ', error?.response?.data)
      }
    }
  }

  useEffect(() => {
    if (hostPropertyData) {
      setSavedValues({
        ...hostPropertyData?.data?.hostProperty,
        ...hostPropertyData?.data,
        hostProperty: undefined,
        numberOfBathrooms: parseFloat(
          hostPropertyData?.data?.numberOfBathrooms ? hostPropertyData?.data?.numberOfBathrooms : 0
        ),
      })
    }
  }, [hostPropertyData])

  useEffect(() => {
    setMissingField(
      hostPropertyData?.data?.hostProperty?.hostPropertyStatusTypeId ===
        HostPropertyStatus['Property Fields Not Set']
    )
  }, [hostPropertyData])

  if (hostPropertyLoading || hostPropertyFetching) {
    return (
      <div className='d-flex justify-content-center flex-column'>
        <Spinner className='align-self-center' animation='border' />
      </div>
    )
  }

  if (propertyId && hostPropertyError) {
    return (
      <div className='d-flex justify-content-center flex-column'>
        <strong>Property cannot found.</strong>
        <button className='btn btn-danger mt-6' onClick={editModalClose}>
          Back to Property List
        </button>
      </div>
    )
  }

  console.log('property', hostPropertyData?.data)

  return (
    <>
      <Card>
        <Card.Body>
          {propertyId && (
            <div className='d-flex justify-content-between'>
              <div className='ms-2 pb-2 flex'>
                <Link
                  to={`/host-details/${encryptText(hostPropertyData?.data?.hostProperty?.hostId)}`}
                >
                  <span className='badge badge-light-primary py-2'>
                    {hostPropertyData?.data?.hostName}
                  </span>
                </Link>
                <span className='badge badge-info py-2 ms-3'>
                  {hostPropertyData?.data?.hostProperty?.name}
                </span>
                {hostPropertyData?.data?.hostPropertyAutomation.usePms &&
                  hostPropertyData?.data?.pmsReferenceId != null &&
                  hostPropertyData?.data?.hostPropertyPMS.length > 0 && (
                    <span className='badge badge-light-danger py-2 ms-3'>
                      Calendar Last Sync:{' '}
                      {hostPropertyData?.data?.hostPropertyPMS[0]?.lastControlOn
                        ? new Date(
                            hostPropertyData?.data?.hostPropertyPMS[0]?.lastControlOn
                          ).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })
                        : 'Unknown'}
                    </span>
                  )}
                {(hostPropertyData?.data?.pmsReferenceId == null ||
                  !hostPropertyData?.data?.hostPropertyAutomation.usePms) &&
                  hostPropertyData?.data?.hostPropertyICals.length > 0 && (
                    <span className='badge badge-light-danger py-2 ms-3'>
                      Calendar Last Sync:{' '}
                      {hostPropertyData?.data?.hostPropertyICals[0]?.lastControlOn
                        ? new Date(
                            hostPropertyData?.data?.hostPropertyICals[0]?.lastControlOn
                          ).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })
                        : 'Unknown'}
                    </span>
                  )}
                {hostPropertyData?.data?.hostPropertyICals.length == 0 &&
                  hostPropertyData?.data?.hostPropertyPMS.length == 0 && (
                    <span className='badge badge-light-danger py-3 ms-3'>
                      Calendar Last Sync: Unknown
                    </span>
                  )}
              </div>
              <div className='d-flex'>
                <ButtonToolbar className='me-6'>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-`} style={{width: 300}} className='d-flex flex-wrap'>
                        {isAutoEnabledLoading ? (
                          <Spinner animation='border' />
                        ) : (
                          <>
                            <strong className='mb-5 fs-6'>
                              Client Status:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data.hostStatus == 'Active'
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Client Property Status:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data.hostPropertyStatus == 'Active'
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Automatic Schedule:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data.isAutomaticSchedule
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Available Vendor:{' '}
                              <span>{isAutoEnabled?.data.availableVendorByZipCodeCount}</span>
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              has PMS:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data?.hostPropertyPms?.isValid
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              has ICAL:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data?.hostPropertyIcals?.some(
                                        (item: any) => item.isValid == true
                                      )
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Service Offer: <span>{isAutoEnabled?.data.serviceOfferCount}</span>
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Calender : <span>{isAutoEnabled?.data.calendarCount}</span>
                            </strong>
                            <br />
                          </>
                        )}
                      </Tooltip>
                    }
                  >
                    <span className='svg-icon svg-icon-primary svg-icon-2x d-flex align-items-center ms-3'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        version='1.1'
                      >
                        <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                          <rect x='0' y='0' width='24' height='24' />
                          <circle fill='#000000' opacity='0.3' cx='12' cy='12' r='10' />
                          <rect fill='#000000' x='11' y='10' width='2' height='7' rx='1' />
                          <rect fill='#000000' x='11' y='7' width='2' height='2' rx='1' />
                        </g>
                      </svg>
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
                {hostPropertyData?.data?.hostProperty.canVendorEdit && (
                  <>
                    <button
                      className='btn btn-light-warning btn-sm me-6'
                      onClick={() =>
                        history.push('/clients/property/checklists/' + encryptText(propertyId))
                      }
                    >
                      Checklists
                    </button>
                    <button
                      className='btn btn-danger btn-sm me-6'
                      onClick={() => dispatch(OpenDeletePropertyModal(propertyId))}
                    >
                      Delete Property
                    </button>
                  </>
                )}
                <button className='btn btn-dark btn-sm me-6' onClick={refreshCalender}>
                  Sync Calendar
                </button>
                <button
                  className='btn btn-info btn-sm'
                  onClick={() => {
                    setShowResetJobsModal(true)
                  }}
                >
                  Reset Jobs
                </button>
              </div>
            </div>
          )}
          {!propertyId ? (
            // <StepWizard nav={<StepNav stepNames={['Location', 'Automation Settings', 'Property Detail', 'Rooms']} />} transitions={{
            //     enterRight: '',
            //     enterLeft: '',
            //     exitRight: '',
            //     exitLeft: ''
            // }}>
            //     <LocationStep missingField={missingField} location={{
            //         address: hostPropertyData?.data?.hostProperty?.address,
            //         city: hostPropertyData?.data?.hostProperty?.city,
            //         state: hostPropertyData?.data?.hostProperty?.state,
            //         country: hostPropertyData?.data?.hostProperty?.country,
            //         latitude: hostPropertyData?.data?.hostProperty?.latitude,
            //         longitude: hostPropertyData?.data?.hostProperty?.longitude,
            //         suite: hostPropertyData?.data?.hostProperty?.suite,
            //         zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
            //     }} pmsReferenceId={hostPropertyData?.data?.pmsReferenceId} handleChangeHostProperty={handleChangeHostProperty} />
            //     <AutomationStep missingField={missingField} type={type} sameDayBooking={hostPropertyData?.data?.hostProperty?.sameDayBooking} hostPropertyAutomation={{ ...hostPropertyData?.data?.hostPropertyAutomation, isAutomaticCalendarSync: true, isAutomaticSchedule: true }} handleChangeHostProperty={handleChangeHostProperty} />
            //     <PropertyDetailStep missingField={missingField} values={{
            //         parkingDescription: hostPropertyData?.data?.hostProperty?.parkingDescription,
            //         cleaningProviderId: hostPropertyData?.data?.hostProperty?.cleaningProviderId,
            //         hasDryer: hostPropertyData?.data?.hostProperty?.hasDryer,
            //         hasLaundry: hostPropertyData?.data?.hostProperty?.hasLaundry,
            //         hasParking: hostPropertyData?.data?.hostProperty?.hasParking,
            //         hasStorage: hostPropertyData?.data?.hostProperty?.hasStorage,
            //         sameDayBooking: hostPropertyData?.data?.hostProperty?.sameDayBooking,
            //         numberOfCleaner: hostPropertyData?.data?.hostProperty?.numberOfCleaner,
            //         hasProQutoeRequest: hostPropertyData?.data?.hostProperty.hasProQuoteRequest,
            //         hasPlusQuoteRequest: hostPropertyData?.data?.hostProperty.hasPlusQuoteRequest,
            //     }} hostPropertyAccess={hostPropertyData?.data?.hostPropertyAccess}
            //         hostPropertyOthers={hostPropertyData?.data?.hostPropertyOthers} hostPropertyStorage={hostPropertyData?.data?.hostPropertyStorage} handleChangeHostProperty={handleChangeHostProperty} />
            //     <RoomBedStep handleChangeHostProperty={handleChangeHostProperty} loading={loading} handlePostProperty={handlePostProperty} hostPropertyBedrooms={hostPropertyData?.data?.hostPropertyBedrooms} numberOfBeds={hostPropertyData?.data?.hostProperty?.numberOfBeds} numberOfBedrooms={hostPropertyData?.data?.hostProperty?.numberOfBedrooms} numberOfBathrooms={parseFloat(hostPropertyData?.data?.hostProperty?.numberOfBathrooms)} pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId} />
            // </StepWizard>
            <></>
          ) : (
            <Tabs
              defaultActiveKey='property-detail'
              id='uncontrolled-tab-example'
              className='mb-3 p-3'
              onSelect={handleChangeTab}
            >
              <Tab eventKey='property-detail' title='Property Detail'>
                <LocationStep
                  missingField={true}
                  editMode={true}
                  location={{
                    address: hostPropertyData?.data?.hostProperty?.address,
                    city: hostPropertyData?.data?.hostProperty?.city,
                    state: hostPropertyData?.data?.hostProperty?.state,
                    country: hostPropertyData?.data?.hostProperty?.country,
                    latitude: hostPropertyData?.data?.hostProperty?.latitude,
                    longitude: hostPropertyData?.data?.hostProperty?.longitude,
                    suite: hostPropertyData?.data?.hostProperty?.suite,
                    zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
                  }}
                  staticMapUrl={hostPropertyData?.data?.hostProperty?.staticMapUrl}
                  pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                  handleChangeHostProperty={handleChangeHostProperty}
                />

                <div className='p-3 m-3'>
                  <RoomBedStep
                    disabled={hostPropertyData?.data?.hostProperty?.cleaningProviderId === 2}
                    editMode={true}
                    handleChangeHostProperty={handleChangeHostProperty}
                    hostPropertyBedrooms={hostPropertyData?.data?.hostPropertyBedrooms}
                    numberOfBeds={hostPropertyData?.data?.hostProperty?.numberOfBeds}
                    numberOfBedrooms={hostPropertyData?.data?.hostProperty?.numberOfBedrooms}
                    numberOfBathrooms={parseFloat(
                      hostPropertyData?.data?.hostProperty?.numberOfBathrooms
                    )}
                    pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                  />
                </div>

                <PropertyDetailStep
                  missingField={true}
                  editMode={true}
                  propertyType={propertyType}
                  values={{
                    parkingDescription: hostPropertyData?.data?.hostProperty?.parkingDescription,
                    cleaningProviderId: hostPropertyData?.data?.hostProperty?.cleaningProviderId,
                    isMarketplace: hostPropertyData?.data.hostProperty.isMarketplace,
                    hasDryer: hostPropertyData?.data?.hostProperty?.hasDryer,
                    hasLaundry: hostPropertyData?.data?.hostProperty?.hasLaundry,
                    hasParking: hostPropertyData?.data?.hostProperty?.hasParking,
                    hasStorage: hostPropertyData?.data?.hostProperty?.hasStorage,
                    sameDayBooking: hostPropertyData?.data?.hostProperty?.sameDayBooking,
                    numberOfCleaner: hostPropertyData?.data?.hostProperty?.numberOfCleaner,
                    hasProQuoteRequest: hostPropertyData?.data?.hostProperty.hasProQuoteRequest,
                    hasPlusQuoteRequest: hostPropertyData?.data?.hostProperty.hasPlusQuoteRequest,
                  }}
                  hostPropertyAccess={hostPropertyData?.data?.hostPropertyAccess}
                  hostPropertyOthers={hostPropertyData?.data?.other}
                  hostPropertyStorage={hostPropertyData?.data?.hostPropertyStorages}
                  handleChangeHostProperty={handleChangeHostProperty}
                />
              </Tab>
              {!editDisabled && propertyType !== 'pending' && (
                <Tab eventKey='job-history' title='Jobs'>
                  {selectedTab === 'job-history' && <JobHistoryStep />}
                </Tab>
              )}

              <Tab eventKey='automation' title='Automation Settings'>
                <AutomationStep
                  missingField={missingField}
                  editMode={true}
                  type={type}
                  providerId={hostPropertyData?.data?.hostProperty?.cleaningProviderId}
                  hostPropertyICals={hostPropertyData?.data?.hostPropertyICals}
                  sameDayBooking={hostPropertyData?.data?.hostProperty?.sameDayBooking}
                  hostPropertyAutomation={{
                    ...hostPropertyData?.data?.hostPropertyAutomation,
                    isAutomaticCalendarSync:
                      hostPropertyData?.data.hostPropertyAutomation.isAutomaticSchedule,
                  }}
                  pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                  handleChangeHostProperty={handleChangeHostProperty}
                />
              </Tab>
              {propertyType !== 'pending' && (
                <Tab eventKey='permission' title='Staff Access'>
                  {selectedTab === 'permission' && (
                    <PermissionStep
                      providerType={hostPropertyData?.data?.hostProperty?.cleaningProviderId}
                      hostId={hostPropertyData?.data?.hostProperty?.hostId}
                    />
                  )}
                </Tab>
              )}
              {propertyType !== 'pending' && (
                <Tab eventKey='staff-priority' title='Staff Prioritization'>
                  {selectedTab === 'staff-priority' && <StaffPriorityStep />}
                </Tab>
              )}
              {propertyType !== 'pending' && (
                <Tab eventKey='reported-issues' title='Reported Issues'>
                  {selectedTab === 'reported-issues' && <ReportedIssuesStep />}
                </Tab>
              )}
              {propertyId && (
                <Tab eventKey='turnovers' title='Turnovers '>
                  {selectedTab === 'turnovers' && hostPropertyData && (
                    <TurnoversStep
                      propertyId={propertyId}
                      timeZone={hostPropertyData?.data?.hostProperty?.timeZone}
                    />
                  )}
                </Tab>
              )}
              {/* {propertyId && <Tab eventKey="logs" title="Logs">
                                {selectedTab === "logs" && hostPropertyData && isAutoEnabled?.data.jobLogs && <JobLogsStep data={isAutoEnabled?.data.jobLogs} />}
                            </Tab>} */}
            </Tabs>
          )}
        </Card.Body>
        {propertyId &&
        !editDisabled &&
        selectedTab !== 'job-history' &&
        selectedTab !== 'permission' &&
        selectedTab !== 'staff-priority' &&
        selectedTab !== 'property-offered-services' ? (
          <Card.Footer className='d-flex align-items-center justify-content-between'>
            <div />
            <button className='btn btn-success' disabled={loading} onClick={handleUpdateProperty}>
              Save Changes {loading ? <Spinner size='sm' animation='border' /> : null}
            </button>
          </Card.Footer>
        ) : null}
      </Card>
      <Modal
        show={showResetJobsModal}
        onHide={() => {
          setShowResetJobsModal(false)
        }}
        centered
      >
        <Modal.Body>
          Are you sure you want to reset all future jobs and calendar records?
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end'>
          <button
            className='btn btn-link text-warning me-3'
            onClick={() => {
              setShowResetJobsModal(false)
            }}
          >
            Cancel
          </button>
          <button className='btn btn-danger' onClick={handleResetJobs}>
            Reset Jobs
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PropertyAddWizard
