import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {
  LiveJobResponse,
  MarketplaceListResponse,
} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import MarketplaceCard from './MarketplaceCard'

const Marketplace = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)

  return (
    <>
      <PageTitle>Quote Requests</PageTitle>
      <div className='pt-3'>
        <MarketplaceCard />
      </div>
    </>
  )
}

export default Marketplace
