import { Column, DataGrid, Export, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import React, { Dispatch, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import config from '../../../../config/config.json';
import { JobStatusType } from '../../../../enums/JobEnum';
import { IJobDetail } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import * as deleteDialogActionCreators from '../../../../redux/actionCreators/deleteDialogActionCreators';
import { DeleteActions } from '../../../../redux/actionTypes/deleteDialogTypes';
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton';
import EmptyTable from '../../../../umut-components/Icons/EmptyTable';
import { decryptText, getDateFilter, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../../../utils/util';
import ReviewTab from './ReviewTab/ReviewTab';

type Props = {
    data: any,
    refetch: () => void,
    jobEventsActive: boolean,
    jobDetail: IJobDetail
}


const ServiceTable: React.FC<Props> = ({ data, refetch, jobEventsActive, jobDetail }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const tableRef = useRef<DataGrid>(null)
    const dispatch = useDispatch<Dispatch<DeleteActions>>();



    const displayDeleteButton = (cellData: any) => {
        return (
            <DeleteButton iconWidth="20" iconHeight="20" deleteFn={() => {
                dispatch(deleteDialogActionCreators.openDeleteDialog(
                    async () => {
                        try {
                            await ApiCalls.deleteJobService(cellData?.data?.id);
                            dispatch(deleteDialogActionCreators.closeDeleteDialog());
                            refetch();
                        }
                        catch (err: any) {

                        }
                    },
                    `Are you sure you want to delete ${cellData?.data?.serviceTypeName + (cellData?.data?.note ? (' (' + cellData?.data?.note + ')') : '')}?`,
                    'Delete Service?'
                ))
            }} />
        )
    }

    const displayStatus = (cellData: any) => {
        return (
            <strong className={`text-${cellData?.data?.jobServiceStatusTypeName === "Pending" ? "warning" : cellData?.data?.jobServiceStatusTypeName === "Completed" ? "success" : "dark"}`}>{cellData?.data?.jobServiceStatusTypeName}</strong>
        )
    }

    const calculateStartOn = (cellValue: any) => {
        return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone);
    }

    const calculateFinishedOn = (cellValue: any) => {
        return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone);
    }


    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone);
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone);
    }
    const calculateStaffFullName = (cellValue: any) => {
        if (cellValue?.staff)
            return cellValue?.staff?.firstName + ' ' + cellValue?.staff?.lastName
    }


    if (data?.length === 0) {
        return <EmptyTable title="No Services" />
    }



    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>

            <DataGrid
                id={"services" + jobId}
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                ref={tableRef}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(65vh - 60px)'}
                className='mt-6'
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Job Services")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Services: {0}" column='serviceTypeName' summaryType='count' />
                </Summary>

                <Column dataField="serviceTypeName" caption='Type' minWidth={250} cssClass='cls' />
                <Column dataField='serviceTypeDescription' caption='Description' minWidth={255} cssClass='cls' />
                <Column dataField="jobServiceStatusTypeName" cellRender={displayStatus} caption='Status' minWidth={210} cssClass='cls' />
                <Column dataField="note" minWidth={140} cssClass='cls' />
                {/* <Column dataField="staff" caption="Staff" cellRender={calculateStaffFullName} /> */}
                <Column visible={jobDetail?.jobStatusTypeName !== JobStatusType.PENDING && jobDetail?.jobStatusTypeName !== JobStatusType.SCHEDULED} dataField="startedOn" caption="Started At" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateStartOn} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('startedOn')} />
                </Column>
                <Column visible={jobDetail?.jobStatusTypeName !== JobStatusType.PENDING && jobDetail?.jobStatusTypeName !== JobStatusType.SCHEDULED} dataField="finishedOn" caption="Finished At" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateFinishedOn} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('finishedOn')} />
                </Column>
                {jobEventsActive && <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />}
                <MasterDetail autoExpandAll={true} enabled={true} render={(data: any) => <ReviewTab jobEventsActive={jobEventsActive} data={data} />} />
            </DataGrid >

        </div >

    )

}


export default ServiceTable;