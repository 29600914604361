import React, {useEffect} from 'react'
import {useState} from 'react'
import {Card, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {IHostPropertyCheckList} from '../../../../interfaces/Checklist'
import DeleteDialog from '../../../../umut-components/Modals/DeleteDialog'
import ChecklistAddDialog from './ChecklistAddDialog'
import ChecklistCloneDialog from './ChecklistCloneDialog'
import ChecklistEditDialog from './ChecklistEditDialog'
import ChecklistTable from './ChecklistTable'
import ApiCalls from '../../../../network/ApiCalls'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {decryptText} from '../../../../utils/util'
import {PageTitle} from '../../../../../_metronic/layout/core'

const PropertyChecklists: React.FC = () => {
  let {propertyId}: any = useParams()
  propertyId = decryptText(propertyId)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])
  const [checklistAddModalOpen, setChecklistAddModalOpen] = useState(false)
  const [checklistCloseModalOpen, setChecklistCloneModalOpen] = useState(false)

  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    refetch: refetchChecklists,
  } = useQuery(
    ['Get Checklist By Property Id', propertyId],
    () => ApiCalls.getHostPropertyChecklists(propertyId),
    {refetchOnWindowFocus: false}
  )
  const {data: propertyDetailData} = useQuery(
    'Get Property by Id',
    () => ApiCalls.getHostPropertyById(propertyId),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const {data: masterChecklistData} = useQuery(
    ['Get Admin Master Checklist', propertyId],
    () => ApiCalls.getMasterChecklists(),
    {refetchOnWindowFocus: false, enabled: true, cacheTime: 100000}
  )

  const handleCloseChecklistAddModal = () => {
    setChecklistAddModalOpen(false)
  }

  const handleCloseChecklistCloneModal = () => {
    setChecklistCloneModalOpen(false)
    refetchChecklists()
  }

  const handleAddPropertySuccess = () => {
    setChecklistAddModalOpen(false)
    refetchChecklists()
  }

  const refreshPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (checklistData) {
      setChecklists(checklistData?.data)
    }
  }, [checklistData])

  return (
    <>
      <PageTitle>Checklist</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Header>
            <Card.Title className='text-muted'>
              {propertyDetailData?.data?.hostProperty?.name}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {checklistLoading ? (
              <div className='d-flex justify-content-center'>
                <Spinner animation='border' />
              </div>
            ) : checklists.length > 0 ? (
              <ChecklistTable
                data={checklists}
                refetchChecklists={refetchChecklists}
                refreshPage={refreshPage}
              />
            ) : (
              <div className='d-flex h-100 justify-content-center align-items-center fw-bold'>
                No checklist found for this property
              </div>
            )}
          </Card.Body>
          <Card.Footer className='d-flex align-items-center justify-content-end'>
            <div />
            <button
              className='btn btn-info'
              style={{height: '100%'}}
              onClick={() => {
                setChecklistCloneModalOpen(true)
              }}
            >
              Clone From Turnify
            </button>
            <button
              className='btn btn-success ms-3'
              style={{height: '100%'}}
              onClick={() => {
                setChecklistAddModalOpen(true)
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Checklist
            </button>
          </Card.Footer>
        </Card>
      </div>
      <ChecklistCloneDialog
        modalOpen={checklistCloseModalOpen}
        modalClose={handleCloseChecklistCloneModal}
        masterCheckLists={masterChecklistData?.data}
        hostPropertyId={propertyId}
      />
      <ChecklistAddDialog
        modalOpen={checklistAddModalOpen}
        handleClose={handleCloseChecklistAddModal}
        handleAddPropertySuccess={handleAddPropertySuccess}
      />
      <ChecklistEditDialog refetchChecklists={refetchChecklists} />
      <DeleteDialog />
    </>
  )
}

export default PropertyChecklists
