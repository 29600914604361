import React, {useEffect, useState} from 'react'

type GroupSubCheckbox = {
  label: string
  value: any
}

type GroupCheckbox = {
  label: string
  value: any
  sublist: GroupSubCheckbox[]
}

interface ITextInput {
  onValueChange: (val: any[]) => void
  data: GroupCheckbox[]
  placeholder?: string
  selectedValues?: string[] | number[] | any[]
  classNames?: string
  type?: string
  min?: string
  max?: string
  step?: number
  disabled?: boolean
  errorText?: string
  characterLimit?: number
}
export const GroupedCheckbox: React.FC<ITextInput> = ({
  onValueChange,
  data,
  placeholder,
  selectedValues,
  classNames,
  type,
  disabled,
  errorText,
}) => {
  const [selectedMainChecks, setSelectedMainChecks] = useState<any>([])

  useEffect(() => {
    const list = []
    const raw = selectedValues != undefined ? [...selectedValues] : []
    if (raw.length > 0) {
      for (const mainItem of data) {
        if (mainItem.sublist.length > 0) {
          if (mainItem.sublist.every((v: any) => raw.includes(v.value))) {
            list.push(mainItem.value)
          }
        }
      }
    }
    setSelectedMainChecks(list)
  }, [data, selectedValues])

  const controlIsMainChecked = (val: any) => {
    if (selectedMainChecks.length > 0) {
      const find = selectedMainChecks.includes(val)
      if (find) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const controlIsChecked = (val: any) => {
    const raw = selectedValues != undefined ? [...selectedValues] : []
    if (raw.length > 0) {
      const find = raw.includes(val)
      if (find) {
        return true
      } else {
        return false
      }
    }
  }

  const selectMainCheck = (item: any) => {
    const raw = selectedValues != undefined ? [...selectedValues] : []
    const isChecked = selectedMainChecks.includes(item.value)
    if (isChecked) {
      for (const val of item.sublist) {
        var findIndex = raw.findIndex((v) => v === val.value)
        if (findIndex > -1) {
          raw.splice(findIndex, 1)
        }
      }
    } else {
      for (const val of item.sublist) {
        var find = raw.find((v) => v === val.value)
        if (!find) {
          raw.push(+val.value)
        }
      }
    }

    onValueChange(raw)
  }

  const selectSubCheck = (e: any) => {
    const raw = selectedValues != undefined ? [...selectedValues] : []
    var findIndex = raw.findIndex((v) => v === +e.target.value)
    if (findIndex > -1) {
      raw.splice(findIndex, 1)
    } else {
      raw.push(+e.target.value)
    }

    onValueChange(raw)
  }

  return (
    <div>
      {data.length > 0 && (
        <>
          {data.map((item: any, k: number) => {
            if (item.sublist.length > 0) {
              return (
                <div className='form-check form-check-solid fv-row mb-4'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id={'maincheck-' + k}
                    value={item.value}
                    checked={controlIsMainChecked(item.value)}
                    onChange={(e) => {
                      selectMainCheck(item)
                    }}
                  />
                  <label className='form-check-label fw-bold ps-2 fs-6' htmlFor={'maincheck-' + k}>
                    {item.label}
                  </label>
                  {item.sublist.map((subitem: any, sk: number) => {
                    return (
                      <div className='form-check form-check-solid fv-row mt-2 mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={'subcheck-' + k + '-' + sk}
                          value={subitem.value}
                          onChange={selectSubCheck}
                          checked={controlIsChecked(subitem.value)}
                        />
                        <label
                          className='form-check-label fw-bold ps-2 fs-6'
                          htmlFor={'subcheck-' + k + '-' + sk}
                        >
                          {subitem.label}
                        </label>
                      </div>
                    )
                  })}
                </div>
              )
            }
          })}
        </>
      )}
      {errorText && <small className='text-danger'>{errorText}</small>}
    </div>
  )
}
