import {Dispatch, FC, useImperativeHandle, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import ApiCalls from '../../../../network/ApiCalls'
import {
  convertUTCDateToLocalDate,
  decryptText,
  formatDate,
  toConvertedDate,
  toRequestTimeFormat,
} from '../../../../utils/util'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
} from 'devextreme-react/data-grid'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import {IReVendorStaffAssign} from '../../../../network/PostRequestModels/ReAssignRequests'
import config from '../../../../config/config.json'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import dayjs from 'dayjs'
import {AssignAction} from '../../../../redux/actionTypes/assignTypes'
import * as assignActionCreators from '../../../../redux/actionCreators/assignActionCreators'

type Props = {
  data: any
}
const StaffAccordionTab = React.forwardRef(({data}: Props, ref) => {
  let {jobId}: any = useParams()
  const {user} = useSelector((state: RedusxAppState) => state.user)

  const assignDispatch = useDispatch<Dispatch<AssignAction>>()

  const [staffList, setStaffList] = useState([])
  const {jobDetail}: any = useSelector((state: RedusxAppState) => state.job)
  jobId = decryptText(jobId) ?? jobDetail.id

  const {
    data: suitableStaffs,
    isLoading,
    error,
  } = useQuery(
    ['Get Suitable Staff', user.userSection.vendorId],
    () => ApiCalls.getAvailableStaff(user.userSection.vendorId, 11, jobId, data?.data.duration),
    {cacheTime: 50000, refetchOnWindowFocus: true}
  )

  const getAssingObject = (): IReVendorStaffAssign => {
    let reAssignRequest: IReVendorStaffAssign = {
      jobId: Number(jobId),
      vendorId: user.userSection.vendorId,
      cleanerIds: staffList,
    }
    return reAssignRequest
  }

  const selectStaff = (cellValue: any) => {
    setStaffList(cellValue.selectedRowsData.map((element: any) => element.userId))
    if (cellValue.selectedRowsData.at?.(-1)?.availableTime?.start) {
      const utcOffset = dayjs
        .tz(
          cellValue.selectedRowsData.at?.(-1)?.availableTime?.start,
          jobDetail?.hostProperty!.city.timezone
        )
        .utcOffset()
      const scheduleTz = new Date(
        dayjs
          .utc(cellValue.selectedRowsData.at?.(-1)?.availableTime?.start)
          .utcOffset(utcOffset)
          .format(config.date_time_format)
      )
      assignDispatch(assignActionCreators.scheduleStartChange(scheduleTz))
    } else {
      assignDispatch(
        assignActionCreators.scheduleStartChange(
          dayjs(
            convertUTCDateToLocalDate(
              jobDetail?.cleaningPeriodStart,
              jobDetail?.hostProperty!.city.timezone
            )
          ).toString()
        )
      )
    }
  }

  const toStaffDetail = (cellValue: any) => {
    if (cellValue?.row?.data) {
      assignDispatch(assignActionCreators.openAddAssignDialog(cellValue?.row?.data))
    }
  }

  const renderProfilePhoto = (cellValue: any) => {
    return (
      <ProfilePhotoTemp
        profilePhotoPath={cellValue?.data?.profilePhotoPath}
        firstName={cellValue?.data?.firstName}
        lastName={cellValue?.data?.lastName}
        width={45}
        height={45}
        borderRadius='50%'
      />
    )
  }
  const calculateFullName = (cellValue: any) => {
    return cellValue?.firstName + ' ' + cellValue?.lastName
  }

  const calculateAvailableStartTime = (cellValue: any) => {
    if (cellValue?.availableTime?.start) {
      return toConvertedDate(
        cellValue?.availableTime?.start,
        jobDetail?.hostProperty!.city.timezone
      )
    } else {
      return toConvertedDate(jobDetail?.cleaningPeriodStart, jobDetail?.hostProperty!.city.timezone)
    }
  }

  const calculateAvailableFinishTime = (cellValue: any) => {
    return toConvertedDate(cellValue?.availableTime?.finish, jobDetail?.hostProperty!.city.timezone)
  }

  const clickParag = () => {
    return <p className='text-primary'>Click to see the scheduler</p>
  }
  useImperativeHandle(ref, () => ({getAssingObject}))

  return (
    <>
      <div className='border'>
        <DataGrid
          id='host-staffs'
          keyExpr='id'
          onRowPrepared={(e) => {
            e.rowElement.classList.add('data-grid-row')
          }}
          dataSource={suitableStaffs?.data}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          onCellClick={toStaffDetail}
          onSelectionChanged={selectStaff}
          height={'calc(35vh)'}
        >
          <HeaderFilter visible={true} />
          <Selection mode='multiple' showCheckBoxesMode='always' />
          <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
          <Paging defaultPageSize={50} />

          <Column minWidth={50} cellRender={renderProfilePhoto} cssClass='cls' />
          <Column
            dataField='firstName'
            calculateCellValue={calculateFullName}
            caption='Fullname'
            minWidth={250}
            dataType='string'
            cssClass='cls'
          />

          {/* <Column dataField="availableTime.start" caption="Available Start" dataType="date" format={config.date_time_format_dev_extreme} calculateCellValue={calculateAvailableStartTime} width={200} cssClass='cls' />
                    <Column dataField="availableTime.finish" caption="Available Finish" dataType="date" format={config.date_time_format_dev_extreme} calculateCellValue={calculateAvailableFinishTime} width={250} cssClass='cls' />

                    <Column dataField="offDays" caption="Off Days" width={150} cssClass='cls' /> */}
          {/* <Column dataField="offTime" caption="Off Time" width={500} cssClass='cls' /> */}

          <Column dataField='email' dataType='string' minWidth={350} cssClass='cls' />
          <Column dataField='phoneNumber' dataType='string' minWidth={300} cssClass='cls' />

          <Column
            dataField='hasPermission'
            caption='Permission'
            dataType='boolean'
            minWidth={150}
            cssClass='cls'
          />
          <Column cellRender={clickParag} minWidth={200} cssClass='cls' />
        </DataGrid>
      </div>
    </>
  )
})

export default React.memo(StaffAccordionTab)
