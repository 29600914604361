import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { DayNumbers } from '../../../../enums/DayEnum';
import { IJobDetail } from '../../../../interfaces/Job';
import { IExceptionalDay } from '../../../../interfaces/Staff';
import ApiCalls from '../../../../network/ApiCalls';
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton';
import EmptyTable from '../../../../umut-components/Icons/EmptyTable';
import DateTimePicker from '../../../../umut-components/Inputs/DateTimePicker';
import Seperator from '../../../../umut-components/Seperator/Seperator';
import { decryptText, convertUTCDateToLocalDate } from '../../../../utils/util';
import OffDayEffectedJob from './OffDayEffectedJob';

const days = DayNumbers;

type ExceptionalDayType = {
    exceptionalDay: IExceptionalDay,
    refetch: () => void,
}
type CalendarProps = {
    day: string,
    offDays: any[],
    dayName: string,
    handleOffDays: (day: number, isOffDay: boolean) => void,
    loading: { dayId: number, loading: boolean },
    userId: number

}

const Calendar: React.FC<CalendarProps> = ({ day, offDays, dayName, loading, handleOffDays, userId }) => {
    const [effectedModal, setEffectedModal] = useState(false)
    const [data, setData] = useState<IJobDetail[]>([])
    const [checkJobLoading, setcheckLoading] = useState(false)


    const isOffDay = offDays.some(offDay => offDay == day);

    const effectedModalClose = () => { setEffectedModal(false) }

    const handleCheckJob = async () => {
        if (!isOffDay) {
            setcheckLoading(true)
            const response = await ApiCalls.getOffDayEffectedJob(userId, Number(day))
            setcheckLoading(false)
            if (response) {
                setData(response?.data?.data)
                if (response.data?.count !== 0) {
                    setEffectedModal(true)
                } else {
                    handleOffDays(Number(day), isOffDay)
                }
            }
        } else {
            setcheckLoading(false)

            handleOffDays(Number(day), isOffDay)
        }

    }
    const checkedSave = () => {
        handleOffDays(Number(day), isOffDay)
        effectedModalClose()
    }

    return (
        <>
            <div className={`d-flex flex-column m-5 border bg-light-${!isOffDay ? 'success' : 'danger'} p-2 shadow`} style={{ width: 180, borderRadius: "6px" }}>
                <div className='form-check form-switch d-flex flex justify-content-between border-bottom align-items-center border-secondary'>
                    <strong className="">{dayName}</strong>
                    {loading.dayId == Number(day) && loading?.loading || checkJobLoading ? <Spinner animation="grow" size="sm" variant="success" /> : <input style={{ width: 30, height: 15 }} className="form-check-input" type="checkbox" onChange={() => {
                        handleCheckJob()
                    }} checked={!isOffDay} disabled={loading.loading && checkJobLoading} />}
                </div>
                <strong className={`d-flex justify-content-center pt-2 text-${isOffDay ? 'danger' : 'success'}`}>{isOffDay ? 'Off Day' : 'Working'}</strong>
            </div>
            <OffDayEffectedJob modalOpen={effectedModal} modalClose={effectedModalClose} checkedSave={checkedSave} data={data} />
        </>
    )
}

const ExceptionalDay: React.FC<ExceptionalDayType> = ({ exceptionalDay, refetch }) => {
    const [deleteLoading, setDeleteLoading] = useState(false)

    const deleteOffTime = async () => {
        try {
            setDeleteLoading(true)
            await ApiCalls.deleteStaffOffTime(exceptionalDay.id)
            refetch()
            setDeleteLoading(false)
        } catch (error: any) {
            console.log(error?.response?.message?.data);

        }
    }
    return (
        <div className='d-flex flex-column m-6 bg-light-danger shadow rounded p-3' style={{ width: 400 }}>
            <div className="d-flex justify-content-between align-items-center">
                <span className="svg-icon svg-icon-danger svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M3.5,3 L5,3 L5,19.5 C5,20.3284271 4.32842712,21 3.5,21 L3.5,21 C2.67157288,21 2,20.3284271 2,19.5 L2,4.5 C2,3.67157288 2.67157288,3 3.5,3 Z" fill="#000000" />
                        <path d="M6.99987583,2.99995344 L19.754647,2.99999303 C20.3069317,2.99999474 20.7546456,3.44771138 20.7546439,3.99999613 C20.7546431,4.24703684 20.6631995,4.48533385 20.497938,4.66895776 L17.5,8 L20.4979317,11.3310353 C20.8673908,11.7415453 20.8341123,12.3738351 20.4236023,12.7432941 C20.2399776,12.9085564 20.0016794,13 19.7546376,13 L6.99987583,13 L6.99987583,2.99995344 Z" fill="#000000" opacity="0.3" />
                    </g>
                </svg></span>
                <strong className='d-flex justify-content-center pb-2 border-secondary ms-5'>{`${convertUTCDateToLocalDate(exceptionalDay.offStartDateTime, exceptionalDay.timeZone)} - ${convertUTCDateToLocalDate(exceptionalDay.offEndDateTime, exceptionalDay.timeZone)}`}</strong>
                <DeleteButton iconWidth="15" iconHeight="15" classNames="btn btn-sm btn-danger" disabled={deleteLoading} spinner={deleteLoading} deleteFn={deleteOffTime} />
            </div>
        </div>

    )
}

const OffDayTab: React.FC = () => {
    let { staffId }: any = useParams()
    staffId = decryptText(staffId)
    const { data, isLoading, error, refetch } = useQuery(['Get Staff Off Days', staffId], () => ApiCalls.getStaffOffDays(staffId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: staffId ? true : false });
    const { data: staffExceptionalData, isLoading: staffExceptionalLoading, error: staffExceptionalError, refetch: offTimeReFetch } = useQuery(['Get Staff Exceptional Off Days', staffId], () => ApiCalls.getStaffOffTime(staffId), { cacheTime: 500000, refetchOnWindowFocus: false });
    const [offDays, setOffDays] = useState([]);
    const [exceptionalDays, setExceptionalDays] = useState<IExceptionalDay[]>([]);
    const [modalOpen, setModalOpen] = useState(false)
    const [toogleLoading, settoogleLoading] = useState({ dayId: 0, loading: false })


    const handleOffDays = async (dayId: number, isOffDay: boolean) => {
        if (staffId)
            try {
                settoogleLoading({ dayId: dayId, loading: true })
                if (isOffDay) {
                    let item = data?.data?.data.filter((item: any) => item.weekDay == dayId)[0]
                    await ApiCalls.deleteStaffOffDays(item.id)
                } else {
                    await ApiCalls.postStaffOffDays({ userId: Number(staffId), weekDay: dayId })
                }
                await refetch()
                settoogleLoading({ dayId: dayId, loading: false })
            } catch (error: any) {
            }
    }

    const modalClose = () => { setModalOpen(false) }

    useEffect(() => {
        if (data) {
            setOffDays(data?.data?.data?.map((offday: any) => offday?.weekDay));
        }
    }, [data])


    useEffect(() => {
        if (staffExceptionalData) {
            setExceptionalDays(staffExceptionalData?.data?.data);
        }
    }, [staffExceptionalData])

    if (isLoading || staffExceptionalLoading) {
        <div>Loading...</div>
    }

    if (error) {
        <div>Error</div>
    }

    return (
        <>
            <div className='d-flex flex-row justify-content-center flex-wrap'>
                <Seperator text='Regular' />
                {isLoading ? <Spinner className="mb-10" animation="border" /> : Object.entries(days).map(([key, value]) => <Calendar dayName={key} day={value} offDays={offDays} handleOffDays={handleOffDays} loading={toogleLoading} userId={staffId} />)}
                <Seperator text='Exceptional' />

                <div className="d-flex justify-content-center w-100 flex-wrap">
                    {exceptionalDays.length !== 0 ? exceptionalDays.map(day => <ExceptionalDay refetch={offTimeReFetch} exceptionalDay={day} />) : <EmptyTable title="No Exceptional Time" />}
                </div>
                <div className="d-flex w-100 justify-content-end">
                    <Button className="btn-info" onClick={() => setModalOpen(true)}>
                        Create Exceptional Off Day
                    </Button>
                </div>

            </div>
            {modalOpen && <ExceptionalModal staffId={staffId} modalOpen={modalOpen} close={modalClose} refetch={offTimeReFetch} />}
        </>
    )
}


type ModalProps = {
    modalOpen: boolean,
    close: () => void,
    staffId: number,
    refetch: () => void
}
const ExceptionalModal: FC<ModalProps> = ({ modalOpen, close, staffId, refetch }) => {
    const [offDateTime, setOffDateTime] = useState({ offStartDateTime: '', offEndDateTime: '' })
    const [reqLoading, setReqLoading] = useState(false)
    const [isTimeValid, setIsTimeValid] = useState(false);

    const handleOffTime = async () => {
        try {
            if (offDateTime.offStartDateTime && offDateTime.offEndDateTime && staffId) {
                setReqLoading(true)
                await ApiCalls.postStaffOffTime({ offStartDateTime: offDateTime.offStartDateTime, offEndDateTime: offDateTime.offEndDateTime, userId: Number(staffId) })
                setReqLoading(false)
                refetch()
                close()
            }
        } catch (error: any) {
            setReqLoading(false)
            console.log(error?.response?.message?.data);
        }
    }

    useEffect(() => {
        let startDate = offDateTime.offStartDateTime !== '' ? dayjs(offDateTime.offStartDateTime) : undefined;
        let endDate = offDateTime.offEndDateTime !== '' ? dayjs(offDateTime.offEndDateTime) : undefined;
        if (startDate && endDate) {
            let valid = endDate.diff(startDate, 'minutes') < 0;
            setIsTimeValid(true);
        }
        else {
            setIsTimeValid(false);
        }
    }, [offDateTime])

    return (
        <Dialog open={modalOpen} onClose={close} maxWidth='sm' fullWidth={true} style={{ overflowY: 'unset' }}>
            <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
                Exceptional Off Day
                <button type="button" className="close" onClick={close}>
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            </DialogTitle>
            <DialogContent style={{ overflowY: 'unset' }}>
                <p className="fw-bold">Start Time</p>
                <DateTimePicker onChange={(date: any) => { setOffDateTime({ ...offDateTime, offStartDateTime: date }) }} min={dayjs().add(-1, 'hour').toISOString()} />
                <p className="mt-5 fw-bold">End Time</p>
                <DateTimePicker dateOutOfRangeMessage={'End Time cannot be earlier than Start Time'} onChange={(date: any) => { setOffDateTime({ ...offDateTime, offEndDateTime: date }) }} min={offDateTime.offStartDateTime} />
                <Button disabled={reqLoading || !isTimeValid} onClick={handleOffTime} className="btn btn-success w-100 mt-5">Save {reqLoading && <Spinner animation="border" size="sm" />}</Button>
            </DialogContent>
        </Dialog >
    )
}
export default OffDayTab;